export function toggleFileFormModal(  )
{
  return function (dispatch) {
    dispatch({type:"TOGGLE-FILE-FORM-MODAL", });
  }
}

export function deleteFileIDSave( id, categoryID )
{
  return function (dispatch) {
    dispatch({type:"SAVE-FILE-ID-FOR-DELETE", id, categoryID });
  }
}

export function deleteFileFromCategory( )
{
  return function (dispatch) {
    dispatch({type:"DELETE-FILE-FROM-CATEGORY",  });
  }
}

export function setCategoriesList( )
{
  return function (dispatch, getState ) {
    let { getCategories } = getState();
    let list =
        getCategories.success &&
        getCategories.success.data.payload.data;
    dispatch({type:"SET-CATEGORIES-LIST", list });
  }
}
