import React from "react";
import store from "../../../../../Store"
import {connect} from "react-redux";
import { equalWhenStringified } from "../../../../../helpers/StringHelper"
import {
  setDefaultChainRegionForm,
  setChainRegionDataForUpdate
} from "./ChainRegionsForm-Action"
import getChainRegion from "../../../../../ajax/actions/chain-regions/chain_region_get";
import updateChainRegion from "../../../../../ajax/actions/chain-regions/chain_region_put";
import createChainRegion from "../../../../../ajax/actions/chain-regions/chain_region_post";

class ChainRegionsFormWorker extends React.Component
{
  componentDidMount() {
    let { modal } = this.props;
    let { mode, id } = modal;
    if ( id ) {
      store.dispatch(getChainRegion( id ));
    }
    if(mode === "create") {
      store.dispatch(setDefaultChainRegionForm());
    }
  }

  componentDidUpdate(prev)
  {
    let {  dispatchAPI, modal } = this.props;
    let { id } = modal;

    if(!equalWhenStringified(this.props.getChainRegion, prev.getChainRegion)) {
      let {success} = this.props.getChainRegion;
      if(success) {
        store.dispatch(setChainRegionDataForUpdate());
      }
    }

    if ( dispatchAPI ) {
      if ( !prev.dispatchAPI ) {
        if(id ) {
          store.dispatch(updateChainRegion(id));
        } else {
          store.dispatch(createChainRegion());
        }
      }
    }
  }

  render()
  {
    return null
  }
}
function mapStateToProps(state) {
  let { chainRegionsForm, createChainRegion, updateChainRegion, getChainRegion, chainRegionsList } = state;
  let { modal } = chainRegionsList.data;
  let {dispatchAPI} = chainRegionsForm.data;

  return { chainRegionsForm, dispatchAPI, createChainRegion, updateChainRegion, getChainRegion, chainRegionsList, modal
  };
}
export default connect( mapStateToProps )( ChainRegionsFormWorker );
