import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import getChats from "./chats_get";
import {cancelChatFormDispatch, setDefaultChatForm} from "../../../components/pages/orders/chat/Chat-Actions";
import {ConfigErrorAlert} from "../../../Config";
import Alert from "react-s-alert";

function _success(success)
{
    return { type: 'POST_CHAT_SUCCESS', success };
}
function _error(error)
{
    return { type: 'POST_CHAT_ERROR', error };
}
function _processing(processing)
{
    return { type: 'POST_CHAT_PROCESSING', processing };
}

function createChat( order_id )
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true));

        let {chatState} = getState();
        axios({
            url: apiBaseUrl(`api/orders/${order_id}/messages`),
            method: "post",
            dataType: 'json',
            data: chatState.data.payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        })
        .then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false));
            // handleSuccess(res);
            store.dispatch( getChats(  order_id ) );
            store.dispatch( setDefaultChatForm() );
        }).catch(function(error){
            dispatch(_error(error));
            store.dispatch( cancelChatFormDispatch() )
            dispatch(_processing(false));
            if ( error && error.response.status === 422 ) {
                let message = ``;
                Object.keys(error.response.data.payload.message).map(key =>

                    message = error.response.data.payload.message[key][0]
                );
                Alert.error(`Error 422  - ${ message }`, ConfigErrorAlert );
                // console.log(` <| error |> `,{error} )
            }
        });
    }
}

export default createChat;