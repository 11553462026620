import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { ConfigSider } from "../../Config";
import { connect } from "react-redux";
import { ucFirst } from "../../helpers/StringHelper";
import ChainAdminInfo from "./ChainAdminInfo";
import StoreManagerInfo from "./StoreManagerInfo";
import RegionalDirectorInfo from "./RegionalDirectorInfo";
import TechnicianInfo from "./TechnicianInfo";
import store from "../../Store";
import logoutUser from "../../ajax/actions/user/user_logout_post";
import Loading from "../shared/loaders/Loading";
import i18next from "i18next";
import { approvalStatusData, langOptions } from "../../static-data/SettingData";
import InfiniteDropdown from "../shared/form/InfiniteDropdown";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { initializeIcons } from "@fluentui/react/lib/Icons";

class Sidebar extends React.Component {
  state = {
    width: window.innerWidth,
    logoutClicked: false,
  };

  onClick = () => {
    let { width } = this.state;
    if (width <= 991) {
      document.querySelector("body").classList.remove("menu-open");
    }
  };

  setWidth = () => {
    this.setState({ width: window.innerWidth });
  };

  logOut = () => {
    if (!this.state.logoutClicked) {
      this.setState({ logoutClicked: true }, () =>
        store.dispatch(logoutUser())
      );
    }
  };

  onLangChange = (lang, item) => {
    // console.log(" <| lang |> ", lang);
    localStorage.setItem("lang", item.key);
    window.location.reload();
  };

  componentDidMount() {
    window.addEventListener("resize", this.setWidth);
  }

  componentDidUpdate(lastProps) {
    let { logoutUser } = this.props;
    if (logoutUser !== lastProps.logoutUser) {
      let { error } = logoutUser;
      if (error) {
        this.setState({ logoutClicked: false });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setWidth);
  }

  render() {
    let { role, name, userDetail } = this.props;
    let { MenuItems } = ConfigSider;
    let { logoutClicked } = this.state;
    const lang = localStorage.getItem("lang");
    const dropdownStyles = {
      dropdown: { width: 105 },
    };
    initializeIcons();
    // console.log(" <| lang |> ", lang);
    return (
      <div className="sidebar" id="sidebar">
        <Link to="/orders" className="logo">
          <img src={logo} alt="" />
        </Link>

        {role === "chain-admin" && <ChainAdminInfo data={userDetail.success} />}

        {role === "store-manager" && (
          <StoreManagerInfo data={userDetail.success} />
        )}

        {role === "regional-director" && (
          <RegionalDirectorInfo data={userDetail.success} />
        )}

        {role === "technician" && <TechnicianInfo data={userDetail.success} />}

        <ul className="upper-menu">
          {MenuItems[role].map((item, key) => {
            return (
              <li key={key} onClick={this.onClick}>
                <Link to={item.link}>
                  {i18next.t("sidebar." + item.label, { returnObjects: true })}{" "}
                </Link>
              </li>
            );
          })}
        </ul>
        <ul className="lower-menu">
          <li onClick={this.onClick}>
            <Link to="/upload-files">
              <span>
                {i18next.t("sidebar.files", { returnObjects: true })}
              </span>
            </Link>
          </li>
          <li onClick={this.onClick}>
            <Link to="/jour-nummer">
              <span>
                {i18next.t("sidebar.emergency-number", { returnObjects: true })}
              </span>
            </Link>
          </li>
          <li onClick={this.onClick}>
            <Link to="/account">
              <span>
                {i18next.t("sidebar.my-account", { returnObjects: true })}
              </span>{" "}
              <span>({ucFirst(name)}) </span>
            </Link>
          </li>
          <li>
            <Dropdown
              text="language"
              className="lang-dropdown"
              _value="value"
              _label="name"
              options={langOptions}
              placeholder="Language"
              styles={dropdownStyles}
              onChange={this.onLangChange}
            />
          </li>
          <li onClick={this.logOut}>
            <a className="cursor-point text-white">
              {i18next.t("sidebar.logout", { returnObjects: true })}{" "}
              {logoutClicked && <Loading xs />}
            </a>
          </li>
        </ul>
      </div>
    );
  }
}
function mapStateToProps(state) {
  let { userDetail, logoutUser } = state;
  let role = userDetail.success && userDetail.success.role;
  let name = userDetail.success && userDetail.success.name;
  let { entity_id, entity_type } = userDetail.success && userDetail.success;
  return { userDetail, role, name, entity_id, entity_type, logoutUser };
}
export default connect(mapStateToProps)(Sidebar);
