export function onChatFormChange(name, value) {
  return function (dispatch){
    dispatch({type:"ON-CHAT-FORM-CHANGE", name, value});
  }
}

export function onChatFormSubmit() {
  return function (dispatch) {
    dispatch({type:"ON-CHAT-FORM-SUBMIT" });
  }
}

export function cancelChatFormDispatch() {
  return function (dispatch) {
    dispatch({ type:"CANCEL-CHAT-FORM-DISPATCH" });
  }
}
export function setDefaultChatForm() {
  return function (dispatch) {
    dispatch({ type:"SET-DEFAULT-CHAT-FORM" });
  }
}

export function setUnreadChatCount(count) {
  return function (dispatch) {
    dispatch({ type:"SET-UNREAD-CHAT-COUNT",count });
  }
}

export function setInitialLoadingMode() {
  return function (dispatch) {
    dispatch({ type:"SET-INITIALLOADING-MODE",});
  }
}

export function onHandleScrollLimitIncrease() {
  return function (dispatch) {
    dispatch({ type:"ON-SCROLL-LIMIT-INCREASE",});
  }
}

export function setUnseenChatIDs() {
  return function (dispatch, getState) {
    let {getChats} = getState();
    let {success} = getChats;
    let chats = success && success.data && success.data.payload
        && success.data.payload.data.data;
    dispatch({ type:"SET-UNSEEN-CHAT-IDS", chats });
  }
}

export function chatFormServerValidationErrors( validationErrors ) {
  return function (dispatch) {
    dispatch({type:"CHAT-FORM-SERVER-VALIDATION-ERRORS", validationErrors });
  }
}
