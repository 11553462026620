import axios from "axios";
import { apiBaseUrl, urlTrailArray } from "../../../helpers/UrlHelper";
import { loginToken } from "../../../helpers/LocalStorageHelper";
import { handleError } from "../../../helpers/ErrorHelper";
import { clone } from "../../../helpers/DataHelper";

function _success(success) {
    return { type: "GET_ORDERS_SUCCESS", success };
}

function _error(error) {
    return { type: "GET_ORDERS_ERROR", error };
}

function _processing(processing) {
    return { type: "GET_ORDERS_PROCESSING", processing };
}

function getOrders() {
    return (dispatch, getState) => {
        let { orderList, getChain } = getState();
        let params = clone(orderList.data.params);

        let adminChainMode = urlTrailArray().includes("chains");
        if (adminChainMode) {
            params.chain_id =
                getChain.success && getChain.success.data.payload.id;
        }
        dispatch(_processing(true));

        const request = axios({
            url: apiBaseUrl("api/orders"),
            method: "get",
            params,
            headers: {
                Authorization: "Bearer " + loginToken(),
                lang: localStorage.getItem("lang") || "en",
            },
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
            })
            .catch(function (error) {
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError(error);
            });

        return {
            type: "GET_ORDER_SUCCESS",
            payload: request,
        };
    };
}

export default getOrders;
