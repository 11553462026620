import { StoreFormState } from "./StoreForm-State";
import {
  errorsList,
  errorsPresent,
  mergeServerValidationErrors,
} from "../../../../helpers/ErrorHelper";
import { hasOnlyDigits, isValidEmail } from "../../../../helpers/StringHelper";
import { endOfURL } from "../../../../helpers/UrlHelper";

export default function storeFormReducer(state = StoreFormState, action) {
  let { data } = { ...state };
  let { validationErrors, name, value, stores, chain_id } = action;
  switch (action.type) {
    case "ON-STORE-FORM-CHANGE":
      if (name === "region_id") {
        data.payload[name] = +value;
      } else {
        data.payload[name] = value;
      }
      buildErrors();
      return { ...state, data };

    case "ON-STORE-MULTI-VALUE-CHANGE":
      if (value) {
        if (data.payload[name].includes(value)) {
          data.payload[name] = data.payload[name].filter(
            (item) => item !== value
          );
        } else {
          value && data.payload[name].push(value);
        }
      } else {
        data.payload[name] = [];
      }
      buildErrors();
      return { ...state, data };

    case "SET-STORE-DATA-FOR-UPDATE":
      data.payload = {
        store_name: stores.store_name,
        phone: stores.phone,
        email: stores.email,
        invoice_address: stores.invoice_address,
        contact_person: stores.contact_person,
        chain_id: stores.chain_id,
        region_id: stores.region_id,
      };
      buildErrors();
      return { ...state, data };

    case "SET-DEFAULT-STORE-FORM":
      data = {
        payload: {
          store_name: "",
          phone: "",
          email: "",
          invoice_address: "",
          contact_person: "",
          chain_id: chain_id,
          region_id: 0,
        },
        errors: [],
        errorsPresent: false,
        submitted: false,
        dispatchAPI: false,
        mode: endOfURL(),
        message: "",
      };
      buildErrors();
      return { ...state, data };

    case "CANCEL-STORE-FORM-DISPATCH":
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors();
      return { ...state, data };

    case "ON-STORE-FORM-SUBMIT":
      data.submitted = true;
      buildErrors();
      if (!data.errorsPresent) data.dispatchAPI = true;
      return { ...state, data };

    case "STORE-FORM-SERVER-VALIDATION-ERRORS":
      validationErrors &&
        Object.keys(validationErrors).map(
          (key) =>
            (data.errors[key] = [
              { message: ` - ${validationErrors[key][0]}`.slice(0, -1) },
            ])
        );
      data.dispatchAPI = false;
      return { ...state, data };

    default:
      return state;
  }

  function buildErrors() {
    data.errors = errorsList(errors);
    data.errorsPresent = errorsPresent(errors);
  }

  function errors(field) {
    let { payload, submitted, serverValidationErrors } = data;
    let errors = {};
    Object.keys(payload).map((key) => (errors[key] = []));

    if (submitted) {
      if (field === "store_name") {
        if (!_(field)) newError(field, 0, "is required");
      }

      if (field === "phone") {
        if (!_(field)) newError(field, 0, "is required");
        else if (!hasOnlyDigits(_(field)))
          newError(field, 0, "must be a number");
        else if (_(field).length < 9)
          newError(field, 0, "must be atleast 9 digits");
      }

      //if (field === "email") {
      //if (!_(field).length) newError(field,0, "is required");
      //if (_(field).length > 42) newError(field, 0, "is too Long");
      //if (!isValidEmail(_(field))) newError(field, 0, "is invalid");
      // }

      if (field === "contact_person") {
        if (!_(field)) newError(field, 0, "is required");
      }

      if (field === "invoice_address") {
        if (!_(field)) newError(field, 0, "is required");
      }
    }

    errors = mergeServerValidationErrors(errors, serverValidationErrors);

    return field ? errors[field] : errors;

    function newError(field, step, message) {
      errors && errors[field].push({ step, message });
    }

    function _(field) {
      return data.payload[field];
    }
  }
}
