import React from "react";
import Select from "react-select"
import {fromRecord, inRecord, isArray} from "../../../helpers/DataHelper";
import {names, ucFirst, unslufigy} from "../../../helpers/StringHelper";
import i18next from "i18next";

class SearchableSelect extends React.Component
{
    label()
    {
        let { name, label, errors } = this.props;
        let errorsPresent = errors && errors.length;
        if ( label ) return (
            <label className={ names("label-text", errorsPresent && "has-error", "animated fadeIn faster") }>
                { label } { errorsPresent ? errors[0].message + "." : null }
            </label>
        );
        else {
            if ( name ) {
                let _label = unslufigy( name );
                if ( name.includes("_id")) _label = name.split("_id")[0];
                return (
                    <label className={ names("label-text", errorsPresent && "has-error", "animated fadeIn faster") }>
                        { ucFirst( _label ) } { errorsPresent ? errors[0].message + "." : null }
                    </label>
                )
            }
        }
    }

    labelText()
    {
        let { label, name } = this.props;
        if ( label ) return label;
        else {
            if ( name ) {
                let _label = unslufigy( name );
                if ( name.includes("_id")) _label = ucFirst( name.split("_id")[0] );
                return _label
            }
        }
    }

    changed = ( item ) =>
    {
        let { onChange, name, isMulti } = this.props;
        /*if (item && item.value)
            onChange({target: {name, value: item.value}});
        else
            onChange({target: {name, value: ""}});*/

        if(isMulti) {
            if (onChange) {
                let filterVal = item.map(v => v.value);
                onChange({target: {name, value: filterVal}});
            }
        }else {
            if (onChange) {
                onChange({target: {name, value: (item && item.value) ? item.value : "" }});
            }
        }
    };

    render()
    {
        let {
            name, options, value, clearable, notSearchable, placeholder, _value, _label,
            errors, required, processing, noFormGroup, disabled, isMulti, translate
        } = this.props;

        let _options = [];
        options && isArray( options ) && options.map(option => {
            if (translate !== false) {
                _options.push({ value: option[ _value ], label: i18next.t(option[ _label ], {returnObjects:true}) })
            } else {
            _options.push({ value: option[ _value ], label: option[ _label ] })
            }
        });

        _value = { value, label:
                inRecord( _options, "value", "==", value ) ?
                    fromRecord( _options, "value", "==", value ).label  : null };

        /* if ( !_options.length ) {
             _options.unshift({ value: "nothing", label: "-- No ${ this.labelText() } --", isDisabled: true });
         }
         else _options.unshift({ value: "nothing", label: "-- Select ${ this.labelText() } --", isDisabled: true });*/

        return (

            <div className={ names(
                !noFormGroup && "form-group",
                errors && errors.length && "has-error",
                disabled && "item-disabled",
                required && "required",
                processing && "processing") }>

                { this.label() }

                <Select name={ name }
                        value={ !value ? placeholder : _value }
                        options={ _options }
                        isClearable={ clearable }
                        onChange={ this.changed }
                        placeholder={ placeholder }
                        isSearchable={ !notSearchable }
                        isDisabled={ disabled }
                        isMulti={ isMulti }
                />
            </div>

        )

    }
}
export default SearchableSelect;
