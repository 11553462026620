import { endOfURL } from "../../../../helpers/UrlHelper"

const DefaultUserFormState = {
  payload:{
    name: "",
    email: "",
    phone_no: "",
    role: "",

    entity_type: "",
    entity_id: [],

    chain_id: [],
    region_id: [],
    store_id: []

  },
  selected_chain_options :[],
  selected_region_options : [],
  selected_store_options : [],
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
  mode:endOfURL(),
  message: ""

};

export const UserFormState = { data: DefaultUserFormState };
