export function accountFormServerValidationErrors( validationErrors ) {
  return function (dispatch) {
    dispatch({type:"ACCOUNT-FORM-SERVER-VALIDATION-ERRORS", validationErrors });
  }
}

export function onAccountFormSubmit( ) {
  return function (dispatch) {
    dispatch({type:"ON-ACCOUNT-FORM-SUBMIT" });
  }
}

export function onAccountFormChange(name, value) {
  return function (dispatch){
      dispatch({type:"ON-ACCOUNT-FORM-CHANGE", name, value});
  }
}


export function cancelAccountFormDispatch() {
  return function (dispatch) {
    dispatch({ type:"CANCEL-ACCOUNT-FORM-DISPATCH" });
  }
}

export function setDefaultAccountForm() {
  return function (dispatch){
    dispatch({type:"SET-DEFAULT-ACCOUNT-FORM"});
  }
}

export function setAccountDataForUpdate() {
  return function (dispatch, getState) {
    let { userDetail } = getState();
    let accounts = userDetail && userDetail.success;
    if(accounts) {
      dispatch({type:"SET-ACCOUNT-DATA-FOR-UPDATE",  accounts:JSON.parse(JSON.stringify(accounts)) });
    }
  }
}
