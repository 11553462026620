import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import store from "../../../../Store";
import getStores from "../../../../ajax/actions/stores/stores_get";

class StoreWorker extends React.Component {
  componentDidMount() {
    let { entity_id, entity_type } = this.props;
    if (entity_type === "region") {
      store.dispatch(getStores(null, null, entity_id, null));
    } else {
      store.dispatch(getStores(null, entity_id, null, null));
    }
  }

  componentDidUpdate(prev) {
    let { deleteStore, getChain } = this.props;

    if (deleteStore !== prev.deleteStore) {
      let { success } = deleteStore;
      if (success) {
        store.dispatch(getStores());
      }
    }

    if (getChain !== prev.getChain) {
      let { success } = getChain;
      if (success) {
        store.dispatch(getStores());
      }
    }
  }

  render() {
    return null;
  }
}
function mapStateToProps(state) {
  let { deleteStore, getChain, userDetail } = state;
  let entity_id = userDetail.success && userDetail.success.entity_id;
  let entity_type = userDetail.success && userDetail.success.entity_type;
  return { deleteStore, getChain, userDetail, entity_id, entity_type };
}
export default withRouter(connect(mapStateToProps)(StoreWorker));
