import i18n from "i18next"
import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import common_en from "./assets/translate/en.json"
import common_sv from "./assets/translate/sv.json"
import common_fi from "./assets/translate/fi.json"
const resources = {
    en: {
        translation: common_en
    },
    sv: {
        translation: common_sv
    },
    fi: {
        translation: common_fi
    },
}
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en",
        debug: true,
        interpolation: {
            escapeValue: false
        }
    })
export default i18n
