import { endOfURL } from "../../../../helpers/UrlHelper"

const DefaultRoleFormState = {
  payload:{
    title:"",
    slug:"",
  },
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
  mode:endOfURL(),
  message: ""

};

export const RoleFormState = { data: DefaultRoleFormState }
