export function setUserRole(role) {

  return function (dispatch) {
    dispatch({type:"SET-USER-ROLE", role});
  }
}

export function setEntityDetails(details) {

  return function (dispatch) {
    dispatch({type:"SET-ENTITY-DETAILS", details});
  }
}
export function setEntities(entities) {

  return function (dispatch) {
    dispatch({type:"SET-ENTITIES", entities});
  }
}

export function logOutUser() {

  return function (dispatch) {
    dispatch({type:"LOG-OUT-USER"});
  }
}



