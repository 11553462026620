import React from "react";
import { Route, Switch } from "react-router-dom"
import Login from "../pages/login/LoginForm";
import {Redirect} from "react-router";
import NotFound from "../shared/pages/NotFound";
import RedirectTo from "../shared/pages/RedirectTo";
import {urlTrail} from "../../helpers/UrlHelper";

class LoginRoutes extends React.Component
{
    render()
    {
        return (

            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/se/login" component={Login} />
                <Route exact path="/fn/login" component={Login} />
                <Route path="*" render={() => <RedirectTo path={ urlTrail() } />} />
                {/*<Route path="*" component={ NotFound } />*/}
            </Switch>

        )
    }
}
export default LoginRoutes;
