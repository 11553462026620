import { endOfURL } from '../../../../helpers/UrlHelper'

const DefaultWorkTypeFormState = {
  payload:{
    title:"",
    translations:"",
  },
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
  mode:endOfURL(),
  message: ""

};

export const WorkTypeFormState = { data: DefaultWorkTypeFormState }
