import { AccountFormState } from "./AccountForm-State"
import { errorsList, errorsPresent, mergeServerValidationErrors } from "../../../helpers/ErrorHelper"
import { endOfURL } from "../../../helpers/UrlHelper"
import {isObject} from "../../../helpers/DataHelper";

export default function accountFormReducer(state = AccountFormState, action) {

  let { data } = { ...state };
  let { validationErrors, name, value, accounts } = action;
  switch (action.type) {

    case "ON-ACCOUNT-FORM-CHANGE":
      data.payload[name] = value;
      buildErrors();
      return { ...state, data };

    case "SET-ACCOUNT-DATA-FOR-UPDATE":
      data.payload = {
        name: accounts.name,
        email: accounts.email,
        phone_no: accounts.phone_no,
        role: accounts.role,
        entity_type:"",
        entity_id:""
      };
      buildErrors();
      return { ...state, data };

    case "SET-DEFAULT-ACCOUNT-FORM":
      data = {
        payload:{
          name: "",
          email: "",
          phone_no: "",
          role: ""
        },
        errors: [],
        errorsPresent: false,
        submitted: false,
        dispatchAPI: false,
        mode:endOfURL(),
        message: ""
      }
      buildErrors()
      return { ...state, data }

    case "CANCEL-ACCOUNT-FORM-DISPATCH":
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors()
      return {...state, data};

    case "ON-ACCOUNT-FORM-SUBMIT":
      data.submitted = true
      buildErrors()
      if (!data.errorsPresent) data.dispatchAPI = true
      return { ...state, data }

    case "ACCOUNT-FORM-SERVER-VALIDATION-ERRORS":
      validationErrors && isObject( validationErrors ) && Object.keys(validationErrors).map(key =>
        data.errors[key] = [{ message: " - "+validationErrors[key][0].slice(0, -1) }]
      )
      data.dispatchAPI = false
      return { ...state, data }

    default:
      return state
  }

  function buildErrors()
  {
    data.errors = errorsList(errors)
    data.errorsPresent = errorsPresent(errors)
  }

  function errors(field)
  {
    let { payload, submitted, validationErrors } = data;
    let errors = {};
    Object.keys(payload).map(key => errors[key] = []);

    if (submitted) {
      if (field === "name") {
        if (!_(field)) newError(field, 0, "is required")
      }

      if (field === "phone_no") {
        if (!_(field)) newError(field, 0, "is required")
      }
    }

    errors = mergeServerValidationErrors(errors, validationErrors)

    return field ? errors[field] : errors

    function newError(field, step, message) {
      errors && errors[field].push({ step, message })
    }

    function _(field) {
      return data.payload[field]
    }
  }
}

