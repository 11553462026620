export function chainRegionFormServerValidationErrors( validationErrors ) {
  return function (dispatch) {
    dispatch({type:"CHAIN-REGION-FORM-SERVER-VALIDATION-ERRORS", validationErrors });
  }
}

export function onChainRegionFormSubmit( ) {
  return function (dispatch) {
    dispatch({type:"ON-CHAIN-REGION-FORM-SUBMIT" });
  }
}

export function onChainRegionFormChange(name, value) {
  return function (dispatch){
      dispatch({type:"ON-CHAIN-REGION-FORM-CHANGE", name, value});
  }
}

export function cancelChainRegionFormDispatch() {
  return function (dispatch) {
    dispatch({ type:"CANCEL-CHAIN-REGION-FORM-DISPATCH" });
  }
}

export function setDefaultChainRegionForm() {
  return function (dispatch){
    dispatch({type:"SET-DEFAULT-CHAIN-REGION-FORM"});
  }
}

export function setChainRegionDataForUpdate() {
  return function (dispatch, getState) {
    let { getChainRegion } = getState();
    let regions = getChainRegion.success && getChainRegion.success.data && getChainRegion.success.data.payload;
    if(regions) {
      dispatch({type:"SET-CHAIN-REGION-DATA-FOR-UPDATE",  regions:JSON.parse(JSON.stringify(regions)) });
    }
  }
}
