import { OrderDetailState } from "./OrderDetail-State"
import {errorsList, errorsPresent, mergeServerValidationErrors} from "../../../../helpers/ErrorHelper";

export default function orderDetailReducer(state = OrderDetailState, action) {

  let { data } = { ...state };
  let {id, validationErrors, name, value, user_role, order, mode, visible_mode } = action;

  switch (action.type) {

    case "ON-ORDER-UPLOAD-FORM-CHANGE":
      data.files = value;
      buildErrors();
      return { ...state, data };

    case "TOGGLE-ORDER-DETAIL":
      data.payload.order_id = id;
      data.payload.visible = true;
      return { ...state, data };

    case "TOGGLE-CHAT-BOX":
      data.payload.chat_box_visible = visible_mode;
      return { ...state, data };

    case "SET-USER-ROLE-ORDER-DETAIL":
      data.role = user_role;
      return { ...state, data };

    case "SET-ORDER-SUCCESS-CONFIRMATION-MODE":
      data.payload.order_success_confirmation_mode = mode;
      return { ...state, data };

    case "SET-ORDER-DETAIL-FOR-UPDATE":
      data.form_payload = {
        status: order.status,
        technician_id: order.technician_id,
        approval_status: order.approval_status,
        priority: order.priority,
        planned_at:order.planned_at,
        completed_at:order.completed_at
      };
      buildErrors();
      return { ...state, data };

    case "ON-ORDER-DETAIL-FORM-CHANGE":
      data.form_payload[name] = value;
      if(name === "technician_id") {
        data.form_payload.status = "pending";
      }
      buildErrors();
      return { ...state, data };

    case "ON-ORDER-DETAIL-CHAT-FORM-CHANGE":
      data.chatForm[name] = value;
      buildErrors();
      return { ...state, data };

    case "TOGGLE-ORDER-DETAIL-MODAL-CLOSE":
      data.payload.visible = false;
      return { ...state, data };

    case "TOGGLE-ORDER-UPLOAD-MODAL":
      data.payload.uploadModal = visible_mode;
      data.payload.files =  [];
      return { ...state, data };

    case "ON-ORDER-DETAIL-FORM-SUBMIT":
      data.submitted = true;
      buildErrors();
      if (!data.errorsPresent) data.dispatchAPI = true;
      return { ...state, data };

    case "CANCEL-ORDER-DETAIL-EDIT-FORM-DISPATCH":
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors();
      return {...state, data};

    case "ORDERS-FORM-SERVER-VALIDATION-ERRORS":
      Object.keys(validationErrors).map(key =>
          data.errors[key] = [{ message: " - "+validationErrors[key][0].slice(0, -1) }]
      )
      data.dispatchAPI = false;
      return { ...state, data };

    default: return state
  }

  function buildErrors() {
    data.errors = errorsList(errors);
    data.errorsPresent = errorsPresent(errors)
  }

  function errors(field) {
    let { form_payload, submitted, serverValidationErrors, role } = data;
    let errors = {};
    Object.keys(form_payload).map(key => errors[key] = []);

    if (submitted) {
      if(role === "chain-admin" || role === "regional-director") {
        if (field === "approval_status") {
          if (!_(field)) newError(field, 0, "is required")
        }
      }

      if(role === "super-admin" || role === "admin" || role === "technician") {
        if (field === "status") {
          if (!_(field)) newError(field, 0, "is required")
        }
      }
      if(role === "super-admin" || role === "admin" ) {

        /*if (field === "technician_id") {
          if (!_(field)) newError(field, 0, "is required")
        }*/
      }
      if(role === "super-admin" || role === "admin" || role === "technician") {
        /*if (field === "planned_at") {
          if (_("status") === "planned" || _("status") === "revisit-required") {
            if (!_(field)) newError(field, 0, "is required");
          }
        }*/
      }
    }

    errors = mergeServerValidationErrors(errors, serverValidationErrors);

    return field ? errors[field] : errors

    function newError(field, step, message) {
      errors && errors[field].push({ step, message })
    }

    function _(field) {
      return data.form_payload[field]
    }
  }
}
