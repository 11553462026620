import { ChainDashboardState } from "./ChainDashboard-State";

export default function chainDashboardReducer(state = ChainDashboardState, action) {

  let { data } = { ...state };
  let {chain } = action;

  switch (action.type) {

    case "SET-CHAIN-DASHBOARD-DATA":
      data.chain_id = chain.id;
      data.chain_name = chain.chain_name;
      return { ...state, data };

    default: return state
  }
}
