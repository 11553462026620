import React from "react";
import i18next from "i18next";
class StoreManagerInfo extends React.Component {
  render() {
    let { data } = this.props;
    return (
      <div className="sidebar-title">
        <h4>{i18next.t("header.store-manager-dashboard")}</h4>
      </div>
    );
  }
}
export default StoreManagerInfo;
