import store from "../../../../../Store";
import getChainRegions from "../../../../../ajax/actions/chain-regions/chain_regions_get";

export function toggleChainRegionListFilter(e)
{
  return function (dispatch) {
    let { name, value } = e.target;
    dispatch({type:"TOGGLE-CHAIN-REGION-LIST-FILTER", name, value});
    store.dispatch( getChainRegions());
  }
}

export function toggleRegionFormModal( id )
{
  return function (dispatch) {
    dispatch({type:"TOGGLE-REGION-FORM-MODAL", id });
  }
}
