import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import getRoles from "../../../../ajax/actions/role/roles_get";
import store from "../../../../Store";

class RoleWorker extends React.Component
{
  componentDidMount()
  {
    store.dispatch(getRoles());
  }

  componentDidUpdate(prev) {
    let { deleteRole,} = this.props;

    if(deleteRole !== prev.deleteRole) {
      let {success} = deleteRole;
      if(success) {
        store.dispatch(getRoles());
      }
    }
  }

  render()
  {
      return null
  }
}
function mapStateToProps(state) {
   let {deleteRole }  = state;
   return { deleteRole }
 }
export default withRouter(connect(mapStateToProps)( RoleWorker ));
