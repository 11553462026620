import React from 'react';
import MyIcon from "../icons/MyIcon";

class MaintenanceMode extends React.Component
{
  render()
  {
    return (

        <div className="row">
          <div className="col-lg-4 offset-lg-4 col-md-8 offset-md-2 col-12 text-center pd-3 pt-5 ">

            <MyIcon type="tools" width={80} />

            <h4 className="mt-5">App is in maintenance mode.</h4>
            <h4 className="mt-2"> Please try again later.</h4>

            <div className="mt-20">
              <button className="btn btn-secondary btn-in" onClick={() => window.location.href = `/`}>
                Refresh
              </button>
            </div>

          </div>
        </div>

    )
  }
}
export default MaintenanceMode;