import axios from 'axios'
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from "../../../helpers/LocalStorageHelper";
import {handleError} from "../../../helpers/ErrorHelper";
import {clone} from "../../../helpers/DataHelper";

function _success(success) {
    return {type: 'GET_USERS_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_USERS_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_USERS_PROCESSING', processing};
}

function getUsers()
{
    return (dispatch, getState) =>
    {
        dispatch(_processing(true));
        let { userList } = getState();

        let _params = clone( userList.data.params );

        if ( !_params.entity_type ) delete _params.entity_type;
        if ( !_params.entity_id )   delete _params.entity_id;

        if ( _params.chain_id ) {
            _params.entity_type = `chain`;
            _params.entity_id = _params.chain_id;
        }

        if ( _params.region_id ) {
            _params.entity_type = `region`;
            _params.entity_id = _params.region_id;
        }

        if ( _params.store_id ) {
            _params.entity_type = `store`;
            _params.entity_id = _params.store_id;
        }
        const request = axios({
            url:apiBaseUrl(`api/users`),
            method:'get',
            params: _params,
            headers:{
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        }).then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false));

        }).catch(function(error){
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error);
        });

        return {
            type:'GET_USERS_SUCCESS',
            payload:request
        }
    }
}

export default getUsers;