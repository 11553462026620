import errorSound from "./assets/sounds/error.wav";

export const ConfigApp = {
  Name: "Haglunds",
  Production: true, // comment this out for Staging Server, uncomment for Live Server
};

export const ConfigAPI = {
  Live: { EndPoint: "https://api.haglundsbygg.se/" },
  // Live:    { EndPoint: "https://haglundsstg.keplerhosting.cloud/" },
  // Staging: { EndPoint: "https://maninvan.hbgdesignlab.io/" },
  // Staging: { EndPoint: "https://haglundsstg.keplerhosting.cloud/" },
  Staging: { EndPoint: "http://localhost:8000/" },
};
/**
 * for the category path
 * @type {{Staging: {EndPoint: string}, Live: {EndPoint: string}}}
 */
export const ConfigPath = {
  Live: { EndPoint: "https://api.haglundsbygg.se" },
  // Staging: { EndPoint: "https://maninvan.hbgdesignlab.io/" },
  // Staging: { EndPoint: "https://haglundsstgdashboard.keplerhosting.cloud" },
  Staging: { EndPoint: "http://localhost:8000" },
};

export const ConfigSider = {
  MenuItems: {
    "super-admin": [
      { link: "/chains", label: "chains" },
      { link: "/users", label: "users" },
      { link: "/roles", label: "roles" },
      { link: "/work-types", label: "work-types" },
      { link: "/order-status", label: "order-status" },
      { link: "/orders", label: "orders" },
      { link: "/stores/import", label: "import-stores" },
      { link: "/users/bulk-update", label: "bulk-update-users" },
    ],
    admin: [
      { link: "/chains", label: "chains" },
      { link: "/users", label: "users" },
      { link: "/orders", label: "orders" },
      { link: "/stores/import", label: "import-stores" },
    ],
    "chain-admin": [
      { link: "/orders", label: "orders" },
      { link: "/regions", label: "regions" },
      { link: "/stores", label: "stores" },
      { link: "/users", label: "users" },
    ],
    "regional-director": [
      { link: "/orders", label: "orders" },
      { link: "/stores", label: "stores" },
    ],
    "store-manager": [
      { link: "/orders", label: "orders" },
      { link: "/orders/create", label: "create-order" },
    ],
    technician: [{ link: "/orders", label: "orders" }],
  },
};

export const ConfigSpinner = {
  Default: {
    Type: "Circles",
    Color: "#777",
  },
};

export const ConfigErrorAlert = {
  position: "top-right",
  effect: "jelly",
  timeout: 4000,
  beep: errorSound,
  stack: 1,
};
export const ConfigSuccessAlert = {
  position: "top-right",
  effect: "jelly",
  timeout: 4000,
  beep: false,
  stack: 1,
};

export const ConfigModal = {
  width: 650,
  height: 500,
  effect: "fadeInUp",
};
