import React from "react";
import {connect} from "react-redux"
import store from "../../../../Store";
import UploadFileFormWorker from "./UploadFileFormWorker"
import {onUploadFileFormChange, onUploadFileFormSubmit} from "./UploadFileForm-Action";
import SaveButton from "../../../shared/buttons/SaveButton";
import TextField from "../../../shared/form/TextField";
import {ucFirst} from "../../../../helpers/StringHelper";
import TextAreaField from "../../../shared/form/TextAreaField";
import {endOfURL} from "../../../../helpers/UrlHelper";
import UploadField from "../../../shared/form/UploadField";
import SearchableSelect from "../../../shared/form/SearchableSelect";
import i18next from "i18next";

class UploadFileForm extends React.Component {

    fileFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onUploadFileFormSubmit());
    }

    onChange = (e) => {
        store.dispatch(onUploadFileFormChange(e.target.name, e.target.value));
    }

    render() {
        let {uploadFileForm, createCategory, updateCategory, fileList, file_modal, getCategory, loggedInRole, chains } = this.props;
        let {payload, errors} = uploadFileForm.data;
        let {title, description, sub_title, category_name, files, chain_id} = payload;
        let processing = createCategory.processing;
        // console.log(" <| uploadFileForm.data |> ",uploadFileForm.data )
        return (
            <div className={getCategory.processing ? "item-disabled" : null}>
                <div className={ file_modal ? "col-md-11 m-auto" : "offset-lg-1 col-lg-7 col-md-12"}>
                    {/*<h2>{"${file_modal ? "Update " : ucFirst(endOfURL())} File"} </h2>*/}
                    <h2>{ i18next.t(file_modal ? "file.update-file" : endOfURL() === "create" ? "file.create-file" : "file.update-file", {returnObjects:true}) } </h2>
                    <form onSubmit={this.fileFormSubmit}>

                        <TextField name="category_name"
                                   label={i18next.t("file.category-name",{ returnObjects:true })}
                                   value={category_name}
                                   errors={errors.category_name}
                                   onChange={this.onChange}/>

                        <TextField name="title"
                                   value={title}
                                   label={i18next.t("fields.title",{ returnObjects:true })}
                                   errors={errors.title}
                                   onChange={this.onChange}/>

                        <TextField name="sub_title"
                                   value={sub_title}
                                   label={i18next.t("file.sub-title",{ returnObjects:true })}
                                   errors={errors.sub_title}
                                   onChange={this.onChange}/>


                        <TextAreaField name="description"
                                       value={description}
                                       label={i18next.t("description",{ returnObjects:true })}
                                       errors={errors.description}
                                       onChange={this.onChange}/>

                        <SearchableSelect clearable name="chain_id"
                                               options={ chains }
                                               _value="id"
                                               _label="chain_name"
                                              label={i18next.t("chain.chain",{ returnObjects:true })}
                                               value={ chain_id }
                                               errors={errors.chain_id}
                                          placeholder={"-- "+i18next.t("chain.chain",{ returnObjects:true })+" --"}
                                               onChange={ this.onChange } />

                        <UploadField onChange={this.onChange}
                                     name="files"
                                     value={files}
                                     multiple
                                     supportedFormats=".docx, .png, .jpg, .pdf"
                        />

                        <div className="text-center">
                            <SaveButton processing={ processing || updateCategory.processing }/>
                        </div>

                        <UploadFileFormWorker/>

                    </form>
                </div>
            </div>

        )
    }
}

function mapStateToProps(state) {

    let {uploadFileForm, createCategory, getCategory, fileList, updateCategory, userDetail, getChains } = state;
    let {mode, search, errors} = uploadFileForm.data;
    let chains = getChains.success && getChains.success.data.payload.data;
    let loggedInRole = userDetail.success && userDetail.success.role;
    let {file_modal} = fileList.data;
    return {
        uploadFileForm, mode, search, createCategory, getCategory, errors, fileList, file_modal, updateCategory, userDetail, loggedInRole, getChains, chains
    };
}

export default connect(mapStateToProps)(UploadFileForm);
