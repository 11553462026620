import React from 'react';
import store from '../../../Store'
import { withRouter } from 'react-router';
import Loading from "../loaders/Loading";

class PaginationButtons extends React.Component
{
  state = {
    loaderAt: ``
  };

  constructor( props )
  {
    super( props )
    this.unlisten = props.history.listen(() => this.forceUpdate());
  }

  componentWillUnmount()
  {
    this.unlisten();
  }

  onClick( button, page )
  {
    this.setState({ loaderAt: button }, () => {
      let { fetchAction, history } = this.props
      let e = { target: { name: `page`, value: page } }
      fetchAction && store.dispatch( fetchAction( e, history ))
    })
  }

  loaderAt( button )
  {
    let { processing } = this.props;
    let { loaderAt } = this.state;
    return processing && loaderAt === button;
  }

  componentDidUpdate( lastProps )
  {
    if ( lastProps.processing )
      if ( !this.props.processing )
        this.setState({ loaderAt: `` })
  }

  render()
  {
    let { pages } = this.props;
    let { current_page, last_page, total } = pages;
    let showFormerDots = current_page > 2 && current_page !== 3;
    let showRearDots = current_page !== last_page && current_page !== last_page - 1 && last_page - current_page > 2;
    if ( current_page && last_page ) //&& ( current_page !== last_page ) )
      return (

          <tr>
            <td colSpan={3} style={{ borderTop: 0 }}>
              <nav className="pagination-buttons mt-5">

                <ul className="pagination text-center">

                  {/* FIRST PAGE BUTTON */}
                  {current_page > 2 ? (
                      <li className="page-item">
                        <a className={`page-link ${ +current_page === 1 ? `item-disabled` : `` } `}
                           onClick={() => this.onClick(`first`, 1)} >
                          { this.loaderAt(`first`) ?
                              <Loading xs /> : <span className="pagn-btn">1</span> }
                        </a>
                      </li>
                  ) : null }

                  { showFormerDots ? (
                      <li className="page-item">
                        ...
                      </li>
                  ) : null }

                  {/* PREVIOUS PAGE BUTTON */}
                  { current_page !== 1 ? (
                      <li className="page-item">
                        <a className={`page-link ${ +current_page === 1 ? `item-disabled` : `` } `}
                           onClick={() => this.onClick(`previous`, +current_page - 1)} >
                          { this.loaderAt(`previous`) ?
                              <Loading xs /> : <span className="pagn-btn">{current_page - 1}</span> }
                        </a>
                      </li>
                  ) : null }

                  {/* CURRENT PAGE BUTTON */}
                  <li className="page-item">
                    <a className={`page-link item-disabled item-red ${ +current_page === 1 ? `item-disabled` : `` } `}>
                      <span className="pagn-btn">{current_page}</span>
                    </a>
                  </li>

                  {/* NEXT PAGE BUTTON */}
                  { (current_page !== last_page && total > 1) ? (
                      <li className="page-item">
                        <a className={`page-link ${ +current_page === last_page ? `item-disabled` : `` } `}
                           onClick={() => this.onClick(`next`, +current_page + 1)} >
                          { this.loaderAt(`next`) ?
                              <Loading xs /> : <span className="pagn-btn">{current_page + 1}</span> }
                        </a>
                      </li>
                  ) : null }

                  { showRearDots ? (
                      <li className="page-item">
                        ...
                      </li>
                  ) : null }

                  {/* LAST PAGE BUTTON */}
                  {(current_page !== last_page && current_page !== last_page - 1 && total > 1) ? (
                      <li className="page-item">
                        <a className={`page-link ${ +current_page === last_page ? `item-disabled` : `` } `}
                           onClick={() => this.onClick(`last`, last_page)} >
                          { this.loaderAt(`last`) ?
                              <Loading xs /> : <span className="pagn-btn">{last_page}</span>
                          }
                        </a>
                      </li>
                  ) : null }

                </ul>

              </nav>
            </td>
          </tr>

      );
    return null

  }
}

export default withRouter( PaginationButtons );