import store from "../../../../Store";
import getChains from "../../../../ajax/actions/chains/chains_get";
import getChainRegions from "../../../../ajax/actions/chain-regions/chain_regions_get";
import getStores from "../../../../ajax/actions/stores/stores_get";
import {fromRecord, inRecord} from "../../../../helpers/DataHelper";
import {urlTrailArray} from "../../../../helpers/UrlHelper";

export function userFormServerValidationErrors( validationErrors ) {
  return function (dispatch) {
    dispatch({type:"USER-FORM-SERVER-VALIDATION-ERRORS", validationErrors });
  }
}

export function onUserFormSubmit( ) {
  return function (dispatch) {
    dispatch({type:"ON-USER-FORM-SUBMIT" })
  }
}

export function onUserFormMultiValueChange( name, value ) {
  return function (dispatch) {
    dispatch({type:"ON-USER-FORM-MULTI-VALUE-CHANGE", name, value })
  }
}

export function onUserFormChange( name, value )
{
  return function ( dispatch, getState )
  {

    dispatch({ type:"ON-USER-FORM-CHANGE", name, value} );

    let { userDetail, getChain } = getState();
    let loggedInRole = userDetail.success && userDetail.success.role;

    let adminChainMode = urlTrailArray().includes("chains");
    let adminMode = ( loggedInRole === "admin" || loggedInRole === "super-admin" ) && !adminChainMode;
    let chainAdminMode = loggedInRole === "chain-admin";

    let adminChainChainID = getChain.success && getChain.success.data.payload.id;
    let chainAdminChainID = userDetail.success && userDetail.success.entity_detail && userDetail.success.entity_detail.id;

    if ( name === "role" ) {
      // reset all lists
      store.dispatch( getChains( null, "clear"));
      store.dispatch( getChainRegions( null, null, "clear"));
      store.dispatch( getStores( null, null, null, "clear"));

      /*if ( dashboard_chain_id ) {
        store.dispatch( getChainRegions( 500, chainAdminChainID ));
        store.dispatch( getStores( 500, chainAdminChainID ));

      }  else */

      if ( adminChainMode ) {
        dispatch({ type:"ON-USER-FORM-CHANGE", name: "chain_id", value: adminChainChainID } );
        store.dispatch( getChains( 500 ));
        store.dispatch( getChainRegions( 500, adminChainChainID ));
        store.dispatch( getStores( 500, adminChainChainID ));
      }
      else if ( adminMode ) { // if admin is logged in and is not in any dashboard
        if (value === "chain-admin" || value === "regional-director" || value === "store-manager" ) {
          store.dispatch( getChains( 500 ));
        }

      } else if ( chainAdminMode ) { // if chain admin is logged in
        store.dispatch( getChainRegions( 500, chainAdminChainID ));
        store.dispatch( getStores( 500, chainAdminChainID ));
      }
    }
    // if selected chain has no region, clear regions and stores
    if ( name === "chain_id" ) {
      if ( getState().getChains.success.data.payload.data &&
          inRecord( getState().getChains.success.data.payload.data, "id", "==", value ) &&
          !fromRecord( getState().getChains.success.data.payload.data, "id", "==", value ).has_region )
      {
        store.dispatch( getChainRegions( null, null, "clear"));
        store.dispatch( getStores( null, null, null, "clear"));
      }
    }

    if ( name === "region_id" && chainAdminMode ) {
      store.dispatch( getStores( 500, chainAdminChainID, value ));
    }

  }
}

export function cancelUserFormDispatch() {
  return function (dispatch) {
    dispatch({ type:"CANCEL-USER-FORM-DISPATCH" });
  }
}

export function setDefaultUserForm() {
  return function (dispatch){
    dispatch({type:"SET-DEFAULT-USER-FORM"});
    store.dispatch( getChains( null, "clear" ));
    store.dispatch( getChainRegions( null, null, "clear" ));
    store.dispatch( getStores( null, null, null, "clear" ));


  }
}

export function setUserDataForUpdate()
{
  return function (dispatch, getState) {
    let { getUser } = getState();
    let user = getUser && getUser.success && getUser.success.data.payload[0];
    dispatch({type:"SET-USER-DATA-FOR-UPDATE",  users: JSON.parse(JSON.stringify( user )) });

    switch ( user.role ) { // fetch some and clear others

      case "chain-admin":
        store.dispatch( getChains( 500 ));
        store.dispatch( getChainRegions( null, null, "clear" ));
        store.dispatch( getStores( null, null, null, "clear" ));
        break;

      case "regional-director":
        store.dispatch( getChains( 500 ));
        store.dispatch( getChainRegions( 500, user.entity_detail.chain_id ));
        store.dispatch( getStores( null, null, null, "clear" ));
        break;

      case "store-manager":
        store.dispatch( getChains( 500 ));
        store.dispatch( getChainRegions( 500, user.entity_detail.chain_id ));
        store.dispatch( getStores( 500, user.entity_detail.chain_id ));
        break;

      default:
        store.dispatch( getChains( 500, "clear" ));
        store.dispatch( getChainRegions( 500, null, "clear" ));
        store.dispatch( getStores( 500, null, null, "clear" ));
    }

  }
}
