export default function getStoreReducer
  (state = { error:'', success:'', processing: false }, action)
{
  switch (action.type)
  {
    case 'IMPORT_STORES_SUCCESS':
      return {...state, ...{success: action.success}};

    case 'IMPORT_STORES_ERROR':
      return {...state, ...{error: action.error}};

    case 'IMPORT_STORES_PROCESSING':
      return {...state, ...{processing: action.processing}};

    default: return state;
  }
}