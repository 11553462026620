import axios from 'axios';
import {handleError} from '../../../helpers/ErrorHelper'
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken, logOut} from "../../../helpers/LocalStorageHelper";

function _success(success)
{
    return { type: 'LOGOUT_USER_SUCCESS', success };
}
function _error(error)
{
    return { type: 'LOGOUT_USER_ERROR', error };
}
function _processing(processing)
{
    return { type: 'LOGOUT_USER_PROCESSING', processing };
}

function logoutUser()
{
    return ( dispatch ) =>
    {
        dispatch(_processing(true ));
        dispatch(_error(null ));
        dispatch(_success(null ));

        const request = axios({

            url: apiBaseUrl(`api/auth/logout`),
            method: "post",
            dataType: 'json',
            headers:{
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            },
        })
            .then(function(res){
                dispatch(_success(res));
                dispatch(_processing(false));
                if ( res.status === 204 ) logOut()

            }).catch(function(error){
                handleError( error );
                dispatch(_error(error));
                dispatch(_processing(false));
            });

        return {
            type: 'LOGOUT_USER_SUCCESS',
            payload: request
        }
    }
}


export default logoutUser;