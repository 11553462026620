import React from "react";
import logo from "../../assets/images/logo.png";

class Header extends React.Component {
  menuToggle = () => {
    if (document.body.classList.contains("menu-open")) {
      document.body.classList.remove("menu-open");
    } else {
      document.body.classList.add("menu-open");
    }
  };

  render() {
    return (
      <header>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <nav className="navbar main-nav">
                <div className="brandLogo">
                  <a href="/">
                    <img src={logo} alt="" />
                  </a>
                </div>

                <div className={`navbar-toggle-btn`} onClick={this.menuToggle}>
                  <span />
                  <span />
                  <span />
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
