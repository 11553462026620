import React from 'react';
import { names, ucFirst,  unslufigy } from '../../../helpers/StringHelper'
import PropTypes from 'prop-types';

class TextField extends React.Component
{
    placeholder()
    {
        let { placeholder, name, label } = this.props;
        if ( placeholder ) return placeholder;
        if ( label ) return label;
        else {
            if ( name ) {
                let _label = unslufigy( name );
                if ( name.includes(`_id`)) _label = ucFirst( name.split(`_id`)[0] );
                return _label;
            }
        }
    }

    label()
    {
        let { name, label, errors } = this.props;
        let errorsPresent = errors && errors.length;
        if ( label ) return (
            <label className={ names(`label-text`, errorsPresent && `has-error`, `animated fadeIn faster`) }>
                { label } { errorsPresent ? errors[0].message + '.' : null }
            </label>
        );
        else {
            if ( name ) {
                let _label = unslufigy( name );
                if ( name.includes(`_id`)) _label = name.split(`_id`)[0];
                return (
                    <label className={ names(`label-text`, errorsPresent && `has-error`, `animated fadeIn faster`) }>
                        { _label } { errorsPresent ? errors[0].message + '.' : null }
                    </label>
                )
            }
        }
    }

    render()
    {
        let {
            name, size, value, icon, onChange, id, noLabel, labelIcon, addonAfter, disabled, className, errors, required,
            placeholder, addonBefore,
        } = this.props;

        return (

            <div className={ names(`form-group`, `animated fadeIn faster`, errors && errors.length && `has-error`, required && `required`) }>

                { !noLabel ? <>{ this.label() }</> : null }

                <input type="text"
                       className="form-control"
                       size={size || `default`}
                       name={name}
                       disabled={disabled}
                       id={id || name}
                       value={ value }
                       placeholder={this.placeholder()}
                       onChange={ onChange }
                />
            </div>

        )
    }
}
let { string, number, oneOfType } = PropTypes;
TextField.propTypes = { value: oneOfType([ string, number ]) };

export default TextField;