import React from "react";
import store from "../../../../Store";
import {
  onOrderDetailFormSubmit,
  toggleOrderDetailModalClose,
  onOrderDetailFormChange,
} from "./OrderDetail-Actions";
import { connect } from "react-redux";
import { fullDate, justDate } from "../../../../helpers/DateTimeHelper";
import SearchableSelect from "../../../shared/form/SearchableSelect";
import SaveButton from "../../../shared/buttons/SaveButton";
import OrderDetailWorker from "./OrderDetailWorker";
import { approvalStatusData } from "../../../../static-data/SettingData";
import ResponsiveModal from "../../../shared/modals/ResponsiveModal";
import Badge from "../../../shared/badges/Badge";
import DateField from "../../../shared/form/DateField";
import i18next from "i18next";

class OrderUpdateFormModal extends React.Component {
  state = {
    displayErrors: false,
  };

  orderEditFormSubmit = (e) => {
    e.preventDefault();
    store.dispatch(onOrderDetailFormSubmit());
  };

  onChange = (e) => {
    store.dispatch(onOrderDetailFormChange(e.target.name, e.target.value));
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.updateOrder !== prevProps.updateOrder) {
      let { error } = this.props.updateOrder;
      if (error) {
        if (error.response && error.response.status === 422)
          this.setState({ displayErrors: true });
      }
    }
  }

  render() {
    let {
      visible,
      orderData,
      orderStatusAll,
      status,
      user_role,
      errors,
      errorsPresent,
      approval_status,
      technician_id,
      updateOrder,
      technicians,
      getOrder,
      planned_at,
      completed_at,
      priorityArr,
      payload,
    } = this.props;
    let { priority } = payload;
    let { displayErrors } = this.state;
    let { processing } = updateOrder;
    return (
      <ResponsiveModal
        visible={visible}
        closeModal={() => {
          this.setState({ displayErrors: false });
          store.dispatch(toggleOrderDetailModalClose());
        }}
      >
        <div className={getOrder.processing ? "item-disabled" : ""}>
          <OrderDetailWorker />
          {displayErrors && updateOrder.error && (
            <div className="alert alert-danger" role="alert">
              {Object.keys(updateOrder.error.response.data.payload.message).map(
                (key) => {
                  return updateOrder.error.response.data.payload.message[
                    key
                  ].map((item) => {
                    return <p>{item}</p>;
                  });
                }
              )}
            </div>
          )}
          <form onSubmit={this.orderEditFormSubmit}>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>{i18next.t("order.project-number")}</td>
                  <td>{orderData.project_number}</td>
                </tr>
                <tr>
                  <td>{i18next.t("chain.chain")}</td>
                  <td>{orderData.chain && orderData.chain.chain_name}</td>
                </tr>
                <tr>
                  <td>{i18next.t("fields.store")}</td>
                  <td>{orderData.store && orderData.store.store_name}</td>
                </tr>
                <tr>
                  <td>{i18next.t("fields.work-type")}</td>
                  <td>{orderData.worktype_label}</td>
                </tr>
                <tr>
                  <td>{i18next.t("fields.priority")}</td>
                  <td>
                    {/*{orderData.priority_label}*/}

                    {(user_role === "super-admin" ||
                      user_role === "admin" ||
                      user_role === "chain-admin" ||
                      user_role === "regional-director") && (
                      <SearchableSelect
                        name="priority"
                        value={priority}
                        _value="id"
                        _label="title"
                        options={i18next.t("priorityArr", {
                          returnObjects: true,
                        })}
                        errors={errors.priority}
                        onChange={this.onChange}
                        translate={false}
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>{i18next.t("fields.task-created")}</td>
                  <td>{fullDate(orderData.created_at)}</td>
                </tr>
                <tr>
                  <td>{i18next.t("fields.technician")}</td>
                  <td>{orderData.technician_name || "- N/A -"}</td>
                </tr>
                <tr>
                  <td>{i18next.t("fields.status")}</td>
                  <td>
                    <Badge type={orderData.status}>
                      {i18next.t(orderData.status)}
                    </Badge>
                    {orderData.status === "planned" && orderData.planned_at ? (
                      <span>{justDate(orderData.planned_at)}</span>
                    ) : (
                      ""
                    )}
                    {orderData.status === "completed" &&
                    orderData.completed_at ? (
                      <span>{justDate(orderData.completed_at)}</span>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
                <tr>
                  <td>{i18next.t("fields.approval-status")}</td>
                  <td>
                    <Badge type={orderData.approval_status}>
                      {i18next.t(orderData.approval_status)}
                    </Badge>
                    {orderData.approval_status_date && (
                      <span>{fullDate(orderData.approval_status_date)}</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    {(user_role === "super-admin" ||
                      user_role === "admin" ||
                      user_role === "chain-admin" ||
                      user_role === "regional-director") && (
                      <SearchableSelect
                        name="approval_status"
                        value={approval_status}
                        _value="value"
                        _label="value"
                        label={i18next.t("fields.approval-status", {
                          returnObjects: true,
                        })}
                        placeholder={
                          "-- " +
                          i18next.t("fields.approval-status", {
                            returnObjects: true,
                          }) +
                          " --"
                        }
                        options={approvalStatusData}
                        onChange={this.onChange}
                      />
                    )}
                    {(user_role === "super-admin" ||
                      user_role === "admin" ||
                      user_role === "chain-admin" ||
                      user_role === "technician" ||
                      user_role === "regional-director") && (
                      <SearchableSelect
                        name="status"
                        value={status}
                        _value="slug"
                        _label="title"
                        label={i18next.t("fields.status", {
                          returnObjects: true,
                        })}
                        placeholder={
                          "-- " +
                          i18next.t("fields.status", {
                            returnObjects: true,
                          }) +
                          " --"
                        }
                        options={orderStatusAll}
                        onChange={this.onChange}
                      />
                    )}
                    {(user_role === "super-admin" ||
                      user_role === "admin" ||
                      user_role === "chain-admin" ||
                      user_role === "technician") &&
                      (status === "planned" ||
                        status === "revisit-required") && (
                        <DateField
                          name="planned_at"
                          value={planned_at}
                          onChange={this.onChange}
                          errors={errorsPresent && errors.planned_at}
                        />
                      )}
                    {(user_role === "super-admin" ||
                      user_role === "admin" ||
                      user_role === "chain-admin" ||
                      user_role === "technician") &&
                      status === "completed" && (
                        <DateField
                          name="completed_at"
                          value={completed_at}
                          onChange={this.onChange}
                          errors={errorsPresent && errors.completed_at}
                        />
                      )}
                    {(user_role === "super-admin" || user_role === "admin") && (
                      <SearchableSelect
                        name="technician_id"
                        value={technician_id}
                        _value="id"
                        _label="name"
                        label={i18next.t("fields.technician", {
                          returnObjects: true,
                        })}
                        placeholder={
                          "-- " +
                          i18next.t("fields.technician", {
                            returnOjects: true,
                          }) +
                          " --"
                        }
                        options={technicians}
                        onChange={this.onChange}
                        translate={false}
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>{i18next.t("fields.work-leader")}</td>
                  <td>{orderData.chain && orderData.chain.chain_name}</td>
                </tr>
                <tr>
                  <td>{i18next.t("fields.orderer")}</td>
                  <td>{orderData.creator && orderData.creator.name}</td>
                </tr>
                <tr>
                  <td>{i18next.t("fields.main-in-charge")}</td>
                  <td>{orderData.chain && orderData.chain.contact_person}</td>
                </tr>
                <tr>
                  <td colSpan={2} className="text-center">
                    <SaveButton
                      processing={processing}
                      style={{ display: "inline-block" }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </ResponsiveModal>
    );
  }
}

function mapStateToProps(state) {
  let {
    orderDetail,
    getOrder,
    getOrderStatusAll,
    getTechnicians,
    updateOrder,
    route,
    getOrderPriority,
  } = state;
  let { user_role } = route.data;

  let orderData = getOrder.success && getOrder.success.data.payload;

  let orderStatusAll =
    getOrderStatusAll.success && getOrderStatusAll.success.data.payload;

  let technicians =
    getTechnicians.success && getTechnicians.success.data.payload;

  let priorities =
    getOrderPriority.success &&
    getOrderPriority.success.data.payload.priorities;

  let priorityArr = [];

  priorities &&
    Object.keys(priorities).map((key) => {
      priorityArr.push({ id: key, title: priorities[key] });
    });

  let { order_id, visible } = orderDetail.data.payload;
  let { errors, errorsPresent } = orderDetail.data;
  let {
    status,
    priority,
    approval_status,
    technician_id,
    planned_at,
    completed_at,
  } = orderDetail.data.form_payload;

  let payload = orderDetail.data.form_payload;
  return {
    orderDetail,
    order_id,
    visible,
    getOrder,
    orderData,
    getOrderStatusAll,
    orderStatusAll,
    status,
    planned_at,
    completed_at,
    approval_status,
    technician_id,
    getTechnicians,
    technicians,
    updateOrder,
    route,
    user_role,
    errors,
    errorsPresent,
    getOrderPriority,
    priorities,
    priorityArr,
    priority,
    payload,
  };
}

export default connect(mapStateToProps)(OrderUpdateFormModal);
