const DefaultWorkTypeListState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    limit: 25,
    page:   1,
    sort_order:  "desc",
    sort_by: "id",
    q: "",
    all: true,
  },
  modal: {
    mode: "",
    id: ""
  },
  fields: [
    { field: "id", label: "id", sort: true },
    { field: "title", label: "title", sort: true },
  ]

};

export const WorkTypeListState = {data:DefaultWorkTypeListState}
