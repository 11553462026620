const DefaultBulkUserUpdateFormState = {
  payload:{
    data_file: ""
  },
  files: [],
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
};

export const BulkUserUpdateFormState = { data: DefaultBulkUserUpdateFormState };
