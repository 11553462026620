import React from 'react';
import i18next from "i18next";

class AdminDashboard extends React.Component
{
    render()
    {
        return (
            <h1>{i18next.t('welcome',{returnObjects:true})}!</h1>
        )
    }
}
export default AdminDashboard;