import React from "react";
import {connect} from "react-redux"
import store from "../../../Store";
import AccountFormWorker from "./AccountFormWorker"
import {onAccountFormChange, onAccountFormSubmit} from "./AccountForm-Action";
import SaveButton from "../../shared/buttons/SaveButton";
import TextField from "../../shared/form/TextField";
import PasswordForm from "./password/PasswordForm";
import i18next from "i18next";

class AccountForm extends React.Component
{
    accountFormSubmit = (e) =>
    {
        e.preventDefault();
        store.dispatch(onAccountFormSubmit());
    };

    onChange = (e) => {
        store.dispatch(onAccountFormChange(e.target.name, e.target.value));
    };

    render() {


        let { accountForm, updateMyAccount, userData } = this.props;
        let { payload, errors } = accountForm.data;
        let { name, email, phone_no, role } = payload;
        let processing = updateMyAccount.processing;
        return (
            <div className="row">
                <div className="offset-lg-1 col-lg-7 col-md-12">
                    <AccountFormWorker />
                    <h2>{i18next.t( "my-account.my-account", {returnObjects:true} )}</h2>
                    <form onSubmit={this.accountFormSubmit}>
                        {
                            (role === "chain-admin" || role === "store-manager") && (
                                <TextField disabled
                                           label={i18next.t("chain.chain", {returnObjects:true})}
                                           value={userData.entity_detail && userData.entity_detail.chain && userData.entity_detail.chain.chain_name}
                                            />
                            )
                        }
                        {
                            role === "store-manager" && (
                                <TextField disabled
                                           label="Store Manager"
                                           value={userData.entity_detail && userData.entity_detail.store_name}
                                            />
                            )
                        }

                        <TextField name="name"
                                   value={name}
                                   errors={errors.name}
                                   onChange={this.onChange} />


                        <TextField disabled
                                   name="email"
                                   value={email}
                                  />

                        <TextField name="phone_no"
                                   value={phone_no}
                                   errors={errors.phone_no}
                                   onChange={this.onChange} />

                        <TextField disabled
                                   label="role"
                                   value={userData.role_label}
                                    />
                        <div className="text-center">
                            <SaveButton btnClass={"text-uppercase mt-5"} processing={processing}/>
                        </div>
                    </form>
                    <PasswordForm/>
                </div>
            </div>

        )
    }
}

function mapStateToProps(state)
{

    let { accountForm, updateMyAccount, getAccount, getRoles, userDetail} = state;
    let userData = userDetail && userDetail.success;
    let { mode, search, errors } = accountForm.data;
    let roles = getRoles.success && getRoles.success.data.payload.data;
    return {
        accountForm, mode, search, updateMyAccount,
        getAccount, getRoles, roles, errors,userDetail, userData
    };
}

export default connect(mapStateToProps)(AccountForm);
