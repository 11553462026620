import React from "react";
import store from "../../../../Store";
import {withRouter} from "react-router";
import {fewerLimitOptions, limitOptions} from "../../../../static-data/LimitOptions"
import { toggleChainListFilter } from "./ChainList-Actions"
import {connect} from "react-redux";
import SearchableSelect from "../../../shared/form/SearchableSelect";
import i18next from "i18next";

class ChainListFilters extends React.Component
{
  toggleFilter = (e) =>
  {
    store.dispatch(toggleChainListFilter( e));
  }

  render()
  {
    let { chainList } = this.props;
    let {params} = chainList.data;
    return (
      <div className="row table-filter mb-5">
        <div className="col custom-col">
          <div className="form-group">
            <SearchableSelect name="limit"
                              noFormGroup
                              notSearchable
                              label={i18next.t("filters.limit", {returnObjects:true})}
                              options={ fewerLimitOptions  }
                              _value="total"
                              _label="label"
                              value={ params.limit }
                              placeholder={"-- "+i18next.t("filters.limit", {returnObjects:true})+" --"}
                              onChange={ this.toggleFilter } />
          </div>
        </div>

        <div className="col custom-col two-col-block">
          <div className="form-group">
            <label>{i18next.t("filters.search", {returnObjects:true})}</label>
            <input type="text" className="form-control" name="q" placeholder={i18next.t("filters.search", {returnObjects:true})}
                   onChange={this.toggleFilter} value={params.q}/>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  let { chainList } = state;
  return {chainList}
}

export default withRouter(connect(mapStateToProps)( ChainListFilters ));
