export const settingType = [
  { name: "Public", value: "public" },
  { name: "Private", value: "private" },
];

export const hasRegion = [
  { name: "yes", value: "1" },
  { name: "no", value: "0" },
];

export const approvalStatusData = [
  { name: "pending", value: "pending" },
  { name: "approved", value: "approved" },
  { name: "rejected", value: "rejected" },
];

export const langOptions = [
  { text: "English", key: "en" },
  { text: "Swedish", key: "sv" },
  { text: "Finnish", key: "fi" },
];
