import React from 'react';
import Loading from "../loaders/Loading";
import i18next from "i18next";

class SaveButton extends React.Component
{
    render()
    {
        let {  processing, text, btnClass, style } = this.props;
        return (

            <button className={`btn-theme btn-gray ${btnClass}`} disabled={processing ? true : null} style={ style }>
                { processing ? <Loading xs color="white" /> : text || i18next.t( `buttons.save`, {returnObjects:true} ) }
            </button>

        )
    }
}
export default SaveButton;