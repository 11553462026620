import {OrdersFormState} from "./OrdersForm-State"
import {errorsList, errorsPresent, mergeServerValidationErrors} from "../../../../helpers/ErrorHelper"
import {endOfURL} from "../../../../helpers/UrlHelper"

export default function ordersFormReducer(state = OrdersFormState, action) {

    let { data } = { ...state };
    let { validationErrors, name, value, userRole } = action
    switch (action.type) {

        case "ON-ORDERS-FORM-CHANGE":
            if(name === "documents") {
                data.files = value;
            } else {
                data.payload[name] = value;
            }
            buildErrors();
            return { ...state, data };

        case "SET-ROLE-FOR-ORDERS-FORM":
            data.loggedInRole = userRole;
            return { ...state, data };

        case "SET-DEFAULT-ORDERS-FORM":
            data = {
                payload:{
                    description:"",
                    worktype_id:"",
                    priority:"",
                    documents:[],
                    store_id:"",
                    chain_id:"",
                    region_id:"",
                    approval_status: ""
                },
                loggedInRole: "",
                files:[],
                errors: [],
                errorsPresent: false,
                submitted: false,
                dispatchAPI: false,
                mode:endOfURL(),
                message: ""
            };
            buildErrors();
            return { ...state, data };

        case "CANCEL-ORDERS-FORM-DISPATCH":
            data.dispatchAPI = false;
            data.submitted = false;
            buildErrors();
            return {...state, data};

        case "ON-ORDERS-FORM-SUBMIT":
            data.submitted = true;
            buildErrors();
            if (!data.errorsPresent) data.dispatchAPI = true;
            return { ...state, data };

        case "ORDERS-FORM-SERVER-VALIDATION-ERRORS":
            Object.keys(validationErrors).map(key =>
                data.errors[key] = [{ message: " - "+validationErrors[key][0].slice(0, -1) }]
            )
            data.dispatchAPI = false;
            return { ...state, data };

        default: return state
    }

    function buildErrors() {
        data.errors = errorsList(errors);
        data.errorsPresent = errorsPresent(errors)
    }

    function errors(field) {
        let { payload, submitted, serverValidationErrors, loggedInRole } = data;
        let errors = {};
        Object.keys(payload).map(key => errors[key] = []);

        if (submitted) {
            if (field === "worktype_id") {
                if (!_(field)) newError(field, 0, "is required")
            }

            if (field === "priority") {
                if (!_(field)) newError(field, 0, "is required")
            }
            if (field === "description") {
                if (!_(field)) newError(field, 0, "is required")
            }

            if ( field === "chain_id" ) {
                if ((loggedInRole === "super-admin" || loggedInRole === "admin") && !_(field) ) newError( field, 0, "is required")
                if ( loggedInRole === "regional-director" && !_(field) ) newError( field, 0, "is required")
            }

            /*if ( field === "region_id" ) {
              if ( (loggedInRole === "super-admin" || loggedInRole === "admin") && !_(field) ) newError( field, 0, "is required")
              // if ( loggedInRole === "store-manager" && !_(field) ) newError( field, 0, "is required")
            }*/

            if ( field === "approval_status" ) {
                if ( loggedInRole === "chain-admin" || loggedInRole === "regional-director" )
                    if (!_(field)) newError( field, 0, "is required")
            }

            if ( field === "store_id" ) {
                if (( loggedInRole === "super-admin" || loggedInRole === "admin" || loggedInRole === "chain-admin" || loggedInRole === "regional-director"))
                    if (!_(field) ) newError( field, 0, "is required")
            }
        }

        errors = mergeServerValidationErrors(errors, serverValidationErrors);

        return field ? errors[field] : errors

        function newError(field, step, message) {
            errors && errors[field].push({ step, message })
        }

        function _(field) {
            return data.payload[field]
        }
    }
}

