import React from "react";
import { Route } from "react-router-dom"

import "./assets/scss/main.scss";
import "animate.css";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/jelly.css";

import MainRoute from "./components/routes/main-route/MainRoute";
import Alert from "react-s-alert";

class App extends React.Component
{
    render()
    {
        return (

            <div className="App">
                <Route path="/" component={ MainRoute }/>
                <Alert stack={{limit: 1}} />
            </div>

        )
    }
}
export default App;
