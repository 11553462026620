import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";

import {
    cancelChainFormDispatch,
    chainFormServerValidationErrors
} from "../../../components/pages/chains/form/ChainForm-Action";
import {toggleChainFormModal} from "../../../components/pages/chains/list/ChainList-Actions";
import getChains from "./chains_get";

function _success(success)
{
    return { type: 'POST_CHAIN_SUCCESS', success };
}
function _error(error)
{
    return { type: 'POST_CHAIN_ERROR', error };
}
function _processing(processing)
{
    return { type: 'POST_CHAIN_PROCESSING', processing };
}

function createChain( )
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true));

        let {chainForm} = getState();
        const request = axios({
            url: apiBaseUrl(`api/chains`),
            method: "post",
            dataType: 'json',
            data: chainForm.data.payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        })
            .then(function(res){
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res);

                store.dispatch(cancelChainFormDispatch());
                store.dispatch(toggleChainFormModal());
                store.dispatch(getChains());

            }).catch(function(error){
                dispatch(_error(error));
                dispatch(_processing(false));
                if ( error && error.response.status === 422 ) {
                    store.dispatch(cancelChainFormDispatch());
                    store.dispatch( chainFormServerValidationErrors( error.response.data.payload.message ))
                }
            });

        return {
            type: 'POST_CHAIN_SUCCESS',
            payload: request
        }
    }
}


export default createChain;