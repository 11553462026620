const DefaultImportStoreListState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    limit: 25,
    page:   1,
  },
  modal: {
    id: "",
    mode: ""
  },
  fields: [
    { field: "id", label: "id", sort: false },
    { field: "chain", label: "chain", sort: false },
    { field: "imported_by", label: "imported-by", sort: false },
    { field: "date", label: "date", sort: false },
    { field: "total_imported", label: "total-imported", sort: false },
    { field: "total_failed", label: "total-failed", sort: false },
  ]
};

export const ImportStoresListState = {data: DefaultImportStoreListState}
