import React from "react";
import {names, randomString, unslufigy} from "../../../helpers/StringHelper";
import MyIcon from "../icons/MyIcon";
import Badge from "../badges/Badge";
import uploadIcon from "../../../assets/images/cloud_upload.svg"
import i18next from "i18next";

class UploadField extends React.Component
{
    state = {
        files:[],
        uploadKey:randomString()
    };
    
    label()
    {
        let { name, label, errors } = this.props;
        let errorsPresent = errors && errors.length;
        if ( label ) return (
            <label className={ names("label-text", errorsPresent && "has-error", "animated fadeIn faster") }>
                { label } { errorsPresent ? errors[0].message + "." : null }
            </label>
        );
        else {
            if ( name ) {
                let _label = unslufigy( name );
                if ( name.includes("_id")) _label = name.split("_id")[0];
                return (
                    <label className={ names("label-text", errorsPresent && "has-error", "animated fadeIn faster") }>
                        { _label } { errorsPresent ? errors[0].message + "." : null }
                    </label>
                )
            }
        }
    }

    removeFile(name)
    {
        let { value, onChange, } = this.props;
        let files = {};
        Object.keys( value ).map(key => {
            if(value[key].name !== name ) {
                files[key] = value[key];
            }
        });
        this.setState({ uploadKey:randomString() });

        onChange({ target: { name: this.props.name, value: files } })
    }


    render()
    {
        let {
            name, value, onChange, supportedFormats, errors, required, multiple, noFileRemoval
        } = this.props;
        let { uploadKey } = this.state;

        return (
            <div className={ names("form-group", "animated fadeIn faster", errors && errors.length && "has-error", required && "required") }>

                { errors && errors.length ? this.label() : null }

                <input
                    type="file"
                    key={uploadKey}
                    onChange={e => onChange({ target: { name, value: e.target.files, files: this.state.files.concat(Array.from(e.target.files))  } })}
                    id="fileUpload"
                    multiple={ multiple }
                />

                <div className="info-msg">
                    <label htmlFor="fileUpload" className="uploader-custom">
                        <img src={uploadIcon} alt=""/>
                        <p>{i18next.t("file.attach-image", {returnObjects:true})}</p>
                        { supportedFormats ?
                            <p className="fileType">
                                Only { supportedFormats } formats are allowed.
                            </p>
                            : null }
                    </label>
                </div>
                
                {value && Object.keys(value).length ?
                    <div className="pd-2">
                        <ul className="file-list">
                            {
                                value && Object.keys(value).map(key => {

                                    return (

                                        <li key={key}>
                                            <Badge type="secondary" style={{marginRight: 10}}>
                                                {value[key].name}
                                                { !noFileRemoval && (
                                                    <MyIcon type="times"
                                                            onClick={() => this.removeFile(value[key].name)}
                                                            className="remove-file"
                                                    />
                                                )}
                                            </Badge>
                                        </li>
                                    )

                                })
                            }
                        </ul>

                    </div>
                    :
                    null
                }
                {/*<FileManager
                    files={this.state.files}
                >
                    {this.uploadFiles}
                </FileManager>*/}

            </div>
        )
    }
}
export default UploadField;
