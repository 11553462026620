import React from 'react';
import store from '../../../../Store'
import {connect} from 'react-redux';
import { equalWhenStringified } from '../../../../helpers/StringHelper'
import getChain from '../../../../ajax/actions/chains/chain_get'
import {
  setDefaultChainForm,
  setChainDataForUpdate
} from './ChainForm-Action'
import updateChain from '../../../../ajax/actions/chains/chain_put'
import createChain from '../../../../ajax/actions/chains/chain_post'

class ChainFormWorker extends React.Component
{
  componentDidMount()
  {
    let { modal } = this.props;
    let { id, mode } = modal;

    if( id ) {
      store.dispatch(getChain( id ));
    }
    if( mode ===`create` ) {
      store.dispatch(setDefaultChainForm());
    }
  }

  componentDidUpdate(prev)
  {
    let {  dispatchAPI, modal } = this.props;
    let { id } = modal;

    if(!equalWhenStringified(this.props.getChain, prev.getChain)) {
      let {success} = this.props.getChain;
      if(success) {
        store.dispatch(setChainDataForUpdate());
      }
    }

    if ( dispatchAPI ) {
      if ( !prev.dispatchAPI ) {
        if( id ) {
          store.dispatch(updateChain(id));
        } else {
          store.dispatch(createChain());
        }
      }
    }
  }

  render()
  {
    return null
  }
}
function mapStateToProps(state) {
  let { chainForm, createChain, updateChain, getChain, chainList } = state;
  let { modal } = chainList.data;
  let {dispatchAPI} = chainForm.data;
  return { chainForm, dispatchAPI, createChain, updateChain, getChain, modal, chainList
  };
}
export default connect( mapStateToProps )( ChainFormWorker );