import { PasswordFormState } from "./PasswordForm-State"
import { errorsList, errorsPresent, mergeServerValidationErrors } from "../../../../helpers/ErrorHelper"
import { endOfURL } from "../../../../helpers/UrlHelper"
import {isObject} from "../../../../helpers/DataHelper";
import i18next from "i18next";

export default function passwordFormReducer(state = PasswordFormState, action) {

  let { data } = { ...state };
  let { validationErrors, name, value } = action;
  switch (action.type) {

    case "ON-PASSWORD-FORM-CHANGE":
      data.payload[name] = value;
      buildErrors();
      return { ...state, data };

    case "SET-PASSWORD-DATA-FOR-UPDATE":
      data.payload = {
        new_password: "",
        confirm_password: ""
      }
      buildErrors();
      return { ...state, data };

    case "SET-DEFAULT-PASSWORD-FORM":
      data = {
        payload:{
          new_password: "",
          confirm_password: ""
        },
        errors: [],
        errorsPresent: false,
        submitted: false,
        dispatchAPI: false,
        mode:endOfURL(),
        message: ""
      }
      buildErrors()
      return { ...state, data }

    case "CANCEL-PASSWORD-FORM-DISPATCH":
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors()
      return {...state, data};

    case "ON-PASSWORD-FORM-SUBMIT":
      data.submitted = true
      buildErrors()
      if (!data.errorsPresent) data.dispatchAPI = true
      return { ...state, data }

    case "PASSWORD-FORM-SERVER-VALIDATION-ERRORS":
      validationErrors && isObject( validationErrors ) && Object.keys(validationErrors).map(key =>
          data.errors[key] = [{ message: " - "+validationErrors[key][0].slice(0, -1) }]
      )
      data.dispatchAPI = false
      return { ...state, data }

    default:
      return state
  }

  function buildErrors()
  {
    data.errors = errorsList(errors)
    data.errorsPresent = errorsPresent(errors)
  }

  function errors(field)
  {
    let { payload, submitted, validationErrors } = data;
    let errors = {};
    Object.keys(payload).map(key => errors[key] = []);

    if (submitted) {
      if (field === "new-password") {
        if (!_(field)) newError(field, 0, i18next.t("password.is-required"))
        else if (_(field).length < 6) newError(field, 0, i18next.t("password.password-length"))
      }

      if (field === "confirm-password") {
        if (!_(field)) newError(field, 0, i18next.t("password.is-required"))
        else if ( data.payload.new_password && data.payload.confirm_password ) {
          if ( data.payload.new_password !== data.payload.confirm_password ) {
            newError(field, 0, i18next.t("password.no-match"))
          }
        }
      }
    }

    errors = mergeServerValidationErrors(errors, validationErrors)

    return field ? errors[field] : errors

    function newError(field, step, message) {
      errors && errors[field].push({ step, message })
    }

    function _(field) {
      return data.payload[field]
    }
  }
}

