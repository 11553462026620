import { UploadFileFormState } from "./UploadFileForm-State"
import { errorsList, errorsPresent, mergeServerValidationErrors } from "../../../../helpers/ErrorHelper"
import {slugify} from "../../../../helpers/StringHelper"
import { endOfURL } from "../../../../helpers/UrlHelper"

export default function uploadFileFormReducer(state = UploadFileFormState, action) {

  let { data } = { ...state };
  let { validationErrors, name, value, uploadFiles, id } = action;
  switch (action.type) {

    case "ON-UPLOAD-FILE-FORM-CHANGE":
      data.payload[name] = value;
      /*if(name === "title") {
        data.payload.slug = slugify(value);
      }*/
      buildErrors();
      return { ...state, data };

    case "SET-CATEGORY-ID-FOR-UPDATE-FORM":
      data.category_id = id;
      /*if(name === "title") {
        data.payload.slug = slugify(value);
      }*/
      buildErrors();
      return { ...state, data };

    case "SET-UPLOAD-FILE-DATA-FOR-UPDATE":
      data.payload = {
        title: uploadFiles.title,
        sub_title: uploadFiles.sub_title,
        description: uploadFiles.description,
        files: uploadFiles.files,
        category_name: uploadFiles.category_name,
        category_name: uploadFiles.category_name,
      };
      data.category_id = uploadFiles.id;
      buildErrors();
      return { ...state, data };

    case "SET-DEFAULT-UPLOAD-FILE-FORM":
      data = {
        payload:{
          title:"",
          category_name:"",
          files:[],
          description:"",
          sub_title:"",
          chain_id:""
        },
        category_id:"",
        errors: [],
        errorsPresent: false,
        submitted: false,
        dispatchAPI: false,
        mode:endOfURL(),
        message: ""
      };
      buildErrors();
      return { ...state, data }

    case "CANCEL-UPLOAD-FILE-FORM-DISPATCH":
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors();
      return {...state, data};

    case "ON-UPLOAD-FILE-FORM-SUBMIT":
      data.submitted = true;
      buildErrors();
      if (!data.errorsPresent) data.dispatchAPI = true;
      return { ...state, data };

    case "UPLOAD-FILE-FORM-SERVER-VALIDATION-ERRORS":
      Object.keys(validationErrors).map(key =>
        data.errors[key] = [{ message: " - "+validationErrors[key][0].slice(0, -1) }]
      )
      data.dispatchAPI = false;
      return { ...state, data };

    default: return state
  }

  function buildErrors() {
    data.errors = errorsList(errors);
    data.errorsPresent = errorsPresent(errors)
  }

  function errors(field) {
    let { payload, submitted, serverValidationErrors } = data;
    let errors = {};
    Object.keys(payload).map(key => errors[key] = []);

    if (submitted) {
      if (field === "title") {
        if (!_(field)) newError(field, 0, "is required")
      }

      if (field === "description") {
        if (!_(field)) newError(field, 0, "is required")
      }
      if (field === "category") {
        if (!_(field)) newError(field, 0, "is required")
      }
    }

    errors = mergeServerValidationErrors(errors, serverValidationErrors);

    return field ? errors[field] : errors;

    function newError(field, step, message) {
      errors && errors[field].push({ step, message })
    }

    function _(field) {
      return data.payload[field]
    }
  }
}

