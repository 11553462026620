import React from "react";
import { connect } from "react-redux";
import ChainRegionsWorker from "./ChainRegionsWorker";
import ChainRegionsListFilters from "./ChainRegionsListFilters";
import swal from "sweetalert";
import store from "../../../../../Store";
import deleteChainRegion from "../../../../../ajax/actions/chain-regions/chain_region_delete";
import PaginationButtons from "../../../../shared/buttons/PaginationButtons";
import {
  toggleChainRegionListFilter,
  toggleRegionFormModal,
} from "./ChainRegionsList-Actions";
import MyIcon from "../../../../shared/icons/MyIcon";
import NoResultsFound from "../../../../shared/folder/NoResultsFound";
import Loading from "../../../../shared/loaders/Loading";
import ResponsiveModal from "../../../../shared/modals/ResponsiveModal";
import ChainRegionsForm from "../form/ChainRegionsForm";
import TableHead from "../../../../shared/table/TableHead";
import Page from "../../../../shared/pages/Page";
import { loginToken } from "../../../../../helpers/LocalStorageHelper";
import { apiBaseUrl } from "../../../../../helpers/UrlHelper";
import i18n from "i18next";
import i18next from "i18next";

class ChainRegions extends React.Component {
  state = {
    initialLoading: true,
  };

  componentDidUpdate(lastProps) {
    let { getChainRegions } = this.props;
    if (getChainRegions) {
      if (getChainRegions.success && this.state.initialLoading) {
        this.setState({ initialLoading: false });
      }
    }
  }

  deleteConfirm = (chain_id, id) => {
    swal(i18next.t("confirm.delete-chain"), {
      buttons: {
        cancel: "Cancel",
        catch: {
          text: "Delete",
        },
      },
    }).then((selected) => {
      if (selected) this.delete(chain_id, id);
    });
  };

  delete(chain_id, id) {
    store.dispatch(deleteChainRegion(chain_id, id));
  }

  toggleSort = (field) => {
    store.dispatch(
      toggleChainRegionListFilter({
        target: { name: "sort_by", value: field },
      })
    );
  };

  render() {
    let { getChainRegions, list, pages, modal, params, fields, chain_id } =
      this.props;
    let bearer_token = loginToken();
    let export_file = apiBaseUrl(
      "api/export?type=RegionsExport&access_token=" + bearer_token
    );
    if (chain_id) {
      export_file = apiBaseUrl(
        "api/export?type=RegionsExport&access_token=" +
          bearer_token +
          "&chain_id=" +
          chain_id
      );
    }
    return (
      <Page title={i18next.t("region.regions")}>
        <ChainRegionsWorker />

        <h2>
          {i18next.t("chain.chain-region.title")}
          {getChainRegions.processing && <Loading marginLeft={20} />}
          <span className="pull-right">
            <button
              type="button"
              onClick={() => store.dispatch(toggleRegionFormModal())}
              className="btn-theme btn-gray mt-30"
            >
              {i18next.t("chain.chain-region.create-region")}
            </button>
          </span>

          {list && list.length ? (
            <span className="pull-right">
              <a href={export_file} rel="nofollow" target="_blank">
                <button
                  type="button"
                  className="btn-theme btn-gray mt-30 mr-100"
                >
                  {i18next.t("chain.chain-region.export-regions")}
                </button>
              </a>
            </span>
          ) : null}
        </h2>

        <ChainRegionsListFilters />

        <div className="table-dashboard">
          <div className="table-responsive">
            <table className="table">
              <TableHead
                fields={fields}
                params={params}
                onClick={this.toggleSort}
              />

              <tbody>
                {list && list.length
                  ? list.map((l, key) => {
                      return (
                        <tr key={key}>
                          <td>{l.id}</td>
                          <td
                            className="clickable-col"
                            onClick={() =>
                              store.dispatch(toggleRegionFormModal(l.id))
                            }
                          >
                            {l.chain && l.chain.chain_name}
                          </td>
                          <td>{l.region_name}</td>
                          <td>{l.contact_person}</td>
                          <td>
                            <button
                              className="btn btn-secondary btn-sm"
                              onClick={() =>
                                store.dispatch(toggleRegionFormModal(l.id))
                              }
                            >
                              <MyIcon type="edit" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm ml-1"
                              onClick={() =>
                                this.deleteConfirm(l.chain_id, l.id)
                              }
                            >
                              <MyIcon type="trash" />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
              {list && list.length ? (
                <tfoot>
                  <PaginationButtons
                    processing={getChainRegions.processing}
                    pages={pages}
                    fetchAction={toggleChainRegionListFilter}
                  />
                </tfoot>
              ) : null}
            </table>
            {!this.state.initialLoading && (!list || !list.length) && (
              <NoResultsFound />
            )}
            {this.state.initialLoading && (
              <div className="text-center">
                <Loading />
              </div>
            )}
          </div>
        </div>

        <ResponsiveModal
          visible={modal.mode}
          closeModal={() => store.dispatch(toggleRegionFormModal())}
        >
          <ChainRegionsForm />
        </ResponsiveModal>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  let { getChainRegions, chainRegionsList, chainDashboard } = state;
  let list =
    getChainRegions &&
    getChainRegions.success &&
    getChainRegions.success.data &&
    getChainRegions.success.data.payload.data;

  let { chain_id } = chainDashboard.data;

  let { modal, params, fields } = chainRegionsList.data;

  let current_page =
    getChainRegions.success &&
    getChainRegions.success.data &&
    getChainRegions.success.data.payload.current_page;
  let last_page =
    getChainRegions.success &&
    getChainRegions.success.data &&
    getChainRegions.success.data.payload.last_page;
  let total =
    getChainRegions.success &&
    getChainRegions.success.data &&
    getChainRegions.success.data.payload.total;
  let pages = { current_page, last_page, total };

  return {
    getChainRegions,
    list,
    pages,
    chainRegionsList,
    modal,
    params,
    fields,
    chainDashboard,
    chain_id,
  };
}

export default connect(mapStateToProps)(ChainRegions);
