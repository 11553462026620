import React from "react";
import App from "./App"
import store, { history } from "./Store"
import { Router, Route } from "react-router-dom"
import { Provider } from "react-redux"

class Boot extends React.Component
{
    render()
    {
        return (
          <Provider store={store}>
            <Router history={history}>
              <Route path="/" component={App} />
            </Router>
          </Provider>
        )
    }
}
export default Boot;
