import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import getChains from "../../../../ajax/actions/chains/chains_get";
import store from "../../../../Store";

class ChainWorker extends React.Component
{
  componentDidMount()
  {
    store.dispatch(getChains());
  }

  componentDidUpdate(prev) {
    let { deleteChain,} = this.props;

    if(deleteChain !== prev.deleteChain) {
      let {success} = deleteChain;
      if(success) {
        store.dispatch(getChains());
      }
    }
  }

  render()
  {
      return null
  }
}
function mapStateToProps(state) {
   let {deleteChain }  = state;
   return { deleteChain }
 }
export default withRouter(connect(mapStateToProps)( ChainWorker ));
