export default function postExportReducer
  (state = { error:"", success:"", processing:false}, action)
{
  switch (action.type) {
    case "POST_EXPORT_SUCCESS":
      return {...state, ...{success: action.success}};

    case "POST_EXPORT_ERROR":
      return {...state, ...{error: action.error}};

    case "POST_EXPORT_PROCESSING":
      return {...state, ...{processing: action.processing}};

    default: return state;
  }
}
