const DefaultOrderDetailState ={
  payload:{
    initialLoading: true,
    priority:"",
    error:"",
    success:"",
    order_id:"",
    visible:false,
    order_success_confirmation_mode:false,
    chat_box_visible:false,
    uploadModal:false,
  },
  files:[],
  form_payload:{
    status:"",
    approval_status:"",
    priority:"",
    technician_id:"",
    planned_at:"",
    completed_at:"",
  },
  role:"",
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
  message: ""
};

export const OrderDetailState = { data: DefaultOrderDetailState };
