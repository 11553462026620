import axios from 'axios'
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from "../../../helpers/LocalStorageHelper";
import {handleError} from "../../../helpers/ErrorHelper";

function _success(success) {
    return {type: 'GET_ORDER_STATUS_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_ORDER_STATUS_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_ORDER_STATUS_PROCESSING', processing};
}

function getOrderStatus(id) {
    return (dispatch,) => {
        dispatch(_processing(true));

        const request = axios({
            url:apiBaseUrl(`api/orderstatus/${id}`),
            method:'get',
            headers:{
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        }).then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false));
        }).catch(function(error){
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error);
        });

        return {
            type:'GET_ORDER_STATUS_SUCCESS',
            payload:request
        }
    }
}

export default getOrderStatus;