import axios from 'axios'
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from "../../../helpers/LocalStorageHelper";
import {handleError, handleSuccess} from "../../../helpers/ErrorHelper";
import getOrders from "./orders_get";
import store from '../../../Store';

function _success(success) {
    return {type: 'DELETE_ORDER_SUCCESS', success};
}

function _error(error) {
    return {type: 'DELETE_ORDER_ERROR', error};
}

function _processing(processing) {
    return {type: 'DELETE_ORDER_PROCESSING', processing};
}

function deleteOrder(id) {
    return (dispatch,) => {
        dispatch(_processing(true));

        const request = axios({
            url:apiBaseUrl(`api/orders/${id}`),
            method:'delete',
            headers:{
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        }).then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false));
            handleSuccess( res )
            store.dispatch( getOrders() )

        }).catch(function(error){
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error);
        });

        return {
            type:'DELETE_ORDER_SUCCESS',
            payload:request
        }
    }
}

export default deleteOrder;