import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import store from "../../../../Store";
import getOrders from "../../../../ajax/actions/orders/orders_get";
import getWorkTypes from "../../../../ajax/actions/work-types/work_types_get";
import getChains from "../../../../ajax/actions/chains/chains_get";
import getChain from "../../../../ajax/actions/chains/chain_get";
import getOrderStatuses from "../../../../ajax/actions/order-status/order_statuses_get";
import { setDefaultOrdersFilters } from "./OrderList-Actions";
import getOrderPriority from "../../../../ajax/actions/orders/order_priority_get";

class OrdersWorker extends React.Component {
    componentDidMount() {
        store.dispatch(setDefaultOrdersFilters());

        let { user_role } = this.props;
        store.dispatch(getOrders());
        store.dispatch(getWorkTypes());
        store.dispatch(getOrderStatuses());
        store.dispatch(getOrderPriority());

        if (
            user_role !== "regional-director" &&
            user_role !== "technician" &&
            user_role !== "chain-admin" &&
            user_role !== "store-manager"
        ) {
            store.dispatch(getChains(100));
        }
    }

    componentDidUpdate(lastProps) {
        let { match, chain_id } = this.props;
        if (this.props.getChain !== lastProps.getChain) {
            let { success } = this.props.getChain;
            if (success) {
                store.dispatch(getOrders());
            }
        }

        if (chain_id !== lastProps.chain_id) {
            store.dispatch(getChain(chain_id));
        }

        if (match.pathname !== lastProps.match.pathname) {
            store.dispatch(setDefaultOrdersFilters());
        }
    }

    render() {
        return null;
    }
}

function mapStateToProps(state) {
    let { route, chainDashboard, getChain } = state;
    let { user_role } = route.data;
    let { chain_id } = chainDashboard.data;
    return { route, user_role, chainDashboard, chain_id, getChain };
}

export default withRouter(connect(mapStateToProps)(OrdersWorker));
