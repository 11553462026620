import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import store from "../../../../Store";
import getWorkTypes from "../../../../ajax/actions/work-types/work_types_get";

class WorkTypeWorker extends React.Component
{
  componentDidMount()
  {
    store.dispatch(getWorkTypes());
  }

  componentDidUpdate(prev) {
    let { deleteWorkType,} = this.props;

    if(deleteWorkType !== prev.deleteWorkType) {
      let {success} = deleteWorkType;
      if(success) {
        store.dispatch(getWorkTypes());
      }
    }
  }

  render()
  {
      return null
  }
}
function mapStateToProps(state) {
   let {deleteWorkType }  = state;
   return { deleteWorkType }
 }
export default withRouter(connect(mapStateToProps)( WorkTypeWorker ));
