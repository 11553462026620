import React from "react";
import WorkTypeWorker from "./WorkTypeWorker";
import {connect} from "react-redux";
import store from "../../../../Store";
import swal from "sweetalert";
import PaginationButtons from "../../../shared/buttons/PaginationButtons";
import WorkTypeListFilters from "./WorkTypeListFilters";
import {toggleWorkTypeFormModal, toggleWorkTypeListFilter} from "./WorkTypeList-Actions";
import Loading from "../../../shared/loaders/Loading";
import deleteWorkType from "../../../../ajax/actions/work-types/work_type_delete";
import MyIcon from "../../../shared/icons/MyIcon";
import NoResultsFound from "../../../shared/folder/NoResultsFound";
import ResponsiveModal from "../../../shared/modals/ResponsiveModal";
import WorkTypeForm from "../form/WorkTypeForm";
import TableHead from "../../../shared/table/TableHead";
import Page from "../../../shared/pages/Page";
import i18next from "i18next";

class WorkTypes extends React.Component {
    state = {
        initialLoading: true
    }

    componentDidUpdate( lastProps )
    {
        let { getWorkTypes } = this.props;
        if(getWorkTypes) {
            if ( getWorkTypes.success  && this.state.initialLoading ) {
                this.setState({ initialLoading: false })
            }
        }
    }

    deleteConfirm = (id) => {
        swal(i18next.t("confirm.delete-work-type",{returnObjects:true}), {
            buttons: {
                cancel: i18next.t("cancel",{returnObjects:true}),
                catch: {
                    text: i18next.t("delete",{returnObjects:true}),
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    }

    delete(id)
    {
        store.dispatch(deleteWorkType(id));
    }

    toggleSort = (field) =>
    {
        store.dispatch(  toggleWorkTypeListFilter({
            target: { name: "sort_by", value: field }
        }))
    };

    render() { 
        let { list, getWorkTypes, pages, modal, params, fields, } = this.props;
        let { initialLoading } = this.state;
        return (
            <Page title={i18next.t("work-type.work-types",{returnObjects:true})}>

                <h2>
                    {i18next.t("work-type.work-types",{returnObjects:true})}{" "}{getWorkTypes.processing && <Loading marginLeft={20}/>}
                    <span className="pull-right">
                         <button onClick={() => store.dispatch(toggleWorkTypeFormModal())}
                                 className="btn-theme btn-gray mt-30">
                                {i18next.t("work-type.create-work-type",{returnObjects:true})}
                        </button>
                    </span>
                </h2>

                <WorkTypeListFilters />

                <div className="table-dashboard table-small">
                    <div className="table-responsive">
                        <WorkTypeWorker/>
                        <table className="table">
                            <TableHead fields={ fields }
                                       params={ params }
                                       onClick={ this.toggleSort } />
                            <tbody>
                            {
                                list && list.length ? list.map((l, key) => {
                                    return (
                                        <tr key={key}>
                                            <td >{l.id}</td>
                                            <td className="clickable-col"
                                                onClick={() => store.dispatch(toggleWorkTypeFormModal(l.id))}>
                                                {l.title}
                                            </td>
                                            <td>
                                                <button className="btn btn-secondary btn-sm"
                                                        onClick={() => store.dispatch(toggleWorkTypeFormModal(l.id))}>
                                                    <MyIcon type="edit" />
                                                </button>
                                                <button type="button"
                                                        className="btn btn-danger btn-sm ml-1"
                                                        onClick={() => this.deleteConfirm(l.id)}><MyIcon type="trash" />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                }) : null
                            }
                            </tbody>
                            { list && list.length ? (
                                <tfoot>
                                <PaginationButtons pages={pages}
                                                   processing={getWorkTypes.processing}
                                                   fetchAction={toggleWorkTypeListFilter} />
                                </tfoot>
                            ) : null }
                        </table>

                        { !initialLoading && ( !list || !list.length) && <NoResultsFound/> }
                        { initialLoading && <div className="text-center"><Loading/></div> }
                    </div>
                </div>

                <ResponsiveModal visible={ modal.mode }
                                 closeModal={ () => store.dispatch( toggleWorkTypeFormModal( )) } >
                    <WorkTypeForm />
                </ResponsiveModal>
            </Page>
        )
    }
}

function mapStateToProps(state) {
    let {getWorkTypes, workTypeList} = state;
    let { modal, params, fields } = workTypeList.data;

    let list =
        getWorkTypes.success &&
        getWorkTypes.success.data.payload;

    let current_page = getWorkTypes.success && getWorkTypes.success.data && getWorkTypes.success.data.payload.current_page;
    let last_page = getWorkTypes.success && getWorkTypes.success.data && getWorkTypes.success.data.payload.last_page;
    let total = getWorkTypes.success && getWorkTypes.success.data && getWorkTypes.success.data.payload.total;
    let pages = {current_page, last_page, total}

    return { getWorkTypes, pages, list, modal, workTypeList, params, fields };
}


export default connect(mapStateToProps)(WorkTypes);
