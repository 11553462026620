import { OrderListState } from "./OrderList-State";
import i18next from "i18next";

export default function orderListReducer(state = OrderListState, action) {
  let { data } = { ...state };
  let { name, value } = action;

  switch (action.type) {
    case "TOGGLE-ORDER-LIST-FILTER":
      data.params[name] = value;
      data.params["lang"] = i18next.language;
      if (name === "sort_by") {
        if (data.params.sort_by === value) {
          data.params.sort_order =
            data.params.sort_order === "desc" ? "asc" : "desc";
        }
      }
      if (name === "limit") {
        data.params.page = 1;
      }
      return { ...state, data };

    case "SET-DEFAULT-ORDERS-FILTERS":
      data.params["lang"] = i18next.language;
      return { ...state, data };

    default:
      return state;
  }
}
