import axios from "axios";
import { apiBaseUrl } from "../../../helpers/UrlHelper";
import { loginToken } from "../../../helpers/LocalStorageHelper";
import { handleError } from "../../../helpers/ErrorHelper";

function _success(success) {
    return { type: "GET_ORDER_SUCCESS", success };
}

function _error(error) {
    return { type: "GET_ORDER_ERROR", error };
}

function _processing(processing) {
    return { type: "GET_ORDER_PROCESSING", processing };
}

function getOrder(id) {
    return (dispatch) => {
        dispatch(_processing(true));

        const request = axios({
            url: apiBaseUrl(`api/orders/${id}`),
            method: "get",
            headers: {
                Authorization: "Bearer " + loginToken(),
                lang: localStorage.getItem("lang") || "en",
            },
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
            })
            .catch(function (error) {
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError(error);
            });

        return {
            type: "GET_ORDER_SUCCESS",
            payload: request,
        };
    };
}

export default getOrder;
