import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { enGB, fi, sv } from "date-fns/locale";
import { DateRangePicker, START_DATE, END_DATE } from "react-nice-dates";
import i18next from "i18next";

let dateRange = {};

export default function DateRangeField({ name, label, placeholder, onChange }) {
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  function dateFilter(val, date) {
    if (moment(date).format("DD-MM-YYYY HH:mm:ss") != 'Invalid date') {
      dateRange[val] = moment(date).format("DD-MM-YYYY HH:mm:ss");
    } else {
      delete dateRange[val];
      onChange({target: {name: 'date_range', value: ''}});
    }
    if (dateRange['start'] && dateRange['end']) {
      onChange({target: {name: 'date_range', value: dateRange['start']+'|'+dateRange['end']}});
    }
  }

  function getCurrentLng() {
    if (i18next.language === 'sv') {
      return sv;
    } else if (i18next.language === 'fn') {
      return fi;
    } else {
      return enGB;
    }
  }

  function clearDate() {
    setStartDate(null);
    setEndDate(null);
    onChange({target: {name: 'date_range', value: ''}});
  }

  return (
      <div className="form-group">
        <label className="label-text">
          { label }
        </label>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={e => { setStartDate(e); dateFilter('start', e) }}
          onEndDateChange={e => { setEndDate(e); dateFilter('end', e) }}
          minimumLength={1}
          format='dd-MM-yy'
          locale={ getCurrentLng() }
        >
          {({ startDateInputProps, endDateInputProps, focus }) => (
            <div className='date-range'> 
              <input
                className={'input' + (focus === START_DATE ? ' -focused' : '')}
                {...startDateInputProps} 
                placeholder={ placeholder[0] } 
              />
              <input
                className={'input' + (focus === END_DATE ? ' -focused' : '')}
                {...endDateInputProps} 
                placeholder={ placeholder[1] }
              />
            { startDate && endDate ? (
              <div onClick={clearDate} aria-hidden="true" className="css-tlfecz-indicatorContainer"><svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg></div>
            ) : null }
            <div className=" css-1g48xl4-IndicatorsContainer" style={{background: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABHNCSVQICAgIfAhkiAAAAJRJREFUKJHtkbsRgzAQBffUgKvQjEKVQCLHlIA7cCl04EJIXAI5CS24AHgOCPyTZZH7pe92b+YOCtExqNS7Uvkrf3gvrBRmpdDtgZRCpxRmB0sL6msF25x6WFpnwzTC2tQIHuDa2DCNDqBG8A7C08FKghwIYJ8bfAR3BTtjXBCnHJiFXwRmB6RbDixGycftjT5+m7kDHtpWgwj9EIcAAAAASUVORK5CYII=) no-repeat 100% 10px"}}>
            </div>
          </div>
          )}
        </DateRangePicker>
      </div>
  )
}
