import { endOfURL } from "../../../../helpers/UrlHelper"

const DefaultUploadFileFormState = {
  payload:{
    title:"",
    category_name:"",
    files:[],
    description:"",
    sub_title:"",
    chain_id:"",
  },
  category_id:"",
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
  mode:endOfURL(),
  message: ""

};

export const UploadFileFormState = { data: DefaultUploadFileFormState }
