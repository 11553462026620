import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import {
    cancelOrderStatusFormDispatch,
    orderStatusFormServerValidationErrors, setDefaultOrderStatusForm
} from "../../../components/pages/order-status/form/OrderStatusForm-Action";
import {toggleOrderStatusFormModal} from "../../../components/pages/order-status/list/OrderStatusList-Actions";

import getOrderStatuses from "./order_statuses_get";

function _success(success)
{
    return { type: 'POST_ORDER_STATUS_SUCCESS', success };
}
function _error(error)
{
    return { type: 'POST_ORDER_STATUS_ERROR', error };
}
function _processing(processing)
{
    return { type: 'POST_ORDER_STATUS_PROCESSING', processing };
}

function createOrderStatus( )
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true));
        let {orderStatusForm} = getState();

        const request = axios({
            url: apiBaseUrl(`api/orderstatus`),
            method: "post",
            dataType: 'json',
            data: orderStatusForm.data.payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        })
            .then(function(res)
            {
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res);

                store.dispatch(setDefaultOrderStatusForm())
                store.dispatch(cancelOrderStatusFormDispatch());
                store.dispatch(toggleOrderStatusFormModal());
                store.dispatch(getOrderStatuses());

            }).catch(function(error)
            {
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError( error );

                if ( error && error.response.status === 422 ) {
                    store.dispatch(cancelOrderStatusFormDispatch());
                    store.dispatch(orderStatusFormServerValidationErrors(error.response.data.payload.errors))
                }
            });

        return {
            type: 'POST_ORDER_STATUS_SUCCESS',
            payload: request
        }
    }
}


export default createOrderStatus;