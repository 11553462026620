import axios from 'axios'
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from "../../../helpers/LocalStorageHelper";
// import {handleError} from "../../../helpers/ErrorHelper";
function _success(success) {
    return {type: 'GET_CATEGORY_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_CATEGORY_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_CATEGORY_PROCESSING', processing};
}

function getCategory(id) {
    return (dispatch,) => {
        dispatch(_processing(true));

        axios({
            url:apiBaseUrl(`api/admin/categories/${id}`),
            method:'get',
            headers:{
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        }).then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false));
        }).catch(function(error){
            dispatch(_error(error));
            dispatch(_processing(false));
            // handleError(error);
        });
    }
}

export default getCategory;