export function workTypeFormServerValidationErrors( validationErrors ) {
  return function (dispatch) {
    dispatch({type:"WORK-TYPE-FORM-SERVER-VALIDATION-ERRORS", validationErrors });
  }
}

export function onWorkTypeFormSubmit( ) {
  return function (dispatch) {
    dispatch({type:"ON-WORK-TYPE-FORM-SUBMIT" });
  }
}

export function onWorkTypeFormChange(name, value) {
  return function (dispatch){
      dispatch({type:"ON-WORK-TYPE-FORM-CHANGE", name, value});
  }
}

export function cancelWorkTypeFormDispatch() {
  return function (dispatch) {
    dispatch({ type:"CANCEL-WORK-TYPE-FORM-DISPATCH" });
  }
}

export function setDefaultWorkTypeForm() {
  return function (dispatch){
    dispatch({type:"SET-DEFAULT-WORK-TYPE-FORM"});
  }
}

export function setWorkTypeDataForUpdate() {
  return function (dispatch, getState) {
    let { getWorkType } = getState();
    let workType = getWorkType && getWorkType.success && getWorkType.success.data.payload;
    if(workType) {
      dispatch({type:"SET-WORK-TYPE-DATA-FOR-UPDATE",  workType:JSON.parse(JSON.stringify(workType)) });
    }
  }
}
