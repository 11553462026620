import { endOfURL } from "../../../../helpers/UrlHelper"

const DefaultPasswordFormState = {
  payload:{
    new_password: "",
    confirm_password: ""
  },
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
  mode:endOfURL(),
  message: ""

};

export const PasswordFormState = { data: DefaultPasswordFormState };
