import store from "../../../../Store";
import getOrder from "../../../../ajax/actions/orders/order_get";

export function toggleOrderDetail(id) {
  return function (dispatch) {
    dispatch({ type: "TOGGLE-ORDER-DETAIL", id });
    store.dispatch(getOrder(id));
  };
}

export function toggleChatBox(visible_mode) {
  return function (dispatch) {
    dispatch({ type: "TOGGLE-CHAT-BOX", visible_mode });
  };
}
export function toggleUploadFiles(visible_mode) {
  return function (dispatch) {
    dispatch({ type: "TOGGLE-ORDER-UPLOAD-MODAL", visible_mode });
  };
}

export function setUserRoleOnOrderDetailState() {
  return function (dispatch, getState) {
    let { route } = getState();
    let { user_role } = route.data;
    dispatch({ type: "SET-USER-ROLE-ORDER-DETAIL", user_role });
  };
}

export function setOrderDetailDataForUpdate() {
  return function (dispatch, getState) {
    let { getOrder } = getState();
    let order = getOrder && getOrder.success && getOrder.success.data.payload;
    if (order) {
      dispatch({ type: "SET-ORDER-DETAIL-FOR-UPDATE", order });
    }
  };
}

export function toggleOrderDetailModalClose() {
  return function (dispatch) {
    dispatch({ type: "TOGGLE-ORDER-DETAIL-MODAL-CLOSE" });
  };
}

export function onOrderDetailFormChange(name, value) {
  return function (dispatch) {
    dispatch({ type: "ON-ORDER-DETAIL-FORM-CHANGE", name, value });
  };
}

export function onOrderUploadFormChange(name, value) {
  return function (dispatch) {
    dispatch({ type: "ON-ORDER-UPLOAD-FORM-CHANGE", name, value });
  };
}

export function onOrderDetailChatFormChange(name, value) {
  return function (dispatch) {
    dispatch({ type: "ON-ORDER-DETAIL-CHAT-FORM-CHANGE", name, value });
  };
}

export function onOrderDetailFormSubmit() {
  return function (dispatch) {
    dispatch({ type: "ON-ORDER-DETAIL-FORM-SUBMIT" });
  };
}

export function cancelOrderDetailEditFormDispatch() {
  return function (dispatch) {
    dispatch({ type: "CANCEL-ORDER-DETAIL-EDIT-FORM-DISPATCH" });
  };
}

export function orderDetailEditFormServerValidationErrors(validationErrors) {
  return function (dispatch) {
    dispatch({
      type: "ORDER-DETAIL-EDIT-FORM-SERVER-VALIDATION-ERRORS",
      validationErrors,
    });
  };
}

export function setOrderSuccessConfirmationMode(mode) {
  return function (dispatch) {
    dispatch({ type: "SET-ORDER-SUCCESS-CONFIRMATION-MODE", mode });
  };
}
