import store from "../../../../Store";
import getWorkTypes from "../../../../ajax/actions/work-types/work_types_get";

export function toggleWorkTypeListFilter(e)
{
  return function (dispatch) {
    let { name, value } = e.target;
    dispatch({type:"TOGGLE-WORK-TYPE-LIST-FILTER", name, value});
    store.dispatch( getWorkTypes());
  }
}

export function toggleWorkTypeFormModal( id )
{
  return function (dispatch) {
    dispatch({type:"TOGGLE-WORK-TYPE-FORM-MODAL", id });
  }
}
