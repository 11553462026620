import { ChainFormState } from './ChainForm-State'
import { errorsList, errorsPresent, mergeServerValidationErrors } from '../../../../helpers/ErrorHelper'
import { endOfURL } from '../../../../helpers/UrlHelper'

export default function chainFormReducer(state = ChainFormState, action) {

  let { data } = { ...state };
  let { validationErrors, name, value, chains } = action
  switch (action.type) {

    case 'ON-CHAIN-FORM-CHANGE':
      data.payload[name] = value;
      buildErrors();
      return { ...state, data };

    case 'SET-CHAIN-DATA-FOR-UPDATE':
      data.payload = {
        chain_name: chains.chain_name,
        phone: chains.phone,
        email: chains.email,
        invoice_address: chains.invoice_address,
        contact_person: chains.contact_person,
        has_region: chains.has_region,
      };
      buildErrors();
      return { ...state, data };

    case 'SET-DEFAULT-CHAIN-FORM':
      data = {
        payload:{
          chain_name:``,
          phone:``,
          email:``,
          invoice_address:``,
          contact_person:``,
          has_region: 1,
        },
        errors: [],
        errorsPresent: false,
        submitted: false,
        dispatchAPI: false,
        mode:endOfURL(),
        message: ``
      };
      buildErrors();
      return { ...state, data };

    case 'CANCEL-CHAIN-FORM-DISPATCH':
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors();
      return {...state, data};

    case 'ON-CHAIN-FORM-SUBMIT':
      data.submitted = true;
      buildErrors();
      if (!data.errorsPresent) data.dispatchAPI = true;
      return { ...state, data };

    case 'CHAIN-FORM-SERVER-VALIDATION-ERRORS':
      validationErrors && Object.keys(validationErrors).map(key =>
          data.errors[key] = [{ message: ` - ${validationErrors[key][0]}`.slice(0, -1) }]
      );
      data.dispatchAPI = false
      return { ...state, data };

    default:
      return state
  }

  function buildErrors() {
    data.errors = errorsList(errors);
    data.errorsPresent = errorsPresent(errors)
  }

  function errors(field)
  {
    let { payload, submitted, validationErrors } = data;
    let errors = {};
    Object.keys(payload).map( key => errors[key] = [] );

    if (submitted) {
      if (field === `chain_name`) {
        if (!_(field)) newError(field, 0, `is required`)
      }

      if (field === `phone`) {
        if (!_(field)) newError(field, 0, `is required`)
      }

      if (field === `email`) {
        if (!_(field)) newError(field, 0, `is required`)
      }

      if (field === `invoice_address`) {
        if (!_(field)) newError(field, 0, `is required`)
      }

      if (field === `contact_person`) {
        if (!_(field)) newError(field, 0, `is required`)
      }

      if (field === `has_region`) {
        if (!String(_(field))) newError(field, 0, `is required`)
      }

    }

    errors = mergeServerValidationErrors(errors, validationErrors)

    return field ? errors[field] : errors

    function newError(field, step, message) {
      errors && errors[field].push({ step, message })
    }

    function _(field) {
      return data.payload[field]
    }
  }
}

