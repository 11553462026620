import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import getCategories from "./categories_get";

function _success(success)
{
    return { type: 'DELETE_CATEGORY_SUCCESS', success };
}
function _error(error)
{
    return { type: 'DELETE_CATEGORY_ERROR', error };
}
function _processing(processing)
{
    return { type: 'DELETE_CATEGORY_PROCESSING', processing };
}

function deleteCategory( id )
{
    return ( dispatch ) =>
    {
        dispatch(_processing(true));
        axios({
            url: apiBaseUrl(`api/admin/categories/${id}`),
            method: "delete",
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        })
        .then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false));
            handleSuccess(res);
            store.dispatch(getCategories());

        }).catch(function(error)
        {
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError( error );
        });
    }
}
export default deleteCategory;