import React from "react";
import store from "../../../../Store"
import {connect} from "react-redux";
import getChains from "../../../../ajax/actions/chains/chains_get";
import importStores from "../../../../ajax/actions/stores/import_store_post";

class ImportStoresFormWorker extends React.Component
{
  componentDidMount()
  {
    store.dispatch( getChains( ))
  }

  componentDidUpdate(prev)
  {
    let { dispatchAPI } = this.props;

    if ( dispatchAPI ) {
      if ( !prev.dispatchAPI ) {
        store.dispatch(importStores());
      }
    }
  }

  render()
  {
    return null
  }
}
function mapStateToProps(state) {
  let { importStoresForm } = state;

  let { dispatchAPI } = importStoresForm.data;

  return { importStoresForm, dispatchAPI,
  };
}
export default connect( mapStateToProps )( ImportStoresFormWorker );
