import React from "react";
import {connect} from "react-redux"
import store from "../../../../Store";
import ChainFormWorker from "./ChainFormWorker"
import {onChainFormChange, onChainFormSubmit} from "./ChainForm-Action";
import SaveButton from "../../../shared/buttons/SaveButton";
import TextField from "../../../shared/form/TextField";
import {names, ucFirst} from "../../../../helpers/StringHelper";
import SearchableSelect from "../../../shared/form/SearchableSelect";
import {hasRegion} from "../../../../static-data/SettingData";
import i18next from "i18next";

class ChainForm extends React.Component {

    chainFormSubmit = (e) =>
    {
        e.preventDefault();
        store.dispatch(onChainFormSubmit());
    }

    onChange = (e) => {
        store.dispatch(onChainFormChange(e.target.name, e.target.value));
    }

    render() {
        let {chainForm, createChain, updateChain, modal, getChain } = this.props;
        let {payload, errors} = chainForm.data;
        let {phone,email, contact_person, invoice_address, has_region, chain_name} = payload;
        let processing = createChain.processing || updateChain.processing;
        return (
            <div className={names("row", getChain.processing && "item-disabled")}>
                <div className="offset-lg-1 col-lg-7 col-md-12">
                    <h2>{ucFirst(modal.mode)} Chain</h2>
                    <form onSubmit={this.chainFormSubmit}>

                        <TextField name="chain_name"
                                   label={i18next.t("chain.chain-name", {returnObjects:true})}
                                   value={chain_name}
                                   errors={errors.chain_name}
                                   onChange={this.onChange} />

                        <TextField name="phone"
                                   value={phone}
                                   label={i18next.t("my-account.phone", {returnObjects:true})}
                                   errors={errors.phone}
                                   onChange={this.onChange} />

                        <TextField name="email"
                                   value={email}
                                   label={i18next.t("my-account.email", {returnObjects:true})}
                                   errors={errors.email}
                                   onChange={this.onChange} />

                        <TextField name="invoice_address"
                                   value={invoice_address}
                                   label={i18next.t("my-account.invoice-address", {returnObjects:true})}
                                   errors={errors.invoice_address}
                                   onChange={this.onChange} />

                        <TextField name="contact_person"
                                   value={contact_person}
                                   label={i18next.t("my-account.contact-person", {returnObjects:true})}
                                   errors={errors.contact_person}
                                   onChange={this.onChange} />

                        <SearchableSelect name="has_region"
                                     value={has_region}
                                     label={i18next.t("my-account.has-region",{returnObjects:true})}
                                     _label="name"
                                     _value="value"
                                     errors={ errors.has_region }
                                     onChange={this.onChange}
                                     options={hasRegion} />

                        <div className="text-center">
                            <SaveButton processing={processing}/>
                        </div>

                    </form>
                </div>
                <ChainFormWorker />
            </div>

        )
    }
}

function mapStateToProps(state) {

    let {chainForm, createChain, updateChain, getChain, chainList } = state;
    let { modal } = chainList.data;
    let {mode, search, errors} = chainForm.data;
    return {
        chainForm, mode, search, createChain, updateChain, getChain, errors, chainList, modal
    };
}

export default connect(mapStateToProps)(ChainForm);
