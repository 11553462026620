import { UserListState } from "./UserList-State"

export default function userListReducer(state = UserListState, action) {

  let { data } = { ...state };
  let { name, value, chainID, id } = action;

  switch (action.type) {

    case "TOGGLE-USER-LIST-FILTER":
      data.params[ name ] = value;
      if ( name === "chain_id" ) {
        data.params.region_id = "";
        data.params.store_id = "";
      }
      if ( name === "region_id" ) {
        data.params.store_id = "";
      }
      if ( name === "sort_by" ) {
        if ( data.params.sort_by === value ) {
          data.params.sort_order = data.params.sort_order === "desc" ? "asc" : "desc"
        }
      }
      if ( name === "limit" ) {
        data.params.page = 1;
      }
      return { ...state, data };

    case "SET-CHAIN-MODE":
      data.params.chain_id = chainID;
      return { ...state, data };

    case "TOGGLE-USER-FORM-MODAL":
      if ( data.modal.mode ) { // if open, close
        data.modal = { mode: "", id: "" }

      } else { // if close, open
        data.modal.id = id || "";
        data.modal.mode = id ? "update" : "create";
      }
      return { ...state, data };

    case "SET-DEFAULT-USER-FILTERS":
      return { ...state, data };

    default: return state
  }
}
