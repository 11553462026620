const DefaultUserListState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    limit: 25,
    page:   1,
    sort_order:  "desc",
    sort_by: "id",
    q: "",
    role_id: "",

    entity_type: "",
    entity_id: "",

    chain_id: "",
    region_id: "",
    store_id: ""
  },
  modal: {
    mode: "",
    id: ""
  },
  fields: [
    { field: "id", label: "id", sort: true },
    { field: "name", label: "name", sort: true },
    { field: "role_title",   label: "role", sort: false },
    { field: "email", label: "email", sort: true },
    { field: "phone_no", label: "phone", sort: true },
  ]
};

export const UserListState = { data: DefaultUserListState };
