import React from "react";
import store from "../../../Store"
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {
  cancelAccountFormDispatch,
  accountFormServerValidationErrors,
  setAccountDataForUpdate
} from "./AccountForm-Action"
import getRoles from "../../../ajax/actions/role/roles_get";
import updateMyAccount from "../../../ajax/actions/user/my_account_put";

class AccountFormWorker extends React.Component
{
  constructor(props) {
    super(props);
    store.dispatch(setAccountDataForUpdate());
  }

  componentDidMount()
  {
    // let { role } = this.props;
    store.dispatch(setAccountDataForUpdate());
    // if(role === "super-admin" || role === "admin") {
    //   // store.dispatch( getRoles( 100 ) )
    // }
  }

  componentDidUpdate(prev)
  {
    let {  dispatchAPI, id, } = this.props;

    if(this.props.updateMyAccount !== prev.updateMyAccount) {
      let {error, success} = this.props.updateMyAccount;
      if(success) {
        store.dispatch(cancelAccountFormDispatch());
      }

      if ( error && error.response && error.response.status === 422 ) {
        store.dispatch(cancelAccountFormDispatch());
        store.dispatch( accountFormServerValidationErrors( error.response.data.message ))
      }
    }

    if ( dispatchAPI ) {
      if ( !prev.dispatchAPI ) {
        if(id ) {
           store.dispatch(updateMyAccount());
        }
      }
    }
  }

    render()
    {
        return null
    }
}
function mapStateToProps(state) {
   let { accountForm, updateMyAccount, getUser, userDetail } = state;

   let id = userDetail && userDetail.success.id;
   let role = userDetail && userDetail.success.role;

    let {dispatchAPI} = accountForm.data;
    return { accountForm, dispatchAPI, updateMyAccount, getUser, userDetail, id, role
   };
}
export default withRouter(connect( mapStateToProps )( AccountFormWorker ));
