import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import {cancelOrdersFormDispatch} from "../../../components/pages/orders/form/OrdersForm-Action";
import store from '../../../Store';
import {clone} from "../../../helpers/DataHelper";

function _success(success) {
    return {type: 'POST_ORDERS_SUCCESS', success};
}

function _error(error) {
    return {type: 'POST_ORDERS_ERROR', error};
}

function _processing(processing) {
    return {type: 'POST_ORDERS_PROCESSING', processing};
}

function createOrders()
{
    return (dispatch, getState) =>
    {
        dispatch(_processing(true));
        dispatch(_error(null));
        dispatch(_success(null));

        let { ordersForm } = getState();
        let { files, payload } = ordersForm.data;

        if ( !ordersForm.data.payload.approval_status )
            delete ordersForm.data.payload.approval_status;

        let formData = new FormData();


        files && Object.keys(files).map(key => {
                formData.append(`documents[]`, files[ key ])
        });

        Object.keys(payload).map((key) => {
        if( key !== 'documents') {
            formData.append(key, payload[key]);
        }
    });

        const request = axios({
            url: apiBaseUrl(`api/orders`),
            method: "post",
            data: formData,
            headers: {
                'Content-type': 'multipart/form-data',
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res);

            }).catch(function (error) {
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError(error)
                store.dispatch( cancelOrdersFormDispatch() )
            });

        return {
            type: 'POST_ORDERS_SUCCESS',
            payload: request
        }
    }
}


export default createOrders;