import { UserFormState } from "./UserForm-State"
import { errorsList, errorsPresent, mergeServerValidationErrors } from "../../../../helpers/ErrorHelper"
import { endOfURL } from "../../../../helpers/UrlHelper"
import {inRecord, isObject} from "../../../../helpers/DataHelper";

export default function userFormReducer(state = UserFormState, action) {

  let { data } = { ...state };
  let { validationErrors, name, value, users, option } = action
  switch (action.type) {

    case "ON-USER-FORM-CHANGE":
      data.payload[name] = value;
      if ( name === "role" ) {
        if( value === "store-manager" ) {
          data.payload.chain_id = "";
          data.payload.region_id = "";
          data.payload.store_id = [];
        } else if( value === "chain-admin" ) {
          data.payload.chain_id = [];
          data.payload.region_id = "";
          data.payload.store_id = "";
        } else if ( value === "regional-director" ) {
          data.payload.chain_id = "";
          data.payload.region_id = [];
          data.payload.store_id = "";
        } else {
          data.payload.chain_id = "";
          data.payload.region_id = "";
          data.payload.store_id = "";
        }

      }
      if ( name === "chain_id" ) {
        data.payload.region_id = [];
        data.payload.store_id = [];
      }
      if ( name === "region_id" ) {
        data.payload.store_id = [];
      }
      buildErrors();
      return { ...state, data };

    case "ON-USER-FORM-MULTI-VALUE-CHANGE":
      if( value ) {
        if(data.payload[name].includes( value)) {
          data.payload[name] = data.payload[name].filter( item => item !== value );
        } else {
          value && data.payload[name].push(value);
        }
      } else {
        data.payload[name] = []
      }

      // data.payload[name] =  value;
      if ( name === "chain_id" ) {
        data.payload.region_id = [];
        data.payload.store_id = [];
      }
      if ( name === "region_id" ) {
        data.payload.store_id = [];
      }
      buildErrors();
      return { ...state, data };

      case "ON-USER-FORM-CLEAR-ALL-VALUE":
        data.payload[name] = [];
      if ( name === "chain_id" ) {
        data.payload.region_id = [];
        data.payload.store_id = [];
      }
      if ( name === "region_id" ) {
        data.payload.store_id = [];
      }
      buildErrors();
      return { ...state, data };

    case "SET-USER-DATA-FOR-UPDATE":
      data.payload = {
        name: users.name,
        email: users.email,
        phone_no: users.phone_no,
        role: users.role,

        entity_type: "",
        entity_id: [],

        chain_id: [],
        region_id: [],
        store_id: []
      };
      switch ( users.role ) {
        case "chain-admin":
          // data.payload.chain_id = users.entity_id; oldone
          data.payload.chain_id = users.entities_ids;
          data.payload.region_id = [];
          data.payload.store_id = [];
          data.selected_chain_options= []
          users.entities_data.length && users.entities_data.map( en =>
            data.selected_chain_options.push( { value: en["id"], label: en["chain_name"] })
          )
          break;

        case "regional-director":
          data.payload.chain_id = users.entity_detail.chain_id;
          // data.payload.region_id = users.entity_id;
          data.payload.region_id = users.entities_ids;
          data.payload.store_id = [];
          data.selected_region_options= []
          users.entities_data.length && users.entities_data.map( en =>
              data.selected_region_options.push( { value: en["id"], label: en["region_name"] })
          )

          break;

        case "store-manager":
          data.payload.chain_id = users.entity_detail.chain_id;
          data.payload.region_id = users.entity_detail.region_id;
          // data.payload.store_id = users.entity_id;
          data.payload.store_id = users.entities_ids;
          data.selected_store_options = [];
          users.entities_data.length && users.entities_data.map( en =>
              data.selected_store_options.push( { value: en["id"], label: en["store_name"] })
          )
          break;
      }

      buildErrors();
      return { ...state, data };

    case "SET-DEFAULT-USER-FORM":
      data = {
        payload:{
          name: "",
          email: "",
          phone_no: "",
          role: "",

          entity_type: "",
          entity_id: [],

          chain_id: [],
          region_id: [],
          store_id: []
        },
        errors: [],
        errorsPresent: false,
        submitted: false,
        dispatchAPI: false,
        mode:endOfURL(),
        message: ""
      };
      buildErrors();
      return { ...state, data };

    case "CANCEL-USER-FORM-DISPATCH":
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors();
      return {...state, data};

    case "ON-USER-FORM-SUBMIT":
      data.submitted = true;
      buildErrors();
      if (!data.errorsPresent) data.dispatchAPI = true;
      return { ...state, data };

    case "USER-FORM-SERVER-VALIDATION-ERRORS":
      validationErrors && isObject( validationErrors ) &&
      Object.keys(validationErrors).map(key =>
        data.errors[key] = [{ message: " - "+validationErrors[key][0].slice(0, -1) }]
      );
      data.dispatchAPI = false;
      return { ...state, data };

    default:
      return state
  }

  function buildErrors()
  {
    data.errors = errorsList(errors)
    data.errorsPresent = errorsPresent(errors)
  }

  function errors(field)
  {
    let { payload, submitted, validationErrors } = data;
    let errors = {};
    Object.keys(payload).map(key => errors[key] = []);

    if (submitted) {
      if (field === "name") {
        if (!_(field)) newError(field, 0, "is required")
      }

      if (field === "email") {
        if (!_(field)) newError(field, 0, "is required")
      }

      if (field === "role") {
        if (!_(field)) newError(field, 0, "is required")
      }

      if (field === "phone_no") {
        if (!_(field)) newError(field, 0, "is required")
      }

      if ( field === "chain_id" ) {
        if ( payload.role === "chain-admin" && !_(field) ) newError( field, 0, "is required")
        if ( payload.role === "regional-director" && !_(field) ) newError( field, 0, "is required")
      }

      if ( field === "region_id" ) {
        if ( payload.role === "regional-director" && !_(field) ) newError( field, 0, "is required")
        // if ( payload.role === "store-manager" && !_(field) ) newError( field, 0, "is required")
      }

      if ( field === "store_id" ) {
        if ( payload.role === "store-manager" && !_(field) ) newError( field, 0, "is required")
      }
    }

    errors = mergeServerValidationErrors(errors, validationErrors)

    return field ? errors[field] : errors

    function newError(field, step, message) {
      errors && errors[field].push({ step, message })
    }

    function _(field) {
      return data.payload[field]
    }
  }
}

