import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";

function _success(success)
{
    return { type: 'DELETE_FILE_SUCCESS', success };
}
function _error(error)
{
    return { type: 'DELETE_FILE_ERROR', error };
}
function _processing( processing, id )
{
    return { type: 'DELETE_FILE_PROCESSING', processing, id };
}

function deleteFile( id )
{
    return ( dispatch ) =>
    {
        dispatch(_processing(true, id ));
        axios({
            url: apiBaseUrl(`api/admin/files/${id}`),
            method: "delete",
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        })
        .then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false, id ));
            handleSuccess(res);

        }).catch(function(error)
        {
            dispatch(_error(error));
            dispatch(_processing(false, id ));
            handleError( error );
        });
    }
}
export default deleteFile;