import React from "react";
import { names } from "../../../helpers/StringHelper";
import i18next from "i18next";

class TableHead extends React.Component {
  render() {
    let { fields, params, onClick, noActions } = this.props;
    return (
      <thead>
        <tr>
          {fields.map((field) => {
            let label = field.label;
            return (
              <th
                scope="col"
                key={field.field}
                className={names(
                  params.sort_by === field.field &&
                    params.sort_order === `desc` &&
                    "th-active-desc",
                  params.sort_by === field.field &&
                    params.sort_order === `asc` &&
                    "th-active-asc",
                  field.sort && `th-sortable`
                )}
                onClick={() => field.sort && onClick && onClick(field.field)}
              >
                {i18next.t("fields." + label, { returnObjects: true })}
              </th>
            );
          })}
          {!noActions && (
            <th>{i18next.t("fields.actions", { returnObjects: true })}</th>
          )}
        </tr>
      </thead>
    );
  }
}
export default TableHead;
