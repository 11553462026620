import React from "react";
import { connect } from "react-redux";
import store from "../../../../Store";
import StoreFormWorker from "./StoreFormWorker";
import {
  onStoreFormChange,
  onStoreFormSubmit,
  onStoreMultiValueChange,
} from "./StoreForm-Action";
import SaveButton from "../../../shared/buttons/SaveButton";
import TextField from "../../../shared/form/TextField";
import { names, ucFirst } from "../../../../helpers/StringHelper";
import SelectField from "../../../shared/form/SelectField";
import i18next from "i18next";

class StoreForm extends React.Component {
  storeFormSubmit = (e) => {
    e.preventDefault();
    store.dispatch(onStoreFormSubmit());
  };

  onChange = (e) => {
    store.dispatch(onStoreFormChange(e.target.name, e.target.value));
  };

  onMultiValueChange = (e) => {
    store.dispatch(onStoreMultiValueChange(e.target.name, e.target.value));
  };

  render() {
    let {
      storeForm,
      createStore,
      updateStore,
      getStore,
      chainData,
      regions,
      modal,
      entity,
      entities,
      user_role,
    } = this.props;
    let { payload, errors } = storeForm.data;
    let {
      store_name,
      phone,
      email,
      invoice_address,
      region_id,
      contact_person,
      chain_id,
    } = payload;
    let processing = createStore.processing || updateStore.processing;
    let showRegion = false;
    if (chainData) {
      showRegion = chainData.has_region === 1;
    } else if (entity) {
      showRegion = entity.has_region === 1;
    }
    let { id, mode } = modal;
    return (
      <div className={names("row", getStore.processing && "item-disabled")}>
        <div className="offset-lg-1 col-lg-7 col-md-12">
          {/*<h2>{"${ucFirst( modal.mode )} Store"}</h2>*/}
          <h2>
            {i18next.t(
              modal.mode === "create"
                ? "store.create-store"
                : "store.update-store",
              { returnObjects: true }
            )}
          </h2>
          <form onSubmit={this.storeFormSubmit}>
            <TextField
              name="store_name"
              label={i18next.t("store.store-name", { returnObjects: true })}
              value={store_name}
              errors={errors.store_name}
              onChange={this.onChange}
            />

            <TextField
              name="phone"
              value={phone}
              label={i18next.t("fields.phone", { returnObjects: true })}
              errors={errors.phone}
              onChange={this.onChange}
            />

            <TextField
              name="email"
              value={email}
              label={i18next.t("fields.email", { returnObjects: true })}
              errors={errors.email}
              onChange={this.onChange}
            />

            <TextField
              name="invoice_address"
              label={i18next.t("fields.store-address", { returnObjects: true })}
              value={invoice_address}
              errors={errors.invoice_address}
              onChange={this.onChange}
            />

            <TextField
              name="contact_person"
              label={i18next.t("fields.contact-person", {
                returnObjects: true,
              })}
              value={contact_person}
              errors={errors.contact_person}
              onChange={this.onChange}
            />

            {user_role === "chain-admin" ? (
              <SelectField
                name="chain_id"
                options={entities}
                _value="id"
                _label="chain_name"
                value={chain_id}
                errors={errors.chain_id}
                label={i18next.t("fields.chain", { returnObjects: true })}
                placeholder={
                  "-- " +
                  i18next.t("fields.chain", {
                    returnObjects: true,
                  }) +
                  " --"
                }
                disabled={id && mode === "update"}
                onChange={this.onChange}
              />
            ) : null}

            {showRegion ? (
              <SelectField
                name="region_id"
                value={region_id}
                _value="id"
                _label="region_name"
                options={regions}
                label={i18next.t("region.region", { returnObjects: true })}
                placeholder={
                  "-- " +
                  i18next.t("region.region", {
                    returnObjects: true,
                  }) +
                  " --"
                }
                errors={errors.region_id}
                onChange={this.onChange}
              />
            ) : null}

            <div className="text-center">
              <SaveButton processing={processing} />
            </div>
            <StoreFormWorker />
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    storeForm,
    createStore,
    updateStore,
    getStore,
    getChain,
    getChainRegions,
    storeList,
    route,
  } = state;
  let { entity, entities, user_role } = route.data;
  let chainData = getChain && getChain.success && getChain.success.data.payload;
  let { modal } = storeList.data;

  let regions =
    getChainRegions &&
    getChainRegions.success &&
    getChainRegions.success.data &&
    getChainRegions.success.data.payload.data;

  let { mode, search, errors } = storeForm.data;
  return {
    storeForm,
    mode,
    search,
    createStore,
    updateStore,
    getStore,
    errors,
    getChain,
    chainData,
    getChainRegions,
    regions,
    modal,
    storeList,
    entity,
    entities,
    user_role,
  };
}

export default connect(mapStateToProps)(StoreForm);
