import { ImportStoresFormState } from "./ImportStoresForm-State"
import { errorsList, errorsPresent, mergeServerValidationErrors } from "../../../../helpers/ErrorHelper"

export default function importStoresFormReducer(state = ImportStoresFormState, action) {

  let { data } = { ...state };
  let { validationErrors, name, value } = action
  switch (action.type) {

    case "ON-IMPORT-STORE-FORM-CHANGE":
      if( name === "chain_id" ) {
        data.payload[name] = +value;
      }else {
        data.payload[name] = value;
      }
      buildErrors();
      return { ...state, data };

    case "SET-DEFAULT-IMPORT-STORE-FORM":
      data = {
        payload:{
          chain_id: "",
          data_file: ""
        },
        errors: [],
        files: [],
        errorsPresent: false,
        submitted: false,
        dispatchAPI: false,
      }
      buildErrors()
      return { ...state, data }

    case "CANCEL-IMPORT-STORE-FORM-DISPATCH":
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors();
      return {...state, data};

    case "ON-IMPORT-STORE-FORM-SUBMIT":
      data.submitted = true;
      buildErrors();
      if (!data.errorsPresent) data.dispatchAPI = true;
      return { ...state, data };

    case "IMPORT-STORE-FORM-SERVER-VALIDATION-ERRORS":
      validationErrors && Object.keys(validationErrors).map(key =>
        data.errors[key] = [{ message: " - "+validationErrors[key][0].slice(0, -1)}]
      )
      data.dispatchAPI = false;
      return { ...state, data };

    default:
      return state
  }

  function buildErrors() {
    data.errors = errorsList(errors);
    data.errorsPresent = errorsPresent(errors)
  }

  function errors(field) {
    let { payload, submitted, serverValidationErrors } = data;
    let errors = {};
    Object.keys(payload).map(key => errors[key] = []);

    if (submitted)
    {
      if (field === "chain_id") {
        if (!_(field)) newError(field, 0, "is required")
      }
      if (field === "data_file") {
        if (!_(field)) newError(field, 0, "is required")
      }
    }

    errors = mergeServerValidationErrors(errors, serverValidationErrors);

    return field ? errors[field] : errors

    function newError(field, step, message) {
      errors && errors[field].push({ step, message })
    }

    function _(field) {
      return data.payload[field]
    }
  }
}

