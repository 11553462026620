import store from "../../../../Store";
import getChains from "../../../../ajax/actions/chains/chains_get"

export function toggleChainListFilter(e)
{
  return function (dispatch) {
    let { name, value } = e.target;
    dispatch({type:"TOGGLE-CHAIN-LIST-FILTER", name, value});
    store.dispatch( getChains());
  }
}

export function toggleChainFormModal( id )
{
  return function (dispatch) {
    dispatch({type:"TOGGLE-CHAIN-FORM-MODAL", id });
  }
}
