import { WorkTypeFormState } from './WorkTypeForm-State'
import { errorsList, errorsPresent, mergeServerValidationErrors } from '../../../../helpers/ErrorHelper'
import { endOfURL } from '../../../../helpers/UrlHelper'

export default function workTypeFormReducer(state = WorkTypeFormState, action) {

  let { data } = { ...state };
  let { validationErrors, name, value, workType } = action
  switch (action.type) {

    case 'ON-WORK-TYPE-FORM-CHANGE':
      data.payload[name] = value;
      buildErrors();
      return { ...state, data };

    case 'SET-WORK-TYPE-DATA-FOR-UPDATE':
      data.payload = {
        title: workType.title,
        translations: workType.translations,
      };
      buildErrors();
      return { ...state, data };

    case 'SET-DEFAULT-WORK-TYPE-FORM':
      data = {
        payload:{
          title:"",
          translations:"",
        },
        errors: [],
        errorsPresent: false,
        submitted: false,
        dispatchAPI: false,
        mode:endOfURL(),
        message: ""
      }
      buildErrors()
      return { ...state, data }

    case 'CANCEL-WORK-TYPE-FORM-DISPATCH':
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors();
      return {...state, data};

    case 'ON-WORK-TYPE-FORM-SUBMIT':
      data.submitted = true;
      buildErrors();
      if (!data.errorsPresent) data.dispatchAPI = true;
      return { ...state, data };

    case 'WORK-TYPE-FORM-SERVER-VALIDATION-ERRORS':
      Object.keys(validationErrors).map(key =>
        data.errors[key] = [{ message: " - "+validationErrors[key][0].slice(0, -1) }]
      )
      data.dispatchAPI = false;
      return { ...state, data };

    default: return state
  }

  function buildErrors() {
    data.errors = errorsList(errors);
    data.errorsPresent = errorsPresent(errors)
  }

  function errors(field) {
    let { payload, submitted, serverValidationErrors } = data;
    let errors = {};
    Object.keys(payload).map(key => errors[key] = []);

    if (submitted) {
      if (field === "title") {
        if (!_(field)) newError(field, 0, "is required")
      }

      // if (field === "slug") {
      //   if (!_(field)) newError(field, 0, "is required")
      // }
    }

    errors = mergeServerValidationErrors(errors, serverValidationErrors);

    return field ? errors[field] : errors

    function newError(field, step, message) {
      errors && errors[field].push({ step, message })
    }

    function _(field) {
      return data.payload[field]
    }
  }
}

