import { ChatState } from "./Chat-State"
import {errorsList, errorsPresent, mergeServerValidationErrors} from "../../../../helpers/ErrorHelper";

export default function chatStateReducer(state = ChatState, action) {

  let { data } = { ...state };
  let {id, validationErrors, name, value, chats,count  } = action;

  switch (action.type) {

    case "ON-CHAT-FORM-CHANGE":
      data.payload[name] = value;
      buildErrors();
      return { ...state, data };

    case "SET-INITIALLOADING-MODE":
      data.initialLoading = !!data.initialLoading;
      buildErrors();
      return { ...state, data };

    case "SET-UNSEEN-CHAT-IDS":
      let counter = 0;
      data.initialLoading = false;
      chats && chats.length && chats.map( c => {
        counter = counter + 1;
        if( counter <= data.unread_chat_count ) {
          if( !data.chat_seen.includes(c.id) ){
            data.chat_seen.push( c.id );
          }
        }
      })
      buildErrors();
      return { ...state, data };

    case "SET-UNREAD-CHAT-COUNT":
      data.unread_chat_count = count;
      buildErrors();
      return { ...state, data };

    case "ON-CHAT-FORM-SUBMIT":
      data.submitted = true;
      buildErrors();
      if (!data.errorsPresent) data.dispatchAPI = true;
      return { ...state, data };

    case "CANCEL-CHAT-FORM-DISPATCH":
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors();
      return {...state, data};

    case "ON-SCROLL-LIMIT-INCREASE":
      data.params.limit = data.params.limit + 10;
      buildErrors();
      return {...state, data};

    case "SET-DEFAULT-CHAT-FORM":
      data = {
        initialLoading: false,
        payload:{
          body:"",
        },
        params:{
          limit:10
        },
        chat_seen:[],
        unread_chat_count:"",
        errors: [],
        errorsPresent: false,
        submitted: false,
        dispatchAPI: false,
        message: ""
      };
      buildErrors();
      return {...state, data};

    case "CHAT-FORM-SERVER-VALIDATION-ERRORS":
      Object.keys(validationErrors).map(key =>
          data.errors[key] = [{ message: " - "+validationErrors[key][0].slice(0, -1) }]
      )
      data.dispatchAPI = false;
      return { ...state, data };

    default: return state
  }

  function buildErrors() {
    data.errors = errorsList(errors);
    data.errorsPresent = errorsPresent(errors)
  }

  function errors(field) {
    let { payload, submitted, serverValidationErrors } = data;
    let errors = {};
    Object.keys(payload).map(key => errors[key] = []);

    if (submitted) {
      if (field === "body") {
        if (!_(field)) newError(field, 0, "is required")
      }
    }

    errors = mergeServerValidationErrors(errors, serverValidationErrors);

    return field ? errors[field] : errors

    function newError(field, step, message) {
      errors && errors[field].push({ step, message })
    }

    function _(field) {
      return data.payload[field]
    }
  }
}
