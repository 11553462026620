import React from "react";
import i18next from "i18next";
class ChainAdminInfo extends React.Component {
  render() {
    let { data } = this.props;
    return (
      <div className="sidebar-title">
        <h4>{i18next.t("header.chain-dashboard")}</h4>
      </div>
    );
  }
}
export default ChainAdminInfo;
