import React from 'react';
import store from '../../../../Store'
import {connect} from 'react-redux';
import { equalWhenStringified } from '../../../../helpers/StringHelper'
import {
  setDefaultWorkTypeForm,
  setWorkTypeDataForUpdate
} from './WorkTypeForm-Action'
import getWorkType from "../../../../ajax/actions/work-types/work_type_get";
import updateWorkType from "../../../../ajax/actions/work-types/work_type_put";
import createWorkType from "../../../../ajax/actions/work-types/work_type_post";

class WorkTypeFormWorker extends React.Component
{
  componentDidMount()
  {
    let { modal } = this.props;
    let { id, mode } = modal;
    if(id) {
      store.dispatch(getWorkType(id));
    }
    if(mode === "create") {
      store.dispatch(setDefaultWorkTypeForm());
    }
  }

  componentDidUpdate(prev)
  {
    let { dispatchAPI, modal } = this.props;
    let { id } = modal;

    if(!equalWhenStringified(this.props.getWorkType, prev.getWorkType)) {
      let {success} = this.props.getWorkType;
      if (success) {
        store.dispatch(setWorkTypeDataForUpdate());
      }
    }


    if (dispatchAPI) {
      if (!prev.dispatchAPI) {
        if(id) {
          store.dispatch(updateWorkType(id));
        } else {
          store.dispatch(createWorkType());
        }
      }
    }
  }

  render()
  {
    return null
  }
}
function mapStateToProps(state) {
  let { workTypeForm, createWorkType, updateWorkType, getWorkType, workTypeList } = state;
  let { modal } = workTypeList.data;
  let { dispatchAPI } = workTypeForm.data;

  return { workTypeForm, dispatchAPI, createWorkType, updateWorkType, getWorkType, workTypeList, modal };
}
export default connect(mapStateToProps)(WorkTypeFormWorker);
