import importStores from "../../../../ajax/actions/stores/import_store_post";
import store from "../../../../Store";
import {resetForm} from "../../../../helpers/FormHelper";

export function importStoreFormServerValidationErrors( validationErrors ) {
  return function (dispatch) {
    dispatch({type:"IMPORT-STORE-FORM-SERVER-VALIDATION-ERRORS", validationErrors });
  }
}

export function onImportStoreFormSubmit( ) {
  return function (dispatch) {
    dispatch({type:"ON-IMPORT-STORE-FORM-SUBMIT" });
  }
}

export function onImportStoreFormChange( name, value ) {
  return function (dispatch){
      dispatch({type:"ON-IMPORT-STORE-FORM-CHANGE", name, value});
  }
}

export function cancelImportStoreFormDispatch() {
  return function (dispatch) {
    dispatch({ type:"CANCEL-IMPORT-STORE-FORM-DISPATCH" });
  }
}

export function setDefaultImportStoreForm() {
  return function (dispatch){
    store.dispatch( importStores("clear"))
    resetForm("bulk-store-import-form")
    dispatch({type:"SET-DEFAULT-IMPORT-STORE-FORM"});
  }
}
