import { FileListState } from "./FileList-State"

export default function fileListReducer(state = FileListState, action) {

  let { data } = { ...state }
  let { name, value, id, list, categoryID } = action;

  switch (action.type) {

    case "TOGGLE-ROLE-LIST-FILTER":
      data.params[ name ] = value;
      if ( name === "sort_by" ) {
        if ( data.params.sort_by === value ) {
          data.params.sort_order = data.params.sort_order === "desc" ? "asc" : "desc"
        }
      }
      if ( name === "limit" ) {
        data.params.page = 1;
      }
      return { ...state, data };

    case "TOGGLE-FILE-FORM-MODAL":
      data.file_modal = !data.file_modal;
      return { ...state, data };

    case "SAVE-FILE-ID-FOR-DELETE":
      data.delete_file_id = id;
      data.delete_file_category_id = categoryID;
      return { ...state, data };

    case "SET-CATEGORIES-LIST":
      data.file_list = list;
      return { ...state, data };

    case "DELETE-FILE-FROM-CATEGORY":
      data.file_list.map( f => {
        if( f.id === data.delete_file_category_id ) {
         f.uploads = f.uploads.filter( s => s.id !== data.delete_file_id )
        }
      } )
      return { ...state, data };

    default: return state
  }
}
