import bulkUsersUpdate from "../../../../ajax/actions/user/bulk_user_update_post";
import store from "../../../../Store";
import {resetForm} from "../../../../helpers/FormHelper";

export function bulkUserUpdateFormServerValidationErrors( validationErrors ) {
  return function (dispatch) {
    dispatch({type:"BULK-USERS-UPDATE-FORM-SERVER-VALIDATION-ERRORS", validationErrors });
  }
}

export function onBulkUserUpdateFormSubmit( ) {
  return function (dispatch) {
    dispatch({type:"ON-BULK-USERS-UPDATE-FORM-SUBMIT" });
  }
}

export function onBulkUserUpdateFormChange( name, value ) {
  return function (dispatch){
      dispatch({type:"ON-BULK-USERS-UPDATE-FORM-CHANGE", name, value});
  }
}

export function cancelBulkUserUpdateFormDispatch() {
  return function (dispatch) {
    dispatch({ type:"CANCEL-BULK-USERS-UPDATE-FORM-DISPATCH" });
  }
}

export function setDefaultBulkUserUpdateForm() {
  return function (dispatch){
    store.dispatch( bulkUsersUpdate( "clear" ));
    resetForm("bulk-user-update-form");
    dispatch({type:"SET-DEFAULT-BULK-USERS-UPDATE-FORM"});
  }
}
