const DefaultStoreListState = {
  payload: {
    initialLoading: true,
    flashMessage: null,
    error: "",
    success: "",
  },
  params: {
    limit: 25,
    page: 1,
    sort_order: "desc",
    sort_by: "id",
    q: "",
    region_id: "",
    chain_id: "",
  },
  modal: {
    id: "",
    mode: "",
  },
  fields: [
    { field: "id", label: "id", sort: true },
    { field: "store-name", label: "store-name", sort: true },
    { field: "phone", label: "phone", sort: true },
    { field: "email", label: "email", sort: true },
    { field: "invoice-address", label: "store-address", sort: true },
    { field: "chain", label: "chain", sort: false },
    { field: "region", label: "region", sort: false },
    { field: "contact-person", label: "contact-person", sort: true },
  ],
};

export const StoreListState = { data: DefaultStoreListState };
