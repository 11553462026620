import {ConfigAPI, ConfigApp, ConfigPath} from "../Config";

export const apiBaseUrl = endpoint =>
{
    let url = ConfigApp.Production ? ConfigAPI.Live.EndPoint : ConfigAPI.Staging.EndPoint
    return endpoint ? url + endpoint : url;
};

export const basePath = endpoint =>
{
    let url = ConfigApp.Production ? ConfigPath.Live.EndPoint : ConfigPath.Staging.EndPoint
    return endpoint ? url + endpoint : url;
};

export const urlTrailArray = ()  => urlTrailWithOutParams().split("/").filter(item => item.length)
export const endOfURL = () => lastItemInURL();
export const lastItemInURL = () => urlTrailArray()[urlTrailArray().length - 1];

export const urlTrailWithOutParams = () =>
{
    if ( !urlTrail().includes("?") ) return urlTrail();
    let part = urlTrail().split("?");
    return part[0];
}

export const urlTrail = () =>
{
    let trail = "";
    let { URL } = document;
    let part = URL.trim().split("//");
    part[1].split("/").map((p, i) => { if (i) trail += "/"+p });
    return trail
}

export const pushToPage = (props, path) =>
{
    props.history.push(path);
};

export const urlTrailWithoutLastItem = () =>
{
    let url = ""
    urlTrailArray().map(( item, i) => {
        if (i !== urlTrailArray().length - 1  ) {
            url += "/"+item
        }
    })
    return url;
}

export const activateTab = ( key, index ) =>
{
    return urlTrailArray()[ index ] === key ? "tab-active" : null;
}
