import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import store from "../../../../../Store";
import getChainRegions from "../../../../../ajax/actions/chain-regions/chain_regions_get";

class ChainRegionsWorker extends React.Component
{
  componentDidMount()
  {
      let { entity_id } = this.props;
      store.dispatch(getChainRegions(null, entity_id, null));
  }

  componentDidUpdate(prev) {
    let { deleteChainRegion,getChain} = this.props;

    if(deleteChainRegion !== prev.deleteChainRegion) {
      let {success} = deleteChainRegion;
      if(success) {
        store.dispatch(getChainRegions());
      }
    }

    if(getChain !== prev.getChain) {
      let {success} = getChain;
      if(success) {
        store.dispatch(getChainRegions());
      }
    }
  }

  render()
  {
      return null
  }
}
function mapStateToProps(state) {
   let {deleteChainRegion, getChain, userDetail }  = state;
   // console.log(` <| userDetail |> `, userDetail)
    let entity_id = userDetail.success && userDetail.success.entity_id;
    let entity_type = userDetail.success && userDetail.success.entity_type;
   return { deleteChainRegion, getChain, userDetail, entity_id, entity_type }
 }
export default withRouter(connect(mapStateToProps)( ChainRegionsWorker ));
