import { ChainRegionsFormState } from "./ChainRegionsForm-State"
import { errorsList, errorsPresent, mergeServerValidationErrors } from "../../../../../helpers/ErrorHelper"
import { endOfURL } from "../../../../../helpers/UrlHelper"
import {isValidEmail} from "../../../../../helpers/StringHelper";
import i18next from "i18next";

export default function chainRegionsFormReducer(state = ChainRegionsFormState, action) {

  let { data } = { ...state };
  let { validationErrors, name, value, regions } = action
  switch (action.type) {

    case "ON-CHAIN-REGION-FORM-CHANGE":
      data.payload[name] = value;
      buildErrors();
      return { ...state, data };

    case "SET-CHAIN-REGION-DATA-FOR-UPDATE":
      data.payload = {
        region_name: regions.region_name,
        phone: regions.phone,
        email: regions.email,
        contact_person: regions.contact_person,
        chain_id: regions.chain_id,
      };
      buildErrors();
      return { ...state, data };

    case "SET-DEFAULT-CHAIN-REGION-FORM":
      data = {
        payload:{
          region_name:"",
          phone:"",
          email:"",
          contact_person:"",
          chain_id:"",
        },
        errors: [],
        errorsPresent: false,
        submitted: false,
        dispatchAPI: false,
        mode:endOfURL(),
        message: ""
      };
      buildErrors();
      return { ...state, data };

    case "CANCEL-CHAIN-REGION-FORM-DISPATCH":
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors();
      return {...state, data};

    case "ON-CHAIN-REGION-FORM-SUBMIT":
      data.submitted = true;
      buildErrors();
      if (!data.errorsPresent) data.dispatchAPI = true;
      return { ...state, data };

    case "CHAIN-REGION-FORM-SERVER-VALIDATION-ERRORS":
      Object.keys(validationErrors).map(key =>
        data.errors[key] = [{ message: " - "+validationErrors[key][0].slice(0, -1) }]
      );
      data.dispatchAPI = false
      return { ...state, data };

    default:
      return state
  }

  function buildErrors() {
    data.errors = errorsList(errors);
    data.errorsPresent = errorsPresent(errors)
  }

  function errors(field) {
    let { payload, submitted, validationErrors } = data;
    let errors = {};
    Object.keys( payload ).map( key => errors[key] = [] );

    if (submitted) {
      if (field === "region_name") {
        if (!_(field)) newError(field, 0, i18next.t("chain.is-required"))
      }

      if (field === "phone") {
        if (!_(field)) newError(field, 0, i18next.t("chain.is-required"))
        else if (_(field).length !== 10) newError(field, 0, i18next.t("chain.field-length"));
      }

      if (field === "email") {
        /*if (!_(field).length) newError(field,0, "is required");
        else if (_(field).length > 42) newError(field, 0, "is too Long");
        else*/ if (_(field).length && !isValidEmail(_(field))) newError(field, 0, i18next.t("chain.is-invalid"));
      }

      if (field === "contact_person") {
        if (!_(field)) newError(field, 0, i18next.t("chain.is-required"))
      }
    }

    errors = mergeServerValidationErrors( errors, validationErrors )

    return field ? errors[field] : errors

    function newError(field, step, message) {
      errors && errors[field].push({ step, message })
    }

    function _(field) {
      return data.payload[field]
    }
  }
}

