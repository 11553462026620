import axios from "axios";
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from "../../../helpers/LocalStorageHelper"
import { handleError, handleSuccess } from "../../../helpers/ErrorHelper"
import store from "../../../Store";

function _success(success)
{
    return { type: "POST_EXPORT_SUCCESS", success };
}
function _error(error)
{
    return { type: "POST_EXPORT_ERROR", error };
}
function _processing(processing)
{
    return { type: "POST_EXPORT_PROCESSING", processing };
}

function createExport( mode, chainID )
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true));
        let params = {};
        if( mode === "user" ) {
            params = {type: "UsersExport"}
        } else if( mode === "store" ) {
            params = { type :"StoresExport",chain_id:chainID }
        } else if ( mode === "order" ) {
            params = { type:"OrdersExport" }
        } else if( mode === "chain" ) {
            params = { type: "ChainsExport"}
        }else if( mode === "region" ) {
            params = { type: "RegionsExport"}
        }
        // console.log(" <| data |> ", params)
       axios({
            url: apiBaseUrl("api/export"),
            method: "get",
            dataType: "json",
            params,
            headers: {
                "Authorization": "Bearer " + loginToken(),
                lang:localStorage.getItem("lang" ) || "en"
            }
       })
        .then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false));
            // res.download("./download-export/export.pdf");
            handleSuccess("Data has been exported");
        }).catch(function(error){
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError( error );
        });
    }
}

export default createExport;
