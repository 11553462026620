import axios from 'axios'
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from "../../../helpers/LocalStorageHelper";
import {handleError} from "../../../helpers/ErrorHelper";
import {clone} from "../../../helpers/DataHelper";

function _success(success) {
    return {type: 'GET_CHATS_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_CHATS_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_CHATS_PROCESSING', processing};
}

function getChats( order_id ) {
    return (dispatch, getState) => {

        dispatch(_processing(true));
        dispatch(_error(null));
        dispatch(_success(null));

        let { chatState } = getState();
        let { params } = chatState.data;

        axios({
            url:apiBaseUrl(`api/orders/${order_id}/messages`),
            method:'get',
            params,
            headers:{
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        }).then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false));
        }).catch(function(error){
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error);
        });
    }
}

export default getChats;