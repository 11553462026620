import { fromRecord } from "../../../../helpers/DataHelper";
import store from "../../../../Store";
import getChainRegions from "../../../../ajax/actions/chain-regions/chain_regions_get";
import getStores from "../../../../ajax/actions/stores/stores_get";
import { handleError } from "../../../../helpers/ErrorHelper";
import { ISO_8601 } from "moment/moment";

export function ordersFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        handleError(validationErrors);
        dispatch({
            type: "ORDERS-FORM-SERVER-VALIDATION-ERRORS",
            validationErrors,
        });
    };
}

export function onOrdersFormSubmit() {
    return function (dispatch) {
        dispatch({ type: "ON-ORDERS-FORM-SUBMIT" });
    };
}

export function onOrdersFormChange(name, value) {
    return function (dispatch, getState) {
        dispatch({ type: "ON-ORDERS-FORM-CHANGE", name, value });

        let { ordersForm, getChains } = getState();
        let chain_id = ordersForm.data.payload.chain_id;
        if (name === "chain_id") {
            if (
                getChains.success &&
                getChains.success.data.payload.data &&
                fromRecord(
                    getChains.success.data.payload.data,
                    "id",
                    "==",
                    value
                ).has_region
            ) {
                store.dispatch(getChainRegions(null, null, "clear"));
                store.dispatch(getStores(null, chain_id, "clear"));
                store.dispatch(getChainRegions(null, chain_id, ""));
            } else if (
                getChains.success &&
                getChains.success.data.payload.data &&
                fromRecord(
                    getChains.success.data.payload.data,
                    "id",
                    "==",
                    value
                ).has_region == 0
            ) {
                store.dispatch(getChainRegions(null, null, "clear"));
                store.dispatch(getStores(null, null, "clear"));
                store.dispatch(getStores(null, chain_id, null, ""));
            }
        }
        if (name === "region_id") {
            dispatch({
                type: "ON-ORDERS-FORM-CHANGE",
                name: "store_id",
                value: "",
            });
            store.dispatch(getStores(null, null, null, "clear")); // clear the list
            store.dispatch(getStores(500, chain_id, value)); // fetch again
        }
    };
}

export function cancelOrdersFormDispatch() {
    return function (dispatch) {
        dispatch({ type: "CANCEL-ORDERS-FORM-DISPATCH" });
    };
}

export function setDefaultOrdersForm() {
    return function (dispatch) {
        dispatch({ type: "SET-DEFAULT-ORDERS-FORM" });
    };
}

export function setRoleForOrderForm(userRole) {
    return function (dispatch) {
        dispatch({ type: "SET-ROLE-FOR-ORDERS-FORM", userRole });
    };
}
