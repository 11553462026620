const DefaultRoleListState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    limit: 25,
    page:   1,
    sort_by:  "id",
    sort_order: "desc",
    q: "",
  },
  modal: {
    mode: "",
    id: ""
  },
  fields: [
    { field: "id", label: "id", sort: true },
    { field: "title", label: "title", sort: true },
    { field: "slug", label: "slug", sort: true },
  ]
};

export const RoleListState = { data: DefaultRoleListState };
