import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'

function _success(success)
{
    return { type: 'DELETE_CHAIN_REGION_SUCCESS', success };
}
function _error(error)
{
    return { type: 'DELETE_CHAIN_REGION_ERROR', error };
}
function _processing(processing)
{
    return { type: 'DELETE_CHAIN_REGION_PROCESSING', processing };
}

function deleteChainRegion(chain_id, region_id )
{
    return ( dispatch ) =>
    {
      dispatch(_processing(true));
      const request = axios({
        url: apiBaseUrl(`api/chains/${chain_id}/regions/${region_id}`),
        method: "delete",
        dataType: 'json',
        headers: {
          'Authorization': 'Bearer ' + loginToken(),
            lang:localStorage.getItem('lang' ) || 'en'
        }
      })
            .then(function(res){
                handleSuccess(res)
                dispatch(_success(res));
                dispatch(_processing(false));

            }).catch(function(error){
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError( error )

            });

        return {
            type: 'DELETE_CHAIN_REGION_SUCCESS',
            payload: request
        }
    }
}


export default deleteChainRegion;