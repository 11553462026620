const DefaultChainRegionListState = {
  payload: {
    initialLoading: true,
    flashMessage: null,
    error: "",
    success: "",
  },
  params: {
    limit: 25,
    page: 1,
    sort_order: "desc",
    sort_by: "id",
    q: "",
  },
  modal: {
    id: "",
    mode: "",
  },
  fields: [
    { field: "id", label: "id", sort: true },
    { field: "chain", label: "chain", sort: true },
    { field: "region_name", label: "region", sort: true },
    { field: "contact_person", label: "regional-director", sort: true },
  ],
};

export const ChainRegionsListState = { data: DefaultChainRegionListState };
