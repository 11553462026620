import React from "react";
import OrderWorker from "./OrdersWorker";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import store from "../../../../Store";
import PaginationButtons from "../../../shared/buttons/PaginationButtons";
import OrderListFilters from "./OrderListFilters";
import { toggleOrderListFilter } from "./OrderList-Actions";
import { fullDate, justDate } from "../../../../helpers/DateTimeHelper";
import OrderUpdateForm from "../detail/OrderUpdateFormModal";
import { toggleOrderDetail } from "../detail/OrderDetail-Actions";
import MyIcon from "../../../shared/icons/MyIcon";
import NoResultsFound from "../../../shared/folder/NoResultsFound";
import Page from "../../../shared/pages/Page";
import Loading from "../../../shared/loaders/Loading";
import Badge from "../../../shared/badges/Badge";
import { withRouter } from "react-router";
import TableHead from "../../../shared/table/TableHead";
import deleteOrder from "../../../../ajax/actions/orders/order_delete";
import { ConfigAPI } from "../../../../Config";
import { apiBaseUrl } from "../../../../helpers/UrlHelper";
import createExport from "../../../../ajax/actions/export/export_post";
import { loginToken } from "../../../../helpers/LocalStorageHelper";
import i18next from "i18next";

class Orders extends React.Component {
  state = {
    initialLoading: true,
  };

  componentDidUpdate(lastProps) {
    let { getOrders } = this.props;
    if (getOrders) {
      if (getOrders.success && this.state.initialLoading) {
        this.setState({ initialLoading: false });
      }
    }
  }

  deleteConfirm = (id) => {
    swal(i18next.t("confirm.delete-order", { returnObjects: true }), {
      buttons: {
        cancel: i18next.t("cancel", { returnObjects: true }),
        catch: {
          text: i18next.t("delete", { returnObjects: true }),
          value: id,
        },
      },
    }).then((value) => {
      if (value) this.delete(value);
    });
  };

  delete(id) {
    store.dispatch(deleteOrder(id));
  }

  openModalOrderUpdate(id) {
    store.dispatch(toggleOrderDetail(id));
  }

  toggleSort = (field) => {
    store.dispatch(
      toggleOrderListFilter({
        target: { name: "sort_by", value: field },
      })
    );
  };

  exportOrders = () => {
    store.dispatch(createExport("order"));
  };

  render() {
    let { list, getOrders, pages, user_role, match, fields, params, chain_id } =
      this.props;
    function is(role) {
      return user_role === role;
    }
    let bearer_token = loginToken();
    let { EndPoint } = ConfigAPI;
    let export_file = apiBaseUrl(
      "api/export?type=OrdersExport&access_token=" +
        bearer_token +
        "&" +
        new URLSearchParams(params).toString()
    );

    return (
      <Page list title={i18next.t("order.orders")}>
        <OrderWorker />

        <h2>
          {i18next.t("order.orders", { returnObjects: true })}{" "}
          {getOrders.processing && <Loading marginLeft={20} />}
          {(is("store-manager") ||
            is("admin") ||
            is("super-admin") ||
            is("chain-admin") ||
            is("regional-director")) && (
            <span className="pull-right">
              <Link to={match.url === "/" ? "/orders" : match.url + "/create"}>
                <button type="button" className="btn-theme btn-gray mt-30">
                  {i18next.t("order.create-order", {
                    returnObjects: true,
                  })}
                </button>
              </Link>
            </span>
          )}
          {list && list.length ? (
            <span className="pull-right">
              <a href={export_file} rel="nofollow" target="_blank">
                <button
                  type="button"
                  className="btn-theme btn-gray mt-30 mr-100"
                >
                  {i18next.t("order.export-orders", {
                    returnObjects: true,
                  })}
                </button>
              </a>
            </span>
          ) : null}
        </h2>

        <OrderListFilters />

        <div className="table-dashboard first-col-lg">
          <div className="table-responsive">
            <table className="table">
              <TableHead
                fields={fields}
                params={params}
                onClick={this.toggleSort}
              />

              <tbody>
                {list && list.length
                  ? list.map((l, key) => {
                      return (
                        <tr key={key}>
                          <td className="clickable-col">
                            <Link to={"orders/" + l.id}>
                              {l.project_number}
                            </Link>
                          </td>
                          <td>{l.chain && l.chain.chain_name}</td>
                          <td>{l.store && l.store.store_name}</td>
                          <td>{l.worktype_label}</td>
                          <td>
                            {i18next.t(l.priority_label, {
                              returnObjects: true,
                            })}
                          </td>
                          <td>{fullDate(l.created_at)}</td>
                          <td>
                            <Badge type={l.status}>
                              {i18next.t(l.status, {
                                returnObjects: true,
                              })}
                            </Badge>
                            {l.status === "completed" && l.completed_at ? (
                              <>
                                <br />
                                <small>{justDate(l.completed_at)}</small>
                              </>
                            ) : null}
                            {l.status === "planned" && l.planned_at ? (
                              <>
                                <br />
                                <small>{justDate(l.planned_at)}</small>
                              </>
                            ) : null}
                          </td>
                          <td>{l.creator && l.creator.name}</td>
                          <td>
                            <Badge type={l.approval_status}>
                              {i18next.t(l.approval_status, {
                                returnObjects: true,
                              })}
                            </Badge>
                            {l.approval_status_date && (
                              <>
                                <br />
                                <small>
                                  {fullDate(l.approval_status_date)}
                                </small>
                              </>
                            )}
                          </td>
                          <td>
                            <button
                              type="button"
                              onClick={() => this.openModalOrderUpdate(l.id)}
                              className="btn btn-secondary btn-sm"
                            >
                              <MyIcon type="edit" />
                            </button>

                            <Link
                              to={
                                match.url === "/"
                                  ? "/orders"
                                  : match.url + "/" + l.id
                              }
                              className="btn btn-red btn-sm ml-1"
                            >
                              <MyIcon type="eye" />
                            </Link>

                            <a
                              target="_blank"
                              className="btn btn-red btn-sm ml-1"
                              href={apiBaseUrl(
                                "api/orders/generatePdf/" +
                                  l.id +
                                  "?token=" +
                                  localStorage.getItem("Bearer")
                              )}
                            >
                              <MyIcon type="print" />
                            </a>

                            {(is("super-admin") ||
                              is("admin") ||
                              is("chain-admin") ||
                              is("regional-director")) && (
                              <button
                                type="button"
                                onClick={() => this.deleteConfirm(l.id)}
                                className="btn btn-red btn-sm ml-1"
                              >
                                <MyIcon type="trash" />
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
              {list && list.length ? (
                <tfoot>
                  <PaginationButtons
                    pages={pages}
                    processing={getOrders.processing}
                    fetchAction={toggleOrderListFilter}
                  />
                </tfoot>
              ) : null}
            </table>

            <OrderUpdateForm />

            {!this.state.initialLoading && (!list || !list.length) && (
              <NoResultsFound />
            )}
            {this.state.initialLoading && (
              <div className="text-center">
                <Loading />
              </div>
            )}
          </div>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  let { getOrders, route, orderList, chainDashboard } = state;
  let { user_role } = route.data;
  let { fields, params } = orderList.data;

  let { chain_id } = chainDashboard.data;

  let list = getOrders.success && getOrders.success.data.payload.data;

  let current_page =
    getOrders.success &&
    getOrders.success.data &&
    getOrders.success.data.payload.current_page;
  let last_page =
    getOrders.success &&
    getOrders.success.data &&
    getOrders.success.data.payload.last_page;
  let total =
    getOrders.success &&
    getOrders.success.data &&
    getOrders.success.data.payload.total;
  let pages = { current_page, last_page, total };

  return {
    getOrders,
    pages,
    list,
    route,
    user_role,
    orderList,
    fields,
    params,
    chainDashboard,
    chain_id,
  };
}

export default withRouter(connect(mapStateToProps)(Orders));
