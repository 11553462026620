import axios from 'axios'
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from "../../../helpers/LocalStorageHelper";
import {handleError} from "../../../helpers/ErrorHelper";
import {clone} from "../../../helpers/DataHelper";

function _success(success) {
    return {type: 'GET_TECHNICIANS_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_TECHNICIANS_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_TECHNICIANS_PROCESSING', processing};
}

function getTechnicians(limit)
{
    return (dispatch,) =>
    {
        dispatch(_processing(true));

        let params = {limit:limit};

        const request = axios({
            url:apiBaseUrl(`api/users/technicians`),
            method:'get',
            params: params,
            headers:{
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        }).then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false));
        }).catch(function(error){
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error);
        });

        return {
            type:'GET_TECHNICIANS_SUCCESS',
            payload:request
        }
    }
}

export default getTechnicians;