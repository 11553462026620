import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import {
    cancelRoleFormDispatch,
    roleFormServerValidationErrors
} from "../../../components/pages/roles/form/RoleForm-Action";
import getRoles from "./roles_get";

function _success(success)
{
    return { type: 'PUT_ROLE_SUCCESS', success };
}
function _error(error)
{
    return { type: 'PUT_ROLE_ERROR', error };
}
function _processing(processing)
{
    return { type: 'PUT_ROLE_PROCESSING', processing };
}

function updateRole(id )
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true));
        let {roleForm} = getState();

        const request = axios({
            url: apiBaseUrl(`api/roles/${id}`),
            method: "put",
            dataType: 'json',
            data: roleForm.data.payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        })
            .then(function(res){
                dispatch(_success(res));
                dispatch(_processing(false));
                store.dispatch(cancelRoleFormDispatch());
                store.dispatch(getRoles());
                handleSuccess( res  )

            }).catch(function(error){
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError( error )
                if ( error && error.response.status === 422 ) {
                    store.dispatch(cancelRoleFormDispatch());
                    store.dispatch(roleFormServerValidationErrors(error.response.data.message))
                }

            });

        return {
            type: 'PUT_ROLE_SUCCESS',
            payload: request
        }
    }
}


export default updateRole;