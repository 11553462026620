import React from "react";
import ChainWorker from "./ChainWorker";
import {connect} from "react-redux";
import store from "../../../../Store";
import {Link} from "react-router-dom";
import swal from "sweetalert";
import deleteChain from "../../../../ajax/actions/chains/chain_delete";
import PaginationButtons from "../../../shared/buttons/PaginationButtons";
import ChainListFilters from "./ChainListFilters";
import {toggleChainFormModal, toggleChainListFilter} from "./ChainList-Actions";
import MyIcon from "../../../shared/icons/MyIcon";
import NoResultsFound from "../../../shared/folder/NoResultsFound";
import Page from "../../../shared/pages/Page";
import Loading from "../../../shared/loaders/Loading";
import ResponsiveModal from "../../../shared/modals/ResponsiveModal";
import ChainForm from "../form/ChainForm";
import TableHead from "../../../shared/table/TableHead";
import createExport from "../../../../ajax/actions/export/export_post";
import {apiBaseUrl} from "../../../../helpers/UrlHelper";
import {loginToken} from "../../../../helpers/LocalStorageHelper";
import i18next from "i18next";

class Chains extends React.Component
{
    state =
        {
            initialLoading: true
        };

    componentDidUpdate(lastProps)
    {
        let { getChains } = this.props;
        if ( getChains ) {
            if ( getChains.success && this.state.initialLoading ) {
                this.setState({ initialLoading: false })
            }
        }
    }

    deleteConfirm = (id) =>
    {
        swal(i18next.t("confirm.delete-chain",{returnObjects:true}), {
            buttons: {
                cancel: i18next.t("cancel",{returnObjects:true}),
                catch: {
                    text: i18next.t("delete",{returnObjects:true}),
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    }

    delete(id)
    {
        store.dispatch(deleteChain(id));
    }

    toggleSort = (field) =>
    {
        store.dispatch(  toggleChainListFilter({
            target: { name: "sort_by", value: field }
        }))
    };

    exportChain = () => {
        store.dispatch( createExport("chain") );
    }

    render() {
        let {list, getChains, pages, modal, params, fields } = this.props;
        let bearer_token = loginToken()
        let export_file = apiBaseUrl("api/export?type=ChainsExport&access_token="+bearer_token);
        return (
            <Page title={i18next.t("chain.chains", {returnObjects:true})}>
                <h2>
                    {i18next.t("chain.chains", {returnObjects:true})}{" "}{getChains.processing && <Loading marginLeft={20}/>}
                    <span className="pull-right">
                        <button type="button"
                                onClick={() => store.dispatch( toggleChainFormModal( ))}
                                className="btn-theme btn-gray mt-30">{i18next.t("chain.create-chain",{returnObjects:true})}
                        </button>
                    </span>
                    <span className="pull-right">
                         <a href={export_file} rel="nofollow" target="_blank" >
                             <button type="button" className="btn-theme btn-gray mt-30 mr-100">{i18next.t("chain.export-chains",{returnObjects:true})}</button>
                         </a>
                    </span>
                </h2>
                <ChainListFilters />
                <div className="table-dashboard">
                    <div className="table-responsive">
                        <ChainWorker/>
                        <table className="table">
                            <TableHead fields={ fields }
                                       params={ params }
                                       onClick={ this.toggleSort } />
                            <tbody>
                            {list && list.length ? list.map((l, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{ l.id }</td>
                                        <td className="clickable-col">
                                            <Link to={"/chains/"+l.id}>{l.chain_name}</Link>
                                        </td>
                                        <td>{l.phone}</td>
                                        <td>{l.email}</td>
                                        <td>{l.invoice_address}</td>
                                        <td>{l.contact_person}</td>
                                        <td>
                                            <button className="btn btn-secondary btn-sm"
                                                    onClick={() => store.dispatch(toggleChainFormModal(l.id))}>
                                                <MyIcon type="edit" />
                                            </button>
                                            <Link to={"/chains/"+l.id} className="btn btn-red btn-sm ml-1">
                                                <MyIcon type="eye" />
                                            </Link>
                                            <button type="button"
                                                    className="btn btn-red btn-sm ml-1"
                                                    onClick={() => this.deleteConfirm(l.id)}><MyIcon type="trash" />
                                            </button>
                                        </td>
                                    </tr>
                                )
                            }) : null
                            }
                            </tbody>
                            { list && list.length ? (
                                <tfoot>
                                <PaginationButtons processing={getChains.processing}
                                                   pages={pages}
                                                   fetchAction={toggleChainListFilter}/>
                                </tfoot>
                            ) : null }
                        </table>
                        { !this.state.initialLoading && ( !list || !list.length) && <NoResultsFound/> }
                        { this.state.initialLoading && <div className="text-center"><Loading/></div> }
                    </div>
                </div>
                <ResponsiveModal visible={ modal.mode }
                                 closeModal={ () => store.dispatch( toggleChainFormModal( )) } >
                    <ChainForm />
                </ResponsiveModal>
            </Page>
        )
    }
}

function mapStateToProps(state)
{
    let { getChains, chainList } = state;
    let { modal, fields, params } = chainList.data;

    let list =
        getChains.success &&
        getChains.success.data.payload.data;

    let current_page = getChains.success && getChains.success.data && getChains.success.data.payload.current_page;
    let last_page = getChains.success && getChains.success.data && getChains.success.data.payload.last_page;
    let total = getChains.success && getChains.success.data && getChains.success.data.payload.total;
    let pages = { current_page, last_page, total };

    return { getChains, pages, list, modal, params, fields, chainList };
}


export default connect(mapStateToProps)(Chains);
