import { MainRouteState } from "./MainRoute-State"

export default function MainRouteReducer (state = MainRouteState, action) {
  let { data } = { ...state };
  let { role, details, entities } = action;

  switch (action.type) {

    case "SET-USER-ROLE":
      data.user_role = role;
      data.action = "render";
      return {...state, data}

    case "SET-ENTITY-DETAILS":
      data.entity = details;
      data.action = "render";
      return {...state, data}

    case "SET-ENTITIES":
      data.entities = entities;
      data.action = "render";
      return {...state, data}

    case "LOG-OUT-USER":
      data.user_role = ``;
      return {...state, data}

    default: return state;
  }
}
