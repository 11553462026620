import { endOfURL } from "../../../../helpers/UrlHelper";

const DefaultStoreFormState = {
  payload: {
    store_name: "",
    phone: "",
    email: "",
    invoice_address: "",
    contact_person: "",
    chain_id: "",
    region_id: 0,
  },
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
  mode: endOfURL(),
  message: "",
};

export const StoreFormState = { data: DefaultStoreFormState };
