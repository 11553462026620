import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import {clone} from "../../../helpers/DataHelper";

function _success(success)
{
    return { type: 'POST_ACCOUNT_SUCCESS', success };
}
function _error(error)
{
    return { type: 'POST_ACCOUNT_ERROR', error };
}
function _processing(processing)
{
    return { type: 'POST_ACCOUNT_PROCESSING', processing };
}

function updateMyAccount( password_mode )
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let payload = {};
        let { accountForm, userDetail, passwordForm } = getState();

        if ( !password_mode  ) {
            payload = clone( accountForm.data.payload );

        } else { // password mode
            payload = clone( userDetail.success );
            payload.password = passwordForm.data.payload.new_password;
        }

        axios({
            url: apiBaseUrl(`api/profile`),
            method: "post",
            dataType: 'json',
            data: payload ,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        })
            .then(function(res){
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res);
            })
            .catch(function(error){
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError( error )

            });
    }
}

export default updateMyAccount;