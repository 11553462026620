export const loginToken = () => localStorage.getItem("a_t");

/* Find out if anyone is logged in | @return boolean */
export const loggedIn   = () => !! localStorage.getItem("a_t");

export const logInNewUser = (data) =>
{
  localStorage.setItem("a_t",          data.access_token);
  // localStorage.setItem("refresh_token",   data.refresh_token);
  localStorage.setItem("expires_in",      data.expires_in);
  // localStorage.setItem("token_type",      data.token_type);
};

export const logOut = ( history ) =>
{
  let lang = localStorage.getItem("lang");
  let url = "/login"
  if( lang === "sv" ) {
    url = "/se/login"
  } else if( lang === "fn" ) {
    url = "/fn/login"
  }
  localStorage.removeItem("a_t");
  if ( history ) history.push(url);
  else window.location.href = (url);
};
