import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import {
    cancelUserFormDispatch,
    userFormServerValidationErrors
} from "../../../components/pages/users/form/UserForm-Action";
import {cancelOrderStatusFormDispatch} from "../../../components/pages/order-status/form/OrderStatusForm-Action";

import getOrderStatuses from "./order_statuses_get";

function _success(success)
{
    return { type: 'PUT_ORDER_STATUS_SUCCESS', success };
}
function _error(error)
{
    return { type: 'PUT_ORDER_STATUS_ERROR', error };
}
function _processing(processing)
{
    return { type: 'PUT_ORDER_STATUS_PROCESSING', processing };
}

function updateOrderStatus(id )
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true));
        let {orderStatusForm} = getState();

        const request = axios({
            url: apiBaseUrl(`api/orderstatus/${id}`),
            method: "put",
            dataType: 'json',
            data: orderStatusForm.data.payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        })
            .then(function(res){
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res);

                store.dispatch(cancelOrderStatusFormDispatch());
                store.dispatch(getOrderStatuses());

            }).catch(function(error){
                dispatch(_error(error));
                dispatch(_processing(false));
                if ( error && error.response.status === 422 ) {
                    store.dispatch(cancelUserFormDispatch());
                    store.dispatch(userFormServerValidationErrors(error.response.data.message))
                }
                handleError( error )

            });

        return {
            type: 'PUT_ORDER_STATUS_SUCCESS',
            payload: request
        }
    }
}


export default updateOrderStatus;