import React from "react";
import store from "../../../Store";
import {connect} from "react-redux";
import {Route, Switch} from "react-router-dom"
import getChain from "../../../ajax/actions/chains/chain_get";
import ChainInformation from "./ChainInformation";
import ChainTabBar from "./ChainTabBar";
import ChainRegions from "./chain-regions/list/ChainRegions";
import ChainRegionsForm from "./chain-regions/form/ChainRegionsForm";
import Stores from "../stores/list/Stores";
import StoreForm from "../stores/form/StoreForm";
import UserForm from "../users/form/UserForm";
import Users from "../users/list/UserList";
import Orders from "../orders/list/Orders";
import OrderDetail from "../orders/detail/OrderDetail";
import OrderUpdateForm from "../orders/detail/OrderUpdateFormModal";
import OrdersForm from "../orders/form/OrdersForm";
import {toggleChainFormModal} from "./list/ChainList-Actions";
import ChainForm from "./form/ChainForm";
import ResponsiveModal from "../../shared/modals/ResponsiveModal";
import {setChainDashboardData} from "./ChainDashboard-Actions";
import i18next from "i18next";

class ChainDashboard extends React.Component
{
    chain_id = this.props.match.params.chain_id;

    componentDidMount()
    {
        store.dispatch(getChain(this.chain_id))
    }

    componentDidUpdate(prev) {
        if( this.props.getChain !== prev.getChain) {
            let {success} = this.props.getChain;
            if( success ) {
                store.dispatch( setChainDashboardData( ) );
            }
        }
    }

    render()
    {
        let { match, user_role, list, modal } = this.props;
        let { url } = match;
        return (

            (user_role === "super-admin" || user_role === "admin" || user_role === "chain-admin") && (
                <div className="col-lg-12">

                    <h4>{i18next.t("chain.chain", {returnObjects:true})}: { list.chain_name }</h4>

                    <ChainTabBar/>

                    <Switch>
                        <Route exact path={url} component={ChainInformation}/>

                        <Route exact path={url+"/regions"} component={ChainRegions}/>
                        <Route exact path={url+"/regions/create"} component={ChainRegionsForm}/>
                        <Route exact path={url+"/regions/:id([0-9]{0,9})/update"} component={ChainRegionsForm}/>

                        <Route exact path={url+"/stores"} component={Stores}/>
                        <Route exact path={url+"/stores/create"} component={StoreForm}/>
                        <Route exact path={url+"/stores/:id([0-9]{0,9})/update"} component={StoreForm}/>

                        <Route exact path={url+"/users"} render={() => <Users mode="chain" chain_id={list.id} />} />
                        <Route exact path={url+"/users/create"} render={() => <UserForm mode="chain" dashboard_chain_id={list.id} />}/>
                        <Route exact path={url+"/users/:id([0-9]{0,9})/update"} render={() => <UserForm mode="chain" chain_id={list.id} />}/>

                        <Route exact path={url+"/orders"} component={Orders} />
                        <Route exact path={url+"/orders/create"} component={OrdersForm} />
                        <Route exact path={url+"/orders/:id([0-9]{0,9})"} component={ OrderDetail } />
                        <Route exact path={url+"/orders/:id([0-9]{0,9})/update"} component={ OrderUpdateForm } />
                    </Switch>

                    <ResponsiveModal visible={ modal.mode }
                                     closeModal={ () => store.dispatch( toggleChainFormModal( )) } >
                        <ChainForm />
                    </ResponsiveModal>

                </div>
            )

        )
    }
}

function mapStateToProps(state) {

    let { route, getChain, chainList } = state;
    let { modal } = chainList.data;
    let list = getChain &&
        getChain.success &&
        getChain.success.data &&
        getChain.success.data.payload;
    let {user_role} = route.data;
    return { route, user_role, getChain, list, chainList, modal };
}

export default connect(mapStateToProps)(ChainDashboard);
