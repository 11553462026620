import React from "react";
import store from "../../../../Store";
import {withRouter} from "react-router";
import {fewerLimitOptions, limitOptions} from "../../../../static-data/LimitOptions"
import {connect} from "react-redux";
import SearchableSelect from "../../../shared/form/SearchableSelect";
import {toggleImportStoresListFilter} from "./ImportStoresList-Actions";
import i18next from "i18next";

class ImportStoresListFilters extends React.Component
{
    toggleFilter = (e) => {
        store.dispatch(toggleImportStoresListFilter(e));
    };

    render()
    {
        let { params } = this.props;
        return (
            <div className="row table-filter mb-5">
                <div className="col custom-col">
                    <div className="form-group">
                        <SearchableSelect name="limit"
                                          noFormGroup
                                          options={ fewerLimitOptions  }
                                          _value="total"
                                          _label="label"
                                          notSearchable
                                          label={i18next.t("filters.limit", {returnObjects:true})}
                                          value={ params.limit }
                                          placeholder={"-- "+i18next.t("filters.limit", {returnObjects:true})+" --"}
                                          onChange={ this.toggleFilter } />
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state)
{
    let { importStoresList } = state;
    let { params } = importStoresList.data;

    return {
        importStoresList, params
    }
}

export default withRouter(connect(mapStateToProps)(ImportStoresListFilters));
