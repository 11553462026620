import React from "react"
import {Route, Switch, withRouter} from "react-router-dom"
import { connect } from "react-redux"
import { loggedIn, logOut } from "../../../helpers/LocalStorageHelper"
import store from "../../../Store"
import {setEntities, setEntityDetails, setEntityID, setUserRole} from "./MainRoute-Actions"
import { allowedRole } from "../../../static-data/UserData"
import { pushToPage, urlTrailArray } from "../../../helpers/UrlHelper"
import { equalWhenStringified } from "../../../helpers/StringHelper"
import Header from "../../layout/Header";
import Sidebar from "../../layout/Sidebar";
import userDetails from "../../../ajax/actions/user/user_detail_get";
import AdminRoutes from "./../AdminRoutes";
import LoginRoutes from "./../LoginRoutes";
import SuperAdminRoutes from "./../SuperAdminRoutes";
import ChainAdminRoutes from "./../ChainAdminRoutes";
import StoreManagerRoutes from "./../StoreManagerRoutes";
import Loading from "../../shared/loaders/Loading";
import RegionalDirectorRoutes from "./../RegionalDirectorRoutes";
import TechnicianRoutes from "./../TechnicianRoutes";
import MaintenanceMode from "../../shared/pages/MaintenanceMode";
import NotFound from "../../shared/pages/NotFound";


class MainRoute extends React.Component
{
    constructor(props)
    {
        super(props);
        if( loggedIn( )) store.dispatch(userDetails( ));
    }
    componentDidMount() {
        const current_path = this.props.location.pathname
        if(current_path === "/se/login")
            localStorage.setItem("lang","sv")
    }

    componentDidUpdate( lastProp )
    {
        this.userDetailsChanged( lastProp );
    }

    userDetailsChanged( lastProp )
    {
        let { userDetail, redirectTo } = this.props;
        if ( !equalWhenStringified( userDetail, lastProp.userDetail )) {
            let { success } = userDetail;
            if( success ) {
                store.dispatch( setUserRole( success.role ));
                store.dispatch( setEntityDetails( success.entity_detail ));
                store.dispatch( setEntities( success.entities_data ));
                if ( redirectTo ) {
                    pushToPage( this.props, redirectTo )
                }
            }
        }
    }

    render()
    {
        let { user_role, action, processing, error } = this.props;

        if ( error && error.response && error.response.status === 500 ) {
            return <MaintenanceMode/>
        }
        else if ( processing ) return (
            <div className="text-center mt-5">
                <Loading lg />
            </div>
        );
        else {
            if ( action === "render" && user_role ) {
                if( !allowedRole( user_role )) logOut();
                return (

                    <>

                        <Header/>

                        <section className={"section-wrapper"}>
                            <div className="container-fluid p-0">
                                <div className="row no-gutters">
                                    <div className="col-lg-2 col-md-3 col-sidebar">

                                        <Sidebar/>

                                    </div>
                                    <div className="col-lg-10 col-container">
                                        <div className="content-area">

                                                {(() => {
                                                    switch( user_role )
                                                    {
                                                        case "super-admin":
                                                            return (
                                                                    <Route path="/" component={ SuperAdminRoutes } />
                                                            );

                                                        case "admin":
                                                            return <Route path="/" component={ AdminRoutes } />;

                                                        case "chain-admin":
                                                            return <Route path="/" component={ ChainAdminRoutes } />;

                                                        case "store-manager":
                                                            return <Route path="/" component={ StoreManagerRoutes } />;

                                                        case "regional-director":
                                                            return <Route path="/" component={ RegionalDirectorRoutes } />;

                                                        case "technician":
                                                            return <Route path="/" component={ TechnicianRoutes } />;

                                                        default: return null

                                                    }

                                                })()}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </>
                )
            } else if( action === "render-404") {
                return processing ? <Loading /> : <NotFound/>;
            }

            else {
                return <LoginRoutes/>
            }
        }

    }
}
function mapStateToProps(state)
{
    let { route, userDetail, loginForm } = state;
    let { user_role, action } = route.data;
    let { processing, error } = userDetail;
    let { redirectTo } = loginForm.data;
    return { userDetail, route, user_role, action, processing, error, loginForm, redirectTo };
}
export default withRouter(connect( mapStateToProps )( MainRoute ));
