import React from 'react';
import RoleWorker from "./RoleWorker";
import {connect} from "react-redux";
import store from '../../../../Store';
import swal from "sweetalert";
import deleteRole from "../../../../ajax/actions/role/role_delete";
import PaginationButtons from "../../../shared/buttons/PaginationButtons";
import RoleListFilters from "./RoleListFilters";
import {toggleRoleFormModal, toggleRoleListFilter} from "./RoleList-Actions";
import Loading from "../../../shared/loaders/Loading";
import MyIcon from "../../../shared/icons/MyIcon";
import NoResultsFound from "../../../shared/folder/NoResultsFound";
import ResponsiveModal from "../../../shared/modals/ResponsiveModal";
import RoleForm from "../form/RoleForm";
import TableHead from "../../../shared/table/TableHead";
import Page from "../../../shared/pages/Page";
import i18next from "i18next";

class Roles extends React.Component {
    state = {
        initialLoading: true
    }

    componentDidUpdate( lastProps )
    {
        let { getRoles } = this.props;
        if(getRoles) {
            if ( getRoles.success  && this.state.initialLoading ) {
                this.setState({ initialLoading: false })
            }
        }
    }

    deleteConfirm = (id) => {
        swal(i18next.t("confirm.delete-chain",{returnObjects:true}), {
            buttons: {
                cancel: i18next.t("cancel",{returnObjects:true}),
                catch: {
                    text: i18next.t("delete",{returnObjects:true}),
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    }

    delete(id)
    {
        store.dispatch(deleteRole(id));
    }

    toggleSort = (field) =>
    {
        store.dispatch(  toggleRoleListFilter({
            target: { name: "sort_by", value: field }
        }))
    };

    render()
    {
        let { list, getRoles, pages, modal, fields, params } = this.props;
        return (
            <Page title={i18next.t("role.roles",{returnObjects:true})}>
                <RoleWorker/>

                <h2>
                    {i18next.t("role.roles",{returnObjects:true})}{" "}{getRoles.processing && <Loading marginLeft={20}/>}
                    <span className="pull-right">
                        <button type="button"
                                onClick={ () => store.dispatch( toggleRoleFormModal()) }
                                className="btn-theme btn-gray mt-30">{i18next.t("role.create-role",{returnObjects:true})}
                        </button>
                    </span>
                </h2>

                <RoleListFilters />

                <div className="table-dashboard table-small">
                    <div className="table-responsive">
                        <table className="table">
                            <TableHead fields={ fields }
                                       params={ params }
                                       onClick={ this.toggleSort } />
                            <tbody>
                            {
                                list && list.length ? list.map((l, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{l.id}</td>
                                            <td className="clickable-col"
                                                onClick={() => store.dispatch(toggleRoleFormModal(l.id))}>
                                                {/* {l.title} */}
                                                {i18next.t(l.title,{returnObjects:true})}
                                            </td>
                                            <td>{l.slug}</td>
                                            <td>
                                                <button className="btn btn-secondary btn-sm"
                                                        onClick={() => store.dispatch( toggleRoleFormModal(l.id))}>
                                                    <MyIcon type="edit" />
                                                </button>
                                                <button type="button"
                                                        className="btn btn-danger btn-sm ml-1"
                                                        onClick={() => this.deleteConfirm(l.id)}><MyIcon type="trash" />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                }) : null
                            }
                            </tbody>
                            { list && list.length ? (
                                <tfoot>
                                <PaginationButtons pages={pages}
                                                   processing={getRoles.processing}
                                                   fetchAction={toggleRoleListFilter} />
                                </tfoot>
                            ) : null }
                        </table>
                        { !this.state.initialLoading && ( !list || !list.length) && <NoResultsFound/> }
                        { this.state.initialLoading && <div className="text-center"><Loading/></div> }
                    </div>
                </div>

                <ResponsiveModal visible={ modal.mode }
                                 closeModal={ () => store.dispatch( toggleRoleFormModal()) } >
                    <RoleForm  />
                </ResponsiveModal>

            </Page>
        )
    }
}

function mapStateToProps(state) {
    let { getRoles, roleList } = state;

    let { modal, fields, params } = roleList.data;
    let list =
        getRoles.success &&
        getRoles.success.data.payload.data;

    let current_page = getRoles.success && getRoles.success.data && getRoles.success.data.payload.current_page;
    let last_page = getRoles.success && getRoles.success.data && getRoles.success.data.payload.last_page;
    let total = getRoles.success && getRoles.success.data && getRoles.success.data.payload.total;
    let pages = {current_page, last_page, total}

    return { getRoles, pages, list, roleList, modal, fields, params };
}


export default connect(mapStateToProps)(Roles);
