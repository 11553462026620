import store from "../../../../Store";
import getStores from "../../../../ajax/actions/stores/stores_get";

export function toggleStoreListFilter(e) {
  return function (dispatch) {
    let { name, value } = e.target;
    dispatch({ type: "TOGGLE-STORE-LIST-FILTER", name, value });
    store.dispatch(getStores());
  };
}

export function toggleStoreFormModal(id) {
  return function (dispatch) {
    dispatch({ type: "TOGGLE-STORE-FORM-MODAL", id });
  };
}
