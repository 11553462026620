import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import {clone} from "../../../helpers/DataHelper";
import store from '../../../Store';
import getOrder from "./order_get";
import {cancelOrderDetailEditFormDispatch} from "../../../components/pages/orders/detail/OrderDetail-Actions";

function _success(success)
{
    return { type: 'PUT_ORDERS_SUCCESS', success };
}
function _error(error)
{
    return { type: 'PUT_ORDERS_ERROR', error };
}
function _processing(processing)
{
    return { type: 'PUT_ORDERS_PROCESSING', processing };
}

function updateOrders( id )
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true));
        dispatch(_error(null));
        dispatch(_success(null));

        let { orderDetail } = getState();
        let _orderDetail = clone( orderDetail );
        if ( !orderDetail.data.form_payload.technician_id ) {
            delete _orderDetail.data.form_payload.technician_id;
        }
        let { status } = orderDetail.data.form_payload;

        if( status !== 'planned' && status !== 'revisit-required' ) {
            delete _orderDetail.data.form_payload.planned_at;
        }

        if( status !== 'completed' ) {
            delete _orderDetail.data.form_payload.completed_at;
        }
        // store.dispatch( cancelOrderDetailEditFormDispatch() );
        const request = axios({
            url: apiBaseUrl(`api/orders/${id}`),
            method: "put",
            dataType: 'json',
            data: _orderDetail.data.form_payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        })
            .then(function(res){
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res);
                store.dispatch( cancelOrderDetailEditFormDispatch() );
                if ( getState().getOrder.success ) {
                    store.dispatch( getOrder( getState().getOrder.success.data.payload.id ))
                }

            }).catch(function(error){
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError( error )
                store.dispatch( cancelOrderDetailEditFormDispatch() );
            });

        return {
            type: 'PUT_ORDERS_SUCCESS',
            payload: request
        }
    }
}


export default updateOrders;