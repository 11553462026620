import React from "react";
import store from "../../../../Store";
import getImportStores from "../../../../ajax/actions/stores/import_stores_get";
import {setDefaultImportStoreForm} from "../import-form/ImportStoresForm-Action";

class ImportStoresListWorker extends React.Component
{
    componentDidMount()
    {
        store.dispatch( getImportStores( ));
    }

    componentWillUnmount()
    {
        store.dispatch( setDefaultImportStoreForm() )
    }

    render()
    {
        return null
    }
}

export default ImportStoresListWorker;
