import React from "react";
import store from "../../../../Store";
import { connect } from "react-redux";
import {
  onChatFormChange,
  onChatFormSubmit,
  onHandleScrollLimitIncrease,
  setDefaultChatForm,
} from "./Chat-Actions";
import { toggleChatBox } from "../detail/OrderDetail-Actions";
import { dayMonth, onlyTime } from "../../../../helpers/DateTimeHelper";
// import {fakeChat} from "../../../../static-data/FakeData";
import Loading from "../../../shared/loaders/Loading";
import createChat from "../../../../ajax/actions/chat/chat_post";
import getChats from "../../../../ajax/actions/chat/chats_get";
import createChatSeen from "../../../../ajax/actions/chat/chat_seen_post";
import i18next from "i18next";

class ChatBox extends React.Component {
  state = {
    initialLoading: false,
    chatList: [],
  };

  componentDidMount() {
    // store.dispatch( setDefaultChatForm() )
    store.dispatch(getChats(this.props.order_id));
    this.periodicFetch();
    store.dispatch(createChatSeen(this.props.order_id));
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  }

  componentDidUpdate(lastProps) {
    let { dispatchAPI } = this.props;

    if (dispatchAPI) {
      if (!lastProps.dispatchAPI) {
        store.dispatch(createChat(this.props.order_id));
      }
    }

    if (this.props.getChats !== lastProps.getChats) {
      let { success, processing } = this.props.getChats;
      if (processing && !this.state.initialLoading) {
        this.setState({ initialLoading: true });
        // store.dispatch( setInitialLoadingMode() );
      }
      if (success) {
        let chatList =
          success &&
          success.data &&
          success.data.payload &&
          success.data.payload.data;
        this.setState({ chatList, initialLoading: false });
        // slideAndScrollTo("#single-chat-last-item")
        let shadesEl = document.querySelector(".chat-box-body");
        document.querySelector(".chat-box-body").scrollTop =
          shadesEl.scrollHeight - shadesEl.clientHeight;
        // window.scrollTo(0,document.querySelector(".chat-box-body").scrollHeight);
        window.scrollTo(0, shadesEl.scrollHeight - shadesEl.clientHeight);
        // document.getElementById("chat-scroll-div").scrollIntoView({block: "end", behavior: "smooth"});
      }
    }

    if (this.props.limit !== lastProps.limit) {
      store.dispatch(getChats(this.props.order_id));
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    let shadesEl = document.querySelector(".chat-box-body");
    let lastScrollTop = 0;
    // let a = shadesEl.scrollTop;
    // let b = shadesEl.scrollHeight - shadesEl.clientHeight;
    let st = window.pageYOffset || shadesEl.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
    if (st > lastScrollTop) {
      // downscroll code
    } else {
      // upscroll code
      // let limit = this.state.limit + 10;
      // this.setState({ limit });
      store.dispatch(onHandleScrollLimitIncrease());
      // store.dispatch( getChats( this.props.order_id, limit ) );
    }
  };

  periodicFetch() {
    this.timer = setInterval(
      () =>
        !this.props.getChats.processing &&
        store.dispatch(getChats(this.props.order_id)),
      10000
    );
  }

  chatFormSubmit = (e) => {
    e.preventDefault();
    store.dispatch(onChatFormSubmit());
  };

  closeChatBox = () => {
    store.dispatch(setDefaultChatForm());
    store.dispatch(toggleChatBox(false));
  };

  onChange = (e) => {
    store.dispatch(onChatFormChange(e.target.name, e.target.value));
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      store.dispatch(onChatFormSubmit());
      e.preventDefault();
    }
  };

  render() {
    let { body, userID, errors, errorsPresent, createChat } = this.props;
    // let chatList = fakeChat;
    let { chatList, initialLoading } = this.state;
    return (
      <div className="chat-box open">
        {/*<ChatWorker order_id={this.props.order_id} />*/}
        <div className="chat-box-header">
          <h2>
            {i18next.t("chat.leave-message")}
            <span className="close-button" onClick={this.closeChatBox} />
          </h2>
          <p>{i18next.t("chat.chat")}</p>
        </div>

        <div
          className="chat-box-body"
          id="chat-scroll-div"
          onScroll={this.handleScroll}
        >
          {!chatList.length && initialLoading ? (
            <div className="text-center">
              <Loading />
            </div>
          ) : chatList && chatList.length ? (
            chatList.map((c, key) => {
              let chatName = "Me";
              let chat_classname = "sent";
              if (c.sender_id !== userID) {
                chatName = c.sender && c.sender.name && c.sender.name.charAt(0);
                chat_classname = "received";
              }
              return (
                <div
                  className={"single-message " + chat_classname}
                  key={key}
                  id={
                    key === chatList.length - 1 ? "single-chat-last-item" : ""
                  }
                >
                  <div className="message-detail">
                    <span>{chatName}</span> -{" "}
                    <span>{dayMonth(c.created_at)}</span> -{" "}
                    <span>{onlyTime(c.created_at)}</span>
                  </div>
                  <div className="message-bubble">{c.body}</div>
                </div>
              );
            })
          ) : null}
        </div>

        <div className="chat-box-footer">
          <h6 className={errorsPresent ? "has-error red" : null}>
            {i18next.t("chat.send-message", { returnObjects: true })}{" "}
            {errorsPresent ? errors.body[0].message + "." : null}
          </h6>
          <div className="message-send">
            <textarea
              rows={4}
              name="body"
              className="form-control"
              value={body}
              onKeyPress={this.onKeyPress}
              onChange={this.onChange}
            />
            <div className="button-block" onClick={this.chatFormSubmit}>
              <button>
                {createChat.processing ? (
                  <Loading color="white" xs />
                ) : (
                  i18next.t("chat.send", { returnObjects: true })
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { getChats, route, userDetail, orderDetail, chatState, createChat } =
    state;

  let { success } = getChats;
  let chatList =
    success &&
    success.data &&
    success.data.payload &&
    success.data.payload.data.data;

  let { payload } = orderDetail.data;
  let { chat_box_visible } = payload;
  let { dispatchAPI, params } = chatState.data;
  let { body } = chatState.data.payload;
  let { errors, errorsPresent } = chatState.data;
  let { limit } = params;

  // console.log(" <| chatState |> ",chatState )

  let userID = userDetail && userDetail.success && userDetail.success.id;

  let { user_role } = route.data;

  return {
    route,
    user_role,
    userID,
    orderDetail,
    payload,
    chat_box_visible,
    getChats,
    chatState,
    chatList,
    body,
    errors,
    errorsPresent,
    createChat,
    dispatchAPI,
    limit,
  };
}

export default connect(mapStateToProps)(ChatBox);
