export const limitOptions = [
  { total: 10, label: "10-results" },
  { total: 25, label: "25-results" },
  { total: 50, label: "50-results" },
  { total: 100, label: "100-results" },
  { total: 500, label: "500-results" },
];

export const fewerLimitOptions = [
  { total: 25, label: "25-results" },
  { total: 50, label: "50-results" },
  { total: 100, label: "100-results" },
  { total: 500, label: "500-results" },
];

export const deviceTypeOptions = [
  { slug: "ios", label: "iOS" },
  { slug: "web", label: "Web" },
  { slug: "android", label: "Android" },
];

export const appTypeOptions = [
  { slug: "customer", label: "Customer App" },
  { slug: "driver", label: "Driver App" },
];

export const userClassOptions = [
  { value: 30, label: "General" },
  { value: 10, label: "VIP" },
];
