import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from '../../../Store';
import getUsers from "../user/users_get";
import {
    bulkUserUpdateFormServerValidationErrors,
    cancelBulkUserUpdateFormDispatch,
    setDefaultBulkUserUpdateForm
} from "../../../components/pages/users/bulk-update-form/BulkUserUpdateForm-Action";

function _success(success) {
    return {type: 'BULK_USERS_UPDATE_SUCCESS', success};
}

function _error(error) {
    return {type: 'BULK_USERS_UPDATE_ERROR', error};
}

function _processing(processing) {
    return {type: 'BULK_USERS_UPDATE_PROCESSING', processing};
}

function bulkUsersUpdate( clearOnly )
{
    return (dispatch, getState) => {

        if ( clearOnly ) {
            dispatch(_error(null));
            dispatch(_success(null));
            dispatch(_processing(false));

        } else {

            dispatch(_processing(true));
            dispatch(_success(null));
            dispatch(_error(null));

            let {bulkUsersUpdateForm} = getState();
            let formData = new FormData();

            formData.append(`data_file`, bulkUsersUpdateForm.data.payload.data_file[0]);

            axios({
                url: apiBaseUrl(`api/import/user/update`),
                method: "post",
                data: formData,
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + loginToken(),
                    lang:localStorage.getItem('lang' ) || 'en'
                }
            })
                .then(function (res) {
                    dispatch(_success(res));
                    dispatch(_processing(false));
                    handleSuccess(res);

                    store.dispatch(cancelBulkUserUpdateFormDispatch());
                    store.dispatch(setDefaultBulkUserUpdateForm());
                    store.dispatch(getUsers())

                })
                .catch(function (error) {
                    dispatch(_error(error));
                    dispatch(_processing(false));
                    handleError(error);
                    store.dispatch(cancelBulkUserUpdateFormDispatch());
                    if (error.response && error.response.status === 422) {
                        store.dispatch(bulkUserUpdateFormServerValidationErrors(error.response.data.payload.message))
                    }
                });
        }

    }
}


export default bulkUsersUpdate;