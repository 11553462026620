import axios from "axios/index";
import { apiBaseUrl } from "../../../helpers/UrlHelper";
import { loginToken } from "../../../helpers/LocalStorageHelper";
import { handleError } from "../../../helpers/ErrorHelper";
import { clone } from "../../../helpers/DataHelper";

function _success(success) {
    return { type: "GET_CHAIN_REGIONS_SUCCESS", success };
}

function _error(error) {
    return { type: "GET_CHAIN_REGIONS_ERROR", error };
}

function _processing(processing) {
    return { type: "GET_CHAIN_REGIONS_PROCESSING", processing };
}

function getChainRegions(limit, _chain_id, clear) {
    return (dispatch, getState) => {
        let { getChain, chainRegionsList, route } = getState();
        let chain_id = getChain.success && getChain.success.data.payload.id;
        if (_chain_id) chain_id = _chain_id;

        let { entity, user_role } = route.data;
        if (!chain_id && user_role === "chain-admin") {
            chain_id = entity.id;
        }

        let params = clone(chainRegionsList.data.params);
        if (limit) params.limit = limit;

        dispatch(_processing(true));
        dispatch(_error(null));

        if (clear) {
            dispatch(_success(null));
            dispatch(_processing(false));
            dispatch(_error(null));
        } else {
            const request = axios({
                url: apiBaseUrl("api/chains/"+chain_id+"/regions"),
                method: "get",
                headers: {
                    Authorization: "Bearer " + loginToken(),
                    lang: localStorage.getItem("lang") || "en",
                },
                params,
            })
                .then(function (res) {
                    dispatch(_success(res));
                    dispatch(_processing(false));
                })
                .catch(function (error) {
                    dispatch(_error(error));
                    dispatch(_processing(false));
                    handleError(error);
                });

            return {
                type: "GET_CHAIN_REGIONS_SUCCESS",
                payload: request,
            };
        }
    };
}

export default getChainRegions;
