import getCategory from "../../../../ajax/reducers/category/category-get";

export function uploadFileFormServerValidationErrors( validationErrors ) {
  return function (dispatch) {
    dispatch({type:"UPLOAD-FILE-FORM-SERVER-VALIDATION-ERRORS", validationErrors });
  }
}

export function onUploadFileFormSubmit( ) {
  return function (dispatch) {
    dispatch({type:"ON-UPLOAD-FILE-FORM-SUBMIT" });
  }
}

export function onUploadFileFormChange(name, value) {
  return function (dispatch){
      dispatch({type:"ON-UPLOAD-FILE-FORM-CHANGE", name, value});
  }
}

export function cancelUploadFileFormDispatch() {
  return function (dispatch) {
    dispatch({ type:"CANCEL-UPLOAD-FILE-FORM-DISPATCH" });
  }
}

export function setDefaultUploadFileForm() {
  return function (dispatch){
    dispatch({type:"SET-DEFAULT-UPLOAD-FILE-FORM"});
  }
}

export function setCategoryIDForUpdateForm( id ) {
  return function (dispatch){
    dispatch({type:"SET-CATEGORY-ID-FOR-UPDATE-FORM", id });
  }
}

export function setUploadFileDataForUpdate() {
  return function (dispatch, getState) {
    let { getCategory } = getState();
    // console.log(` <| getCategory |> `, getCategory )
    let uploadFiles = getCategory && getCategory.success && getCategory.success.data.payload;
    if(uploadFiles) {
      dispatch({type:"SET-UPLOAD-FILE-DATA-FOR-UPDATE",  uploadFiles:JSON.parse(JSON.stringify(uploadFiles)) });
    }
  }
}
