import { endOfURL } from '../../../../helpers/UrlHelper'

const DefaultChainFormState = {
  payload:{
    chain_name:``,
    phone:``,
    email:``,
    invoice_address:``,
    contact_person:``,
    has_region: 1,
  },
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
  mode: endOfURL(),
  message: ``

};

export const ChainFormState = { data: DefaultChainFormState }