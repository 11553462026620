export default function getUsersReducer
  (state = { error:'', success:'', processing:false}, action)
{
  switch (action.type) {
    case 'GET_USERS_SUCCESS':
      return {...state, ...{success: action.success}};

    case 'GET_USERS_ERROR':
      return {...state, ...{error: action.error}};

    case 'GET_USERS_PROCESSING':
      return {...state, ...{processing: action.processing}};

    default: return state;
  }
}