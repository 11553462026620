import axios from "axios"
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from "../../../helpers/LocalStorageHelper";
import {handleError} from "../../../helpers/ErrorHelper";
import {clone} from "../../../helpers/DataHelper";

function _success(success) {
    return {type: "GET_ORDER_STATUS_ALL_SUCCESS", success};
}

function _error(error) {
    return {type: "GET_ORDER_STATUS_ALL_ERROR", error};
}

function _processing(processing) {
    return {type: "GET_ORDER_STATUS_ALL_PROCESSING", processing};
}

function getOrderStatusAll( limit )
{
    return (dispatch, getState) => {
        dispatch(_processing(true));
        let {orderStatusList} = getState();

        let params = clone( orderStatusList.data.params );
        if ( limit ) params.limit = limit;

        const request = axios({
            url:apiBaseUrl("api/orderstatus/all"),
            method:"get",
            params,
            headers:{
                "Authorization": "Bearer " + loginToken(),
                lang:localStorage.getItem("lang" ) || "en"
            },
        }).then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false));
        }).catch(function(error){
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error);
        });

        return {
            type:"GET_ORDER_STATUS_ALL_SUCCESS",
            payload:request
        }
    }
}

export default getOrderStatusAll;
