import React from "react";
import store from "../../../../Store"
import {connect} from "react-redux";
import bulkUserUpdate from "../../../../ajax/actions/user/bulk_user_update_post";

class BulkUsersUpdateFormWorker extends React.Component
{
  componentDidUpdate(prev)
  {
    let { dispatchAPI } = this.props;

    if ( dispatchAPI ) {
      if ( !prev.dispatchAPI ) {
        store.dispatch(bulkUserUpdate());
      }
    }
  }

  render()
  {
    return null
  }
}
function mapStateToProps(state) {
  let { bulkUsersUpdateForm } = state;
  let { dispatchAPI } = bulkUsersUpdateForm.data;
  return { bulkUsersUpdateForm, dispatchAPI,
  };
}
export default connect( mapStateToProps )( BulkUsersUpdateFormWorker );
