export default function getOrdersReducer(
  state = { error: "", success: "", processing: false },
  action
) {
  switch (action.type) {
    case "GET_ORDERS_SUCCESS":
      return { ...state, ...{ success: action.success } };

    case "GET_ORDERS_ERROR":
      return { ...state, ...{ error: action.error } };

    case "GET_ORDERS_PROCESSING":
      return { ...state, ...{ processing: action.processing } };

    default:
      return state;
  }
}
