import React from "react";
import {connect} from "react-redux";
import store from "../../../../Store";
import BulkUsersUpdateFormWorker from "./BulkUserUpdateFormWorker";
import UploadField from "../../../shared/form/UploadField";
import SaveButton from "../../../shared/buttons/SaveButton";
import sampleCSV from "../../../../assets/csv/sample_users_bulk_update_csv.csv"
import {isArray} from "../../../../helpers/DataHelper";
import {onBulkUserUpdateFormChange, onBulkUserUpdateFormSubmit} from "./BulkUserUpdateForm-Action";
import i18next from "i18next";

class BulkUserUpdateForm extends React.Component
{
    onChange = (e) => 
    {
        store.dispatch(onBulkUserUpdateFormChange(e.target.name, e.target.value));
    }

    submit = (e) => 
    {
        e.preventDefault();
        store.dispatch(onBulkUserUpdateFormSubmit());
    }

    render()
    {
        let { errors, data_file, processing, import400Errors } = this.props;
        return (

            <div className="row">
                <div className="col-md-8 col-lg-7 col-sm-12 offset-md-1 offset-lg-1">

                    <h2>{ i18next.t("user.bulk-update",{returnObjects:true}) }</h2>

                    { isArray( import400Errors ) && import400Errors.length ?
                        <div className="alert alert-danger" role="alert">
                            <div className="mb-1">
                                <b>{ i18next.t("error.import-store",{returnObjects:true}) }</b>
                            </div>
                            { import400Errors.map( err => { return (
                                <div className="mb-1"><u>Row 3</u> - { err.errors.map( er => { return (
                                    <>{ er }</>
                                )}) }</div>
                            )}) }
                        </div>
                        : null }

                    <form onSubmit={this.submit} id="bulk-user-update-form">

                        <UploadField name="data_file"
                                     value={data_file}
                                     errors={ errors.data_file }
                                     onChange={this.onChange}
                                     supportedFormats=".csv, .txt"
                                     noFileRemoval
                        />

                        <a target="_blank" href={ sampleCSV }><i><u>{i18next.t("download-csv",{returnObjects:true})}</u></i></a>

                        <div className="text-center">
                            <SaveButton btnClass="text-uppercase ml-auto mt-5"
                                        text={i18next.t("buttons.import",{returnObjects:true})}
                                        processing={processing} />
                        </div>
                    </form>
                    <BulkUsersUpdateFormWorker/>
                </div>


            </div>

        )
    }
}
function mapStateToProps(state)
{
    let { bulkUsersUpdateForm, bulkUsersUpdate } = state;
    let { errors, payload, files } = bulkUsersUpdateForm.data;
    let { data_file } = payload;
    let { processing, error } = bulkUsersUpdate;
    let import400Errors =
        error &&
        error.response.data.payload.errors;
    return { bulkUsersUpdateForm, data_file, files, errors, bulkUsersUpdate, processing, error, import400Errors };
}
export default connect( mapStateToProps )( BulkUserUpdateForm );
