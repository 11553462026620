import axios from "axios"
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from "../../../helpers/LocalStorageHelper";
import {handleError} from "../../../helpers/ErrorHelper";
import {clone} from "../../../helpers/DataHelper";
import i18next from "i18next";

function _success(success) {
    return {type: "GET_WORK_TYPES_SUCCESS", success};
}

function _error(error) {
    return {type: "GET_WORK_TYPES_ERROR", error};
}

function _processing(processing) {
    return {type: "GET_WORK_TYPES_PROCESSING", processing};
}

function getWorkTypes(limit)
{
    return (dispatch, getState) => {
        dispatch(_processing(true));
        let {workTypeList} = getState();

        let params = clone(workTypeList.data.params);
        params.lang = i18next.language;
        if (limit) params.limit = limit;

        const request = axios({
            url:apiBaseUrl("api/worktypes"),
            method:"get",
            params,
            headers:{
                "Authorization": "Bearer " + loginToken(),
                lang:localStorage.getItem("lang" ) || "en"
            },
        }).then(function(res){

            dispatch(_success(res));
            dispatch(_processing(false));
        }).catch(function(error){
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error);
        });

        return {
            type:"GET_WORK_TYPES_SUCCESS",
            payload:request
        }
    }
}

export default getWorkTypes;
