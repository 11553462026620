import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import store from "../../../../Store";
import getOrderStatusAll from "../../../../ajax/actions/order-status/order_status_all_get";
import getTechnicians from "../../../../ajax/actions/user/technicians_get";
import {
    cancelOrderDetailEditFormDispatch,
    orderDetailEditFormServerValidationErrors, setOrderDetailDataForUpdate,
    setUserRoleOnOrderDetailState, toggleOrderDetailModalClose
} from "./OrderDetail-Actions";
import updateOrders from "../../../../ajax/actions/orders/orders_put";
import {equalWhenStringified} from "../../../../helpers/StringHelper";
import getOrders from "../../../../ajax/actions/orders/orders_get";
import getOrderPriority from "../../../../ajax/actions/orders/order_priority_get";

class OrderDetailWorker extends React.Component
{
    componentDidMount()
    {
        let { user_role } = this.props;
        if( user_role === "super-admin" || user_role === "admin" ) {
            store.dispatch(getTechnicians());
        }
        store.dispatch(getOrderStatusAll())
        store.dispatch(getOrderPriority())
        store.dispatch(setUserRoleOnOrderDetailState())
    }

    componentDidUpdate(lastProps) 
    {
        let { dispatchAPI, orderDetail } = this.props;
        let {order_id} = orderDetail.data.payload;

        if(!equalWhenStringified(this.props.getOrder, lastProps.getOrder)) {
            let {success} = this.props.getOrder;
            if(success) {
                store.dispatch(setOrderDetailDataForUpdate());
            }
        }

        if(this.props.updateOrder !== lastProps.updateOrder) {
            let {error, success} = this.props.updateOrder;
            if(success) {
                store.dispatch(cancelOrderDetailEditFormDispatch());
                store.dispatch(toggleOrderDetailModalClose());
                store.dispatch(getOrders());
            }

            if ( error && error.response && error.response.status === 422 ) {
                store.dispatch(cancelOrderDetailEditFormDispatch());
                store.dispatch( orderDetailEditFormServerValidationErrors( error.response.data.payload.message ))
            }
        }

        if ( dispatchAPI ) {
            if ( !lastProps.dispatchAPI ) {
                store.dispatch(updateOrders(order_id));
            }
        }
    }

    render()
    {
        return null
    }
}
function mapStateToProps(state) {
    let { route, orderDetail, updateOrder, getOrder } = state;
    let {dispatchAPI} = orderDetail.data;
    let { user_role } = route.data;
    return { route, user_role,orderDetail, dispatchAPI, updateOrder,getOrder };
}
export default withRouter(connect(mapStateToProps)( OrderDetailWorker ));
