import React from "react";
import store from "../../../../Store";
import { withRouter } from "react-router";
import {
  fewerLimitOptions,
  limitOptions,
} from "../../../../static-data/LimitOptions";
import { toggleStoreListFilter } from "./StoreList-Actions";
import { connect } from "react-redux";
import getChainRegions from "../../../../ajax/actions/chain-regions/chain_regions_get";
import SearchableSelect from "../../../shared/form/SearchableSelect";
import i18next from "i18next";

class StoreListFilters extends React.Component {
  toggleFilter = (e) => {
    store.dispatch(toggleStoreListFilter(e));
  };

  componentDidUpdate(prev) {
    let { getChain, chainData } = this.props;

    if (getChain !== prev.getChain) {
      if (chainData.has_region === 1) {
        store.dispatch(getChainRegions(20));
      }
    }
  }

  render() {
    let { storeList, chainData, regions } = this.props;
    let { params } = storeList.data;
    return (
      <div className="row table-filter mb-5">
        <div className="col custom-col">
          <div className="form-group">
            <SearchableSelect
              name="limit"
              noFormGroup
              options={fewerLimitOptions}
              _value="total"
              _label="label"
              label={i18next.t("filters.limit", { returnObjects: true })}
              notSearchable
              value={params.limit}
              placeholder={`-- ${i18next.t("filters.limit", {
                returnObjects: true,
              })} --`}
              onChange={this.toggleFilter}
            />
          </div>
        </div>

        {chainData.has_region === 1 ? (
          <div className="col custom-col">
            <SearchableSelect
              clearable
              name="region_id"
              options={regions}
              _value="id"
              label={i18next.t("region.region", { returnObjects: true })}
              _label="region_name"
              value={params.region_id}
              placeholder={`-- ${i18next.t("region.region", {
                returnObjects: true,
              })} --`}
              onChange={this.toggleFilter}
            />
          </div>
        ) : null}

        <div className="col custom-col two-col-block">
          <div className="form-group">
            <label>
              {i18next.t("filters.search", { returnObjects: true })}
            </label>
            <input
              type="text"
              className="form-control"
              name="q"
              placeholder={i18next.t("filters.search", { returnObjects: true })}
              onChange={this.toggleFilter}
              value={params.q}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { storeList, getChain, getChainRegions } = state;
  let chainData = getChain && getChain.success && getChain.success.data.payload;

  let regions =
    getChainRegions &&
    getChainRegions.success &&
    getChainRegions.success.data &&
    getChainRegions.success.data.payload.data;

  return {
    storeList,
    getChain,
    getChainRegions,
    chainData,
    regions,
  };
}

export default withRouter(connect(mapStateToProps)(StoreListFilters));
