export function loginFormServerValidationErrors( validationErrors ) {
  return function (dispatch) {
    dispatch({type:'LOGIN-FORM-SERVER-VALIDATION-ERRORS', validationErrors });
  }
}

export function onLoginFormChange(name, value) {

  return function (dispatch) {
    dispatch({type:'ON-LOGIN-FORM-CHANGE', name, value});
  }
}

export function onLoginFormSubmit( ) {
  return function (dispatch) {
    dispatch({type:'ON-LOGIN-FORM-SUBMIT' });
  }
}

export function cancelLoginFormDispatch() {
  return function (dispatch) {
    dispatch({ type:'CANCEL-LOGIN-FORM-DISPATCH' });
  }
}

export function setRedirectToPageAfterLogin( page ) {
  return function (dispatch) {
    dispatch({ type:'SET-REDIRECT-TO-PAGE-AFTER-LOGIN', page });
  }
}