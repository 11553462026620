import store from "../../../../Store";
import getUsers from "../../../../ajax/actions/user/users_get"
import getChainRegions from "../../../../ajax/actions/chain-regions/chain_regions_get";
import getStores from "../../../../ajax/actions/stores/stores_get";

export function toggleUserListFilter(e)
{
  return function (dispatch) {
    let { name, value } = e.target;
    dispatch({type:"TOGGLE-USER-LIST-FILTER", name, value});
    if ( e.target.name === "page" ) {
      store.dispatch( getUsers() )
    }
    if ( name === "chain_id" ) {
      store.dispatch( getChainRegions( null, null, "clear" ));
      store.dispatch( getStores( null, null, null, "clear" ));
    }
    if ( name === "region_id" ) {
      store.dispatch( getStores( null, null, null, "clear" ));
    }
    if ( name === "sort_by" ) {
      store.dispatch( getUsers() )
    }
  }
}

export function toggleUserPaginationListFilter(e)
{
  return function (dispatch) {
    let { name, value } = e.target;
    dispatch({type:"TOGGLE-USER-LIST-FILTER", name, value});
    if ( name === "chain_id" ) {
      store.dispatch( getChainRegions( null, null, "clear" ));
      store.dispatch( getStores( null, null, null, "clear" ));
    }
    if ( name === "region_id" ) {
      store.dispatch( getStores( null, null, null, "clear" ));
    }
    store.dispatch(getUsers())
  }
}

export function setChainIDMode(chainID)
{
  return function (dispatch) {
    dispatch({type:"SET-CHAIN-MODE", chainID});
    store.dispatch(getUsers())
  }
}

export function setDefaultUserFilters()
{
  return function (dispatch) {
    dispatch({type:"SET-DEFAULT-USER-FILTERS"});
  }
}

export function toggleUserFormModal( id )
{
  return function (dispatch) {
    dispatch({type:"TOGGLE-USER-FORM-MODAL", id });
  }
}
