import { isObject } from "./DataHelper"

export const setInitialFormData  = payload =>
{
  if ( payload && isObject( payload )) {
    Object.keys( payload ).map(
        key => setFieldValue( key, payload[key])
    )
  }
}

export const setFieldValue = (id, value) =>
{
  let el = document.getElementById( id );
  if ( el ) el.value = value
}

export const dateDifference = (string) =>
{
  let newDate = new Date(string);
  let inputDate = newDate.getFullYear();
  let currentDate = new Date();
  let currentDateYearFormat = currentDate.getFullYear();
  let dateDifference = currentDateYearFormat - inputDate;
  if(dateDifference >= "18"){
    return dateDifference;
  }
};

export const resetForm = ( formID ) =>
{
  if ( document.getElementById( formID ))
    document.getElementById( formID ).reset()
}
