import React from 'react';
import MyIcon from "../icons/MyIcon";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

class NotFound extends React.Component
{
  render()
  {
    let { success } = this.props;
    return (

        <div className="row">
          <div className="col-lg-4 offset-lg-4 col-md-8 offset-md-2 col-12 text-center pd-3 pt-5 ">

            <MyIcon type="tools" width={80} />

            <h4 className="mt-5">The page you are looking for can not be found.</h4>

            <div className="mt-20">

              <Link to={ success ? `/orders` : `/login` } className="btn btn-secondary btn-in" >
                Back to { success ? `Orders` : `Login` } Page
              </Link>

            </div>

          </div>
        </div>

    )
  }
}
function mapStateToProps(state) {
  let { userDetail } = state;
  let { success } = userDetail;
  return { userDetail, success };
}
export default connect( mapStateToProps )( NotFound );