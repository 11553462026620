export function orderStatusFormServerValidationErrors( validationErrors ) {
  return function (dispatch) {
    dispatch({type:'ORDER-STATUS-FORM-SERVER-VALIDATION-ERRORS', validationErrors });
  }
}

export function onOrderStatusFormSubmit( ) {
  return function (dispatch) {
    dispatch({type:'ON-ORDER-STATUS-FORM-SUBMIT' });
  }
}

export function onOrderStatusFormChange(name, value) {
  return function (dispatch){
      dispatch({type:'ON-ORDER-STATUS-FORM-CHANGE', name, value});
  }
}

export function cancelOrderStatusFormDispatch() {
  return function (dispatch) {
    dispatch({ type:'CANCEL-ORDER-STATUS-FORM-DISPATCH' });
  }
}

export function setDefaultOrderStatusForm() {
  return function (dispatch){
    dispatch({type:'SET-DEFAULT-ORDER-STATUS-FORM'});
  }
}

export function setOrderStatusDataForUpdate() {
  return function (dispatch, getState) {
    let { getOrderStatus } = getState();
    let orderStatus = getOrderStatus && getOrderStatus.success && getOrderStatus.success.data.payload;
    if(orderStatus) {
      dispatch({type:'SET-ORDER-STATUS-DATA-FOR-UPDATE',  orderStatus:JSON.parse(JSON.stringify(orderStatus)) });
    }
  }
}