import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import getCategories from "./categories_get";
import {
    cancelUploadFileFormDispatch, setDefaultUploadFileForm,
    uploadFileFormServerValidationErrors
} from "../../../components/pages/upload-files/form/UploadFileForm-Action";
import {toggleFileFormModal} from "../../../components/pages/upload-files/list/FileList-Actions";

function _success(success)
{
    return { type: 'PUT_CATEGORY_SUCCESS', success };
}
function _error(error)
{
    return { type: 'PUT_CATEGORY_ERROR', error };
}
function _processing(processing)
{
    return { type: 'PUT_CATEGORY_PROCESSING', processing };
}

function updateCategory( id )
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true));
        let {uploadFileForm} = getState();
        // console.log(` <| uploadFileForm |> `, uploadFileForm.data.payload)
        let { payload } = uploadFileForm.data;

        let formData = new FormData();

        payload.files && Object.keys(payload.files).map(key => {
            formData.append(`files[]`, payload.files[ key ])
        });

        Object.keys(payload).map((key) => {
            if( key !== 'files') {
                formData.append(key, payload[key]);
            }
        });

        axios({
            url: apiBaseUrl(`api/admin/categories/${id}`),
            method: "post",
            dataType: 'json',
            data: formData,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                'Content-type': 'multipart/form-data',
                lang:localStorage.getItem('lang' ) || 'en'
            }
        })
        .then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false));
            handleSuccess('Category Updated Successfully');

            store.dispatch(cancelUploadFileFormDispatch());
            store.dispatch(getCategories());
            store.dispatch(toggleFileFormModal());
            store.dispatch(setDefaultUploadFileForm());

        }).catch(function(error)
        {
            dispatch(_error(error));
            dispatch(_processing(false));
            // console.log(` <| error |> `, { error } )
            handleError( error );
            store.dispatch(cancelUploadFileFormDispatch());
            if ( error && error.response.status === 422 ) {
                // store.dispatch( uploadFileFormServerValidationErrors( error.response.data.payload.errors ))
            }
        });
    }
}
export default updateCategory;