import React from "react";
import {connect} from "react-redux";
import store from "../../../Store";
import {toggleChainFormModal} from "./list/ChainList-Actions";
import Page from "../../shared/pages/Page";
import i18next from "i18next";

class ChainInformation extends React.Component
{
  render()
  {
    let { list, match, getChain } = this.props;
    let { processing, success } = getChain;
    if ( !processing && success ) return (
        <Page title={i18next.t("chain.chain-information")}>
          <div className="row">
            <div className="offset-lg-1 col-lg-7">
              <h2>{i18next.t("chain.chain-information", {returnObjects:true})}</h2>

              <h6>{i18next.t("id", {returnObjects:true})}</h6>
              <p>{list.id}</p>

              <h6>{i18next.t("chain.chain-name", {returnObjects:true})}</h6>
              <p>{list.chain_name}</p>

              <h6>{i18next.t("my-account.phone", {returnObjects:true})}</h6>
              <p>{list.chain_name}</p>

              <h6>{i18next.t("my-account.email", {returnObjects:true})}</h6>
              <p>{list.email}</p>

              <h6>{i18next.t("my-account.invoice-address", {returnObjects:true})}</h6>
              <p>{list.invoice_address}</p>

              <h6>{i18next.t("my-account.has-region", {returnObjects:true})}</h6>
              <p>{list.has_region === 1 ? i18next.t("yes",{returnObjects:true}) : i18next.t("no",{returnObjects:true})}</p>

            </div>

            <div className="col-lg-3">
              <div className="right-sidebar">

                <button className="btn-theme btn-gray mb-10"
                        onClick={ () => store.dispatch( toggleChainFormModal( list.id )) }>
                  {i18next.t("chain.update-chain-information",{returnObjects:true})}
                </button>

              </div>
            </div>


          </div>
        </Page>
    )
    return null
  }
}

function mapStateToProps( state ) {
  let { getChain, route } = state;
  let { user_role } = route.data;
  let list = getChain &&
      getChain.success &&
      getChain.success.data.payload;

  return { getChain,route, user_role, list };
}

export default connect( mapStateToProps )( ChainInformation );
