import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../Store';
import WorkTypeFormWorker from './WorkTypeFormWorker'
import {onWorkTypeFormChange, onWorkTypeFormSubmit} from "./WorkTypeForm-Action";
import SaveButton from "../../../shared/buttons/SaveButton";
import TextField from "../../../shared/form/TextField";
import {ucFirst} from "../../../../helpers/StringHelper";
import i18next from "i18next";

class WorkTypeForm extends React.Component {

    roleFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onWorkTypeFormSubmit());
    }

    onChange = (e) => {
        store.dispatch(onWorkTypeFormChange(e.target.name, e.target.value));
    }

    render()
    {
        let { workTypeForm, createWorkType, updateWorkType, modal, getWorkType } = this.props;
        let { payload, errors } = workTypeForm.data;
        let { translations } = payload;
        let processing = createWorkType.processing || updateWorkType.processing;
        return (
            <div className={"row "+getWorkType.processing ? "item-disabled" : "" }>
                <div className="offset-lg-1 col-lg-7 col-md-12">
                    {/*<h2>{"${ucFirst( modal.mode )} Work Type"}</h2>*/}
                    <h2>{i18next.t(modal.mode === "create" ? "work-type.create-work-type" : "work-type.update-work-type",{ returnObjects:true})}</h2>
                    <form onSubmit={this.roleFormSubmit}>
                        { translations ? translations.map((l, key) => {
                            return (
                            <TextField name={"title-"+l.locale}
                                       label={i18next.t("fields.title",{returnObjects:true})+" "+l.locale}
                                       value={l.title}
                                       errors={errors.title}
                                       onChange={this.onChange}
                                       key={key} />
                            )
                        }) : null}

                        <div className="text-center">
                            <SaveButton processing={processing}/>
                        </div>

                        <WorkTypeFormWorker/>

                    </form>
                </div>
            </div>

        )
    }
}

function mapStateToProps(state) {
    let { workTypeForm, createWorkType, updateWorkType, getWorkType, workTypeList } = state;
    let { mode, errors } = workTypeForm.data;
    let { modal } = workTypeList.data;

    return {
        workTypeForm, mode, createWorkType, updateWorkType, getWorkType, errors, modal, workTypeList
    };
}

export default connect(mapStateToProps)(WorkTypeForm);
