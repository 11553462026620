import React from "react";
import store from "../../../../Store";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import getOrder from "../../../../ajax/actions/orders/order_get";
import {
  setOrderSuccessConfirmationMode,
  toggleOrderDetail,
  toggleChatBox,
  toggleUploadFiles,
} from "./OrderDetail-Actions";
import { apiBaseUrl, basePath } from "../../../../helpers/UrlHelper";
import Badge from "../../../shared/badges/Badge";
import Loading from "../../../shared/loaders/Loading";
import { fullDate, justDate } from "../../../../helpers/DateTimeHelper";
import Page from "../../../shared/pages/Page";
import OrderUpdateForm from "./OrderUpdateFormModal";
import { ConfigAPI } from "../../../../Config";
import { loginToken } from "../../../../helpers/LocalStorageHelper";
import ChatBox from "../chat/ChatBox";
import getChatUnread from "../../../../ajax/actions/chat/chat_unread_get";
import { setDefaultChatForm, setUnreadChatCount } from "../chat/Chat-Actions";
import MyIcon from "../../../shared/icons/MyIcon";
import deleteOrderImages from "../../../../ajax/actions/orders/order_image_delete";
import OrderImageUploadModal from "./OrderImageUploadModal";
import i18next from "i18next";

class OrderDetail extends React.Component {
  state = {
    initialLoading: true,
  };

  componentDidMount() {
    store.dispatch(getOrder(this.props.match.params.id));
    store.dispatch(getChatUnread(this.props.match.params.id));

    if (window.innerWidth > 992) {
      // store.dispatch(toggleChatBox(true));
      this.handleClick();
    } else {
      store.dispatch(toggleChatBox(false));
    }
  }

  componentDidUpdate(lastProps) {
    let { getOrder, getChatUnread } = this.props;
    if (getOrder) {
      if (getOrder.success && this.state.initialLoading) {
        this.setState({ initialLoading: false });
      }
    }

    if (getChatUnread !== lastProps.getChatUnread) {
      let { success } = getChatUnread;
      if (success) {
        let message_count =
          success &&
          success.data &&
          success.data.payload &&
          success.data.payload.data &&
          success.data.payload.data.message_count;
        store.dispatch(setUnreadChatCount(message_count));
      }
    }
  }

  componentWillUnmount() {
    store.dispatch(setOrderSuccessConfirmationMode(false));
    store.dispatch(setDefaultChatForm());
    store.dispatch(toggleChatBox(false));
    document.removeEventListener("click", this.handleOutsideClick, false);
  }

  closeChatBox = () => {
    store.dispatch(setDefaultChatForm());
    store.dispatch(toggleChatBox(false));
  };

  handleClick = () => {
    let { payload } = this.props;
    if (!payload.chat_box_visible) {
      // attach/remove event handler
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    store.dispatch(toggleChatBox(true));
  };

  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }
    // Uncomment below if you want the chatBoxt
    // to close when clicking anywhere other than
    // on the component itself

    // this.closeChatBox();
  };

  render() {
    let { orderData, getOrder, user_role, mode, payload, message_count } =
      this.props;
    if (orderData) {
      return (
        <Page title={i18next.t("order.order-detail", { returnObjects: true })}>
          <div
            className={
              "row detail-page order-detail-page " +
              (getOrder.processing ? "item-disabled" : "")
            }
          >
            <div className="col-xl-8 offset-xl-1 col-lg-7 col-md-8 order-md-0 order-1 mt-3 mt-md-0">
              {mode ? (
                <>
                  <h2>Tack för din order.</h2>
                  <h4>
                    Din order är nu skapad
                    <br />
                    En service tekniker kontaktar dig inom kort.
                  </h4>
                </>
              ) : (
                <h2>
                  {i18next.t("order.order-detail", { returnObjects: true })}{" "}
                  {getOrder.processing && <Loading marginLeft={20} />}
                </h2>
              )}

              {/*<h4>{orderData.description}</h4>*/}

              <div className="row">
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <h6>
                    {i18next.t("order.project-number", { returnObjects: true })}
                  </h6>
                  <p>{orderData.project_number}</p>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <h6>
                    {i18next.t("order.date-added", { returnObjects: true })}
                  </h6>
                  <p>{fullDate(orderData.created_at)}</p>
                </div>

                <div className="col-xl-6 col-lg-12 col-md-6">
                  <h6>
                    {i18next.t("order.order-status", { returnObjects: true })}
                  </h6>
                  <p>
                    <Badge type={orderData.status_label}>
                      {orderData.status_label}
                    </Badge>
                    <br />
                    {orderData.status === "planned" && orderData.planned_at ? (
                      <small>{justDate(orderData.planned_at)}</small>
                    ) : (
                      ""
                    )}
                    {orderData.status === "completed" &&
                    orderData.completed_at ? (
                      <small>{justDate(orderData.completed_at)}</small>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <h6>
                    {i18next.t("order.approval-status", {
                      returnObjects: true,
                    })}
                  </h6>
                  <p>
                    <Badge type={orderData.approval_status_label}>
                      {orderData.approval_status_label}
                    </Badge>
                    <br />
                    {orderData.approval_status_date && (
                      <small>{fullDate(orderData.approval_status_date)}</small>
                    )}
                  </p>
                </div>

                <div className="col-sm-12 col-md-12">
                  <h6>{i18next.t("order.order", { returnObjects: true })}</h6>
                  {/*{ console.log( orderData.creator ) }*/}
                  <p>
                    {orderData.creator != "" ? orderData.creator.name : "N/A"}
                  </p>
                </div>

                <div className="col-xl-6 col-lg-12 col-md-6">
                  <h6>
                    {i18next.t("order.store-name", { returnObjects: true })}
                  </h6>
                  <p>{orderData.store && orderData.store.store_name}</p>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <h6>
                    {i18next.t("order.store-address", { returnObjects: true })}
                  </h6>
                  <p>{orderData.store && orderData.store.invoice_address}</p>
                </div>
                <div className="col-sm-12 col-md-12">
                  <h6>
                    {i18next.t("order.store-contact", { returnObjects: true })}
                  </h6>
                  <p>{orderData.store && orderData.store.phone}</p>
                </div>

                <div className="col-sm-12 col-md-12">
                  <h6>
                    {i18next.t("order.description", { returnObjects: true })}
                  </h6>
                  <p>{orderData.description}</p>
                </div>

                <div className="col-xl-6 col-lg-12 col-md-6">
                  <h6>
                    {i18next.t("order.work-type", { returnObjects: true })}
                  </h6>
                  <p>{orderData.worktype_label}</p>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <h6>
                    {i18next.t("order.priority", { returnObjects: true })}
                  </h6>
                  <p>
                    {i18next.t(orderData.priority_label, {
                      returnObjects: true,
                    })}
                  </p>
                </div>

                {orderData.files && orderData.files.length ? (
                  <div className="col-sm-12 col-md-6">
                    <h6>{i18next.t("order.files", { returnObjects: true })}</h6>
                    <ul className="file-list">
                      {orderData.files &&
                        orderData.files.map((f, i) => {
                          return (
                            <li key={i}>
                              {/*<a href={apiBaseUrl("${f.url}")} target="_blank">
                                                                    {f.name}
                                                                </a>*/}
                              <a href={basePath(f.url)} target="_blank">
                                {f.name}
                              </a>
                              {/*{f.extension === "docx" || f.extension === "pdf" ? <a href={apiBaseUrl("api${f.url}")} target="_blank">
                                                                    {f.name}
                                                                </a> : <a href={apiBaseUrl("api${f.url}")} target="_blank">
                                                                    <img src={"${testurl}/api${f.url}"} />
                                                                </a>}*/}
                              {/* <span className="ml-5 cursor-point" onClick={ () => store.dispatch( deleteOrderImages( orderData.id, f.path ) ) }>
                                                                     { this.props.deleteOrderImages.processing ? <Loading /> :  <MyIcon type="trash" /> }
                                                                 </span>*/}
                            </li>
                          );
                        })}
                    </ul>
                    {/* <button className="btn-theme btn-gray mt-2"
                                                    onClick={() => store.dispatch( toggleUploadFiles( true ) )}>
                                                Add More Files
                                            </button>*/}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-xl-3 col-lg-5 col-md-3">
              <div className="btn-cta-wrapper">
                {(user_role === "store-manager" ||
                  user_role === "admin" ||
                  user_role === "super-admin") && (
                  <Link to={"/orders/create"} className="btn-theme btn-gray">
                    {i18next.t("order.create-order", { returnObjects: true })}
                  </Link>
                )}
                {(user_role === "super-admin" ||
                  user_role === "admin" ||
                  user_role === "chain-admin" ||
                  user_role === "regional-director") && (
                  <button
                    className="btn-theme btn-gray mt-2"
                    onClick={() =>
                      store.dispatch(toggleOrderDetail(orderData.id))
                    }
                  >
                    {i18next.t("order.edit-order", { returnObjects: true })}
                  </button>
                )}
                <a
                  target="_blank"
                  className="btn-theme btn-light-gray mt-2"
                  href={apiBaseUrl(
                    "api/orders/generatePdf/" +
                      orderData.id +
                      "?token=" +
                      loginToken(),
                  )}
                >
                  {i18next.t("order.send-as-pdf", { returnObjects: true })}
                </a>
                <div
                  className="popover-container"
                  ref={(node) => {
                    this.node = node;
                  }}
                >
                  <button
                    className="btn-theme btn-light-gray mt-2"
                    onClick={this.handleClick}
                  >
                    Meddelanden{" "}
                    {message_count > 0 ? (
                      <span>
                        ( {message_count} ) <MyIcon type="circle" color="red" />
                      </span>
                    ) : (
                      ""
                    )}
                  </button>
                  {payload.chat_box_visible ? (
                    <ChatBox order_id={orderData.id} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <OrderUpdateForm />
          {/*<OrderImageUploadModal/>*/}
        </Page>
      );
    } else if (getOrder.processing) {
      return (
        <div className="text-center mt-5">
          <Loading lg />
        </div>
      );
    } else {
      return "";
    }
  }
}

function mapStateToProps(state) {
  let {
    getOrder,
    route,
    userDetail,
    orderDetail,
    getChatUnread,
    deleteOrderImages,
  } = state;

  let { payload } = orderDetail.data;
  let userID = userDetail && userDetail.success && userDetail.success.id;
  let mode = orderDetail.data.payload.order_success_confirmation_mode;

  let { success } = getChatUnread;
  let message_count =
    success &&
    success.data &&
    success.data.payload &&
    success.data.payload.data &&
    success.data.payload.data.message_count;

  // console.log(" <|message_count  |> ",message_count )

  let { user_role } = route.data;
  let orderData = getOrder.success && getOrder.success.data.payload;

  return {
    getOrder,
    orderData,
    route,
    user_role,
    userID,
    orderDetail,
    mode,
    payload,
    getChatUnread,
    message_count,
    deleteOrderImages,
  };
}

export default connect(mapStateToProps)(OrderDetail);
