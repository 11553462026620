import React from 'react';
import store from '../../../../Store'
import {connect} from 'react-redux';
import { equalWhenStringified } from '../../../../helpers/StringHelper'
import {
  setDefaultOrderStatusForm,
  setOrderStatusDataForUpdate
} from './OrderStatusForm-Action'
import getOrderStatus from "../../../../ajax/actions/order-status/order_status_get";
import updateOrderStatus from "../../../../ajax/actions/order-status/order_status_put";
import createOrderStatus from "../../../../ajax/actions/order-status/order_status_post";


class OrderStatusFormWorker extends React.Component
{
  componentDidMount(props)
  {
    let { modal } = this.props;
    let { id, mode  } = modal;
    if( id ) {
      store.dispatch(getOrderStatus(  id ));
    }
    if( mode === `create`) {
      store.dispatch(setDefaultOrderStatusForm());
    }
  }

  componentDidUpdate(prev)
  {
    let {  dispatchAPI, modal } = this.props;
    let { id } = modal;

    if(!equalWhenStringified(this.props.getOrderStatus, prev.getOrderStatus)) {
      let {success} = this.props.getOrderStatus;
      if(success) {
        store.dispatch(setOrderStatusDataForUpdate());
      }
    }


    if ( dispatchAPI ) {
      if ( !prev.dispatchAPI ) {
        if( id ) {
          store.dispatch(updateOrderStatus(id));
        } else {
          store.dispatch(createOrderStatus());
        }
      }
    }
  }

  render()
  {
    return null
  }
}
function mapStateToProps(state) {
  let { orderStatusForm, createOrderStatus, updateOrderStatus, getOrderStatus, orderStatusList } = state;
  let { modal } = orderStatusList.data;
  let {dispatchAPI} = orderStatusForm.data;

  return { orderStatusForm, dispatchAPI, createOrderStatus, updateOrderStatus, getOrderStatus, modal, orderStatusList
  };
}
export default connect( mapStateToProps )( OrderStatusFormWorker );