import React from "react";
import {connect} from "react-redux";
import store from "../../../../Store";
import {Link} from "react-router-dom";
import PaginationButtons from "../../../shared/buttons/PaginationButtons";
import {toggleImportStoresFormModal, toggleImportStoresListFilter} from "./ImportStoresList-Actions";
import NoResultsFound from "../../../shared/folder/NoResultsFound";
import Loading from "../../../shared/loaders/Loading";
import ResponsiveModal from "../../../shared/modals/ResponsiveModal";
import Page from "../../../shared/pages/Page";
import TableHead from "../../../shared/table/TableHead";
import ImportStoresListWorker from "./ImportStoresListWorker";
import ImportStoresListFilters from "./ImportStoresListFilters";
import {fullDate} from "../../../../helpers/DateTimeHelper";
import ImportStoresForm from "../import-form/ImportStoresForm";
import i18next from "i18next";

class ImportStoresHistory extends React.Component {
    state = {
        initialLoading: true
    }

    componentDidUpdate( lastProps )
    {
        let { getImportStores } = this.props;
        if(getImportStores) {
            if ( getImportStores.success  && this.state.initialLoading ) {
                this.setState({ initialLoading: false })
            }
        }
    }

    toggleSort = (field) =>
    {
        store.dispatch(  toggleImportStoresListFilter({
            target: { name: "sort_by", value: field }
        }))
    };

    render()
    {
        let { list, getImportStores, pages, user_role, modal, fields, params } = this.props;
        let permitRole = false;
        if (( user_role === "super-admin" || user_role === "admin" || user_role === "chain-admin" )) {
            permitRole = true;
        }
        return (
            <Page title={i18next.t("store.import-stores",{returnObjects:true})}>
                <ImportStoresListWorker/>
                <h2>
                    {i18next.t("store.import-stores",{returnObjects:true})}{" "}{getImportStores.processing && <Loading marginLeft={20}/>}
                    {permitRole && (
                        <span className="pull-right">
                            {( user_role === "super-admin" || user_role === "admin" ) && (
                                <button
                                   onClick={ () => store.dispatch( toggleImportStoresFormModal( )) }
                                   className="btn-theme btn-gray mt-30">{i18next.t("store.import-stores",{returnObjects:true})}
                                </button>
                            )}
                        </span>
                    )}

                </h2>
                <ImportStoresListFilters />
                <div className="table-responsive">
                    <table className="table">
                        <TableHead fields={ fields }
                                   params={ params }
                                   onClick={ this.toggleSort }
                                   noActions />
                        <tbody>
                        {
                            list && list.length ? list.map((l, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{l.id}</td>
                                        <td className="clickable-col">
                                            { l.chain && (
                                                <Link to={"/chains/"+l.chain.id}>
                                                    {l.chain.chain_name}
                                                </Link>
                                            )}
                                        </td>
                                        <td>
                                                {l.user.name}
                                        </td>
                                        <td>{ fullDate( l.created_at ) }</td>
                                        <td>{l.total_imported}</td>
                                        <td>{l.total_failed}</td>
                                    </tr>
                                )
                            }) : null
                        }
                        </tbody>
                        { list && list.length ? (
                            <tfoot>
                            <PaginationButtons pages={pages}
                                               processing={getImportStores.processing}
                                               fetchAction={toggleImportStoresListFilter} />
                            </tfoot>
                        ) : null }
                    </table>

                    { !this.state.initialLoading && ( !list || !list.length) && <NoResultsFound/> }
                    { this.state.initialLoading && <div className="text-center"><Loading/></div> }
                </div>

                <ResponsiveModal visible={ modal.mode }
                                 closeModal={ () => store.dispatch( toggleImportStoresFormModal( )) } >
                    <ImportStoresForm />
                </ResponsiveModal>
            </Page>
        )
    }
}

function mapStateToProps(state)
{
    let { getImportStores, route, importStoresList, getChain } = state;
    let { user_role } = route.data;
    let { modal, fields, params } = importStoresList.data;
    let currentChainID =
        getChain.success &&
        getChain.success.data.payload.id;

    let list =
        getImportStores.success &&
        getImportStores.success.data.payload.data;

    let current_page = getImportStores.success && getImportStores.success.data && getImportStores.success.data.payload.current_page;
    let last_page = getImportStores.success && getImportStores.success.data && getImportStores.success.data.payload.last_page;
    let total = getImportStores.success && getImportStores.success.data && getImportStores.success.data.payload.total;
    let pages = { current_page, last_page, total };

    return { getImportStores, pages, list,route, user_role, modal, importStoresList, fields, params, getChain, currentChainID };
}


export default connect(mapStateToProps)( ImportStoresHistory );
