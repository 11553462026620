export const filteredUserRoles = [
  { name: `customer` },
  { name: `restaurant-admin` },
  { name: `driver` },
  { name: `branch-admin` },
  { name: `branch-operator` },
  { name: `driver-captain` },
  { name: `restaurant-customer` },
];

export const allowedRole = user_role =>
{
  return (
    user_role === 'chain-admin' ||
    user_role === 'regional-director' ||
    user_role === 'store-manager' ||
    user_role === 'technician' ||
    user_role === 'admin' ||
    user_role === 'super-admin'
  )
};

export const parentRole = [{name:"Restaurant Admin", value:"restaurant-admin"},{name:"Branch Admin", value:"branch-admin"}]

export const genderList = [{ id:'female', name:'Female' },{ id:'male', name:'Male' }];

export const activeStatus = [{ id:1, name:`Active` }, { id:0, name:`Inactive` }];

export const activeStatusFilter = [{name:"Active", value:1},{name:"Inactive", value:0}];

export const availabilityStatus = [{name:"Available", value:1},{name:"Unavailable", value:0}];

export const customer_type = [{name:"General", value:30},{name:"VIP", value:10}];

export const xpTypeList = [{name:"Normal", value:"normal"},{name:"Deduction", value:"deduction"}];

export const targetUsers = [{name:"All", value:"all"},{name:"Logged In", value:"logged-in-users"}];

export const operatorType = [
  {value:'normal', name:'Normal'},
  {value:'in_house', name:'In House'}

]



