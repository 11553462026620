const DefaultChatState ={
  initialLoading: false,
  payload:{
    body:"",
  },
  params:{
    limit:10
  },

  chat_seen:[],
  unread_chat_count:"",
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
  message: ""
};

export const ChatState = { data: DefaultChatState };
