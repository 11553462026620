export function passwordFormServerValidationErrors(validationErrors) {
  return function (dispatch) {
    dispatch({
      type: "PASSWORD-FORM-SERVER-VALIDATION-ERRORS",
      validationErrors
    });
  };
}

export function onPasswordFormSubmit() {
  return function (dispatch) {
    dispatch({ type: "ON-PASSWORD-FORM-SUBMIT" });
  };
}

export function onPasswordFormChange(name, value) {
  return function (dispatch) {
    dispatch({ type: "ON-PASSWORD-FORM-CHANGE", name, value });
  };
}

export function cancelPasswordFormDispatch() {
  return function (dispatch) {
    dispatch({ type: "CANCEL-PASSWORD-FORM-DISPATCH" });
  };
}

export function setDefaultPasswordForm() {
  return function (dispatch) {
    dispatch({ type: "SET-DEFAULT-PASSWORD-FORM" });
  };
}

export function setPasswordDataForUpdate() {
  return function (dispatch, getState) {
    let { userDetail } = getState();
    let passwords = userDetail && userDetail.success;
    if (passwords) {
      dispatch({
        type: "SET-PASSWORD-DATA-FOR-UPDATE",
        passwords: JSON.parse(JSON.stringify(passwords))
      });
    }
  };
}
