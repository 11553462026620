import axios from 'axios';

import {handleError} from '../../../helpers/ErrorHelper'
import {apiBaseUrl} from "../../../helpers/UrlHelper";

function _success(success)
{
    return { type: 'LOGIN_USER_SUCCESS', success };
}
function _error(error)
{
    return { type: 'LOGIN_USER_ERROR', error };
}
function _processing(processing)
{
    return { type: 'LOGIN_USER_PROCESSING', processing };
}

function loginUser()
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true ));
        dispatch(_error(null ));
        dispatch(_success(null ));

        let { payload } = getState().loginForm.data;
        const request = axios({

            url: apiBaseUrl(`api/auth/login`),
            method: "post",
            dataType: 'json',
            data: payload,
            headers:{
                lang:localStorage.getItem('lang' ) || 'en',

            }
        })
          .then(function(res){
              dispatch(_success(res));
              dispatch(_processing(false));
              // handleSuccess(res);
          }).catch(function(error){
              handleError( error );
              dispatch(_error(error));
              dispatch(_processing(false));
          });

        return {
            type: 'LOGIN_USER_SUCCESS',
            payload: request
        }
    }
}


export default loginUser;