import React from "react";
import store from "../../../../Store";
import { withRouter } from "react-router";
import { fewerLimitOptions } from "../../../../static-data/LimitOptions";
import { toggleOrderListFilter } from "./OrderList-Actions";
import { fromRecord } from "../../../../helpers/DataHelper";
import { connect } from "react-redux";
import getChainRegions from "../../../../ajax/actions/chain-regions/chain_regions_get";
import getStores from "../../../../ajax/actions/stores/stores_get";
import SearchableSelect from "../../../shared/form/SearchableSelect";
import DateRangeField from "../../../shared/form/DateRangeField";
import { approvalStatusData } from "../../../../static-data/SettingData";
import getOrders from "../../../../ajax/actions/orders/orders_get";
import { urlTrailArray } from "../../../../helpers/UrlHelper";
import i18next from "i18next";

class OrderListFilters extends React.Component {
  toggleFilter = (e) => {
    store.dispatch(toggleOrderListFilter(e));
  };

  componentDidUpdate(lastProps) {
    let { chain_id, region_id, chains } = this.props;
    if (chain_id && chain_id !== lastProps.chain_id) {
      if (fromRecord(chains, "id", "==", chain_id).has_region) {
        store.dispatch(store.dispatch(getChainRegions(100, chain_id)));
      }
      store.dispatch(store.dispatch(getStores(100, chain_id)));
    }
    if (region_id && region_id !== lastProps.region_id) {
      store.dispatch(store.dispatch(getStores(100, null, region_id)));
    }
  }

  applyFilters = (e) => {
    store.dispatch(getOrders());
  };

  render() {
    let {
      orderList,
      chains,
      workTypes,
      statuses,
      chainRegions,
      stores,
      status,
      approval_status,
      date_range,
      chain_id,
      region_id,
      store_id,
      worktype_id,
    } = this.props;
    let adminChainMode = urlTrailArray().includes("chains");
    let { params } = orderList.data;
    return (
      <div className="table-filter mb-5">
        <div className="row">
          <div className="col custom-col">
            <div className="form-group">
              <SearchableSelect
                name="limit"
                noFormGroup
                options={fewerLimitOptions}
                _value="total"
                label={i18next.t("filters.limit", {
                  returnObjects: true,
                })}
                _label="label"
                value={params.limit}
                placeholder={
                  "-- " +
                  i18next.t("filters.limit", {
                    returnObjects: true,
                  }) +
                  " --"
                }
                onChange={this.toggleFilter}
                notSearchable
              />
            </div>
          </div>

          <div className="col custom-col">
            <div className="form-group">
              <label>
                {i18next.t("filters.search", {
                  returnObjects: true,
                })}
              </label>
              <input
                type="text"
                className="form-control"
                name="q"
                placeholder={i18next.t("filters.search", {
                  returnObjects: true,
                })}
                onChange={this.toggleFilter}
                value={params.q}
              />
            </div>
          </div>

          {chains && chains.length ? (
            <div className="col custom-col">
              <SearchableSelect
                clearable
                name="chain_id"
                label={i18next.t("chain.chain", {
                  returnObjects: true,
                })}
                options={chains}
                _value="id"
                _label="chain_name"
                value={chain_id}
                placeholder={
                  "-- " +
                  i18next.t("chain.chain", {
                    returnObjects: true,
                  }) +
                  " --"
                }
                disabled={adminChainMode}
                onChange={this.toggleFilter}
                translate={false}
              />
            </div>
          ) : null}

          {chainRegions && chainRegions.length ? (
            <div className="col custom-col">
              <SearchableSelect
                clearable
                name="region_id"
                label={i18next.t("region.region", {
                  returnObjects: true,
                })}
                options={chainRegions}
                _value="id"
                _label="region_name"
                value={region_id}
                placeholder={
                  "-- " +
                  i18next.t("region.region", {
                    returnObjects: true,
                  }) +
                  " --"
                }
                onChange={this.toggleFilter}
                translate={false}
              />
            </div>
          ) : null}

          {stores && stores.length ? (
            <div className="col custom-col">
              <SearchableSelect
                clearable
                name="store_id"
                label={i18next.t("store.store", {
                  returnObjects: true,
                })}
                options={stores}
                _value="id"
                _label="store_name"
                value={store_id}
                placeholder={
                  "-- " +
                  i18next.t("store.store", {
                    returnObjects: true,
                  }) +
                  " --"
                }
                onChange={this.toggleFilter}
                translate={false}
              />
            </div>
          ) : null}

          {workTypes && workTypes.length ? (
            <div className="col custom-col">
              <SearchableSelect
                clearable
                name="worktype_id"
                options={workTypes}
                _value="id"
                label={i18next.t("fields.work-type", {
                  returnObjects: true,
                })}
                _label="title"
                value={worktype_id}
                placeholder={
                  "-- " +
                  i18next.t("fields.work-type", { returnObjects: true }) +
                  " --"
                }
                onChange={this.toggleFilter}
              />
            </div>
          ) : null}

          {statuses && statuses.length ? (
            <div className="col custom-col">
              <SearchableSelect
                clearable
                name="status"
                label={i18next.t("fields.status", {
                  returnObjects: true,
                })}
                options={statuses}
                _value="slug"
                _label="slug"
                value={status}
                placeholder={
                  "-- " +
                  i18next.t("fields.order-status", { returnObjects: true }) +
                  " --"
                }
                onChange={this.toggleFilter}
              />
            </div>
          ) : null}

          <div className="col custom-col">
            <SearchableSelect
              clearable
              name="approval_status"
              options={approvalStatusData}
              label={i18next.t("fields.approval-status", {
                returnObjects: true,
              })}
              _value="value"
              _label="value"
              value={approval_status}
              placeholder={
                "-- " +
                i18next.t("fields.approval-status", { returnObjects: true }) +
                " --"
              }
              onChange={this.toggleFilter}
            />
          </div>

          <div className="col custom-col">
            <DateRangeField
              label={i18next.t("fields.date-range", {
                returnObjects: true,
              })}
              placeholder={[
                i18next.t("fields.start-date", { returnObjects: true }),
                i18next.t("fields.end-date", { returnObjects: true }),
              ]}
              onChange={this.toggleFilter}
            />
          </div>

          <div className="col custom-col">
            <div className="text-right mt-3">
              <button
                className="btn-theme btn-gray m-auto"
                onClick={this.applyFilters}
              >
                {i18next.t("filters.filter", {
                  returnObjects: true,
                })}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    orderList,
    getChains,
    getChainRegions,
    getStores,
    getWorkTypes,
    getOrderStatuses,
  } = state;
 
  let chains = getChains.success && getChains.success.data.payload.data;
  let chainRegions =
    getChainRegions.success && getChainRegions.success.data.payload.data;
  let stores = getStores.success && getStores.success.data.payload.data;
  let workTypes =
    getWorkTypes.success && getWorkTypes.success.data.payload.data;
  let statuses =
    getOrderStatuses.success && getOrderStatuses.success.data.payload.data;

  let {
    chain_id,
    region_id,
    store_id,
    worktype_id,
    status,
    approval_status,
    limit,
    q,
    date_range,
    lang,
  } = orderList.data.params;

  return {
    orderList,
    getChains,
    getChainRegions,
    getStores,
    chains,
    chainRegions,
    stores,
    workTypes,
    statuses,
    chain_id,
    region_id,
    store_id,
    worktype_id,
    status,
    approval_status,
    date_range,
    limit,
    lang,
    q,
  };
}

export default withRouter(connect(mapStateToProps)(OrderListFilters));
