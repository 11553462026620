const DefaultFileListState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    limit: 25,
    page:   1,
    sort_by:  "id",
    sort_order: "desc",
    q: "",
  },
  file_modal:false,
  delete_file_id:"",
  delete_file_category_id:"",
  file_list:[],
};

export const FileListState = { data: DefaultFileListState };
