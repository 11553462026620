import store from "../../../../Store";
import getOrderStatuses from "../../../../ajax/actions/order-status/order_statuses_get";

export function toggleOrderStatusListFilter(e)
{
  return function (dispatch) {
    let { name, value } = e.target;
    dispatch({type:"TOGGLE-ORDER-STATUS-LIST-FILTER", name, value});
    store.dispatch( getOrderStatuses());
  }
}

export function toggleOrderStatusFormModal( id )
{
  return function (dispatch) {
    dispatch({type:"TOGGLE-ORDER-STATUS-FORM-MODAL", id });
  }
}

