import store from "../../../../Store";
import getChainRegions from "../../../../ajax/actions/chain-regions/chain_regions_get";
import getStores from "../../../../ajax/actions/stores/stores_get";
import getOrders from "../../../../ajax/actions/orders/orders_get";

export function toggleOrderListFilter(e) {
  return function (dispatch) {
    let { name, value } = e.target;
    dispatch({ type: "TOGGLE-ORDER-LIST-FILTER", name, value });

    if (name === "chain_id") {
      store.dispatch(getChainRegions(null, null, "clear"));
      store.dispatch(getStores(null, null, null, "clear"));
    }
    if (name === "region_id") {
      store.dispatch(getStores(null, null, null, "clear"));
    }
    if (name === "page" || name === "sort_by") store.dispatch(getOrders());
  };
}

export function setDefaultOrdersFilters() {
  return function (dispatch) {
    dispatch({ type: "SET-DEFAULT-ORDERS-FILTERS" });
  };
}
