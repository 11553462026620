import axios from "axios";
import { apiBaseUrl } from "../../../helpers/UrlHelper";
import { loginToken } from "../../../helpers/LocalStorageHelper";
import { handleError } from "../../../helpers/ErrorHelper";
function _success(success) {
    return { type: "GET_CHAIN_REGION_SUCCESS", success };
}

function _error(error) {
    return { type: "GET_CHAIN_REGION_ERROR", error };
}

function _processing(processing) {
    return { type: "GET_CHAIN_REGION_PROCESSING", processing };
}

function getChainRegion(region_id) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        let { getChain, route } = getState();
        let id =
            getChain && getChain.success && getChain.success.data.payload.id;
        let { entity, user_role } = route.data;
        if (!id && user_role === "chain-admin") {
            id = entity.id;
        }

        const request = axios({
            url: apiBaseUrl("api/chains/"+id+"/regions/"+region_id),
            method: "get",
            headers: {
                Authorization: "Bearer " + loginToken(),
                lang: localStorage.getItem("lang") || "en",
            },
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
            })
            .catch(function (error) {
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError(error);
            });

        return {
            type: "GET_CHAIN_REGION_SUCCESS",
            payload: request,
        };
    };
}

export default getChainRegion;
