import React from "react";
import store from "../../../../Store"
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {
  cancelPasswordFormDispatch,
  passwordFormServerValidationErrors, setDefaultPasswordForm,
} from "./PasswordForm-Action"
import updateMyAccount from "../../../../ajax/actions/user/my_account_put";
class PasswordFormWorker extends React.Component
{
  componentDidUpdate(prev)
  {
    let {  dispatchAPI, id, } = this.props;

    if(this.props.updateMyPassword !== prev.updateMyPassword) {
      let {error, success} = this.props.updateMyPassword;
      if(success) {
        store.dispatch(cancelPasswordFormDispatch());
        store.dispatch( setDefaultPasswordForm() )
      }

      if ( error && error.response.status === 422 ) {
        store.dispatch(cancelPasswordFormDispatch());
        store.dispatch( passwordFormServerValidationErrors( error.response.data.message ))
      }
    }

    if ( dispatchAPI ) {
      if ( !prev.dispatchAPI ) {
        if( id ) {
           store.dispatch(updateMyAccount("password_mode"));
        }
      }
    }
  }

    render()
    {
        return null
    }
}
function mapStateToProps(state) {
   let { passwordForm, updateMyPassword, getUser, userDetail } = state;

   let id = userDetail && userDetail.success.id;

    let {dispatchAPI} = passwordForm.data;
    return { passwordForm, dispatchAPI, updateMyPassword, getUser, userDetail, id
   };
}
export default withRouter(connect( mapStateToProps )( PasswordFormWorker ));
