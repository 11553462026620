export default function getOrderStatusesReducer
  (state = { error:'', success:'', processing:false}, action)
{
  switch (action.type) {
    case 'GET_ORDER_STATUSES_SUCCESS':
      return {...state, ...{success: action.success}};

    case 'GET_ORDER_STATUSES_ERROR':
      return {...state, ...{error: action.error}};

    case 'GET_ORDER_STATUSES_PROCESSING':
      return {...state, ...{processing: action.processing}};

    default: return state;
  }
}