import React from "react";
import {connect} from "react-redux"
import store from "../../../../Store";
import RoleFormWorker from "./RoleFormWorker"
import {onRoleFormChange, onRoleFormSubmit} from "./RoleForm-Action";
import SaveButton from "../../../shared/buttons/SaveButton";
import TextField from "../../../shared/form/TextField";
import {ucFirst} from "../../../../helpers/StringHelper";
import i18next from "i18next";

class RoleForm extends React.Component {

    roleFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onRoleFormSubmit());
    }

    onChange = (e) => {
        store.dispatch(onRoleFormChange(e.target.name, e.target.value));
    }

    render() {
        let {roleForm, createRole, updateRole, modal, getRole } = this.props;
        let {payload, errors} = roleForm.data;
        let {title, slug} = payload;
        let processing = createRole.processing || updateRole.processing;
        return (
            <div className={ getRole.processing ? "item-disabled" : null }>
                <div className="offset-lg-1 col-lg-7 col-md-12">
                    {/*<h2>{"${ucFirst( modal.mode )} Role"} </h2>*/}
                    <h2>{i18next.t(modal.mode==="create" ? "role.create-role" : "role.update-role",{returnObjects:true})} </h2>
                    <form onSubmit={this.roleFormSubmit}>

                        <TextField name="title"
                                   label={i18next.t("fields.title", {returnObjects:true})}
                                   value={title}
                                   errors={errors.title}
                                   onChange={this.onChange} />

                        <TextField name="slug"
                                   label={i18next.t("fields.slug", {returnObjects:true})}
                                   value={slug}
                                   errors={errors.title}
                                   onChange={this.onChange} />


                        <div className="text-center">
                            <SaveButton processing={processing}/>
                        </div>

                        <RoleFormWorker/>

                    </form>
                </div>
            </div>

        )
    }
}

function mapStateToProps(state) {

    let { roleForm, createRole, updateRole, getRole, roleList } = state;
    let { mode, search, errors} = roleForm.data;
    let { modal } = roleList.data;
    return {
        roleForm, mode, search, createRole, updateRole, getRole, errors, roleList, modal
    };
}

export default connect(mapStateToProps)(RoleForm);
