import React from 'react';

class MyIcon  extends React.Component
{
    render()
    {
        let { type, className, onClick, width, color  } = this.props;
        return (

            <i className={`icon fas fa-${type} ${className}`}
               onClick={onClick}

               style={{ fontSize: width || null, color: color || null }}
            />

        )
    }
}
export default MyIcon;