import React from "react";
import store from "../../../../Store";
import { connect } from "react-redux";
import { endOfURL } from "../../../../helpers/UrlHelper";
import { withRouter } from "react-router";
import {
  cancelOrdersFormDispatch,
  onOrdersFormChange,
  ordersFormServerValidationErrors,
  setDefaultOrdersForm,
  setRoleForOrderForm,
} from "./OrdersForm-Action";
import getOrderProjectNumber from "../../../../ajax/actions/orders/order_projectnumber_get";
import getOrderPriority from "../../../../ajax/actions/orders/order_priority_get";
import createOrders from "../../../../ajax/actions/orders/orders_post";
import getWorkTypes from "../../../../ajax/actions/work-types/work_types_get";
import { setOrderSuccessConfirmationMode } from "../detail/OrderDetail-Actions";
import getChains from "../../../../ajax/actions/chains/chains_get";
import getStores from "../../../../ajax/actions/stores/stores_get";
import getChainRegions from "../../../../ajax/actions/chain-regions/chain_regions_get";

class OrdersFormWorker extends React.Component {
  componentDidMount() {
    if (endOfURL() === "create") {
      store.dispatch(setDefaultOrdersForm());
    }

    let { user_role, entity_detail, entities_ids } = this.props;
    if (user_role === "store-manager") {
      store.dispatch(getOrderProjectNumber());
    }

    store.dispatch(getOrderPriority());
    store.dispatch(getWorkTypes());

    if (
      user_role === "admin" ||
      user_role === "super-admin" ||
      (user_role === "chain-admin" && entities_ids.length >= 2)
    ) {
      store.dispatch(getChains());
    }

    store.dispatch(setRoleForOrderForm(user_role));

    if (user_role === "chain-admin" && entities_ids.length == 1) {
      store.dispatch(onOrdersFormChange("chain_id", entity_detail.id));
      store.dispatch(getChainRegions(null, entity_detail.id));
      store.dispatch(getStores(null, entity_detail.id));
    }

    if (user_role === "regional-director") {
      store.dispatch(onOrdersFormChange("chain_id", entity_detail.chain_id));
      store.dispatch(onOrdersFormChange("region_id", entity_detail.id));
      store.dispatch(getStores(null, entity_detail.chain_id, entity_detail.id));
    }
  }

  componentDidUpdate(lastProps) {
    let { dispatchAPI, mode, user_role, entity_id, userDetail } = this.props;

    if (entity_id !== lastProps.entity_id && user_role === "chain-admin") {
      store.dispatch(onOrdersFormChange("chain_id", entity_id));
    }

    if (userDetail !== lastProps.userDetail) {
      let { success } = userDetail;
      if (success) {
        if (success.role === "chain-admin") {
          store.dispatch(onOrdersFormChange("chain_id", success.entity_id));
        }
      }
    }

    if (this.props.createOrder !== lastProps.createOrder) {
      let { error, success } = this.props.createOrder;
      if (success) {
        store.dispatch(cancelOrdersFormDispatch());
        this.props.history.push("/orders/"+success.data.payload.data.id);
        store.dispatch(setOrderSuccessConfirmationMode(true));
        store.dispatch(setDefaultOrdersForm());
      }

      if (error && error.response.status === 422) {
        store.dispatch(cancelOrdersFormDispatch());
        store.dispatch(
          ordersFormServerValidationErrors(error.response.data.payload.message)
        );
      }
    }

    if (dispatchAPI) {
      if (!lastProps.dispatchAPI) {
        if (mode === "create") {
          store.dispatch(createOrders());
        }
      }
    }
  }

  render() {
    return null;
  }
}
function mapStateToProps(state) {
  let { ordersForm, createOrder, route, userDetail } = state;
  let { user_role } = route.data;
  let { dispatchAPI, mode } = ordersForm.data;
  let { entity_id, entity_detail, entities_ids } = userDetail.success;
  return {
    ordersForm,
    dispatchAPI,
    mode,
    createOrder,
    route,
    userDetail,
    user_role,
    entity_id,
    entity_detail,
    entities_ids,
  };
}
export default withRouter(connect(mapStateToProps)(OrdersFormWorker));
