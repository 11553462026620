import React from "react";
import store from "../../../../Store"
import {connect} from "react-redux";
import {withRouter} from "react-router";
import { equalWhenStringified } from "../../../../helpers/StringHelper"
import getRole from "../../../../ajax/actions/role/role_get"
import {
  setDefaultRoleForm,
  setRoleDataForUpdate
} from "./RoleForm-Action"
import updateRole from "../../../../ajax/actions/role/role_put"
import createRole from "../../../../ajax/actions/role/role_post"


class RoleFormWorker extends React.Component
{
  componentDidMount()
  {
    let { mode, id } = this.props.modal;
    if( id ) {
      store.dispatch(getRole( id ));
    }
    if( mode === "create") {
      store.dispatch(setDefaultRoleForm());
    }
  }

  componentDidUpdate(prev)
  {
    let {  dispatchAPI, match, modal } = this.props;
    let { id } = modal;

    if(!equalWhenStringified(this.props.getRole, prev.getRole)) {
      let {success} = this.props.getRole;
      if(success) {
        store.dispatch(setRoleDataForUpdate());
      }
    }


    if ( dispatchAPI ) {
      if ( !prev.dispatchAPI ) {
        if(id ) {
          store.dispatch(updateRole(id));
        } else {
          store.dispatch(createRole());
        }
      }
    }
  }

  render()
  {
    return null
  }
}
function mapStateToProps(state) {
  let { roleForm, createRole, updateRole, getRole, roleList } = state;
  let { modal } = roleList.data;
  let {dispatchAPI} = roleForm.data;

  return { roleForm, dispatchAPI, createRole, updateRole, getRole, roleList, modal
  };
}
export default withRouter(connect( mapStateToProps )( RoleFormWorker ));
