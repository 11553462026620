const DefaultOrderListState = {
  payload: {
    initialLoading: true,
    flashMessage: null,
    error: "",
    success: "",
  },
  params: {
    limit: 25,
    page: 1,
    sort_by: "project_number",
    sort_order: "desc",
    lang: "",
    q: "",
    chain_id: "",
    region_id: "",
    store_id: "",
    worktype_id: "",
    status: "",
    approval_status: "",
  },
  fields: [
    { field: "project_number", label: "project-number", sort: true },
    { field: "chain", label: "chain", sort: false },
    { field: "store", label: "store", sort: false },
    { field: "worktype", label: "work-type", sort: false },
    { field: "priority", label: "priority", sort: true },
    { field: "created_at", label: "task-created", sort: true },
    { field: "status", label: "status", sort: true },
    { field: "orderer", label: "orderer", sort: false },
    { field: "approval_status", label: "approval", sort: true },
  ],
};

export const OrderListState = { data: DefaultOrderListState };
