import { endOfURL } from "../../../../../helpers/UrlHelper"

const DefaultChainRegionFormState = {
  payload:{
    region_name:"",
    phone:"",
    email:"",
    contact_person:"",
    chain_id:"",
  },
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
  mode:endOfURL(),
  message: ""

};

export const ChainRegionsFormState = { data: DefaultChainRegionFormState }
