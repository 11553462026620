import { combineReducers } from "redux"
import loginUser from "./ajax/reducers/user/user-login-post"
import logoutUser from "./ajax/reducers/user/user-logout-post"
import loginForm from "./components/pages/login/LoginForm-Reducer"
import route from "./components/routes/main-route/MainRoute-Reducer"

//Roles
import getRoles from "./ajax/reducers/role/roles-get"
import getRole from "./ajax/reducers/role/role-get"
import updateRole from "./ajax/reducers/role/role-put"
import createRole from "./ajax/reducers/role/role-post"
import deleteRole from "./ajax/reducers/role/role-delete"
import roleList from "./components/pages/roles/list/RoleList-Reducer"
import roleForm from "./components/pages/roles/form/RoleForm-Reducer"

//Users
import getUsers from "./ajax/reducers/user/users-get"
import getTechnicians from "./ajax/reducers/user/technicians-get"
import getUser from "./ajax/reducers/user/user-get"
import updateUser from "./ajax/reducers/user/user-put"
import createUser from "./ajax/reducers/user/user-post"
import deleteUser from "./ajax/reducers/user/user-delete"
import userList from "./components/pages/users/list/UserList-Reducer"
import userForm from "./components/pages/users/form/UserForm-Reducer"
import updateMyAccount from "./ajax/reducers/user/my-account-put"
import accountForm from "./components/pages/account/AccountForm-Reducer"
import passwordForm from "./components/pages/account/password/PasswordForm-Reducer"

import bulkUsersUpdateForm from "./components/pages/users/bulk-update-form/BulkUserUpdateForm-Reducer"
import bulkUsersUpdate from "./ajax/reducers/user/bulk-users-import"


//Chains
import getChains from "./ajax/reducers/chains/chains-get"
import getChain from "./ajax/reducers/chains/chain-get"
import createChain from "./ajax/reducers/chains/chain-post"
import updateChain from "./ajax/reducers/chains/chain-put"
import deleteChain from "./ajax/reducers/chains/chain-delete"
import chainList from "./components/pages/chains/list/ChainList-Reducer"
import chainForm from "./components/pages/chains/form/ChainForm-Reducer"

//Chain Regions
import getChainRegions from "./ajax/reducers/chain-regions/chain-regions-get"
import getChainRegion from "./ajax/reducers/chain-regions/chain-region-get"
import createChainRegion from "./ajax/reducers/chain-regions/chain-region-post"
import updateChainRegion from "./ajax/reducers/chain-regions/chain-region-put"
import deleteChainRegion from "./ajax/reducers/chain-regions/chain-region-delete"
import chainRegionsList from "./components/pages/chains/chain-regions/list/ChainRegionsList-Reducer"
import chainRegionsForm from "./components/pages/chains/chain-regions/form/ChainRegionsForm-Reducer"

//Stores
import getStores from "./ajax/reducers/stores/stores-get"
import getStore from "./ajax/reducers/stores/store-get"
import updateStore from "./ajax/reducers/stores/store-put"
import createStore from "./ajax/reducers/stores/store-post"
import deleteStore from "./ajax/reducers/stores/store-delete"
import storeList from "./components/pages/stores/list/StoreList-Reducer"
import storeForm from "./components/pages/stores/form/StoreForm-Reducer"

import importStores from "./ajax/reducers/stores/import-store-post"
import getImportStores from "./ajax/reducers/stores/import-stores-get"
import importStoresForm from "./components/pages/stores/import-form/ImportStoresForm-Reducer"
import importStoresList from "./components/pages/stores/import-list/ImportStoresList-Reducer"

//WorkTypes
import getWorkTypes from "./ajax/reducers/work-types/work-types-get"
import getWorkType from "./ajax/reducers/work-types/work-type-get"
import updateWorkType from "./ajax/reducers/work-types/work-type-put"
import createWorkType from "./ajax/reducers/work-types/work-type-post"
import deleteWorkType from "./ajax/reducers/work-types/work-type-delete"
import workTypeList from "./components/pages/work-types/list/WorkTypeList-Reducer"
import workTypeForm from "./components/pages/work-types/form/WorkTypeForm-Reducer"

//Order Status
import getOrderStatuses from "./ajax/reducers/order-status/order-statuses-get"
import getOrderStatusAll from "./ajax/reducers/order-status/order-status-all-get"
import getOrderStatus from "./ajax/reducers/order-status/order-status-get"
import updateOrderStatus from "./ajax/reducers/order-status/order-status-put"
import createOrderStatus from "./ajax/reducers/order-status/order-status-post"
import deleteOrderStatus from "./ajax/reducers/order-status/order-status-delete"
import orderStatusList from "./components/pages/order-status/list/OrderStatusList-Reducer"
import orderStatusForm from "./components/pages/order-status/form/OrderStatusForm-Reducer"

//Orders
import getOrderPriority from "./ajax/reducers/orders/order-priority-get"
import getOrderProjectNumber from "./ajax/reducers/orders/order-projectnumber-get"
import createOrder from "./ajax/reducers/orders/orders-post"
import updateOrder from "./ajax/reducers/orders/orders-put"
import deleteOrder from "./ajax/reducers/orders/order-delete"
import getOrder from "./ajax/reducers/orders/order-get"
import getOrders from "./ajax/reducers/orders/orders-get"
import ordersForm from "./components/pages/orders/form/OrdersForm-Reducer"
import orderList from "./components/pages/orders/list/OrderList-Reducer"
import orderDetail from "./components/pages/orders/detail/OrderDetail-Reducer"
import deleteOrderImages from "./ajax/reducers/orders/order-image-delete";
import createOrderImages from "./ajax/reducers/orders/order-images-post";

//User details
import userDetail from "./ajax/reducers/user/user-detail-get"

//Export
import createExport from "./ajax/reducers/export/export-post";

import chainDashboard from "./components/pages/chains/ChainDashboard-Reducer";

//Chats
import getChats from "./ajax/reducers/chat/chats-get";
import getChatUnread from "./ajax/reducers/chat/chat-unread-get";
import createChat from "./ajax/reducers/chat/chat-post";
import createSeenChat from "./ajax/reducers/chat/chat-seen-post";
import chatState from "./components/pages/orders/chat/Chat-Reducer"

//Files
import getCategories from "./ajax/reducers/category/categories-get";
import deleteFile from "./ajax/reducers/file/file-delete";
import createCategory from "./ajax/reducers/category/category-post";
import updateCategory from "./ajax/reducers/category/category-put";
import deleteCategory from "./ajax/reducers/category/category-delete";
import getCategory from "./ajax/reducers/category/category-get";
import uploadFileForm from "./components/pages/upload-files/form/UploadFileForm-Reducer";
import fileList from "./components/pages/upload-files/list/FileList-Reducer";
/**
 * COMBINE ALL REDUCERS
 * Place related reducers in a single line. No pairs
 */
export default combineReducers({

  /* - - - COMPONENT REDUCERS - - - */
    loginForm, route, chainDashboard,

    /* - - - AJAX REDUCERS - - - */
    loginUser, logoutUser,

    userDetail,

    getRoles, getRole, createRole, updateRole, deleteRole, roleList, roleForm,

    getUsers, getTechnicians, getUser, createUser, updateUser, deleteUser, userList, userForm,

    bulkUsersUpdateForm, bulkUsersUpdate,

    accountForm, updateMyAccount, passwordForm,

    getChains, getChain, createChain, updateChain, deleteChain, chainList, chainForm,

    getChainRegions, getChainRegion, createChainRegion, updateChainRegion, deleteChainRegion, chainRegionsList, chainRegionsForm,

    getStores, getStore, updateStore, createStore, deleteStore, storeList, storeForm,

    importStoresForm, importStores, getImportStores, importStoresList,

    getWorkTypes, getWorkType, updateWorkType, createWorkType, deleteWorkType,workTypeList, workTypeForm,

    getOrderStatuses, getOrderStatus,  updateOrderStatus, createOrderStatus, getOrderStatusAll, deleteOrderStatus,orderStatusList, orderStatusForm,

    getOrderPriority, getOrderProjectNumber, createOrder,updateOrder, deleteOrder, getOrder, getOrders, ordersForm, orderList, orderDetail,

    deleteOrderImages, createOrderImages,

    createExport,

    getChats, getChatUnread, createChat, chatState, createSeenChat,

    getCategories, getCategory, createCategory, uploadFileForm, fileList, deleteFile, deleteCategory, updateCategory,
})
