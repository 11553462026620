export function storeFormServerValidationErrors(validationErrors) {
  return function (dispatch) {
    dispatch({ type: "STORE-FORM-SERVER-VALIDATION-ERRORS", validationErrors });
  };
}

export function onStoreFormSubmit() {
  return function (dispatch) {
    dispatch({ type: "ON-STORE-FORM-SUBMIT" });
  };
}

export function onStoreFormChange(name, value) {
  return function (dispatch) {
    dispatch({ type: "ON-STORE-FORM-CHANGE", name, value });
  };
}
export function onStoreMultiValueChange(name, value) {
  return function (dispatch) {
    dispatch({ type: "ON-STORE-MULTI-VALUE-CHANGE", name, value });
  };
}

export function cancelStoreFormDispatch() {
  return function (dispatch) {
    dispatch({ type: "CANCEL-STORE-FORM-DISPATCH" });
  };
}

export function setDefaultStoreForm() {
  return function (dispatch, getState) {
    let { getChain, route } = getState();
    let chain_id =
      getChain && getChain.success && getChain.success.data.payload.id;
    if (!chain_id) {
      chain_id = route.data.entity.id;
    }
    dispatch({ type: "SET-DEFAULT-STORE-FORM", chain_id });
  };
}

export function setStoreDataForUpdate() {
  return function (dispatch, getState) {
    let { getStore } = getState();
    let stores = getStore && getStore.success && getStore.success.data.payload;
    if (stores) {
      dispatch({
        type: "SET-STORE-DATA-FOR-UPDATE",
        stores: JSON.parse(JSON.stringify(stores)),
      });
    }
  };
}
