import React from "react";
import { connect } from "react-redux";
import store from "../../../../../Store";
import {
  onChainRegionFormChange,
  onChainRegionFormSubmit,
} from "./ChainRegionsForm-Action";
import SaveButton from "../../../../shared/buttons/SaveButton";
import ChainRegionsFormWorker from "./ChainRegionsFormWorker";
import TextField from "../../../../shared/form/TextField";
import { ucFirst } from "../../../../../helpers/StringHelper";
import SearchableSelect from "../../../../shared/form/SearchableSelect";
import i18next from "i18next";

class ChainRegionsForm extends React.Component {
  chainRegionFormSubmit = (e) => {
    e.preventDefault();
    store.dispatch(onChainRegionFormSubmit());
  };

  onChange = (e) => {
    store.dispatch(onChainRegionFormChange(e.target.name, e.target.value));
  };

  render() {
    let {
      chainRegionsForm,
      createChainRegion,
      updateChainRegion,
      modal,
      user_role,
      entity,
      entities,
    } = this.props;
    let { payload, errors } = chainRegionsForm.data;
    let { phone, email, contact_person, region_name, chain_id } = payload;
    let processing =
      createChainRegion.processing || updateChainRegion.processing;
    return (
      <div className="row">
        <div className="offset-lg-1 col-lg-7 col-md-12">
          <h2>{ucFirst(modal.mode)} Region</h2>

          <form onSubmit={this.chainRegionFormSubmit}>
            <div className="form-wrapper">
              <TextField
                name="region_name"
                label={i18next.t("chain.chain-region.region-name", {
                  returnObjects: true,
                })}
                value={region_name}
                errors={errors.region_name}
                onChange={this.onChange}
              />

              <TextField
                name="phone"
                value={phone}
                label={i18next.t("my-account.phone", { returnObjects: true })}
                errors={errors.phone}
                onChange={this.onChange}
              />

              <TextField
                name="email"
                value={email}
                label={i18next.t("my-account.email", { returnObjects: true })}
                errors={errors.email}
                onChange={this.onChange}
              />

              <TextField
                name="contact_person"
                value={contact_person}
                label={i18next.t("my-account.contact-person", {
                  returnObjects: true,
                })}
                errors={errors.contact_person}
                onChange={this.onChange}
              />

              {user_role === "chain-admin" ? (
                <SearchableSelect
                  name="chain_id"
                  options={entities}
                  label={i18next.t("chain.chain-id", { returnObjects: true })}
                  _value="id"
                  _label="chain_name"
                  value={chain_id}
                  errors={errors.chain_id}
                  placeholder="-- Chain --"
                  onChange={this.onChange}
                />
              ) : null}

              <div className="text-right">
                <SaveButton processing={processing} />
              </div>
            </div>

            <ChainRegionsFormWorker />
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    chainRegionsForm,
    createChainRegion,
    updateChainRegion,
    getChainRegion,
    chainRegionsList,
    route,
  } = state;
  let { mode, search } = chainRegionsForm.data;
  let { entity, entities, user_role } = route.data;
  let { modal } = chainRegionsList.data;
  return {
    route,
    entity,
    entities,
    user_role,
    chainRegionsForm,
    mode,
    search,
    createChainRegion,
    updateChainRegion,
    getChainRegion,
    modal,
    chainRegionsList,
  };
}

export default connect(mapStateToProps)(ChainRegionsForm);
