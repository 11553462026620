import { endOfURL } from "../../../../helpers/UrlHelper";

const DefaultOrdersFormState = {
  payload: {
    description: "",
    worktype_id: "",
    priority: "",
    documents: [],
    store_id: "",
    chain_id: "",
    region_id: "",
    approval_status: "",
  },
  loggedInRole: "",
  files: [],
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
  mode: endOfURL(),
  message: "",
};

export const OrdersFormState = { data: DefaultOrdersFormState };
