export function roleFormServerValidationErrors( validationErrors ) {
  return function (dispatch) {
    dispatch({type:"ROLE-FORM-SERVER-VALIDATION-ERRORS", validationErrors });
  }
}

export function onRoleFormSubmit( ) {
  return function (dispatch) {
    dispatch({type:"ON-ROLE-FORM-SUBMIT" });
  }
}

export function onRoleFormChange(name, value) {
  return function (dispatch){
      dispatch({type:"ON-ROLE-FORM-CHANGE", name, value});
  }
}

export function cancelRoleFormDispatch() {
  return function (dispatch) {
    dispatch({ type:"CANCEL-ROLE-FORM-DISPATCH" });
  }
}

export function setDefaultRoleForm() {
  return function (dispatch){
    dispatch({type:"SET-DEFAULT-ROLE-FORM"});
  }
}

export function setRoleDataForUpdate() {
  return function (dispatch, getState) {
    let { getRole } = getState();
    let roles = getRole && getRole.success && getRole.success.data.payload;
    if(roles) {
      dispatch({type:"SET-ROLE-DATA-FOR-UPDATE",  roles:JSON.parse(JSON.stringify(roles)) });
    }
  }
}
