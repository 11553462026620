import React from 'react'
import {Route, Switch} from 'react-router-dom'
import Roles from "../pages/roles/list/Roles";
import WorkTypes from "../pages/work-types/list/WorkTypes";
import OrderStatus from "../pages/order-status/list/OrderStatus";
import AccountForm from "../pages/account/AccountForm";
import Chains from "../pages/chains/list/Chains";
import ChainDashboard from "../pages/chains/ChainDashboard";
import Users from "../pages/users/list/UserList";
import Orders from "../pages/orders/list/Orders";
import OrdersForm from "../pages/orders/form/OrdersForm";
import OrderDetail from "../pages/orders/detail/OrderDetail";
import ImportStoresHistory from "../pages/stores/import-list/ImportStoresList";
import BulkUserUpdateForm from "../pages/users/bulk-update-form/BulkUserUpdateForm";
import NotFound from "../shared/pages/NotFound";
import JourNummer from "../pages/jour-nummer/JourNummer";
import UploadFileForm from "../pages/upload-files/form/UploadFileForm";
import Files from "../pages/upload-files/list/Files";

class SuperAdminRoutes extends React.Component
{
    render()
    {
        return (

            <Switch>

                <Route exact path="/roles" component={ Roles } />
                <Route exact path="/work-types" component={ WorkTypes } />
                <Route exact path="/order-status" component={ OrderStatus } />

                <Route exact path="/account" component={ AccountForm } />
                <Route exact path="/jour-nummer" component={ JourNummer } />

                <Route exact path="/chains" component={ Chains } />
                <Route       path="/chains/:chain_id([0-9]{0,9})" component={ ChainDashboard } />

                <Route exact path="/users" component={ Users } />

                <Route exact path="/orders" component={ Orders } />
                <Route exact path="/" component={ Orders } />
                <Route exact path="/orders/create" component={ OrdersForm } />
                <Route exact path="/orders/:id([0-9]{0,9})" component={ OrderDetail } />

                <Route exact path="/stores/import" component={ ImportStoresHistory } />
                <Route exact path="/users/bulk-update" component={ BulkUserUpdateForm } />
                <Route exact path="/upload-files/create" component={ UploadFileForm } />
                <Route exact path="/upload-files" component={ Files } />
                <Route component={ NotFound } />



            </Switch>

        )
    }
}

export default SuperAdminRoutes ;