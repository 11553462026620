import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import {clone} from "../../../helpers/DataHelper";
import store from "../../../Store";
import {
    cancelUserFormDispatch,
    userFormServerValidationErrors
} from "../../../components/pages/users/form/UserForm-Action";
import {toggleUserFormModal} from "../../../components/pages/users/list/UserList-Actions";
import getUsers from "./users_get";

function _success(success)
{
    return { type: 'POST_USER_SUCCESS', success };
}
function _error(error)
{
    return { type: 'POST_USER_ERROR', error };
}
function _processing(processing)
{
    return { type: 'POST_USER_PROCESSING', processing };
}

function createUser( )
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let {userForm} = getState();

        let payload = clone( userForm.data.payload );

        if ( payload.role === `chain-admin` )       payload.entity_id = payload.chain_id;
        if ( payload.role === `regional-director` ) payload.entity_id = payload.region_id;
        if ( payload.role === `store-manager` )     payload.entity_id = payload.store_id;

        if(payload.role === `admin` || payload.role === `super-admin` || payload.role === `technician`) {
            delete payload.entity_type;
            delete payload.entity_id;
        }

        const request = axios({
            url: apiBaseUrl(`api/users`),
            method: "post",
            dataType: 'json',
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        })
            .then(function(res){
                dispatch(_success(res));
                dispatch(_processing(false));

                handleSuccess( res );
                store.dispatch(cancelUserFormDispatch());
                store.dispatch(toggleUserFormModal());
                store.dispatch(getUsers());

            }).catch(function(error)
            {
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError( error );
                if ( error.response && error.response.status === 422 ) {
                    store.dispatch(cancelUserFormDispatch());
                    store.dispatch( userFormServerValidationErrors( error.response.data.payload.errors ))
                }

            });

        return {
            type: 'POST_USER_SUCCESS',
            payload: request
        }
    }
}


export default createUser;