import { ImportStoresListState } from "./ImportStoresList-State"

export default function importStoresListReducer(state = ImportStoresListState, action) {

  let { data } = { ...state };
  let { name, value, id } = action;

  switch (action.type) {

    case "TOGGLE-IMPORT-STORES-LIST-FILTER":
      data.params[ name ] = value;
      if ( name === "sort_by" ) {
        if ( data.params.sort_by === value ) {
          data.params.sort_order = data.params.sort_order === "desc" ? "asc" : "desc"
        }
      }
      if ( name === "limit" ) {
        data.params.page = 1;
      }
      return { ...state, data };

    case "TOGGLE-IMPORT-STORES-FORM-MODAL":
      if ( data.modal.mode ) { // if open, close
        data.modal = { mode: "", id: "" }

      } else { // if close, open
        data.modal.id = id || "";
        data.modal.mode = id ? "update" : "create";
      }
      return { ...state, data };

    default: return state
  }
}
