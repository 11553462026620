import React from "react";
import store from "../../../../Store";
import {
     onOrderUploadFormChange,
    toggleUploadFiles
} from "./OrderDetail-Actions";
import {connect} from "react-redux";
import ResponsiveModal from "../../../shared/modals/ResponsiveModal";
import UploadField from "../../../shared/form/UploadField";
import SaveButton from "../../../shared/buttons/SaveButton";
import createOrderImages from "../../../../ajax/actions/orders/order_images_post";

class OrderImageUploadModal extends React.Component
{
    state = {
        displayErrors: false
    };

    orderUploadFiles = (e) =>
    {
        e.preventDefault();
        store.dispatch( createOrderImages( this.props.orderData.id ) )
    };

    onChange = (e) =>
    {
        store.dispatch(onOrderUploadFormChange(e.target.name, e.target.value));
    };

    render() {
        let {  uploadModal,  getOrder,files, createOrderImages } = this.props;
        let {displayErrors} = this.state;
        return (
            <ResponsiveModal visible={uploadModal}
                             closeModal={() => {
                                 this.setState({displayErrors: false});
                                 store.dispatch(toggleUploadFiles(false));
                             }}>

                <div className={getOrder.processing ? "item-disabled" : null}>
                    <form onSubmit={ this.orderUploadFiles } >
                        <UploadField onChange={this.onChange}
                                     name="documents"
                                     value={files}
                                     multiple
                                     supportedFormats=".docx, .png, .jpg, .pdf"
                        />
                        <div className="text-center">
                            <SaveButton btnClass="text-uppercase ml-auto mt-5" processing={createOrderImages.processing}/>
                        </div>
                    </form>
                </div>

            </ResponsiveModal>
        )
    }
}

function mapStateToProps(state) {
    let {orderDetail, getOrder, route, createOrderImages } = state;
    let {user_role} = route.data;

    let orderData =
        getOrder.success &&
        getOrder.success.data.payload;


    let {order_id, uploadModal} = orderDetail.data.payload;
    let { errors, errorsPresent, files } = orderDetail.data;
    return {
        orderDetail, order_id, uploadModal, getOrder, files,
        orderData,    user_role, errors, errorsPresent, createOrderImages
    };
}

export default connect(mapStateToProps)(OrderImageUploadModal);
