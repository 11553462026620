import axios from "axios";
import { apiBaseUrl } from "../../../helpers/UrlHelper";
import { loginToken } from "../../../helpers/LocalStorageHelper";
import { handleError, handleSuccess } from "../../../helpers/ErrorHelper";
import store from "../../../Store";
import {
  cancelStoreFormDispatch,
  storeFormServerValidationErrors,
} from "../../../components/pages/stores/form/StoreForm-Action";
import { toggleStoreFormModal } from "../../../components/pages/stores/list/StoreList-Actions";
import getStores from "./stores_get";

function _success(success) {
  return { type: "POST_STORE_SUCCESS", success };
}
function _error(error) {
  return { type: "POST_STORE_ERROR", error };
}
function _processing(processing) {
  return { type: "POST_STORE_PROCESSING", processing };
}

function createStore() {
  return (dispatch, getState) => {
    let { storeForm } = getState();
    dispatch(_processing(true));
    const request = axios({
      url: apiBaseUrl("api/stores"),
      method: "post",
      dataType: "json",
      data: storeForm.data.payload,
      headers: {
        Authorization: "Bearer " + loginToken(),
        lang: localStorage.getItem("lang") || "en",
      },
    })
      .then(function (res) {
        dispatch(_success(res));
        dispatch(_processing(false));
        handleSuccess(res);
        store.dispatch(cancelStoreFormDispatch());
        store.dispatch(toggleStoreFormModal());
        store.dispatch(getStores());
      })
      .catch(function (error) {
        dispatch(_error(error));
        dispatch(_processing(false));
        store.dispatch(cancelStoreFormDispatch());
        handleError(error);
        if (error && error.response.status === 422) {
          store.dispatch(cancelStoreFormDispatch());
          store.dispatch(
            storeFormServerValidationErrors(error.response.data.payload.errors)
          );
        }
      });

    return {
      type: "POST_STORE_SUCCESS",
      payload: request,
    };
  };
}

export default createStore;
