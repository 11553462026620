import { endOfURL } from '../../../../helpers/UrlHelper'

const DefaultOrderStatusFormState = {
  payload:{
    title:``,
    slug:``,
  },
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
  mode:endOfURL(),
  message: ``

};

export const OrderStatusFormState = { data: DefaultOrderStatusFormState };