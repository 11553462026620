import React from "react";
import i18next from "i18next";
class TechnicianInfo extends React.Component {
  render() {
    return (
      <div className="sidebar-title">
        <h4>{i18next.t("header.technician-dashboard")}</h4>
      </div>
    );
  }
}
export default TechnicianInfo;
