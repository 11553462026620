import axios from 'axios'
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from "../../../helpers/LocalStorageHelper";
import {handleError} from "../../../helpers/ErrorHelper";

function _success(success) {
    return {type: 'IMPORT_STORES_SUCCESS', success};
}

function _error(error) {
    return {type: 'IMPORT_STORES_ERROR', error};
}

function _processing(processing) {
    return {type: 'IMPORT_STORES_PROCESSING', processing};
}

function getImportStores() {
    return (dispatch,getState) => {
        dispatch(_processing(true));

        let { params } = getState().importStoresList.data;

        axios({
            url:apiBaseUrl(`api/import/store`),
            method:'get',
            params,
            headers:{
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        }).then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false));

        }).catch(function(error){
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error);
        });


    }
}

export default getImportStores;