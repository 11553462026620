import React from "react"
import { Route, Switch } from "react-router-dom"
import {withRouter} from "react-router"

import { connect } from "react-redux"
import Users from "../pages/users/list/UserList";
import AdminDashboard from "../pages/admin-dashboard/AdminDashboard";
import ChainRegions from "../pages/chains/chain-regions/list/ChainRegions";
import Stores from "../pages/stores/list/Stores";
import Orders from "../pages/orders/list/Orders";
import OrderDetail from "../pages/orders/detail/OrderDetail";
import AccountForm from "../pages/account/AccountForm";
import OrdersForm from "../pages/orders/form/OrdersForm";
import NotFound from "../shared/pages/NotFound";
import JourNummer from "../pages/jour-nummer/JourNummer";
import Files from "../pages/upload-files/list/Files";

class ChainAdminRoutes extends React.Component
{

    render()
    {
        return (

            <Switch>
                <Route exact path="/" component={ AdminDashboard } />
                <Route exact path="/account" component={ AccountForm } />
                <Route exact path="/jour-nummer" component={ JourNummer } />
                <Route exact path="/users" component={ Users } />
                <Route exact path="/regions" component={ChainRegions}/>
                <Route exact path="/stores" component={Stores}/>
                <Route exact path="/orders/create" component={ OrdersForm } />
                <Route exact path="/orders" component={ Orders } />
                <Route exact path="/orders/:id([0-9]{0,9})" component={ OrderDetail } />
                <Route exact path="/upload-files" component={ Files } />
                <Route component={ NotFound } />

            </Switch>

        )
    }
}

function mapStateToProps(state) {
    let { route } = state;
    let { user_role } = route.data;
    return { route, user_role };
}

export default withRouter(connect(mapStateToProps)(ChainAdminRoutes ));
