export default function deleteFileReducer
  (state = { error:'', success:'', processing:false, }, action)
{
  switch (action.type) {
    case 'DELETE_FILE_SUCCESS':
      return {...state, ...{success: action.success}};

    case 'DELETE_FILE_ERROR':
      return {...state, ...{error: action.error}};

    case 'DELETE_FILE_PROCESSING':
      return {...state, ...{processing: action.processing, id:action.id }};

    default: return state;
  }
}