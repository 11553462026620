import axios from 'axios'
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from "../../../helpers/LocalStorageHelper";
import {handleError} from "../../../helpers/ErrorHelper";
import {clone} from "../../../helpers/DataHelper";

function _success(success) {
    return {type: 'GET_STORES_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_STORES_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_STORES_PROCESSING', processing};
}

    function getStores( limit, _chain_id, _region_id, clear )
{
    return (dispatch, getState) =>
    {

        let { storeList, getChain, userDetail, route } = getState();

        let chain_id = getChain && getChain.success && getChain.success.data.payload.id;
        // let region_id = getChainRegion && getChainRegion.success && getChainRegion.success.data.payload.id;

        let params = clone( storeList.data.params );
        params.chain_id = chain_id;

        let { role, entity_id } = userDetail.success;

        if ( role === `regional-director` ) params.region_id = entity_id;
        if ( role === `chain-admin` ) params.chain_id = entity_id;

        if ( limit ) params.limit = limit;
        if ( _chain_id ) params.chain_id = _chain_id;
        if ( _region_id ) params.region_id = _region_id;

        if ( clear ) {
            dispatch(_success(null));
            dispatch(_processing(false));
            dispatch(_error(null));
        } else {

            dispatch(_processing(true));
            dispatch(_error(null));

            const request = axios({
                url:apiBaseUrl(`api/stores`),
                method:'get',
                params,
                headers:{
                    'Authorization': 'Bearer ' + loginToken(),
                    lang:localStorage.getItem('lang' ) || 'en'
                },
            }).then(function(res){
                dispatch(_success(res));
                dispatch(_processing(false));
            }).catch(function(error){
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError(error);
            });

            return {
                type:'GET_STORES_SUCCESS',
                payload:request
            }
        }

        // params.region_id= region_id;


    }
}

export default getStores;