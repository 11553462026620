import axios from 'axios'
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from "../../../helpers/LocalStorageHelper";
import {handleError, handleSuccess} from "../../../helpers/ErrorHelper";
import getOrder from "./order_get";
import store from '../../../Store';

function _success(success) {
    return {type: 'DELETE_ORDER_IMAGES_SUCCESS', success};
}

function _error(error) {
    return {type: 'DELETE_ORDER_IMAGES_ERROR', error};
}

function _processing(processing) {
    return {type: 'DELETE_ORDER_IMAGES_PROCESSING', processing};
}

function deleteOrderImages( order_id, path ) {
    return (dispatch,) => {
        dispatch(_processing(true));
        let data = {
            path:path
        }
        axios({
            url:apiBaseUrl(`api/orders/${order_id}/delete`),
            method:'POST',
            data,
            headers:{
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        }).then(function(res){
            dispatch(_success(res));
            dispatch(_processing(false));
            handleSuccess( res )
            store.dispatch( getOrder(order_id) )

        }).catch(function(error){
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error);
        });
    }
}
export default deleteOrderImages;