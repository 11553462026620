import React from 'react';
import Select from 'react-select'
import {fromRecord, inRecord, isArray} from "../../../helpers/DataHelper";
import {names, ucFirst, unslufigy} from "../../../helpers/StringHelper";

class MultiSearchableSelect extends React.Component
{
    label()
    {
        let { name, label, errors } = this.props;
        let errorsPresent = errors && errors.length;
        if ( label ) return (
            <label className={ names(`label-text`, errorsPresent && `has-error`, `animated fadeIn faster`) }>
                { label } { errorsPresent ? errors[0].message + '.' : null }
            </label>
        );
        else {
            if ( name ) {
                let _label = unslufigy( name );
                if ( name.includes(`_id`)) _label = name.split(`_id`)[0];
                return (
                    <label className={ names(`label-text`, errorsPresent && `has-error`, `animated fadeIn faster`) }>
                        { ucFirst( _label ) } { errorsPresent ? errors[0].message + '.' : null }
                    </label>
                )
            }
        }
    }

    labelText()
    {
        let { label, name } = this.props;
        if ( label ) return label;
        else {
            if ( name ) {
                let _label = unslufigy( name );
                if ( name.includes(`_id`)) _label = ucFirst( name.split(`_id`)[0] );
                return _label
            }
        }
    }

    changed = ( item ) =>
    {
        let { onChange, name, isMulti } = this.props;
        let _val = ``;
        if (isMulti && isArray(item) ){
            item.map( o => _val = o.value )
            onChange({target: {name, value: _val}});
        }else{
            onChange({target: {name, value: ``}});
        }
    };

    render()
    {
        let {
            name, options, value, notSearchable, placeholder, _value, _label,
            errors, required, processing, noFormGroup, disabled,
        } = this.props;

        let _options = [];
        options && isArray( options ) && options.map( option =>
            _options.push({ value: option[ _value ], label: option[ _label ] }));

        /*_value = { value, label:
                inRecord( _options, `value`, `==`, value ) ?
                    fromRecord( _options, `value`, `==`, value ).label  : null };*/
// console.log(` <| _value |> `, _value)
        /* if ( !_options.length ) {
             _options.unshift({ value: `nothing`, label: `-- No ${ this.labelText() } --`, isDisabled: true });
         }
         else _options.unshift({ value: `nothing`, label: `-- Select ${ this.labelText() } --`, isDisabled: true });*/
        return (

            <div className={ names(
                !noFormGroup && `form-group`,
                errors && errors.length && `has-error`,
                disabled && `item-disabled`,
                required && `required`,
                processing && `processing`) }>

                { this.label() }

                <Select name={ name }
                        defaultValue={ !value ? placeholder : value }
                        options={ _options }
                        isClearable={ true }
                        onChange={ this.changed  }
                        placeholder={ placeholder }
                        isSearchable={ !notSearchable }
                        isDisabled={ disabled }
                        isMulti
                />
            </div>

        )

    }
}
export default MultiSearchableSelect;