import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import {clone} from "../../../helpers/DataHelper";
import store from "../../../Store";
import {
    cancelUserFormDispatch,
    userFormServerValidationErrors
} from "../../../components/pages/users/form/UserForm-Action";
import getUsers from "./users_get";

function _success(success)
{
    return { type: 'PUT_USER_SUCCESS', success };
}
function _error(error)
{
    return { type: 'PUT_USER_ERROR', error };
}
function _processing(processing)
{
    return { type: 'PUT_USER_PROCESSING', processing };
}

function updateUser( id )
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let {userForm} = getState();

        let payload = clone( userForm.data.payload );

        if ( payload.role === `chain-admin` )       payload.entity_id = payload.chain_id;
        else if ( payload.role === `regional-director` ) payload.entity_id = payload.region_id;
        else if ( payload.role === `store-manager` )     payload.entity_id = payload.store_id;
        else {
            delete payload.entity_id;
            delete payload.entity_type;
        }
        if ( !payload.password ) delete payload.password;

        const request = axios({
            url: apiBaseUrl(`api/users/${id}`),
            method: "put",
            dataType: 'json',
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        })
            .then(function(res){
                dispatch(_success(res));
                dispatch(_processing(false));

                store.dispatch(cancelUserFormDispatch());
                store.dispatch(getUsers());
                handleSuccess( res  )

            }).catch(function(error){
                dispatch(_error(error));
                dispatch(_processing(false));
                if ( error && error.response.status === 422 ) {
                    store.dispatch(cancelUserFormDispatch());
                    store.dispatch(userFormServerValidationErrors(error.response.data.message))
                }
                handleError(error)

            });

        return {
            type: 'PUT_USER_SUCCESS',
            payload: request
        }
    }
}


export default updateUser;