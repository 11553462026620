import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import {
    cancelWorkTypeFormDispatch,
    workTypeFormServerValidationErrors
} from "../../../components/pages/work-types/form/WorkTypeForm-Action";
import getWorkTypes from "./work_types_get";

function _success(success)
{
    return { type: 'PUT_WORK_TYPE_SUCCESS', success };
}
function _error(error)
{
    return { type: 'PUT_WORK_TYPE_ERROR', error };
}
function _processing(processing)
{
    return { type: 'PUT_WORK_TYPE_PROCESSING', processing };
}

function updateWorkType(id )
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true));
        let {workTypeForm} = getState();

        const request = axios({
            url: apiBaseUrl(`api/worktypes/${id}`),
            method: "put",
            dataType: 'json',
            data: workTypeForm.data.payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        })
            .then(function(res){
                dispatch(_success(res));
                dispatch(_processing(false));
                store.dispatch(cancelWorkTypeFormDispatch());
                store.dispatch(getWorkTypes());
                handleSuccess( res  )

            }).catch(function(error){
                dispatch(_error(error));
                dispatch(_processing(false));
                if ( error && error.response.status === 422 ) {
                    store.dispatch(cancelWorkTypeFormDispatch());
                    store.dispatch(workTypeFormServerValidationErrors(error.response.data.message))
                }
                handleError(error)
            });

        return {
            type: 'PUT_WORK_TYPE_SUCCESS',
            payload: request
        }
    }
}


export default updateWorkType;