import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import {cancelOrdersFormDispatch} from "../../../components/pages/orders/form/OrdersForm-Action";
import store from '../../../Store';
import {clone} from "../../../helpers/DataHelper";
import {toggleUploadFiles} from "../../../components/pages/orders/detail/OrderDetail-Actions";
import getOrder from "./order_get";
import Alert from "react-s-alert";
import {ConfigErrorAlert} from "../../../Config";

function _success(success) {
    return {type: 'POST_ORDER_IMAGES_SUCCESS', success};
}

function _error(error) {
    return {type: 'POST_ORDER_IMAGES_ERROR', error};
}

function _processing(processing) {
    return {type: 'POST_ORDER_IMAGES_PROCESSING', processing};
}

function createOrderImages(order_id)
{
    return (dispatch, getState) =>
    {
        dispatch(_processing(true));
        dispatch(_error(null));
        dispatch(_success(null));

        let { orderDetail } = getState();
        let { files } = orderDetail.data;

        let formData = new FormData();
        files && Object.keys(files).map(key => {
                formData.append(`documents[]`, files[ key ])
        });

        axios({
            url: apiBaseUrl(`api/orders/${order_id}/upload`),
            method: "post",
            data: formData,
            headers: {
                'Content-type': 'multipart/form-data',
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        })
        .then(function (res) {
            dispatch(_success(res));
            dispatch(_processing(false));
            handleSuccess(res);
            store.dispatch( getOrder( order_id ) );
            store.dispatch( toggleUploadFiles( false ) );
        }).catch(function (error) {
            dispatch(_error(error));
            dispatch(_processing(false));
            // handleError(error)
            console.log(` <| error |> `, {error} )
            if ( error && error.response.status === 422 ) {
                let message = ``;
                Object.keys(error.response.data.payload.message).map(key =>

                    message = error.response.data.payload.message[key][0]
                );
                Alert.error(`Error 422  - ${ message }`, ConfigErrorAlert );
                // store.dispatch(cancelOversiktFormDispatch());
                // store.dispatch( overSiktFormServerValidationErrors( error.response.data.payload.errors ))
            }
            store.dispatch( cancelOrdersFormDispatch() )
        });
    }
}
export default createOrderImages;