import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import {
    cancelRoleFormDispatch,
    roleFormServerValidationErrors
} from "../../../components/pages/roles/form/RoleForm-Action";
import {toggleRoleFormModal} from "../../../components/pages/roles/list/RoleList-Actions";
import getRoles from "./roles_get";

function _success(success)
{
    return { type: 'POST_ROLE_SUCCESS', success };
}
function _error(error)
{
    return { type: 'POST_ROLE_ERROR', error };
}
function _processing(processing)
{
    return { type: 'POST_ROLE_PROCESSING', processing };
}

function createRole( )
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true));
        let {roleForm} = getState();

        const request = axios({
            url: apiBaseUrl(`api/roles`),
            method: "post",
            dataType: 'json',
            data: roleForm.data.payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        })
            .then(function(res){
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res);

                store.dispatch(cancelRoleFormDispatch());
                store.dispatch(toggleRoleFormModal());
                store.dispatch(getRoles());


            }).catch(function(error)
            {
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError( error )
                if ( error && error.response.status === 422 ) {
                    store.dispatch(cancelRoleFormDispatch());
                    store.dispatch( roleFormServerValidationErrors( error.response.data.payload.errors ))
                }

            });

        return {
            type: 'POST_ROLE_SUCCESS',
            payload: request
        }
    }
}


export default createRole;