import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import {
    cancelWorkTypeFormDispatch,
    workTypeFormServerValidationErrors
} from "../../../components/pages/work-types/form/WorkTypeForm-Action";
import {toggleWorkTypeFormModal} from "../../../components/pages/work-types/list/WorkTypeList-Actions";
import getWorkTypes from "./work_types_get";

function _success(success)
{
    return { type: 'POST_WORK_TYPE_SUCCESS', success };
}
function _error(error)
{
    return { type: 'POST_WORK_TYPE_ERROR', error };
}
function _processing(processing)
{
    return { type: 'POST_WORK_TYPE_PROCESSING', processing };
}

function createWorkType( )
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true));
        let {workTypeForm} = getState();

        const request = axios({
            url: apiBaseUrl(`api/worktypes`),
            method: "post",
            dataType: 'json',
            data: workTypeForm.data.payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        })
            .then(function(res)
            {
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res);
                store.dispatch(cancelWorkTypeFormDispatch());
                store.dispatch(toggleWorkTypeFormModal());
                store.dispatch(getWorkTypes());

            }).catch(function(error)
            {
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError( error )
                if ( error && error.response.status === 422 ) {
                    store.dispatch(cancelWorkTypeFormDispatch());
                    store.dispatch( workTypeFormServerValidationErrors( error.response.data.payload.errors ))
                }

            });

        return {
            type: 'POST_WORK_TYPE_SUCCESS',
            payload: request
        }
    }
}


export default createWorkType;