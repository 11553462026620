import React from "react";
import { connect } from "react-redux";
import store from "../../../../Store";
import PasswordFormWorker from "./PasswordFormWorker";
import {
  onPasswordFormChange,
  onPasswordFormSubmit
} from "./PasswordForm-Action";
import SaveButton from "../../../shared/buttons/SaveButton";
import PasswordField from "../../../shared/form/PasswordField";
import i18next from "i18next";
class PasswordForm extends React.Component {
  passwordFormSubmit = (e) => {
    e.preventDefault();
    store.dispatch(onPasswordFormSubmit());
  };

  onChange = (e) => {
    store.dispatch(onPasswordFormChange(e.target.name, e.target.value));
  };

  render() {
    let { payload, errors, processing } = this.props;
    let { new_password, confirm_password } = payload;
    return (
      <div className="mt-5 ">
        <PasswordFormWorker />
        <h2>{i18next.t("user.update-password", { returnObjects: true })}</h2>
        <form onSubmit={this.passwordFormSubmit}>
          <PasswordField
            name="new_password"
            value={new_password}
            label={i18next.t("password.new-password", { returnObjects: true })}
            errors={errors.new_password}
            onChange={this.onChange}
          />
          <PasswordField
            name="confirm_password"
            label={i18next.t("password.confirm-password", {
              returnObjects: true
            })}
            value={confirm_password}
            errors={errors.confirm_password}
            placeholder={i18next.t("password.confirm-password", {
              returnObjects: true
            })}
            onChange={this.onChange}
          />
          <div className="text-center">
            <SaveButton
              btnClass={"text-uppercase mt-5"}
              processing={processing}
            />
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { passwordForm, updateUser } = state;
  let { payload, errors } = passwordForm.data;
  let processing = updateUser.processing;

  return {
    passwordForm,
    errors,
    payload,
    updateUser,
    processing
  };
}

export default connect(mapStateToProps)(PasswordForm);
