import React from "react";
import {connect} from "react-redux";
import store from "../../../Store";
import Page from "../../shared/pages/Page";
import i18next from "i18next";


class JourNummer extends React.Component
{
  render()
  {
     return (
        <Page title="Jour Nummer">
          <div className="row">
            <div className="offset-lg-1 col-lg-7">
              <h2>
                { i18next.t("jour.title-1", {returnObjects:true}) }
                <br/>
                { i18next.t("jour.title-2", {returnObjects:true}) }
              </h2>

              { i18next.t("jour.jour-city", {returnObjects:true}) !== "" && (<h5>{i18next.t("jour.jour-city")}</h5>)}
              <p>
                {i18next.t("jour.jour-name") !== "" && <>{(i18next.t("jour.jour-name"))} <br /></>}
                {i18next.t("jour.jour-name-2") !== "" && <>{(i18next.t("jour.jour-name-2"))} <br/> </>}
                {i18next.t("jour.jour-name-8") !== "" && <>{(i18next.t("jour.jour-name-8"))} <br/> </>}
                {i18next.t("jour.jour-name-9") !== "" && <>{(i18next.t("jour.jour-name-9"))} <br/> </>}
                {i18next.t("jour.jour-name-10") !== "" && <>{(i18next.t("jour.jour-name-10"))} </>}
              </p>
              
              { i18next.t("jour.jour-city-2") !== "" && (<h5>{i18next.t("jour.jour-city-2")}</h5>)}
              <p>
                {i18next.t("jour.jour-name-3") !== "" && <>{(i18next.t("jour.jour-name-3"))} <br/> </>} 
                {i18next.t("jour.jour-name-11") !== "" && <>{(i18next.t("jour.jour-name-11"))} <br/> </>} 
                {i18next.t("jour.jour-name-12") !== "" && <>{(i18next.t("jour.jour-name-12"))}<br/> </>} 
                {i18next.t("jour.jour-name-13") !== "" && <>{(i18next.t("jour.jour-name-13"))}<br/> </>} 
                {i18next.t("jour.jour-name-14") !== "" && <>{(i18next.t("jour.jour-name-14"))}<br/> </>} 
                {i18next.t("jour.jour-name-4") !== "" && <>{(i18next.t("jour.jour-name-4"))}
                <br/> </>} 
                {i18next.t("jour.jour-name-5") !== "" && <>{(i18next.t("jour.jour-name-5"))}
                <br/> </>} 
                {i18next.t("jour.jour-name-6") !== "" && <>{(i18next.t("jour.jour-name-6"))}
                <br/> </>} 
                {i18next.t("jour.jour-name-7") !== "" && (i18next.t("jour.jour-name-7"))}
              </p>

              {i18next.t("jour.jour-city-3") !== "" && (<h5>{i18next.t("jour.jour-city-3")}</h5>)}
              <p>
                {i18next.t("jour.jour-name-special")} <br />
                {i18next.t("jour.jour-name-special-2")}
              </p>

              {i18next.t("jour.jour-city-4") !== "" && (<h5>{i18next.t("jour.jour-city-4")}</h5>)}
              <p>
                {i18next.t("jour.jour-name-special-3")} <br />
                {i18next.t("jour.jour-name-special-4")}
              </p>

              {i18next.t("jour.jour-city-5") !== "" && (<h5>{i18next.t("jour.jour-city-5")}</h5>)}
              <p>
                {i18next.t("jour.jour-name-special-5")} <br />
                {i18next.t("jour.jour-name-special-6")}
              </p>

              {i18next.t("jour.jour-city-6") !== "" && (<h5>{i18next.t("jour.jour-city-6")}</h5>)}
              <p>
                {i18next.t("jour.jour-name-special-7")} <br />
                {i18next.t("jour.jour-name-special-8")}
              </p>

              {i18next.t("jour.jour-city-7") !== "" && (<h5>{i18next.t("jour.jour-city-7")}</h5>)}
              <p>
                {i18next.t("jour.jour-name-special-9")} <br />
                {i18next.t("jour.jour-name-special-10")}
              </p>

              {i18next.t("jour.jour-city-8") !== "" && (<h5>{i18next.t("jour.jour-city-8")}</h5>)}
              <p>
                {i18next.t("jour.jour-name-special-11")} <br />
                {i18next.t("jour.jour-name-special-12")}
              </p>
            </div>
          </div>
        </Page>
    )
  }
}

export default  JourNummer;
