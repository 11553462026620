import axios from "axios";
import { apiBaseUrl } from "../../../helpers/UrlHelper";
import { loginToken } from "../../../helpers/LocalStorageHelper";
import { handleError, handleSuccess } from "../../../helpers/ErrorHelper";
import store from "../../../Store";
import {
  cancelStoreFormDispatch,
  storeFormServerValidationErrors,
} from "../../../components/pages/stores/form/StoreForm-Action";
import getStores from "./stores_get";
import { toggleStoreFormModal } from "../../../components/pages/stores/list/StoreList-Actions";

function _success(success) {
  return { type: "PUT_STORE_SUCCESS", success };
}
function _error(error) {
  return { type: "PUT_STORE_ERROR", error };
}
function _processing(processing) {
  return { type: "PUT_STORE_PROCESSING", processing };
}

function updateStore(id) {
  return (dispatch, getState) => {
    dispatch(_processing(true));
    let { storeForm } = getState();

    axios({
      url: apiBaseUrl(`api/stores/${id}`),
      method: "put",
      dataType: "json",
      data: storeForm.data.payload,
      headers: {
        Authorization: "Bearer " + loginToken(),
        lang: localStorage.getItem("lang") || "en",
      },
    })
      .then(function (res) {
        dispatch(_success(res));
        dispatch(_processing(false));
        store.dispatch(cancelStoreFormDispatch());
        store.dispatch(toggleStoreFormModal());
        store.dispatch(getStores());
        handleSuccess(res);
      })
      .catch(function (error) {
        dispatch(_error(error));
        dispatch(_processing(false));
        handleError(error);
        if (error && error.response.status === 422) {
          store.dispatch(cancelStoreFormDispatch());
          store.dispatch(
            storeFormServerValidationErrors(error.response.data.payload.message)
          );
        }
      });
  };
}

export default updateStore;
