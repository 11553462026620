import React from "react";
import StoreWorker from "./StoreWorker";
import { connect } from "react-redux";
import store from "../../../../Store";
import swal from "sweetalert";
import PaginationButtons from "../../../shared/buttons/PaginationButtons";
import {
  toggleStoreFormModal,
  toggleStoreListFilter,
} from "./StoreList-Actions";
import deleteStore from "../../../../ajax/actions/stores/store_delete";
import StoreListFilters from "./StoreListFilters";
import MyIcon from "../../../shared/icons/MyIcon";
import NoResultsFound from "../../../shared/folder/NoResultsFound";
import Loading from "../../../shared/loaders/Loading";
import ResponsiveModal from "../../../shared/modals/ResponsiveModal";
import Page from "../../../shared/pages/Page";
import StoreForm from "../form/StoreForm";
import TableHead from "../../../shared/table/TableHead";
import { onImportStoreFormChange } from "../import-form/ImportStoresForm-Action";
import { toggleImportStoresFormModal } from "../import-list/ImportStoresList-Actions";
import { apiBaseUrl, pushToPage } from "../../../../helpers/UrlHelper";
import { withRouter } from "react-router";
import createExport from "../../../../ajax/actions/export/export_post";
import { loginToken } from "../../../../helpers/LocalStorageHelper";
import i18next from "i18next";

class Stores extends React.Component {
  state = {
    initialLoading: true,
  };

  componentDidUpdate(lastProps) {
    let { getStores } = this.props;
    if (getStores) {
      if (getStores.success && this.state.initialLoading) {
        this.setState({ initialLoading: false });
      }
    }
  }

  deleteConfirm = (id) => {
    swal(i18next.t("confirm.delete-store", { returnObjects: true }), {
      buttons: {
        cancel: i18next.t("cancel", { returnObjects: true }),
        catch: {
          text: i18next.t("delete", { returnObjects: true }),
          value: id,
        },
      },
    }).then((value) => {
      if (value) this.delete(value);
    });
  };

  delete(id) {
    store.dispatch(deleteStore(id));
  }

  toggleSort = (field) => {
    store.dispatch(
      toggleStoreListFilter({
        target: { name: "sort_by", value: field },
      })
    );
  };

  importStore = () => {
    let { currentChainID } = this.props;
    store.dispatch(onImportStoreFormChange("chain_id", currentChainID));
    pushToPage(this.props, "/stores/import");
    store.dispatch(toggleImportStoresFormModal());
  };

  exportStores = () => {
    let { currentChainID } = this.props;
    store.dispatch(createExport("store", currentChainID));
  };

  render() {
    let {
      list,
      getStores,
      pages,
      user_role,
      modal,
      fields,
      params,
      match,
      currentChainID,
    } = this.props;
    let permitRole = false;
    if (
      user_role === "super-admin" ||
      user_role === "admin" ||
      user_role === "chain-admin"
    ) {
      permitRole = true;
    }
    let bearer_token = loginToken();
    let export_file = apiBaseUrl(
      "api/export?type=UsersExport&access_token=" + bearer_token
    );
    if (match.path !== "/stores") {
      export_file = apiBaseUrl(
        "api/export?type=StoresExport&access_token=" +
          bearer_token +
          "&chain_id=" +
          currentChainID
      );
    }
    return (
      <Page title={i18next.t("store.stores", { returnObjects: true })}>
        <h2>
          {i18next.t("store.stores", { returnObjects: true })}{" "}
          {getStores.processing && <Loading marginLeft={20} />}
          {permitRole && (
            <span className="pull-right">
              {(user_role === "super-admin" || user_role === "admin") && (
                <button
                  onClick={this.importStore}
                  className="btn-theme btn-gray mt-30"
                >
                  {i18next.t("store.import-stores", { returnObjects: true })}
                </button>
              )}

              <button
                type="button"
                onClick={() => store.dispatch(toggleStoreFormModal())}
                className="btn-theme btn-gray mt-30"
              >
                {i18next.t("store.create-store", { returnObjects: true })}
              </button>
            </span>
          )}
          {list && list.length ? (
            <span className="pull-right">
              <a href={export_file} rel="nofollow" target="_blank">
                <button
                  type="button"
                  className="btn-theme btn-gray mt-30 mr-100"
                >
                  {i18next.t("store.export-stores", { returnObjects: true })}
                </button>
              </a>
            </span>
          ) : null}
        </h2>

        <StoreListFilters />

        <div className="table-responsive">
          <StoreWorker />

          <table className="table">
            <TableHead
              fields={fields}
              params={params}
              onClick={this.toggleSort}
              noActions={!permitRole}
            />

            <tbody>
              {list && list.length
                ? list.map((l, key) => {
                    return (
                      <tr key={key}>
                        <td>{l.id}</td>
                        <td
                          className="clickable-col"
                          onClick={() =>
                            permitRole &&
                            store.dispatch(toggleStoreFormModal(l.id))
                          }
                        >
                          {l.store_name}
                        </td>
                        <td>{l.phone}</td>
                        <td>{l.email}</td>
                        <td>{l.invoice_address}</td>
                        <td>{l.chain && l.chain.chain_name}</td>
                        <td>{l.region && l.region.region_name}</td>
                        <td>{l.contact_person}</td>

                        {permitRole && (
                          <td>
                            <div className="btn-container">
                              <button
                                className="btn btn-secondary btn-sm"
                                onClick={() =>
                                  store.dispatch(toggleStoreFormModal(l.id))
                                }
                              >
                                <MyIcon type="edit" />
                              </button>

                              <button
                                type="button"
                                className="btn btn-danger btn-sm ml-1"
                                onClick={() => this.deleteConfirm(l.id)}
                              >
                                <MyIcon type="trash" />
                              </button>
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })
                : null}
            </tbody>
            {list && list.length ? (
              <tfoot>
                <PaginationButtons
                  pages={pages}
                  processing={getStores.processing}
                  fetchAction={toggleStoreListFilter}
                />
              </tfoot>
            ) : null}
          </table>

          {!this.state.initialLoading && (!list || !list.length) && (
            <NoResultsFound />
          )}
          {this.state.initialLoading && (
            <div className="text-center">
              <Loading />
            </div>
          )}
        </div>

        <ResponsiveModal
          visible={modal.mode}
          closeModal={() => store.dispatch(toggleStoreFormModal())}
        >
          <StoreForm />
        </ResponsiveModal>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  let { getStores, route, storeList, getChain } = state;
  let { user_role } = route.data;
  let { modal, fields, params } = storeList.data;
  let currentChainID = getChain.success && getChain.success.data.payload.id;

  let list = getStores.success && getStores.success.data.payload.data;

  let current_page =
    getStores.success &&
    getStores.success.data &&
    getStores.success.data.payload.current_page;
  let last_page =
    getStores.success &&
    getStores.success.data &&
    getStores.success.data.payload.last_page;
  let total =
    getStores.success &&
    getStores.success.data &&
    getStores.success.data.payload.total;
  let pages = { current_page, last_page, total };

  return {
    getStores,
    pages,
    list,
    route,
    user_role,
    modal,
    storeList,
    fields,
    params,
    getChain,
    currentChainID,
  };
}

export default withRouter(connect(mapStateToProps)(Stores));
