import { endOfURL } from "../../../helpers/UrlHelper"

const DefaultAccountFormState = {
  payload:{
    name: "",
    email: "",
    phone_no: "",
    role: "",
  },
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
  mode:endOfURL(),
  message: ""

};

export const AccountFormState = { data: DefaultAccountFormState };
