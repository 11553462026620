const DefaultChainListState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    limit: 25,
    page:   1,
    sort_order:  "desc",
    sort_by: "id",
    q: "",
  },
  modal: {
    id: "",
    mode: ""
  },
  fields: [
    { field: "id", label: "id", sort: true },
    { field: "chain_name", label: "chain-name", sort: true },
    { field: "phone", label: "phone", sort: true },
    { field: "email", label: "email", sort: true },
    { field: "invoice_address", label: "invoice-address", sort: true },
    { field: "contact_person", label: "contact-person", sort: true },
  ]
}

export const ChainListState = { data:DefaultChainListState};
