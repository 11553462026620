import React from "react";
import store from "../../../../Store";
import {withRouter} from "react-router";
import {fewerLimitOptions, limitOptions} from "../../../../static-data/LimitOptions"
import { urlParamValue } from "../../../../helpers/UrlHelper"
import { setDefaultUserFiltersToURL, toggleUserListFilter } from "./UserList-Actions"
import { fromRecord, inRecord } from "../../../../helpers/DataHelper";
import {connect} from "react-redux";
import getRoles from "../../../../ajax/actions/role/roles_get";
import SearchableSelect from "../../../shared/form/SearchableSelect";
import getChains from "../../../../ajax/actions/chains/chains_get";
import getChainRegions from "../../../../ajax/actions/chain-regions/chain_regions_get";
import getStores from "../../../../ajax/actions/stores/stores_get";
import getUsers from "../../../../ajax/actions/user/users_get";
import i18next from "i18next";

class UsersFilters extends React.Component
{
    componentDidMount()
    {
        let { chainAdminMode, chainAdminChainID } = this.props;
        store.dispatch( getRoles( 100 ));

        if ( chainAdminMode ) {
            store.dispatch( getChainRegions( 1000, chainAdminChainID ));
            store.dispatch( getStores( 1000, chainAdminChainID ) )
        }
        else {
            store.dispatch( getChains( 100 ));
        }
    }

    toggleFilter = (e) =>
    {
        store.dispatch(toggleUserListFilter( e, this.props.history));
    };

    applyFilters = (e) =>
    {
        store.dispatch( getUsers());
    };

    componentDidUpdate( lastProps )
    {
        let { chain_id, region_id, chains } = this.props;
        if ( chain_id && ( chain_id !== lastProps.chain_id )) {
            if(inRecord( chains, "id", "==", chain_id )){
                if ( fromRecord( chains, "id", "==", chain_id ).has_region ) {
                    store.dispatch(  store.dispatch( getChainRegions( 100, chain_id )));
                }
            }
            store.dispatch(  store.dispatch( getStores( 100, chain_id )));
        }
        if ( region_id && ( region_id !== lastProps.region_id )) {
            store.dispatch(  store.dispatch( getStores( 100, null, region_id )));
        }
    }

    render()
    {
        let { roles, chains, chainRegions, stores, role_id, chain_id,  region_id, store_id, limit, q, mode, chainAdminMode } = this.props;


        return (

            <div className="table-filter mb-5">
                <div className="row">
                    <div className="col custom-col">
                        <div className="form-group">

                            <SearchableSelect name="limit"
                                              noFormGroup
                                              options={ fewerLimitOptions  }
                                              _value="total"
                                              _label="label"
                                              label={i18next.t("filters.limit", {returnObjects:true})}
                                              value={ limit }
                                              notSearchable
                                              placeholder={"-- "+i18next.t("filters.limit", {returnObjects:true})+" --"}
                                              onChange={ this.toggleFilter } />

                        </div>
                    </div>

                    {/*<div className="col-lg-4 col-md-6">
                        <InfiniteDropdown options={ roles } loadMore={ getRoles } />
                    </div>*/}

                    {/*<div className="col-lg-4 col-md-6">
                        <AsyncPaginate
                            name=""
                            value={value}
                            loadOptions={getRoles}
                            onChange={onChange}
                        />
                    </div>*/}
                    {
                        !mode && (
                            <div className="col custom-col">
                                <div className="form-group">
                                    <SearchableSelect clearable
                                                      name="role_id"
                                                      noFormGroup
                                                      options={ roles }
                                                      _value="id"
                                                      _label="title"
                                                      label={i18next.t("role.role", {returnObjects:true})}
                                                      placeholder={"-- "+i18next.t("role.role", {returnObjects:true})+" --"}
                                                      value={ role_id }
                                                      onChange={ this.toggleFilter }
                                                      translate={false}
                                    />
                                </div>

                            </div>
                        )
                    }


                    <div className="col custom-col">
                        <div className="form-group">
                            <label>{i18next.t("filters.search", {returnObjects:true})}</label>
                            <input type="text"
                                   className="form-control"
                                   name="q"
                                   placeholder={i18next.t("filters.search", {returnObjects:true})}
                                   onChange={this.toggleFilter}
                                   value={q} />
                        </div>
                    </div>

                    { !chainAdminMode && (
                        (mode || chainAdminMode) ? (
                            <div className="col custom-col item-disabled">
                                <div className="form-group">
                                    <label>{i18next.t("chain.chain", {returnObjects:true})}</label>
                                    <input type="text"
                                           className="form-control"
                                           value={
                                               inRecord( chains, "id", "==", chain_id ) ?
                                                   fromRecord( chains, "id", "==", chain_id ).chain_name :  null

                                           } />
                                </div>

                            </div>
                        ) : (
                            <div className="col custom-col">
                                <SearchableSelect clearable
                                                  name="chain_id"
                                                  options={ chains }
                                                  _value="id"
                                                  _label="chain_name"
                                                  value={ chain_id }
                                                  label={i18next.t("chain.chain", {returnObjects:true})}
                                                  placeholder={"-- "+i18next.t("chain.chain", {returnObjects:true})+" --"}
                                                  onChange={ this.toggleFilter }
                                                  translate={false}
                            />
                            </div>
                        )
                    )}

                    {/*{ }*/}


                    <div className="col custom-col">
                        <SearchableSelect clearable
                                          name="region_id"
                                          options={ chainRegions }
                                          _value="id"
                                          _label="region_name"
                                          value={ region_id }
                                          label={i18next.t("region.region", {returnObjects:true})}
                                          placeholder={"-- "+i18next.t("region.region", {returnObjects:true})+" --"}
                                          onChange={ this.toggleFilter }
                                          translate={false}
                        />

                    </div>

                    <div className="col custom-col">
                        <SearchableSelect clearable
                                          name="store_id"
                                          options={ stores }
                                          _value="id"
                                          _label="store_name"
                                          value={ store_id }
                                          label={i18next.t("store.store", {returnObjects:true})}
                                          placeholder={"-- "+i18next.t("store.store", {returnObjects:true})+" --"}
                                          onChange={ this.toggleFilter }
                                          translate={false}
                        />
                    </div>
                    <div className="col custom-col">
                        <div className="text-right mt-3">
                            <button className="btn-theme btn-gray m-auto" onClick={this.applyFilters}>{i18next.t("filters.filter", {returnObjects:true})}</button>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

function mapStateToProps( state )
{
    let { userList, getRoles, getChains, getChainRegions, getStores, userDetail } = state;

    let roles = getRoles.success && getRoles.success.data.payload.data;
    let chains = getChains.success && getChains.success.data.payload.data;
    let chainRegions = getChainRegions.success && getChainRegions.success.data.payload.data;
    let stores = getStores.success && getStores.success.data.payload.data;
    let { chain_id, role_id, region_id, store_id, limit, q } = userList.data.params;
    let loggedInRole = userDetail.success && userDetail.success.role;
    let chainAdminChainID = userDetail.success && userDetail.success.entity_id;
    let chainAdminMode = loggedInRole === "chain-admin";

    return {
        userList, getRoles, getChains, getChainRegions, getStores,
        roles, chains, chainRegions, stores,
        chain_id, role_id, region_id, store_id, limit, q, userDetail, chainAdminMode, chainAdminChainID
    }
}
export default withRouter( connect( mapStateToProps )( UsersFilters ) );
