import React from "react";
import {connect} from "react-redux";
import SelectField from "../../../shared/form/SelectField";
import store from "../../../../Store";
import ImportStoresFormWorker from "./ImportStoresFormWorker";
import UploadField from "../../../shared/form/UploadField";
import {onImportStoreFormChange, onImportStoreFormSubmit} from "./ImportStoresForm-Action";
import SaveButton from "../../../shared/buttons/SaveButton";
import sampleCSV from "../../../../assets/csv/sample_stores_csv.csv"
import {isArray} from "../../../../helpers/DataHelper";
import i18next from "i18next";

class ImportStoresForm extends React.Component
{
    onChange = (e) => {
        store.dispatch(onImportStoreFormChange(e.target.name, e.target.value));
    }

    submit = (e) => {
        e.preventDefault();
        store.dispatch(onImportStoreFormSubmit());
    }

    render()
    {
        let { chain_id, chains, errors, data_file, processing, import400Errors } = this.props;
        return (
            <>
                <h2>{i18next.t("store.import-stores",{returnObjects:true})}</h2>
                { isArray( import400Errors ) && import400Errors.length ?
                    <div className="alert alert-danger" role="alert">
                        <div className="mb-1">
                            <b>{i18next.t("error.import-store", {returnObjects:true})}</b>
                        </div>
                        { import400Errors.map( err => { return (
                            <div className="mb-1"><u>Row 3</u> - { err.errors.map( er => { return (
                                <>{ er }</>
                            )}) }</div>
                        )}) }
                    </div>
                    : null }
                <form onSubmit={this.submit} id="bulk-import-store-form">
                    <SelectField name="chain_id"
                                 value={chain_id}
                                 _value="id"
                                 _label="chain_name"
                                 options={chains}
                                 label={i18next.t("chain.chain", {returnObjects:true})}
                                 placeholder={"-- "+i18next.t("chain.chain", {returnObjects:true})+" --"}
                                 errors={errors.chain_id}
                                 onChange={this.onChange} />
                    <UploadField name="data_file"
                                 value={data_file}
                                 errors={ errors.data_file }
                                 onChange={this.onChange}
                                 supportedFormats=".csv, .txt"
                                 noFileRemoval
                    />
                    <a target="_blank" href={sampleCSV}><i><u>{i18next.t("download-csv",{returnObjects:true})}</u></i></a>
                    <div className="text-center">
                        <SaveButton btnClass="text-uppercase ml-auto mt-5"
                                    text="Import"
                                    processing={processing} />
                    </div>
                </form>
                <ImportStoresFormWorker/>
            </>
        )
    }
}
function mapStateToProps(state)
{
    let { getChains, importStoresForm, importStores } = state;
    let { errors, payload, files } = importStoresForm.data;
    let { chain_id, data_file } = payload;
    let { processing, error } = importStores;
    let import400Errors =
        error &&
        error.response.data.payload.errors

    let chains =
        getChains.success &&
        getChains.success.data.payload.data;

    return { getChains, importStoresForm, chains, chain_id, data_file, files, errors, importStores, processing, error, import400Errors };
}
export default connect( mapStateToProps )( ImportStoresForm );
