import React from "react";
import store from "../../../../Store"
import {connect} from "react-redux";
import { equalWhenStringified } from "../../../../helpers/StringHelper"
import getUser from "../../../../ajax/actions/user/user_get"
import {
  setDefaultUserForm,
  setUserDataForUpdate
} from "./UserForm-Action"
import updateUser from "../../../../ajax/actions/user/user_put"
import createUser from "../../../../ajax/actions/user/user_post"
import getRoles from "../../../../ajax/actions/role/roles_get";

class UserFormWorker extends React.Component
{
  componentDidMount()
  {
    store.dispatch( getRoles( 100 ));
    let { modal } = this.props;

    if( modal.id ) {
      store.dispatch(getUser( modal.id ));
    }
    if( modal.mode ==="create") {
      store.dispatch(setDefaultUserForm());
    }

  }

  componentDidUpdate(prev)
  {
    let {  dispatchAPI, modal } = this.props;
    let { id } = modal;

    if(!equalWhenStringified(this.props.getUser, prev.getUser)) {
      let {success} = this.props.getUser;
      if(success) {
        store.dispatch(setUserDataForUpdate());
      }
    }

    if ( dispatchAPI ) {
      if ( !prev.dispatchAPI ) {
        if(id ) {
          store.dispatch(updateUser(id));
        } else {
          store.dispatch(createUser());
        }
      }
    }
  }

  render()
  {
    return null
  }
}
function mapStateToProps(state) {
  let { userForm, getUser, userList } = state;
  let { modal } = userList.data;
  let {dispatchAPI} = userForm.data;
  return { userForm, dispatchAPI, getUser, modal, userList
  };
}
export default connect( mapStateToProps )( UserFormWorker );
