import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import getUsers from "../../../../ajax/actions/user/users_get";
import store from "../../../../Store";
import getRoles from "../../../../ajax/actions/role/roles_get";
import {setChainIDMode, setDefaultUserFilters} from "./UserList-Actions";

class UsersWorker extends React.Component
{
  componentDidMount()
  {
    store.dispatch(setDefaultUserFilters());

    let { mode, chain_id } = this.props;
    if(chain_id && mode ) {
      store.dispatch(setChainIDMode(chain_id));
    } else {
      store.dispatch(getUsers());
      store.dispatch(getRoles());
    }
  }

  componentDidUpdate(lastProps)
  {
    let { deleteUser, match } = this.props;

    if(deleteUser !== lastProps.deleteUser) {
      let {success} = deleteUser;
      if(success) {
        store.dispatch(getUsers());
      }
    }

    if ( match.pathname !== lastProps.match.pathname ) {
      store.dispatch(setDefaultUserFilters());
    }
  }

  render()
  {
      return null
  }
}
function mapStateToProps(state) {
   let { deleteUser, userList }  = state;
   return { deleteUser }
 }
export default withRouter(connect(mapStateToProps)( UsersWorker ));
