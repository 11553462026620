import React from "react";
import { connect } from "react-redux";
import store from "../../../../Store";
import OrderStatusFormWorker from "./OrderStatusFormWorker";
import {
  onOrderStatusFormChange,
  onOrderStatusFormSubmit,
} from "./OrderStatusForm-Action";
import SaveButton from "../../../shared/buttons/SaveButton";
import TextField from "../../../shared/form/TextField";
import { ucFirst } from "../../../../helpers/StringHelper";
import i18next from "i18next";

class OrderStatusForm extends React.Component {
  orderStatusFormSubmit = (e) => {
    e.preventDefault();
    store.dispatch(onOrderStatusFormSubmit());
  };

  onChange = (e) => {
    store.dispatch(onOrderStatusFormChange(e.target.name, e.target.value));
  };

  render() {
    let {
      orderStatusForm,
      createOrderStatus,
      updateOrderStatus,
      modal,
      getOrderStatus,
    } = this.props;
    let { payload, errors } = orderStatusForm.data;
    let { title, slug } = payload;
    let processing =
      createOrderStatus.processing || updateOrderStatus.processing;
    return (
      <div
        className={"row " + getOrderStatus.processing ? "" : "item-disabled"}
      >
        <div className="offset-lg-1 col-lg-7 col-md-12">
          {/*<h2>{"${ucFirst( modal.mode )} Order Status"} </h2>*/}
          <h2>
            {i18next.t(
              modal.mode === "create"
                ? "order-status.create-order-status"
                : "order-status.update-order-status",
              { returnObjects: true }
            )}{" "}
          </h2>
          <form onSubmit={this.orderStatusFormSubmit}>
            <TextField
              name="title"
              value={title}
              label={i18next.t("fields.title", { returnObjects: true })}
              errors={errors.title}
              onChange={this.onChange}
            />

            <TextField
              name="slug"
              value={slug}
              label={i18next.t("fields.slug", { returnObjects: true })}
              errors={errors.title}
              onChange={this.onChange}
            />

            <div className="text-center">
              <SaveButton processing={processing} />
            </div>

            <OrderStatusFormWorker />
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    orderStatusForm,
    createOrderStatus,
    updateOrderStatus,
    getOrderStatus,
    orderStatusList,
  } = state;
  let { modal } = orderStatusList.data;
  let { mode, errors } = orderStatusForm.data;
  return {
    orderStatusForm,
    mode,
    createOrderStatus,
    updateOrderStatus,
    getOrderStatus,
    errors,
    modal,
    orderStatusList,
  };
}

export default connect(mapStateToProps)(OrderStatusForm);
