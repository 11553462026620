export function setChainDashboardData()
{
  return function (dispatch, getState) {
    let {getChain} = getState();
    let chain = getChain &&
        getChain.success &&
        getChain.success.data &&
        getChain.success.data.payload;
    dispatch({type:"SET-CHAIN-DASHBOARD-DATA", chain});
  }
}
