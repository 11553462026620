const DefaultImportStoreFormState = {
  payload:{
    chain_id: "",
    data_file: ""
  },
  files: [],
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
};

export const ImportStoresFormState = { data: DefaultImportStoreFormState };
