import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import store from '../../../../Store';
import getOrderStatuses from "../../../../ajax/actions/order-status/order_statuses_get";

class OrderStatusWorker extends React.Component
{
  componentDidMount()
  {
    store.dispatch(getOrderStatuses());
  }

  componentDidUpdate(prev) {
    let { deleteOrderStatus,} = this.props;

    if(deleteOrderStatus !== prev.deleteOrderStatus) {
      let {success} = deleteOrderStatus;
      if(success) {
        store.dispatch(getOrderStatuses());
      }
    }
  }

  render()
  {
      return null
  }
}
function mapStateToProps(state) {
   let {deleteOrderStatus }  = state;
   return { deleteOrderStatus }
 }
export default withRouter(connect(mapStateToProps)( OrderStatusWorker ));
