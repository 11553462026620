import store from "../../../../Store";
import getImportStores from "../../../../ajax/actions/stores/import_stores_get";
import importStores from "../../../../ajax/actions/stores/import_store_post";

export function toggleImportStoresListFilter(e)
{
  return function (dispatch) {
    let { name, value } = e.target;
    dispatch({type:"TOGGLE-IMPORT-STORES-LIST-FILTER", name, value});
    store.dispatch( getImportStores());
  }
}

export function toggleImportStoresFormModal(id)
{
  return function (dispatch) {
    store.dispatch( importStores("clear"))
    dispatch({type:"TOGGLE-IMPORT-STORES-FORM-MODAL", id });
  }
}
