import React from 'react';

class InfiniteDropdown extends React.Component
{
    state = {
        query: ``,
        page: 1
    };

    onQueryChange = (e) =>
    {
        let { value } = e.target;
        this.setState({ query: value })
    };

    render()
    {
        let { query } = this.state;
        return (

            <div className="infinite-dropdown">
                <input className="form-control" type="text" value={ query } onChange={this.onQueryChange} />
                <div className="results-box">
                    <ul className="results">
                        <li className="result-item">

                        </li>
                    </ul>
                </div>
            </div>

        )
    }
}
export default InfiniteDropdown;