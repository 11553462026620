import React from "react";
import newloginBg from "../../../assets/images/new-login-bg.jpg"
import logoW from "../../../assets/images/logo-white.png"
import logoB from "../../../assets/images/logo-black.png"
import {connect} from "react-redux";
import {onLoginFormChange, onLoginFormSubmit} from "./LoginForm-Actions";
import store from "../../../Store";
import TextField from "../../shared/form/TextField";
import PasswordField from "../../shared/form/PasswordField";
import LoginFormWorker from "./LoginFormWorker";
import SaveButton from "../../shared/buttons/SaveButton";
import i18next from "i18next";

class LoginForm extends React.Component
{

    login = (e) => {
        e.preventDefault();
        store.dispatch(onLoginFormSubmit());
    };

    onChange = (e) => {
        store.dispatch(onLoginFormChange(e.target.name, e.target.value));
    };

    render()
    {
        let { payload, errors } = this.props;

        const background = {
            backgroundImage: "url("+newloginBg+")"
        };

        const current_path = this.props.location.pathname;
        const account_paths = ["/se/login"];
        const login_en_path = ["/login", "/"];
        if (account_paths.includes(current_path)) {
            localStorage.setItem("lang", "sv")
        }
        if (login_en_path.includes(current_path)) {
            localStorage.setItem("lang", "en")
        }
        const lang = localStorage.getItem("lang")

        return (

            <section className={"login-page"}>
                <div className="container-fluid p-0 h-100">
                    <div className="row h-100 align-items-center no-gutters">
                        <div className="col-lg-8 h-100 d-none d-lg-block">
                            <div className="logo-block h-100">
                                <div className="image-block h-100 w-100" style={background} />
                                <div className="content-block">
                                    <img src={logoW} alt="" className="d-none d-lg-block"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="login-form">

                                <div className="login-logo d-block d-lg-none">
                                    <img src={logoB} alt="" />
                                </div>

                                <h2>{i18next.t("login.title", {returnObjects:true})}</h2>
                                {/*<p className="login-account">Login to your account here </p>*/}
                                <p className="login-account">{i18next.t("login.description", {returnObjects:true}) } </p>
                                {/*<p className="account-sign-up">Don"t have account?</p>*/}

                                <form onSubmit={this.login}>

                                    <TextField name="email"
                                               value={payload.email}
                                               errors={errors.email}
                                               label={ i18next.t("login.email", {returnObjects:true}) }
                                               onChange={this.onChange}/>

                                    <PasswordField name="password"
                                                   value={payload.password}
                                                   errors={errors.password}
                                                   placeholder={i18next.t("login.password", {returnObjects:true})}
                                                   label={
                                                       <>
                                                           {i18next.t("login.password", {returnObjects:true})}
                                                           {/*<a href="#"
                                                                       id="lnkforget"
                                                                       className="hyperlink">Forgot?</a>*/}
                                                       </>
                                                   }
                                                   onChange={this.onChange} />

                                    <div className="text-center mt-30">
                                        <SaveButton text={i18next.t("login.btn-text", {returnObjects:true})} btnClass={"m-auto"} processing={this.props.loginUser.processing}/>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <LoginFormWorker/>

            </section>

        );
    }
}
function mapStateToProps(state) {
    let { loginForm, loginUser } = state;
    let { payload, errors } = loginForm.data;
    return { loginForm, loginUser, payload, errors };
}
export default connect( mapStateToProps )( LoginForm );
