import React from "react";
import store from "../../../../Store"
import {connect} from "react-redux";
import { equalWhenStringified } from "../../../../helpers/StringHelper"
import {
  setDefaultStoreForm,
  setStoreDataForUpdate
} from "./StoreForm-Action"
import getStore from "../../../../ajax/actions/stores/store_get";
import updateStore from "../../../../ajax/actions/stores/store_put";
import createStore from "../../../../ajax/actions/stores/store_post";
import getChainRegions from "../../../../ajax/actions/chain-regions/chain_regions_get";
import getStores from "../../../../ajax/actions/stores/stores_get";
import getChains from "../../../../ajax/actions/chains/chains_get";

class StoreFormWorker extends React.Component
{
  componentDidMount()
  {
    let { modal } = this.props;
    let { id, mode } = modal;
    if( id ) {
      store.dispatch(getStore( id ));
    }
    if( mode === "create") {
      store.dispatch( setDefaultStoreForm( ));
    }
    let { chainData, entity } = this.props;
    if(chainData && chainData.has_region === 1) {
      store.dispatch( getChainRegions( 20, chainData.id ))
    }else if(entity && entity.has_region === 1) {
      store.dispatch( getChainRegions( 20, entity.id ))
    }

  }

  /*fetchStoresAfterSuccess() {
    let {  entity_id, entity_type } = this.props;
    if( entity_type === "region") {
      store.dispatch(getStores(null, null, entity_id, null));
    } else {
      store.dispatch(getStores(null, entity_id,null, null));
    }
  }*/

  componentDidUpdate(prev)
  {
    let { dispatchAPI, chainData, getChain, modal } = this.props;
    let { id } = modal;

    if(!equalWhenStringified( this.props.getStore, prev.getStore )) {
      let { success } = this.props.getStore;
      if( success ) {
        store.dispatch(setStoreDataForUpdate());
      }
    }

    if(getChain !== prev.getChain){
      let {success} = getChain;
      if(success) {
        if(chainData.has_region === 1) {
          store.dispatch(getChainRegions(20))
        }
      }
    }

    /*if(this.props.createStore !== prev.createStore){
      let {success} = createStore;
      if(success) {
        console.log(" <| success create |> ", success )
        this.fetchStoresAfterSuccess()
      }
    }

    if(this.props.updateStore !== prev.updateStore){
      let {success} = updateStore;
      if(success) {
        console.log(" <| success update |> ", success )
        this.fetchStoresAfterSuccess()
      }
    }*/

    if ( dispatchAPI ) {
      if ( !prev.dispatchAPI ) {
        if(id ) {
          store.dispatch(updateStore(id));
        } else {
          store.dispatch(createStore());
        }
      }
    }
  }

  render()
  {
    return null
  }
}
function mapStateToProps(state) {
  let { storeForm, createStore, updateStore, getStore, getChain, storeList, route, userDetail } = state;
  let { entity } = route.data;
  let entity_id = userDetail.success && userDetail.success.entity_id;
  let entity_type = userDetail.success && userDetail.success.entity_type;
  let chainData = getChain && getChain.success && getChain.success.data.payload;
  let { modal } = storeList.data;
  let { dispatchAPI } = storeForm.data;

  return { storeForm, dispatchAPI, createStore, updateStore, getStore, getChain, chainData, storeList, modal,
    entity, entity_id, entity_type
  };
}
export default connect( mapStateToProps )( StoreFormWorker );
