import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import {
    cancelChainRegionFormDispatch,
    chainRegionFormServerValidationErrors
} from "../../../components/pages/chains/chain-regions/form/ChainRegionsForm-Action";


import getChainRegions from "./chain_regions_get";

function _success(success)
{
    return { type: 'PUT_CHAIN_REGION_SUCCESS', success };
}
function _error(error)
{
    return { type: 'PUT_CHAIN_REGION_ERROR', error };
}
function _processing(processing)
{
    return { type: 'PUT_CHAIN_REGION_PROCESSING', processing };
}

function updateChainRegion( region_id )
{
    return ( dispatch, getState ) =>
    {
        dispatch(_processing(true));
        let {getChain, chainRegionsForm, route } = getState();
        let id = getChain && getChain.success && getChain.success.data.payload.id;
        let {entity, user_role} = route.data;
        if( !id && user_role === 'chain-admin' ) {
            id = entity.id
        }

        const request = axios({
            url: apiBaseUrl(`api/chains/${id}/regions/${region_id}`),
            method: "put",
            dataType: 'json',
            data: chainRegionsForm.data.payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                lang:localStorage.getItem('lang' ) || 'en'
            }
        })
            .then(function(res){
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res);
                store.dispatch(cancelChainRegionFormDispatch());
                store.dispatch(getChainRegions( id ));

            }).catch(function(error){
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError( error )

                if ( error && error.response.status === 422 ) {
                    store.dispatch(cancelChainRegionFormDispatch());
                    store.dispatch(chainRegionFormServerValidationErrors(error.response.data.message))
                }

            });

        return {
            type: 'PUT_CHAIN_REGION_SUCCESS',
            payload: request
        }
    }
}


export default updateChainRegion;