import { BulkUserUpdateFormState } from "./BulkUserUpdateForm-State"
import { errorsList, errorsPresent, mergeServerValidationErrors } from "../../../../helpers/ErrorHelper"

export default function bulkUserUpdateFormReducer(state = BulkUserUpdateFormState, action) {

  let { data } = { ...state };
  let { validationErrors, name, value, } = action
  switch (action.type) {

    case "ON-BULK-USERS-UPDATE-FORM-CHANGE":
      data.payload[name] = value;
      buildErrors();
      return { ...state, data };

    case "SET-DEFAULT-BULK-USERS-UPDATE-FORM":
      data = {
        payload:{
          data_file: ""
        },
        errors: [],
        files: [],
        errorsPresent: false,
        submitted: false,
        dispatchAPI: false,
      }
      buildErrors()
      return { ...state, data }

    case "CANCEL-BULK-USERS-UPDATE-FORM-DISPATCH":
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors();
      return {...state, data};

    case "ON-BULK-USERS-UPDATE-FORM-SUBMIT":
      data.submitted = true;
      buildErrors();
      if (!data.errorsPresent) data.dispatchAPI = true;
      return { ...state, data };

    case "BULK-USERS-UPDATE-FORM-SERVER-VALIDATION-ERRORS":
      validationErrors && Object.keys(validationErrors).map(key =>
          data.errors[key] = [{ message: " - "+validationErrors[key][0].slice(0, -1) }]
      )
      data.dispatchAPI = false;
      return { ...state, data };

    default: return state
  }

  function buildErrors() {
    data.errors = errorsList(errors);
    data.errorsPresent = errorsPresent(errors)
  }

  function errors(field) {
    let { payload, submitted, serverValidationErrors } = data;
    let errors = {};
    Object.keys(payload).map(key => errors[key] = []);

    if (submitted)
    {
      if (field === "data_file") {
        if (!_(field)) newError(field, 0, "is required")
      }
    }

    errors = mergeServerValidationErrors(errors, serverValidationErrors);

    return field ? errors[field] : errors

    function newError(field, step, message) {
      errors && errors[field].push({ step, message })
    }

    function _(field) {
      return data.payload[field]
    }
  }
}

