import axios from "axios";
import { apiBaseUrl } from "../../../helpers/UrlHelper";
import { loginToken } from "../../../helpers/LocalStorageHelper";
import { handleError } from "../../../helpers/ErrorHelper";
import { clone } from "../../../helpers/DataHelper";

function _success(success) {
    return { type: "GET_CHAINS_SUCCESS", success };
}

function _error(error) {
    return { type: "GET_CHAINS_ERROR", error };
}

function _processing(processing) {
    return { type: "GET_CHAINS_PROCESSING", processing };
}

function getChains(limit, clear) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_error(null));

        let { chainList } = getState();
        let params = clone(chainList.data.params);
        if (limit) params.limit = limit;

        if (clear) {
            dispatch(_processing(false));
            dispatch(_error(null));
            dispatch(_success(null));
        } else {
            const request = axios({
                url: apiBaseUrl(`api/chains`),
                method: "get",
                params,
                headers: {
                    Authorization: "Bearer " + loginToken(),
                    lang: localStorage.getItem("lang") || "en",
                },
            })
                .then(function (res) {
                    dispatch(_success(res));
                    dispatch(_processing(false));
                })
                .catch(function (error) {
                    dispatch(_error(error));
                    dispatch(_processing(false));
                    handleError(error);
                });

            return {
                type: "GET_CHAINS_SUCCESS",
                payload: request,
            };
        }
    };
}

export default getChains;
