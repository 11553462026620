import React from "react";
import UserWorker from "./UsersWorker";
import {connect} from "react-redux";
import store from "../../../../Store";
import swal from "sweetalert";
import deleteUser from "../../../../ajax/actions/user/user_delete";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loading from "../../../shared/loaders/Loading";
import PaginationButtons from "../../../shared/buttons/PaginationButtons";
import {
    setChainIDMode,
    toggleUserFormModal,
    toggleUserListFilter,
    toggleUserPaginationListFilter
} from "./UserList-Actions";
import UsersFilters from "./UsersFilters";
import NoResultsFound from "../../../shared/folder/NoResultsFound";
import MyIcon from "../../../shared/icons/MyIcon";
import Page from "../../../shared/pages/Page";
import UserForm from "../form/UserForm";
import ResponsiveModal from "../../../shared/modals/ResponsiveModal";
import TableHead from "../../../shared/table/TableHead";
import createExport from "../../../../ajax/actions/export/export_post";
import {apiBaseUrl} from "../../../../helpers/UrlHelper";
import {loginToken} from "../../../../helpers/LocalStorageHelper";
import i18next from "i18next";


class Users extends React.Component
{
    state = {
        initialLoading: true,
    };

    componentDidUpdate( lastProps )
    {
        let { getUsers, chain_id } = this.props;
        let { initialLoading } = this.state;
        if ( getUsers !== lastProps.getUsers && getUsers.success && initialLoading ) {
            this.setState({ initialLoading: false })
        }
        if(chain_id !== lastProps.chain_id) {
            store.dispatch(setChainIDMode(chain_id));
        }
    }

    deleteConfirm = (id) => {
        swal(i18next.t("confirm.delete-user", {returnObjects:true}), {
            buttons: {
                cancel: i18next.t("cancel",{returnObjects:true}),
                catch: {
                    text: i18next.t("delete",{returnObjects:true}),
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    };

    delete(id)
    {
        store.dispatch(deleteUser(id));
    }

    toggleSort = (field) =>
    {
        store.dispatch( toggleUserListFilter({
            target: { name: "sort_by", value: field }
        }))
    };

    render()
    {
        let { list, processing, pages, mode, chain_id, modal, fields, params } = this.props;
        let { initialLoading } = this.state;
        let bearer_token = loginToken();
        let export_file = apiBaseUrl("api/export?type=UsersExport&access_token="+bearer_token);
        if( mode === "chain" && chain_id ) {
            export_file = apiBaseUrl("api/export?type=UsersExport&access_token="+bearer_token+"&entity_id="+chain_id+"&entity_type="+mode);
        }
        return (

            <Page list title={i18next.t("user.users", {returnObjects:true})} >
                <UserWorker chain_id={chain_id} mode={mode} />
                <h2>{i18next.t("user.users", {returnObjects:true})}{" "}{processing && <Loading sm marginLeft={20}/>}
                    <span className="pull-right">
                         <button className="btn-theme btn-gray mt-30"
                                 onClick={ () => store.dispatch( toggleUserFormModal( )) }>
                                {i18next.t("user.create-user", {returnObjects:true})}
                        </button>
                    </span>
                    { list && list.length ? <span className="pull-right">
                         <a href={export_file} rel="nofollow" target="_blank">
                             <button type="button" className="btn-theme btn-gray mr-100">{i18next.t("user.export-users", {returnObjects:true})}</button>
                         </a>
                    </span> : null }
                </h2>
                <UsersFilters chain_id={chain_id} mode={mode} />
                <div className="table-dashboard table-small">
                    <div className="table-responsive">
                        <table className="table">
                            <TableHead fields={ fields }
                                       params={ params }
                                       onClick={ this.toggleSort } />
                            <tbody>
                            {list && list.length ? list.map((l, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{l.id}</td>
                                            <td className="clickable-col"
                                                onClick={() => store.dispatch( toggleUserFormModal(l.id))}>{l.name}</td>
                                            <td>{ l.role_title }</td>
                                            <td>{l.email}</td>
                                            <td>{l.phone_no}</td>
                                            <td>
                                                <button className="btn btn-secondary btn-sm"
                                                        onClick={() => store.dispatch(toggleUserFormModal(l.id))}>
                                                    <MyIcon type="edit" />
                                                </button>
                                                <button type="button"
                                                        className="btn btn-danger btn-sm ml-1"
                                                        onClick={() => this.deleteConfirm( l.id )}>
                                                    <MyIcon type="trash" />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                }) :  null
                            }
                            </tbody>


                            { list && list.length ? (
                                <tfoot>
                                <PaginationButtons pages={pages}
                                                   processing={ processing }
                                                   fetchAction={toggleUserPaginationListFilter} />
                                </tfoot>
                            ) : null }

                        </table>
                        { !initialLoading && ( !list || !list.length) && <NoResultsFound/> }
                        { initialLoading && <div className="text-center"><Loading/></div> }

                    </div>
                </div>

                <ResponsiveModal visible={ modal.mode }
                                 closeModal={ () => store.dispatch( toggleUserFormModal( )) } >
                    <UserForm />
                </ResponsiveModal>

            </Page>
        )
    }
}

function mapStateToProps(state) {
    let { getUsers, getRoles, userList } = state;

    let { processing } = getUsers;
    let { modal, fields, params } = userList.data;

    let list =
        getUsers.success &&
        getUsers.success.data.payload.data;

    let roles =
        getRoles.success &&
        getRoles.success.data.payload.data;

    let current_page = getUsers.success && getUsers.success.data && getUsers.success.data.payload.current_page;
    let last_page = getUsers.success && getUsers.success.data && getUsers.success.data.payload.last_page;
    let total = getUsers.success && getUsers.success.data && getUsers.success.data.payload.total;
    let pages = { current_page, last_page, total };

    return { getUsers, pages, list, processing, getRoles, roles, modal, userList, fields, params };
}


export default connect(mapStateToProps)(Users);
