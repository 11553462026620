export function chainFormServerValidationErrors( validationErrors ) {
  return function (dispatch) {
    dispatch({type:'CHAIN-FORM-SERVER-VALIDATION-ERRORS', validationErrors });
  }
}

export function onChainFormSubmit( ) {
  return function (dispatch) {
    dispatch({type:'ON-CHAIN-FORM-SUBMIT' });
  }
}

export function onChainFormChange(name, value) {
  return function (dispatch){
      dispatch({type:'ON-CHAIN-FORM-CHANGE', name, value});
  }
}

export function cancelChainFormDispatch() {
  return function (dispatch) {
    dispatch({ type:'CANCEL-CHAIN-FORM-DISPATCH' });
  }
}

export function setDefaultChainForm() {
  return function (dispatch){
    dispatch({type:'SET-DEFAULT-CHAIN-FORM'});
  }
}

export function setChainDataForUpdate() {
  return function (dispatch, getState) {
    let { getChain } = getState();
    let chains = getChain.success && getChain.success.data && getChain.success.data.payload;
    if(chains) {
      dispatch({type:'SET-CHAIN-DATA-FOR-UPDATE',  chains:JSON.parse(JSON.stringify(chains)) });
    }
  }
}