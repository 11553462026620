import axios from "axios";
import { apiBaseUrl } from "../../../helpers/UrlHelper";
import { loginToken } from "../../../helpers/LocalStorageHelper";
import { handleError, handleSuccess } from "../../../helpers/ErrorHelper";

function _success(success) {
  return { type: "DELETE_STORE_SUCCESS", success };
}
function _error(error) {
  return { type: "DELETE_STORE_ERROR", error };
}
function _processing(processing) {
  return { type: "DELETE_STORE_PROCESSING", processing };
}

function deleteStore(id) {
  return (dispatch) => {
    dispatch(_processing(true));
    const request = axios({
      url: apiBaseUrl(`api/stores/${id}`),
      method: "delete",
      dataType: "json",
      headers: {
        Authorization: "Bearer " + loginToken(),
        lang: localStorage.getItem("lang") || "en",
      },
    })
      .then(function (res) {
        dispatch(_success(res));
        dispatch(_processing(false));
        handleSuccess(res);
      })
      .catch(function (error) {
        dispatch(_error(error));
        dispatch(_processing(false));
        handleError(error);
      });

    return {
      type: "DELETE_STORE_SUCCESS",
      payload: request,
    };
  };
}

export default deleteStore;
