import React from "react";
import {connect} from "react-redux";
import store from "../../../../Store";
import {Link} from "react-router-dom"
import fileIcon from "../../../../assets/images/file-icon.svg";
import fileIconRed from "../../../../assets/images/file-icon-red.svg";
import longArrow from "../../../../assets/images/long-arrow.svg";
import removeIcon from "../../../../assets/images/remove-icon.svg";
import shortArrow from "../../../../assets/images/short-arrow.svg";
import swal from "sweetalert";
import {deleteFileIDSave, toggleFileFormModal,} from "./FileList-Actions";
import Loading from "../../../shared/loaders/Loading";
import Page from "../../../shared/pages/Page";
import FileWorker from "./FileWorker";
import ResponsiveModal from "../../../shared/modals/ResponsiveModal";
import UploadFileForm from "../form/UploadFileForm";
import {ucFirst, ucFirstAll} from "../../../../helpers/StringHelper";
import deleteFile from "../../../../ajax/actions/file/file_delete";
import {apiBaseUrl} from "../../../../helpers/UrlHelper";
import deleteCategory from "../../../../ajax/actions/category/category_delete";
import {setUploadFileDataForUpdate} from "../form/UploadFileForm-Action";
import getCategory from "../../../../ajax/actions/category/category_get";
import i18next from "i18next";

class Files extends React.Component {
    state = {
        initialLoading: true,
        list: [],
    }

    componentDidUpdate(lastProps) {
        let {getCategories} = this.props;
        if (getCategories) {
            if (getCategories.success && this.state.initialLoading) {
                let _list = getCategories.success && getCategories.success.data && getCategories.success.data.payload;
                this.setState({initialLoading: false, list: _list})
            }
        }
    }

    removeFile = (e, id, category_id) => {
        e.preventDefault();
        store.dispatch(deleteFileIDSave(id, category_id));
        swal(i18next.t("confirm.delete-file",{returnObjects:true}), {
            buttons: {
                cancel: i18next.t("cancel",{returnObjects:true}),
                catch: {
                    text: i18next.t("delete",{returnObjects:true}),
                    value: id
                }
            }
        }).then((value) => {
            if (value) store.dispatch(deleteFile(id))
        })
    }

    deleteConfirm = (id) => {
        swal(i18next.t("confirm.delete-category",{returnObjects:true}), {
            buttons: {
                cancel: i18next.t("cancel",{returnObjects:true}),
                catch: {
                    text: i18next.t("delete",{returnObjects:true}),
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    }

    delete(id) {
        store.dispatch(deleteCategory(id));
    }

    updateCategory = (id) => {
        store.dispatch(getCategory(id))
        // store.dispatch( setUploadFileDataForUpdate() )
        store.dispatch(toggleFileFormModal())
    }

    /*toggleSort = (field) => {
        store.dispatch(toggleRoleListFilter({
            target: {name: "sort_by", value: field}
        }))
    };*/

    render() {
        let {getCategories, fileList, file_modal,  deleteFile, file_list, user_role} = this.props;
        let {list} = this.state;
        return (
            <Page title={i18next.t("sidebar.files",{returnObjects:true})}>
                <FileWorker/>
                <div className="row">
                    <div className="offset-lg-1 col-lg-7 col-md-12">
                        <div className="collapse-block-wrapper">
                            <h2>{i18next.t("sidebar.files",{returnObjects:true})}</h2>
                            {/*<h6>BESKRIVNING</h6>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                has been the industry’s standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a type specimen book.</p>*/}
                            <div className="collapse-block">
                                {getCategories.processing ? <div className="text-center"><Loading/></div> :
                                    list && list.length ? list.map(li => {
                                        return (
                                            <div className="mb-4">{li.chain ? <h5>{li.chain.chain_name}</h5> : ""}

                                                {li.data.length ? li.data.map(l => {
                                                    return (
                                                        <div className="single-collapse single-collapse-alt" key={l.id}>
                                                            <div className="collapse-title collapsed"
                                                                 data-toggle="collapse"
                                                                 data-target={"#collapse"+l.id} aria-expanded="false"
                                                            >
                                                                <h6>
                                                                    {ucFirstAll(l.category_name)}
                                                                    <p>{ucFirst(l.sub_title)} </p>
                                                                    <span className="right-content">
                                                    <span className="on-close">
                                                        <img src={longArrow} alt="long arrow"/>
                                                    </span>
                                                    <span className="on-open">
                                                        <h6>
                                                            {i18next.t("close")}
                                                        </h6>
                                                        <img src={shortArrow} alt="short arrow"/>
                                                    </span>
                                                </span>
                                                                </h6>
                                                                {user_role === "super-admin" && (
                                                                    <div className="remove-button-wrapper">
                                                                        <a href="javascript:void(0);"
                                                                           onClick={() => this.deleteConfirm(l.id)}>
                                                                            <img src={removeIcon}
                                                                                 alt="remove category"/></a>
                                                                    </div>)}
                                                            </div>
                                                            <div className="collapse-content collapse"
                                                                 id={"collapse"+l.id}>
                                                                <div className="collapse-content-wrapper">
                                                                    <div className="content-text-block">
                                                                        <h6>{ucFirst(l.title)}</h6>
                                                                        <p>{ucFirst(l.description)}</p>

                                                                    </div>

                                                                    <table className="table">
                                                                        <tbody>
                                                                        {l.uploads && l.uploads.length ? l.uploads.map(u => {
                                                                                return (
                                                                                    <tr key={u.id}>
                                                                                        <td><img src={fileIcon}
                                                                                                 alt={u.name}/> {ucFirst(u.name)}
                                                                                        </td>
                                                                                        <td>
                                                                                            <a href={apiBaseUrl("api/admin/files/"+u.id+"/download?access_token="+localStorage.getItem("a_t"))}
                                                                                               target="_blank">{i18next.t("download")}
                                                                                            </a>
                                                                                            {user_role === "super-admin" && (
                                                                                                <div
                                                                                                    className="remove-button-wrapper">
                                                                                                    <a href="javascript:void(0);"
                                                                                                       onClick={(e) => this.removeFile(e, u.id, u.category_id)}>
                                                                                                        {deleteFile.processing && deleteFile.id === u.id ?
                                                                                                            <Loading xs
                                                                                                                     color="white"/> :
                                                                                                            <img
                                                                                                                src={removeIcon}
                                                                                                                alt="remove file"/>}
                                                                                                    </a>
                                                                                                </div>)}
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }) :
                                                                            <tr>
                                                                                <td>{ i18next.t("error.no-files",{returnObjects:true})} </td>
                                                                            </tr>}
                                                                        </tbody>
                                                                    </table>
                                                                    {user_role === "super-admin" && (
                                                                        <div className="button-wrapper">
                                                                            <button type="button"
                                                                                    onClick={() => this.updateCategory(l.id)}
                                                                                    className="btn-theme btn-black mt-10">{i18next.t("add-file",{returnObjects:true})}
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )

                                                }) : null}
                                            </div>


                                        )
                                    }) : <p>{i18next.t("error.no-data", {returnObjects:true})}</p>}
                                {user_role === "super-admin" && (<Link to={"/upload-files/create"}>
                                    <button type="button" className="btn-theme btn-black mt-20">{i18next.t("create-folder", {returnObjects:true})}</button>
                                </Link>)}
                            </div>
                        </div>

                    </div>
                </div>
                <ResponsiveModal visible={file_modal}
                                 closeModal={() => store.dispatch(toggleFileFormModal())}>
                    <UploadFileForm/>
                </ResponsiveModal>
            </Page>
        )
    }
}

function mapStateToProps(state) {
    let {getCategories, fileList, deleteFile, route} = state;
    let {user_role} = route.data;
    /*let list =
        getCategories.success &&
        getCategories.success.data.payload.data;*/


    let {file_modal, file_list} = fileList.data;
    return {getCategories, fileList, file_modal, deleteFile, file_list, route, user_role};
}


export default connect(mapStateToProps)(Files);
