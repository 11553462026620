import React from "react";
import { connect } from "react-redux";
import store from "../../../../Store";
import OrdersFormWorker from "./OrdersFormWorker";
import { onOrdersFormChange, onOrdersFormSubmit } from "./OrdersForm-Action";
import SaveButton from "../../../shared/buttons/SaveButton";
import TextField from "../../../shared/form/TextField";
import { ucFirst } from "../../../../helpers/StringHelper";
import { endOfURL } from "../../../../helpers/UrlHelper";
import TextAreaField from "../../../shared/form/TextAreaField";
import UploadField from "../../../shared/form/UploadField";
import SearchableSelect from "../../../shared/form/SearchableSelect";
import { approvalStatusData } from "../../../../static-data/SettingData";
import i18next from "i18next";

class OrdersForm extends React.Component {
  ordersFormSubmit = (e) => {
    e.preventDefault();
    store.dispatch(onOrdersFormSubmit());
  };

  onChange = (e) => {
    store.dispatch(onOrdersFormChange(e.target.name, e.target.value));
  };

  render() {
    let {
      createOrder,
      priorityArr,
      workTypes,
      projectNumber,
      chains,
      user_role,
      chainRegions,
      stores,
      mode,
      payload,
      errors,
      files,
      entities,
    } = this.props;

    let {
      description,
      worktype_id,
      priority,
      chain_id,
      region_id,
      store_id,
      approval_status,
    } = payload;
    let processing = createOrder.processing;
    return (
      <div className="row mb-3">
        <div className="offset-lg-1 col-lg-7">
          {/*<h2>{"${} Order"} </h2>*/}
          <h2>
            {i18next.t(
              mode === "create" ? "order.create-order" : "order.update-order",
              { returnObjects: true }
            )}
          </h2>
          <form onSubmit={this.ordersFormSubmit}>
            {user_role === "store-manager" && (
              <TextField
                label={i18next.t("fields.project-number", {
                  returnObjects: true,
                })}
                value={projectNumber}
                disabled
              />
            )}

            <SearchableSelect
              name="worktype_id"
              value={worktype_id}
              label={i18next.t("fields.work-type", {
                returnObjects: true,
              })}
              _value="id"
              _label="title"
              options={workTypes}
              placeholder={
                "-- " +
                i18next.t("fields.work-type", { returnObjects: true }) +
                " --"
              }
              errors={errors.worktype_id}
              onChange={this.onChange}
              translate={false}
            />

            <SearchableSelect
              name="priority"
              value={priority}
              label={i18next.t("fields.priority", {
                returnObjects: true,
              })}
              _value="id"
              _label="title"
              options={i18next.t("priorityArr", { returnObjects: true })}
              placeholder={
                "-- " +
                i18next.t("fields.priority", { returnObjects: true }) +
                " --"
              }
              errors={errors.priority}
              onChange={this.onChange}
              translate={false}
            />

            <TextAreaField
              name="description"
              value={description}
              label={i18next.t("description", {
                returnObjects: true,
              })}
              placeholder={i18next.t("order.order-description", {
                returnObjects: true,
              })}
              minRows={3}
              maxRows={6}
              errors={errors.description}
              onChange={this.onChange}
            />

            <UploadField
              onChange={this.onChange}
              name="documents"
              value={files}
              multiple
              supportedFormats=".docx, .png, .jpg, .jpeg, .webp, .pdf"
            />

            {user_role === "regional-director" && (
              <SearchableSelect
                name="approval_status"
                value={approval_status}
                label={i18next.t("fields.approval-status", {
                  returnObjects: true,
                })}
                placeholder={i18next.t("fields.approval-status", {
                  returnObjects: true,
                })}
                _value="value"
                _label="value"
                options={approvalStatusData}
                errors={errors.approval_status}
                onChange={this.onChange}
              />
            )}

            {(user_role === "admin" ||
              user_role === "super-admin" ||
              user_role === "chain-admin") && (
              <>
                <SearchableSelect
                  name="chain_id"
                  value={chain_id}
                  label={i18next.t("chain.chain", {
                    returnObjects: true,
                  })}
                  _value="id"
                  _label="chain_name"
                  options={chains}
                  placeholder={
                    "-- " +
                    i18next.t("chain.chain", { returnObjects: true }) +
                    " --"
                  }
                  errors={errors.chain_id}
                  onChange={this.onChange}
                  translate={false}
                />
                <SearchableSelect
                  name="region_id"
                  options={chainRegions}
                  _value="id"
                  _label="region_name"
                  value={region_id}
                  label={i18next.t("region.region", {
                    returnObjects: true,
                  })}
                  placeholder={
                    "-- " +
                    i18next.t("region.region", { returnObjects: true }) +
                    " --"
                  }
                  errors={errors.region_id}
                  onChange={this.onChange}
                  translate={false}
                />

                <SearchableSelect
                  name="store_id"
                  options={stores}
                  _value="id"
                  _label="store_name"
                  value={store_id}
                  label={i18next.t("store.store", {
                    returnObjects: true,
                  })}
                  placeholder={
                    "-- " +
                    i18next.t("store.store", { returnObjects: true }) +
                    " --"
                  }
                  errors={errors.store_id}
                  onChange={this.onChange}
                  translate={false}
                />
              </>
            )}

            {user_role === "chain-admin" && (
              <>
                <SearchableSelect
                  name="chain_id"
                  value={chain_id}
                  label={i18next.t("chain.chain", {
                    returnObjects: true,
                  })}
                  _value="id"
                  _label="chain_name"
                  options={chains}
                  placeholder={
                    "-- " +
                    i18next.t("chain.chain", { returnObjects: true }) +
                    " --"
                  }
                  errors={errors.chain_id}
                  onChange={this.onChange}
                  translate={false}
                />
                {chainRegions && (
                  <SearchableSelect
                    name="region_id"
                    options={chainRegions}
                    _value="id"
                    _label="region_name"
                    value={region_id}
                    label={i18next.t("region.region", {
                      returnObjects: true,
                    })}
                    placeholder={
                      "-- " +
                      i18next.t("region.region", { returnObjects: true }) +
                      " --"
                    }
                    errors={errors.region_id}
                    onChange={this.onChange}
                    translate={false}
                  />
                )}
                <SearchableSelect
                  name="store_id"
                  options={stores}
                  _value="id"
                  _label="store_name"
                  value={store_id}
                  label={i18next.t("store.store", {
                    returnObjects: true,
                  })}
                  placeholder={
                    "-- " +
                    i18next.t("store.store", { returnObjects: true }) +
                    " --"
                  }
                  errors={errors.store_id}
                  onChange={this.onChange}
                  translate={false}
                />
              </>
            )}

            {user_role === "regional-director" && (
              <SearchableSelect
                name="store_id"
                options={stores}
                _value="id"
                _label="store_name"
                value={store_id}
                label={i18next.t("store.store", {
                  returnObjects: true,
                })}
                placeholder={
                  "-- " +
                  i18next.t("store.store", {
                    returnObjects: true,
                  }) +
                  " --"
                }
                errors={errors.store_id}
                onChange={this.onChange}
                translate={false}
              />
            )}

            {user_role === "store-manager" && (
              <SearchableSelect
                name="store_id"
                options={entities}
                _value="id"
                _label="store_name"
                value={store_id}
                label={i18next.t("store.store", {
                  returnObjects: true,
                })}
                placeholder={
                  "-- " +
                  i18next.t("store.store", {
                    returnObjects: true,
                  }) +
                  " --"
                }
                errors={errors.store_id}
                onChange={this.onChange}
                translate={false}
              />
            )}
            <div className="text-center">
              <SaveButton
                btnClass="text-uppercase ml-auto mt-5"
                processing={processing}
              />
            </div>
            <OrdersFormWorker />
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let {
    ordersForm,
    createOrder,
    getWorkTypes,
    getOrderProjectNumber,
    getOrderPriority,
    userDetail,
    getChains,
    route,
    getChainRegions,
    getStores,
  } = state;

  let { user_role, entities } = route.data;
  // console.log(" <| ordersForm |> ",ordersForm )

  let workTypes = getWorkTypes.success && getWorkTypes.success.data.payload;

  let userID = userDetail && userDetail.success && userDetail.success.id;

  let chains = getChains.success && getChains.success.data.payload.data;

  let chainRegions =
    getChainRegions.success && getChainRegions.success.data.payload.data;
  let stores = getStores.success && getStores.success.data.payload.data;

  let projectNumber =
    getOrderProjectNumber.success &&
    getOrderProjectNumber.success.data.payload.project_number;

  let priorities =
    getOrderPriority.success &&
    getOrderPriority.success.data.payload.priorities;

  let priorityArr = [];

  priorities &&
    Object.keys(priorities).map((key) => {
      priorityArr.push({ id: key, title: priorities[key] });
    });
  let { mode, errors, payload, files } = ordersForm.data;
  return {
    ordersForm,
    mode,
    createOrder,
    errors,
    getWorkTypes,
    workTypes,
    getOrderProjectNumber,
    projectNumber,
    getOrderPriority,
    priorities,
    priorityArr,
    userDetail,
    userID,
    getChains,
    chains,
    route,
    user_role,
    chainRegions,
    files,
    payload,
    stores,
    entities,
  };
}

export default connect(mapStateToProps)(OrdersForm);
