import React from 'react';
import Modal from 'react-responsive-modal';

class ResponsiveModal extends React.Component
{

    render()
    {
        const { visible, closeModal, children } = this.props;
        return (
            <div>
                <Modal open={ !! visible }
                       onClose={ closeModal }
                       animationDuration={200}
                       classNames={{ overlay: `modal-overlay`, modal: `modal-content` }}
                       center >

                    { children }
                </Modal>
            </div>
        );
    }
}

export default ResponsiveModal;