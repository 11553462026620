import React from "react";
import store from "../../../../Store"
import {connect} from "react-redux";
import {withRouter} from "react-router";
import { equalWhenStringified } from "../../../../helpers/StringHelper"
import {
  setUploadFileDataForUpdate,
  setDefaultUploadFileForm
} from "./UploadFileForm-Action"
import getCategory from "../../../../ajax/actions/category/category_get";
import createCategory from "../../../../ajax/actions/category/category_post";
import {endOfURL} from "../../../../helpers/UrlHelper";
import updateCategory from "../../../../ajax/actions/category/category_put";
import getChains from "../../../../ajax/actions/chains/chains_get";

class UploadFileFormWorker extends React.Component
{
  componentDidMount()
  {
    if( this.props.match.id ) {
      store.dispatch(getCategory( this.props.id ));
    }
    if( endOfURL() === "create") {
      store.dispatch(setDefaultUploadFileForm());
    }
    store.dispatch( getChains( null, ));
  }

  componentDidUpdate(prev)
  {
    let {  dispatchAPI, match, modal, category_id } = this.props;
    let { id } = match;

    if(!equalWhenStringified(this.props.getCategory, prev.getCategory)) {
      let {success} = this.props.getCategory;
      if(success) {
        store.dispatch(setUploadFileDataForUpdate());
      }
    }

    if( this.props.createCategory !== prev.createCategory ) {
      let { success } = this.props.createCategory;
      if( success ) {
        this.props.history.push("/upload-files");
      }
    }


    if ( dispatchAPI ) {
      if ( !prev.dispatchAPI ) {
        if( category_id ) {
          store.dispatch(updateCategory(category_id));
        } else {
          store.dispatch(createCategory());
        }
      }
    }
  }

  render()
  {
    return null
  }
}
function mapStateToProps(state) {
  let { uploadFileForm, createCategory, updateCategory, getFile, getCategory } = state;
  // let { modal } = roleList.data;
  let { dispatchAPI, category_id } = uploadFileForm.data;

  return { uploadFileForm, dispatchAPI, createCategory, updateCategory, getFile, getCategory, category_id
  };
}
export default withRouter(connect( mapStateToProps )( UploadFileFormWorker ));
