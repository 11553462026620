import { RoleFormState } from "./RoleForm-State"
import { errorsList, errorsPresent, mergeServerValidationErrors } from "../../../../helpers/ErrorHelper"
import {slugify} from "../../../../helpers/StringHelper"
import { endOfURL } from "../../../../helpers/UrlHelper"

export default function roleFormReducer(state = RoleFormState, action) {

  let { data } = { ...state };
  let { validationErrors, name, value, roles } = action
  switch (action.type) {

    case "ON-ROLE-FORM-CHANGE":
      data.payload[name] = value;
      if(name === "title") {
        data.payload.slug = slugify(value);
      }
      buildErrors();
      return { ...state, data };

    case "SET-ROLE-DATA-FOR-UPDATE":
      data.payload = {
        title: roles.title,
        slug: roles.slug,
      };
      buildErrors();
      return { ...state, data };

    case "SET-DEFAULT-ROLE-FORM":
      data = {
        payload:{
          title:"",
          slug:"",
        },
        errors: [],
        errorsPresent: false,
        submitted: false,
        dispatchAPI: false,
        mode:endOfURL(),
        message: ""
      }
      buildErrors()
      return { ...state, data }

    case "CANCEL-ROLE-FORM-DISPATCH":
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors();
      return {...state, data};

    case "ON-ROLE-FORM-SUBMIT":
      data.submitted = true;
      buildErrors();
      if (!data.errorsPresent) data.dispatchAPI = true;
      return { ...state, data };

    case "ROLE-FORM-SERVER-VALIDATION-ERRORS":
      Object.keys(validationErrors).map(key =>
        data.errors[key] = [{ message: " - "+validationErrors[key][0].slice(0, -1) }]
      )
      data.dispatchAPI = false;
      return { ...state, data };

    default: return state
  }

  function buildErrors() {
    data.errors = errorsList(errors);
    data.errorsPresent = errorsPresent(errors)
  }

  function errors(field) {
    let { payload, submitted, serverValidationErrors } = data;
    let errors = {};
    Object.keys(payload).map(key => errors[key] = []);

    if (submitted) {
      if (field === "title") {
        if (!_(field)) newError(field, 0, "is required")
      }

      if (field === "slug") {
        if (!_(field)) newError(field, 0, "is required")
      }
    }

    errors = mergeServerValidationErrors(errors, serverValidationErrors);

    return field ? errors[field] : errors

    function newError(field, step, message) {
      errors && errors[field].push({ step, message })
    }

    function _(field) {
      return data.payload[field]
    }
  }
}

