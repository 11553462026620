import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import store from "../../../../Store";
import getCategories from "../../../../ajax/actions/category/categories_get";
import deleteFile from "../../../../ajax/reducers/file/file-delete";
import {deleteFileFromCategory, setCategoriesList} from "./FileList-Actions";

class FileWorker extends React.Component
{
  componentDidMount()
  {
    store.dispatch(getCategories());
  }

  componentDidUpdate(prev) {
    let { deleteCategory,deleteFile, delete_file_id, } = this.props;

    if(deleteCategory !== prev.deleteCategory) {
      let {success} = deleteCategory;
      if(success) {
        store.dispatch(getCategories());
      }
    }

    if( deleteFile !== prev.deleteFile ) {
        let {success} = deleteFile;
        if( success ) {
            store.dispatch( deleteFileFromCategory( ) );
        }
    }

    if( this.props.getCategories !== prev.getCategories ) {
        let { success } = this.props.getCategories;
        if( success ) {
            store.dispatch( setCategoriesList( ) )
        }
    }
  }

  render()
  {
      return null
  }
}
function mapStateToProps(state) {
   let {deleteCategory, deleteFile, fileList, getCategories }  = state;
    let { delete_file_id, file_list } = fileList.data;
   return { deleteCategory, deleteFile, fileList, delete_file_id, getCategories, file_list }
 }
export default withRouter(connect(mapStateToProps)( FileWorker ));
