import React from "react";
import {connect} from "react-redux"
import store from "../../../../Store";
import UserFormWorker from "./UserFormWorker"
import { onUserFormChange, onUserFormMultiValueChange, onUserFormSubmit} from "./UserForm-Action";
import SaveButton from "../../../shared/buttons/SaveButton";
import TextField from "../../../shared/form/TextField";
import PasswordField from "../../../shared/form/PasswordField";
import SearchableSelect from "../../../shared/form/SearchableSelect";
import {fromRecord, inRecord, isArray} from "../../../../helpers/DataHelper";
import getChainRegions from "../../../../ajax/actions/chain-regions/chain_regions_get";
import getStores from "../../../../ajax/actions/stores/stores_get";
import {ucFirst} from "../../../../helpers/StringHelper";
import {endOfURL, urlTrailArray} from "../../../../helpers/UrlHelper";
import Loading from "../../../shared/loaders/Loading";
import MultiSearchableSelect from "../../../shared/form/MultiSearchableSelect";
import i18next from "i18next";

class UserForm extends React.Component
{
    userFormSubmit = (e) =>
    {
        e.preventDefault();
        store.dispatch(onUserFormSubmit());
    };

    onChange = (e) => {
        store.dispatch(onUserFormChange( e.target.name, e.target.value,  ));
    };

    onMultiValueChange = (e) => {
        store.dispatch( onUserFormMultiValueChange(  e.target.name, e.target.value ) )

    }

    componentDidUpdate( lastProps )
    {
        /* when user selects different chains, region and stores, fetch necessary  */
        let { chain_id, region_id, chains, role } = this.props;
        if ( role === "regional-director" || role === "store-manager" ) {
            if ( chain_id && ( chain_id !== lastProps.chain_id )) {
                if ( chains && fromRecord( chains, "id", "==", chain_id ).has_region ) {
                    store.dispatch( getChainRegions( 100, chain_id ));
                    if ( role === "store-manager" ) {
                        store.dispatch( getStores( 100, chain_id ));
                    }
                }
            }
            /*if ( region_id && ( region_id !== lastProps.region_id )) {
                if ( role === "store-manager" ) {
                    store.dispatch( getStores( 100, null, region_id ));
                }
            }*/
        }

    }

    render() {
        let { userForm, createUser, updateUser, roles, chains, chainRegions, stores, getUser,
            loggedInRole, adminChainMode, chainAdminMode,
            name, email, password, phone_no, role, chain_id, region_id, store_id, modal
        } = this.props;
        let { errors, selected_store_options, selected_chain_options, selected_region_options } = userForm.data;
        let processing = createUser.processing || updateUser.processing;

        let _roles = null;
        if( adminChainMode || chainAdminMode ) {
            _roles = roles && roles.filter(r => r.slug === "store-manager" ||  r.slug === "regional-director")
        }
        let {mode} = modal;
        let _selected_chain_options = []; let _selected_region_options = []; let _selected_store_options = [];
        if( role === "chain-admin" ) {
            if( mode === "create" ) {
                chains && isArray( chains ) && chains.map( option => {
                    if( chain_id.includes( option.id ) ) {
                        return _selected_chain_options.push({value: option["id"], label: option["chain_name"]});
                    }
                });
            } else if( mode === "update"){
                _selected_chain_options = selected_chain_options
            }
        }

        if( role === "regional-director" ) {
            if( mode === "create" ) {
                chainRegions && isArray(chainRegions) && chainRegions.map(option => {
                    if (region_id.includes(option.id)) {
                        return _selected_region_options.push({value: option["id"], label: option["region_name"]});
                    }
                });
            }else if( mode === "update") {
                _selected_region_options = selected_region_options
            }
        }

        if( role === "store-manager" ) {
            if( mode === "create" ) {
                stores && isArray( stores ) && stores.map( option => {
                    if( store_id.includes( option.id ) ) {
                        return _selected_store_options.push({value: option["id"], label: option["store_name"]});
                    }
                });
            } else if( mode === "update") {
                _selected_store_options = selected_store_options
            }
        }

        return (

            <div className="row">
                <div className="offset-lg-1 col-lg-7 col-md-12">
                    <h2>{ i18next.t(modal.mode === "create" ? "user.create-user" : "user.update-user", {returnObjects:true})}
                        { getUser.processing && <Loading sm marginLeft={20} /> }</h2>

                    <form onSubmit={this.userFormSubmit} className={ getUser.processing ? "item-disabled" : null }>

                        <TextField name="name"
                                   value={name}
                                   label={ i18next.t("fields.name", {returnObjects:true})}
                                   errors={errors.name}
                                   onChange={this.onChange} />

                        <TextField name="email"
                                   value={email}
                                   label={ i18next.t("fields.email", {returnObjects:true})}
                                   errors={errors.email}
                                   onChange={this.onChange} />

                        <PasswordField name="password"
                                       value={password}
                                       errors={errors.password}
                                       label={ endOfURL() === "update" ? i18next.t("user.password", {returnObjects:true}) : null }
                                       placeholder={ endOfURL() === "update" ? i18next.t("user.password", {returnObjects:true}) : null }
                                       onChange={this.onChange} />


                        <TextField name="phone_no"
                                   value={phone_no}
                                   label={ i18next.t("fields.phone", {returnObjects:true})}
                                   errors={errors.phone_no}
                                   onChange={this.onChange} />

                        <SearchableSelect clearable
                                          name="role"
                                          options={ adminChainMode ?  _roles : roles }
                                          value={ role }
                                          _value="slug"
                                          _label="title"
                                          label={ i18next.t("role.role", {returnObjects:true})}
                                          errors={errors.role}
                                          placeholder={ "-- "+i18next.t("role.role", {returnObjects:true})+" --"}
                                          onChange={ this.onChange }
                                          translate={false} />

                        {( loggedInRole === "admin" || loggedInRole === "super-admin" ) && (
                            ( role === "regional-director" || role === "store-manager") && (
                                <SearchableSelect clearable
                                                  name="chain_id"
                                                  options={ chains }
                                                  _value="id"
                                                  _label="chain_name"
                                                  value={ chain_id }
                                                  label={ i18next.t("chain.chain", {returnObjects:true})}
                                                  placeholder={ "-- "+i18next.t("chain.chain", {returnObjects:true})+" --"}
                                                  errors={errors.region_id}
                                                  onChange={ this.onChange } />
                            ))}

                        {( loggedInRole === "admin" || loggedInRole === "super-admin" ) && (role === "chain-admin" &&
                            <MultiSearchableSelect name="chain_id"
                                                    options={ chains }
                                                    _value="id"
                                                    _label="chain_name"
                                                    value={ chain_id.length ?  _selected_chain_options : null }
                                                    disabled={ adminChainMode || chainAdminMode }
                                                    errors={errors.chain_id}
                                                   label={ i18next.t("chain.chain", {returnObjects:true})}
                                                   placeholder={ "-- "+i18next.t("chain.chain", {returnObjects:true})+" --"}
                                                   isMulti={true}
                                                    onChange={ this.onMultiValueChange } />)}

                        {/*{(( role === "regional-director" || role === "store-manager" ) && chain_id ) && (*/}
                        {(( role === "regional-director"  ) && chain_id ) && (
                            /*<SearchableSelect clearable
                                              name="region_id"
                                              options={ chainRegions }
                                              _value="id"
                                              _label="region_name"
                                              value={ region_id }
                                              placeholder="-- Region --"
                                              errors={errors.region_id}
                                              onChange={ this.onChange } />*/
                            <MultiSearchableSelect
                                                   name="region_id"
                                                   options={ chainRegions }
                                                   _value="id"
                                                   _label="region_name"
                                                   isMulti={true}
                                                   value={ region_id.length ? _selected_region_options : null }
                                                   errors={errors.region_id}
                                                   label={ i18next.t("region.region", {returnObjects:true})}
                                                   placeholder={ "-- "+i18next.t("region.region", {returnObjects:true})+" --"}
                                                   onChange={ this.onMultiValueChange } />
                        )}

                        { role === "store-manager" && (
                            /*<SearchableSelect clearable
                                              name="store_id"
                                              options={ stores }
                                              _value="id"
                                              placeholder="-- Store --"
                                              _label="store_name"
                                              value={ store_id }
                                              errors={errors.store_id}

                                              onChange={ this.onChange } />*/
                            <MultiSearchableSelect
                                name="store_id"
                                options={ stores }
                                _value="id"
                                _label="store_name"
                                value={ store_id.length ? _selected_store_options : null }
                                errors={errors.store_id}
                                label={ i18next.t("store.store", {returnObjects:true})}
                                placeholder={ "-- "+i18next.t("store.store", {returnObjects:true})+" --"}
                                isMulti={true}
                                onChange={ this.onMultiValueChange } />

                        )}


                        <div className="text-center">
                            <SaveButton processing={processing}/>
                        </div>

                        <UserFormWorker />

                    </form>
                </div>
            </div>

        )
    }
}

function mapStateToProps(state)
{
    let {
        userForm, createUser, updateUser, getUser, getRoles, getChains,
        getChainRegions, getStores, userDetail, userList, getChain
    } = state;

    let {  search, errors, payload } = userForm.data;
    let { modal } = userList.data;

    let roles = getRoles.success && getRoles.success.data.payload.data;
    let chains = getChains.success && getChains.success.data.payload.data;
    let chainRegions = getChainRegions.success && getChainRegions.success.data.payload.data;
    let stores = getStores.success && getStores.success.data.payload.data;

    let { name, email, password, phone_no, role, chain_id, region_id, store_id } = payload;

    let loggedInRole = userDetail.success && userDetail.success.role;
    let chainAdminChainID = userDetail.success && userDetail.success.entity_detail && userDetail.success.entity_detail.id;
    let adminChainMode =  urlTrailArray().includes("chains");
    let chainAdminMode = loggedInRole === "chain-admin";
    let adminChainChainID = getChain.success && getChain.success.data.payload.id;

    if ( chainAdminMode ) {
        chain_id = chainAdminChainID;

    } else if ( adminChainMode ) {
        chain_id = adminChainChainID
    }

    return {
        userForm, search, createUser, updateUser, getUser, getRoles, errors,
        loggedInRole, chainAdminChainID, modal, userList,
        roles, chains, chainRegions, stores,
        name, email, password, phone_no, role, chain_id, region_id, store_id,
        adminChainMode, chainAdminMode, adminChainChainID
    };
}

export default connect(mapStateToProps)(UserForm);
