import React from 'react';
import store from '../../../Store'
import {connect} from 'react-redux';
import {logInNewUser} from "../../../helpers/LocalStorageHelper";
import userDetails from "../../../ajax/actions/user/user_detail_get";
import loginUser from "../../../ajax/actions/user/user_login_post";
import {
  cancelLoginFormDispatch,
  loginFormServerValidationErrors,
  setRedirectToPageAfterLogin
} from "./LoginForm-Actions";

class LoginFormWorker extends React.Component
{
  componentDidUpdate(prev)
  {
    let {  dispatchAPI, redirectTo } = this.props;

    if(this.props.loginUser !== prev.loginUser) {
      let {success, error} = this.props.loginUser;
      if(success) {
        if ( !redirectTo ) {
          store.dispatch( setRedirectToPageAfterLogin( `/orders` ));
        }

        logInNewUser(success.data);
        store.dispatch( userDetails( success.data.access_token ));
      }
      if ( error ) {
        store.dispatch( cancelLoginFormDispatch() );
        if ( error.response && error.response.status === 422 ) {
          store.dispatch(loginFormServerValidationErrors(error.response.data && error.response.data.errors))
        }
      }
    }

    if ( dispatchAPI ) {
      if ( !prev.dispatchAPI ) {
        store.dispatch(loginUser());
      }
    }
  }

  render()
  {
    return null
  }
}
function mapStateToProps(state) {
  let { loginForm, createUser, updateUser, getUser, loginUser } = state;
  let {dispatchAPI, redirectTo } = loginForm.data;
  return { loginForm, dispatchAPI, createUser, updateUser, getUser, loginUser, redirectTo
  };
}
export default connect( mapStateToProps )( LoginFormWorker );