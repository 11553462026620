import React from "react"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { activateTab, urlTrail } from "../../../helpers/UrlHelper"
import { names } from "../../../helpers/StringHelper"
import {connect} from "react-redux";
import i18next from "i18next";

class ChainTabBar extends React.Component
{
    render()
    {
        let { match, chainData } = this.props;
        let { url } = match;
        return (

            <div className="tab-bar mb-5" id="chain-tab-bar">

                <Link to={url}
                      className={names("tab-bar-tab", url === urlTrail() && "tab-active", activateTab("info", 2))}>
                    {i18next.t("chain.chain-information",{returnObjects:true})}
                </Link>

                <Link to={url+"/stores"}
                      className={names("tab-bar-tab", activateTab("stores", 2))}>
                    {i18next.t("store.stores",{returnObjects:true})}
                </Link>

                <Link to={url+"/orders"}
                      className={names("tab-bar-tab", activateTab("orders", 2))}>
                    {i18next.t("order.orders",{returnObjects:true})}
                </Link>

                <Link to={url+"/users"}
                      className={names("tab-bar-tab", activateTab("users", 2))}>
                    {i18next.t("user.users",{returnObjects:true})}
                </Link>

                {
                    chainData.has_region ? (
                        <Link to={url+"/regions"}
                              className={names("tab-bar-tab", activateTab("regions", 2))}>
                            {i18next.t("region.regions",{returnObjects:true})}
                        </Link>
                    ) : null
                }

            </div>

        )
    }
}

function mapStateToProps(state) {

    let { getChain} = state;

    let chainData = getChain &&
        getChain.success &&
        getChain.success.data.payload;

    return {getChain, chainData};
}

export default withRouter(connect(mapStateToProps)(ChainTabBar));
